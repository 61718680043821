import type { Directive } from 'vue';
import router from '@/router';
import { FnAgrInter, RuleFunI } from './types/type.d';
// 业务规则函数-------------------
// 节流 arg.binding.value 为节流时间
export function throttle(arg: FnAgrInter) {
    arg.el.classList.add('is-disabled');
    // @ts-ignore
    arg.el.disabled = true;
    setTimeout(() => {
        // @ts-ignore
        arg.el.disabled = false;
        arg.el.classList.remove('is-disabled');
    }, arg.binding?.value || 1000);// 设置的是2000毫秒也就是2秒
}

// 规则函数汇总对象
const ruleFun: RuleFunI = {
    throttle
};

// 执行规则函数,根据指令传值进行执行
function runRules(el: HTMLButtonElement, binding: any) {
    const modifiers = Object.keys(binding.modifiers);
    modifiers.forEach((m) => {
        if (m in ruleFun) {
            ruleFun[m]({ el, binding });
        }
    });
}
// 表单事件监听函数，给生命周期调用
const fn = (el: HTMLButtonElement, binding: any) => {
    if (binding.modifiers) {
        // @ts-ignore
        el[binding.arg || 'onclick'] = () => {
            runRules(el, binding);
        };
    }
};

const e: Directive = {
    mounted: fn
};
export default e;
