import { createStore } from 'vuex';
import { systemConfig } from '@/data/';

export default createStore({
    state: {
        enableSmartHome: false
    },
    mutations: {
        setEnableSmartHomeOption(state, enable) {
            state.enableSmartHome = enable;
        }
    },
    actions: {
        getEnableSmartHomeOption(context, callback) {
            systemConfig.getSysConfig((res) => {
                context.commit('setEnableSmartHomeOption', res.EnableSmartHome);

                if (typeof callback === 'function') {
                    callback();
                }
            });
        }
    },
    modules: {
    }
});
