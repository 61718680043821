import { RouteRecordRaw } from 'vue-router';

const installerBaseRouter = 'installerIndex';

const installerSubRouter = {
    home: 'communityhome',
    device: 'device',
    firmWare: 'singlefirmware',
    library: 'singlemaclibrary',
    message: 'message',
    payments: 'singlepayments',
    setting: 'setting',
    subscriptions: 'singlesubscriptions',
    update: 'singleupdate',
    user: 'user',
    userdevice: 'userdevice',
    devicedetail: 'devicedetail',
    familymember: 'familymember',
    paymentinfo: 'singlepaymentinfo'
};

const base = () => import('@/views/installer/base.vue');
// 仪表盘
const communityHome = () => import('@/views/community/home/index.vue');
// 个人相关
const singleDevice = () => import('@/views/installer/device/index.vue');
const singleFirmware = () => import('@/views/installer/firmware/index.vue');
const singleMessage = () => import('@/views/installer/message/index.vue');
const singlePayments = () => import('@/views/installer/payments/index.vue');
const singleSubscriptions = () => import('@/views/installer/subscriptions/index.vue');
const singleSetting = () => import('@/views/installer/setting/index.vue');
const singleUpdate = () => import('@/views/installer/update/index.vue');
const singleUser = () => import('@/views/installer/user/index.vue');
const singleLibrary = () => import('@/views/installer/library/index.vue');
const singleUserDevice = () => import('@/views/installer/user/components/device.vue');
const singleUserDeviceDetail = () => import('@/views/installer/user/components/intercom-device-info.vue');
const singleFamilyMember = () => import('@/views/installer/user/familyMember.vue');
const singlePaymentInfo = () => import('@/views/installer/payments/info.vue');

// 批量相关
const installerBatchRouter = {
    batchSubscriptions: 'batchSubscriptions',
    batchPayments: 'batchPayments',
    batchPaymentInfo: 'batchPaymentInfo'
};
const batchSubscriptions = () => import('@/views/installer/batch-subscriptions/index.vue');
const batchPayments = () => import('@/components/view/installer/batch-payments/index.vue');
const batchPaymentInfo = () => import('@/components/view/installer/batch-payments/info.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: `/${installerBaseRouter}`,
        component: base,
        name: installerBaseRouter,
        redirect: `/${installerBaseRouter}/${installerSubRouter.user}`,
        children: [
            {
                path: installerSubRouter.device,
                name: `${installerBaseRouter}_${installerSubRouter.device}`,
                component: singleDevice,
                props: (route) => ({
                    type: route.query.type,
                    deviceType: route.query.deviceType
                })
            }, {
                path: installerSubRouter.firmWare,
                name: `${installerBaseRouter}_${installerSubRouter.firmWare}`,
                component: singleFirmware
            }, {
                path: installerSubRouter.message,
                name: `${installerBaseRouter}_${installerSubRouter.message}`,
                component: singleMessage
            }, {
                path: installerSubRouter.payments,
                name: `${installerBaseRouter}_${installerSubRouter.payments}`,
                component: singlePayments
            }, {
                path: installerSubRouter.subscriptions,
                name: `${installerBaseRouter}_${installerSubRouter.subscriptions}`,
                component: singleSubscriptions
            }, {
                path: installerSubRouter.setting,
                name: `${installerBaseRouter}_${installerSubRouter.setting}`,
                component: singleSetting
            }, {
                path: installerSubRouter.update,
                name: `${installerBaseRouter}_${installerSubRouter.update}`,
                component: singleUpdate
            }, {
                path: installerSubRouter.user,
                name: `${installerBaseRouter}_${installerSubRouter.user}`,
                component: singleUser
            }, {
                path: installerSubRouter.library,
                name: `${installerBaseRouter}_${installerSubRouter.library}`,
                component: singleLibrary
            }, {
                path: installerSubRouter.userdevice,
                name: `${installerBaseRouter}_${installerSubRouter.userdevice}`,
                component: singleUserDevice,
                props: (route) => ({
                    account: route.query.account,
                    familySip: route.query.familySip,
                    premiumPlan: route.query.premiumPlan,
                    type: route.query.type,
                    deviceType: route.query.deviceType,
                    personalAccountUUID: route.query.personalAccountUUID,
                    owner: route.query.owner
                })
            }, {
                path: installerSubRouter.devicedetail,
                name: `${installerBaseRouter}_${installerSubRouter.devicedetail}`,
                component: singleUserDeviceDetail
            }, {
                path: installerSubRouter.familymember,
                name: `${installerBaseRouter}_${installerSubRouter.familymember}`,
                component: singleFamilyMember,
                props: (route) => ({ id: route.query.id })
            }, {
                path: installerSubRouter.paymentinfo,
                name: `${installerBaseRouter}_${installerSubRouter.paymentinfo}`,
                component: singlePaymentInfo,
                props: (route) => ({ id: route.query.id })
            }, {
                path: installerSubRouter.home,
                name: `${installerBaseRouter}_${installerSubRouter.home}`,
                component: communityHome,
                props: (route) => ({ type: route.query.type })
            }, {
                path: installerBatchRouter.batchSubscriptions,
                name: `${installerBaseRouter}_${installerBatchRouter.batchSubscriptions}`,
                component: batchSubscriptions
            }, {
                path: installerBatchRouter.batchPayments,
                name: `${installerBaseRouter}_${installerBatchRouter.batchPayments}`,
                component: batchPayments,
                props: (route) => ({
                    type: route.query.type,
                    id: route.query.id
                })
            }, {
                path: installerBatchRouter.batchPaymentInfo,
                name: `${installerBaseRouter}_${installerBatchRouter.batchPaymentInfo}`,
                component: batchPaymentInfo,
                props: (route) => ({ id: route.query.id, ProjectType: route.query.ProjectType })
            }
        ]
    }
];

export default routes;
export {
    installerBaseRouter,
    installerSubRouter,
    installerBatchRouter
};
