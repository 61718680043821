import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import '@/assets/icon/iconfont.css';
import '@/assets/less/cover-element.less';
import '@/assets/less/normalize.less';
import globalComponent from '@/components/global';
import dialogInstall from '@/util/dialog-render';
import setGlobDirectives from '@/directives';
import locale from './data/el-lang';
import App from './index.vue';
import router from './router';
import store from './store';
import baseNotice from './util/notice.base';
import '@/assets/svg';
import { updateVersionHandler } from './util/system';
// eslint-disable-next-line no-extend-native
String.prototype.format = function (...restOfParams: string[]) {
    if (restOfParams.length === 0) return this.toString();
    const param = restOfParams[0];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let s = this;
    if (typeof (param) === 'object') {
        Object.keys(param).forEach((key) => {
            s = s.replace(new RegExp(`\\{${key}\\}`, 'g'), param[key]);
        });
        return s.toString();
    }
    for (let i = 0; i < arguments.length; i += 1) s = s.replace(new RegExp(`\\{${i}\\}`, 'g'), restOfParams[i]);
    return s.toString();
};

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $noticeTip: typeof baseNotice;
        $wordList: WordList;
        $formLabelPosition: string;
    }
}

const app = createApp(App);
window.app = app;
app.config.globalProperties.$noticeTip = baseNotice;
app.config.globalProperties.$wordList = WordList;
app.config.globalProperties.$formLabelPosition = 'top';
app.use(dialogInstall);
app.use(store)
    .use(router)
    .use(ElementPlus, { locale })
    .use(globalComponent)
    .mount('#app');

window.crossPlatformConfig = {
    platform: window.crossPlatformConfigInfo ? window.crossPlatformConfigInfo.platform : 'browser'
};

// 指令
const buttonDirective = {
    mounted: (el: HTMLButtonElement, binding: any) => {
        if (binding.modifiers) {
            el.addEventListener(binding.arg || 'click', () => {
                const modifiers = Object.keys(binding.modifiers);
                modifiers.forEach((m) => {
                    if (m === 'pushQueryUrl') {
                        const hash = window.location.hash as string;
                        const path = hash.match(/#(\S*)?/)![1].split('?');
                        router.push({ path: path[0], query: binding!.value });
                    }
                });
            });
        }
    }
};
updateVersionHandler();
setGlobDirectives(app);
app.directive('button', buttonDirective);
