import {
    Events, EventBusType, EventType, CallbackFn
} from './types/eventbus';

class UseEventBus implements EventBusType {
    events: Events = {}

    /**
     * 订阅事件 eventBus.on('event',callbackFn)
     * @date 2022-06-23
     * @param {EventType} event:事件名
     * @param {CallbackFn} callbackFn:事件回调函数
     * @returns {void}
     */
    on(event: EventType, callbackFn: CallbackFn) {
        if (this.events[event]) {
            this.events[event].push(callbackFn);
        } else {
            this.events[event] = [];
            this.events[event].push(callbackFn);
        }
    }

    once(event: EventType, callbackFn: CallbackFn) {
        const callback = () => {
            callbackFn();
            this.off(event, callback);
        };
        this.on(event, callback);
    }

    /**
     * 发布事件： eventBus.emit('event',params)
     * @date 2022-06-23
     * @param {EventType} event:事件名
     * @param {any} params:给事件回调的参数
     * @returns {void}
     */
    emit(event: EventType, params?: any) {
        if (this.events[event]) {
            // const count = this.events[event].length;
            const copyEvents = [...this.events[event]];
            // for (let i = 0; i < count; i += 1) {
            //     copyEvents[i](params);
            // }
            copyEvents.forEach((fn) => {
                // @ts-ignore
                fn(params);
            });
        }
    }

    /**
     * 移除监听事件： eventBus.off('event',callbackFn)；
     * @date 2022-06-23
     * @param {EventType} event:事件名
     * @param {CallbackFn} callbackFn:事件回调函数：传callbackFn则移除此callbackFn，不然此事件名下所有监听事件移除
     * @returns {void}
     */
    off(event: EventType, callbackFn?: CallbackFn) {
        const callbacks = this.events[event];
        if (callbacks && callbackFn) {
            const index = callbacks.indexOf(callbackFn);
            if (index > -1) {
                callbacks.splice(index, 1);
            }
        } else if (callbacks && !callbackFn) {
            callbacks.length = 0;
        }
    }

    /**
     * 一次出发多个事件：eventBus.emits(['event1','event2'],[event1Params,event2Params])
     * @date 2022-06-23
     * @param {EventType[]} eventList:事件名数组
     * @param {any[]} paramsList?:事件回调函数的参数
     * @returns {any}
     */
    emits(eventList: EventType[], paramsList?: any[]) {
        eventList.forEach((event: EventType, index: number) => {
            if (paramsList) {
                this.emit(event, paramsList[index]);
            } else {
                this.emit(event);
            }
        });
    }

    /**
     * 所有事件触发后执行 eventBus.all(['event1','event2'],[event1Params,event2Params])
     * @date 2022-06-23
     * @param {EventType[]} eventList:事件名数组
     * @param {CallbackFn} allCallbackFn:所有事件触发后执行的回调函数
     * @param {any[]} paramsList?:给监听事件的回调参数
     * @returns {void}
     */
    all(eventList: EventType[], allCallbackFn: CallbackFn, paramsList?: any[]) {
        // this.emits(eventList, paramsList);
        // allCallbackFn();
    }
}

function useEventBus() {
    return new UseEventBus();
}

// eslint-disable-next-line import/prefer-default-export
export { useEventBus };