
import {
    computed, defineComponent, reactive, ref,
    watch
} from 'vue';
import { user } from '@/methods/rule';
import './password.less';

export default defineComponent({
    name: 'password-input',
    props: {
        modelValue: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const rules = reactive([{
            vaild: false,
            content: WordList.RulePasswordLLetter,
            name: 'lLetter'
        }, {
            vaild: false,
            content: WordList.RulePasswordULetter,
            name: 'uLetter'
        }, {
            vaild: false,
            content: WordList.RulePasswordDLetter,
            name: 'dLetter'
        }, {
            vaild: false,
            content: WordList.RulePasswordTLetter,
            name: 'tLetter'
        }]);

        const value = ref('');
        watch(computed(() => props.modelValue), () => {
            value.value = props.modelValue;
        }, {
            immediate: true
        });
        watch(value, () => {
            emit('update:modelValue', value.value);
            const {
                ruleLow, ruleUp, ruleNumber, ruleSpecial
            } = user.checkPasswordComplexity(value.value);
            rules[0].vaild = ruleLow;
            rules[1].vaild = ruleUp;
            rules[2].vaild = ruleNumber;
            rules[3].vaild = ruleSpecial;
        });

        const isShowRule = ref(false);
        return {
            value,
            rules,
            isShowRule
        };
    }
});
