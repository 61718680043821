
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'dialog-shell',
    emits: ['submit', 'success', 'close'],
    props: {
        title: {
            type: String,
            required: true
        },
        width: {
            type: String,
            default: '600px'
        },
        footerType: {
            type: String as PropType<'default' | 'customize'>,
            default: 'default'
        },
        top: {
            type: String,
            default: '15vh'
        }
    },
    setup() {
        return {
            dialogVisible: true
        };
    }
});
