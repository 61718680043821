import { community } from '@/data';
import GetRequest from '../basic';

export default null;

const repeatLabels = [
    WordList.ProperAllTextNever,
    WordList.ProperAllTextDaily,
    WordList.ProperAllTextWeekly
];

const never = '0';
const daily = '1';
const weekly = '2';
const neverEachCounts = '3';
const comEndRole = '20';
const statusOn = 1;
const statusOff = 0;
const exportAll = '0';
const exportExcel = '1';

// 身份区分
const resident = '0';
const delivery = '1';
const staff = '2';

const weekList = [
    WordList.ProperAllTextSunday,
    WordList.ProperAllTextMonday,
    WordList.ProperAllTextTuesday,
    WordList.ProperAllTextWednesday,
    WordList.ProperAllTextThursday,
    WordList.ProperAllTextFriday,
    WordList.ProperAllTextSaturday
];

const getPropertyAccessInfo = (id: string, callBack: Function) => {
    GetRequest(`v3/web/${community.ProjectType.value}/access/getInfo`, { ID: id }, callBack);
};

const getPropertyAllUserAccess = (id: string, callBack: Function) => {
    GetRequest(`v3/web/${community.ProjectType.value}/access/getUserAllAccess`, { ID: id }, callBack);
};

export {
    repeatLabels,
    never,
    daily,
    weekly,
    neverEachCounts,
    resident,
    delivery,
    staff,
    comEndRole,
    statusOn,
    statusOff,
    weekList,
    getPropertyAccessInfo,
    getPropertyAllUserAccess,
    exportAll,
    exportExcel
};
