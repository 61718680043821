/*
  一、表单限制需求
  1.非负 的整数
  2.非负 不为0的整数
  3.非负 的浮点数
  4.非负 不为0 的浮点数
  5.非负 不为0 的浮点数，保留两位小数
  6.不能输入中文
  二、指令功能：
    1.在表单输入的时候监听默认input，也可以传值更改为其他表单事件，边输入/其他事件触发后后校验，去除不符合限制的字符
    2.可以使用v-input.x.y 实现组合调用；也规则函数间相互调用，但是不提供是否符合规则的判断功能
    3.不使用v-input.x.y组合调用，可以在文件中加入规则函数。
  1.
  三、设计指令使用方式:
    1、按照指令功能进行划分指令命名和指令文件名
    2、内置常见表单限制规则函数，可根据修饰符 单次使用和 并集组合使用，规则函数也可以导出单独使用
    3、修饰符指定 表单限制效果，多个修饰符则依次校验效果
    4、传递给指令的值 作为修饰符对应规则函数
    5、使用传递给指令的参数 作为 表单事件触发方式，默认input
*/
import type { Directive } from 'vue';
import { FnAgrInter, RuleFunI } from './types/type.d';
// 获取el-input的input和value
function getElInputValue(el: HTMLElement) {
    const elInputChildren = el.children;
    const input = elInputChildren[0] as HTMLInputElement;
    const { value } = input;
    return { input, value };
}

// 常见规则---------
// 限制长度(包含整数和小数点的最大长度)
export function numLength(arg: FnAgrInter) {
    const { input } = getElInputValue(arg.el);
    if (input.value.toString().indexOf('.') !== -1) {
        input.value = input.value.slice(0, arg.binding!.value + 1);
    } else {
        input.value = input.value.slice(0, arg.binding!.value);
    }
}
// 限制长度（为整数的时候，整数长度为限制的总长度-小数长度，为浮点数的时候最大长度为限制的总长度）
export function numExcludeLength(arg: FnAgrInter) {
    const { input } = getElInputValue(arg.el);
    if (input.value.toString().indexOf('.') !== -1) {
        input.value = input.value.slice(0, arg.binding!.value + 1);
    } else {
        input.value = input.value.slice(0, arg.binding!.value - 2);
    }
}
// 小数或整数(不可以负数)
function decimalAndInteger(value: string) {
    let v = value.replace(/(^\s*)|(\s*$)/g, '');
    // 只能是数字和小数点，不能是其他输入
    v = v.replace(/[^\d.]/g, '');
    // 以0开始只能输入一个
    v = v.replace(/^0{2}$/g, '0');
    // 保证第一位只能是数字，不能是点
    v = v.replace(/^\./g, '');
    // 小数只能出现1位
    v = v.replace('.', '$#$').replace(/\./g, '')
        .replace('$#$', '.');
    // 小数点后面保留2位
    v = v.replace(/^(\.-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    return v;
}
// 小数或整数(不可以负数)
export function numIntegerAndFloat(arg: FnAgrInter) {
    const { input, value } = getElInputValue(arg.el);
    const v = decimalAndInteger(value);
    // 匹配空格
    input.value = v;
}

// 业务规则函数-------------------
// 验证百分比（可以小数）
export function percentageFloat(arg: FnAgrInter) {
    const { input, value } = getElInputValue(arg.el);
    const maxVal: number = arg.binding!.value ? arg.binding!.value : 100;
    let v = decimalAndInteger(value);
    // 数字超过100，赋值成最大值100
    v = v!.replace(/^[1-9]\d\d{1,3}$/, `${maxVal}`);
    // 超过100之后不给再输入值
    v = v.replace(/^100\.$/, '100');

    input.value = v;
}
// 去掉中文
export function removeZh(arg: FnAgrInter) {
    const { input, value } = getElInputValue(arg.el);
    input.value = value.replace(/[\u4E00-\u9FA5]+/, '');
}

// 小写字母转大写字母
export function upperCase(arg: FnAgrInter) {
    const input = (arg.el!.children[0].children[0]) as HTMLInputElement;
    const { value } = input;
    input.value = value.toUpperCase();
}

// 规则函数汇总对象
const ruleFn: RuleFunI = {
    numIntegerAndFloat,
    percentageFloat,
    removeZh,
    numLength,
    numExcludeLength,
    upperCase
};

// 执行规则函数,根据指令传值进行执行
function runRules(el: HTMLInputElement, binding: any) {
    const modifiers = Object.keys(binding.modifiers);
    modifiers.forEach((m) => {
        if (m in ruleFn) {
            // setTimeout为了兼容火狐
            setTimeout(() => {
                ruleFn[m]({ el, binding });
            });
        }
    });
}
// 表单事件监听函数，给生命周期调用
const updatedFn = (el: HTMLInputElement, binding: any) => {
    if (!el.classList.contains('el-input')) {
        console.log('v-input只支持el-input，不支持普通input');
        return;
    }
    if (binding.modifiers) {
        el.addEventListener(binding.arg || 'input', () => {
            runRules(el, binding);
        });
    }
};

const unListen = (el: HTMLInputElement, binding: any) => {
    if (!el.classList.contains('el-input')) {
        console.log('v-input只支持el-input，不支持普通input');
        return;
    }
    if (binding.modifiers) {
        el.removeEventListener(binding.arg || 'input', () => {
            runRules(el, binding);
        });
    }
};

const directive: Directive = {
    updated: updatedFn,
    unmounted: unListen
};
export default directive;
