import HttpRequest from '@/util/axios.config';

// 请求结果
interface DataRequestResult {
    msg: string;
    code: number;
    data: Array<object> | object;
}

export default function GetRequest(url: string, params: object, callback: Function) {
    HttpRequest.get(url, params, (data: DataRequestResult) => {
        callback(data.data);
    });
}