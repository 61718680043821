import HttpRequest from '@/util/axios.config';
import { ref } from 'vue';

const InstallerIdentity = '4';
const PerSubRole = '11';
const Community = '0';
const Office = '1';
const Villa = '2';

const ChargeMode = ref(0);
const getDisChargeMode = (callback: Function) => {
    HttpRequest.get('v3/web/common/manage/getDisChargeMode', {}, callback);
};
const getPersonalUserInfo = (id: string, callback: Function) => {
    HttpRequest.get('getpersonaluserinfo', {
        ID: id
    }, callback);
};

export {
    ChargeMode,
    InstallerIdentity,
    PerSubRole,
    Community,
    Office,
    Villa,
    getDisChargeMode,
    getPersonalUserInfo
};