
import {
    computed, watch, Ref, nextTick,
    defineComponent, provide, reactive, ref, toRef, PropType
} from 'vue';
import {
    multipleSelectKey, MultipleSelectValue,
    selectKey, SelectValue, findParentNode,
    multiple, multipleFilterValue
} from './util/data';

export default defineComponent({
    emits: ['update:modelValue', 'change'],
    name: 'cus-select',
    props: {
        modelValue: {
            type: [Number, String, Symbol, Array] as PropType<number | string | symbol | Array<string | number | symbol>>,
            required: true
        },
        width: {
            type: String,
            default: '150px'
        },
        filter: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        },
        isShowFilterInputProp: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit, slots }) {
        const select: Ref<any> = ref(null);
        const options: Ref< null | HTMLElement > = ref(null);
        const isExpand = ref(false);
        const isShowFilterInput = ref(props.isShowFilterInputProp);
        const filterInput: Ref<any> = ref(null);
        const selectedValue = reactive({
            selected: props.modelValue,
            match: !modelValueIsEmpty(props.modelValue),
            label: props.modelValue,
            filterValue: '',
            change: 0
        });
        const multipleSelectedValue: MultipleSelectValue[] = reactive([]);
        const multipleFilter = ref('');
        const changed = toRef(selectedValue, 'change');

        watch(selectedValue, () => {
            emit('update:modelValue', selectedValue.selected);
        });
        watch(multipleSelectedValue, (selectedArray: MultipleSelectValue[]) => {
            const selectKeys: Array<string | number | symbol> = [];
            selectedArray.forEach((selectedItem) => {
                selectKeys.push(selectedItem.selectValue);
            });
            emit('update:modelValue', selectKeys);
            emit('change', selectKeys);
        });

        watch(changed, () => {
            emit('change', selectedValue.selected);
        });

        watch(computed(() => props.modelValue), () => {
            if (multiple && Array.isArray(props.modelValue)) {
                const key: (string|number|symbol)[] = [];
                // 是否不相等
                let flag = false;
                for (let i = 0; i < multipleSelectedValue.length; i += 1) {
                    if (!props.modelValue.includes(multipleSelectedValue[i].selectValue)) {
                        flag = true;
                        break;
                    }
                    key.push(multipleSelectedValue[i].selectValue);
                }

                if (!flag) {
                    for (let i = 0; i < props.modelValue.length; i += 1) {
                        if (!key.includes(props.modelValue[i])) {
                            flag = true;
                            break;
                        }
                    }
                }

                if (!flag) {
                    return;
                }

                multipleSelectedValue.length = 0;
                props.modelValue.forEach((item) => {
                    multipleSelectedValue.push({
                        selectValue: item,
                        label: '',
                        choose: true
                    });
                });
            } else {
                selectedValue.selected = props.modelValue;
                selectedValue.match = false;
            }
        }, { immediate: true });

        watch(isExpand, () => {
            if (isExpand.value === false && !props.multiple) isShowFilterInput.value = false;
        });

        provide(selectKey, selectedValue);
        provide(multipleSelectKey, multipleSelectedValue);
        provide(multiple, props.multiple);
        provide(multipleFilterValue, multipleFilter);

        const blurHandler = () => {
            isExpand.value = false;
        };

        // multiple情况下, 点击选择项或者删除选择项，控制选择框一直显示
        const showOptions = ref(false);
        const clickHandler = () => {
            if (props.disabled === false) {
                // modify by lxf，多选情况下，数据全选时不显示下方选项框----2022.3.28
                if (props.multiple) {
                    if (options.value?.children.length === 0) {
                        isExpand.value = false;
                        isShowFilterInput.value = false;
                    } else {
                        if (props.filter) {
                            filterInput.value.focus();
                            isShowFilterInput.value = true;
                        }
                        isExpand.value = showOptions.value ? true : !isExpand.value;
                        showOptions.value = false;
                    }
                    // 动态计算输入框高度
                    if (options.value) {
                        options.value.style.top = `${select.value.clientHeight + 2}px`;
                    }
                } else {
                    isExpand.value = !isExpand.value;
                }
            }
        };

        const setFilter = (event: PointerEvent) => {
            if (event.target === filterInput.value) {
                showOptions.value = true;
            }
            if (props.filter !== undefined) {
                isShowFilterInput.value = true;
                selectedValue.filterValue = '';
                nextTick(() => {
                    filterInput.value.focus();
                });
            }
        };

        // 最外层或者input失去焦点，关闭下拉选项
        const inputBlur = () => {
            setTimeout(() => {
                if (document.activeElement && !findParentNode(document.activeElement, select.value)) {
                    blurHandler();
                }
            });
        };

        /* eslint-disable no-param-reassign */
        // 监听鼠标移入移出，控制关闭按钮动态显示
        const changeStateToHoverClose = (event: MouseEvent) => {
            if (event.target) {
                event.target.className = 'el-icon-my-multiple-select-close-hover';
            }
        };
        const changeStateToNormalClose = (event: MouseEvent) => {
            if (event.target) {
                event.target.className = 'el-icon-my-multiple-select-close';
            }
        };
        // 点击item上的关闭按钮，取消该项的选择
        const cancelSelectedValue = (unselectedValue: string | number | symbol) => {
            showOptions.value = true;
            multipleSelectedValue.forEach((selectedItem, selectedIndex) => {
                if (selectedItem.selectValue === unselectedValue) {
                    multipleSelectedValue.splice(selectedIndex, 1);
                }
            });
        };
        // multiple情况下, 记录点击选择项情况
        const clickOptions = () => {
            if (props.multiple) {
                showOptions.value = true;
            }
        };

        function modelValueIsEmpty(value: number | string | symbol | (string | number | symbol)[]) {
            return value === '' || (Array.isArray(value) && value.length === 0);
        }

        return {
            select,
            selectedValue,
            isExpand,
            clickHandler,
            blurHandler,
            isShowFilterInput,
            filterInput,
            setFilter,
            inputBlur,
            multipleSelectedValue,
            changeStateToHoverClose,
            changeStateToNormalClose,
            cancelSelectedValue,
            options,
            clickOptions,
            multipleFilter,
            modelValueIsEmpty
        };
    }
});
