import { GetDifferenceData } from '@/components/view/installer/community/change-plan-dialog/all.type';
import GetRequest from '../basic';

const getFeaturePlan = (callback: Function) => {
    GetRequest('v3/web/community/featurePlan/getFeaturePlan', { Type: 0 }, callback);
};
const getAllFeaturePlanFee = (callback: Function) => {
    GetRequest('v3/web/community/featurePlan/getAllFeaturePlanFee', {}, callback);
};

const getDisFeaturePlan = (param: {Type?: '0'|'1' }, callback: Function) => {
    GetRequest('v3/web/community/featurePlan/getDisFeaturePlan', param, callback);
};

const getDifference = (param: GetDifferenceData, callback: Function) => {
    GetRequest('v3/web/community/featurePlan/getDifference', param, callback);
};

const getOfficeFeaturePlan = (callback: Function) => {
    GetRequest('v3/web/community/featurePlan/getFeaturePlan', { Type: 1 }, callback);
};

export default null;
export {
    getDisFeaturePlan,
    getDifference,
    getFeaturePlan,
    getAllFeaturePlanFee,
    getOfficeFeaturePlan
};