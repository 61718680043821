
import { defineComponent, ref, PropType } from 'vue';

export default defineComponent({
    name: 'cus-button',
    emits: ['click'],
    props: {
        type: {
            type: String as PropType<'default' | 'blue' | 'iconread' | 'iconblue'>,
            default: 'default'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const classType = ref({
            default: 'default',
            blue: 'blue',
            iconread: 'icon red',
            iconblue: 'icon blue-empty'
        });

        const clickHandler = () => {
            if (!props.disabled) emit('click');
        };

        return {
            classType,
            clickHandler
        };
    }
});
