/**
 * @name 检查添加message的name规则
 */
const checkName: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (value.length > 64) {
        return callBack(new Error(WordList.RuleNameLess64));
    }
    return callBack();
};

/**
 * @name 检查添加message的title规则
 */
const checkTitle: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (value.length > 32) {
        return callBack(new Error(WordList.RuleMessageTitleLess32));
    }
    return callBack();
};

/**
 * @name 检查添加message的content规则
 */
const checkMessage: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (value.length > 1024) {
        return callBack(new Error(WordList.RuleMessageLess1024));
    }
    return callBack();
};

/**
 * @name 检查添加message的content规则
 */
const checkOldCommunityMessage: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (value.length > 256) {
        return callBack(new Error(WordList.RuleMessageLess256));
    }
    return callBack();
};

export default null;
export {
    checkName,
    checkTitle,
    checkMessage,
    checkOldCommunityMessage
};
