import { isIPv4Format, isIPv6Format } from '@/util/ip';
import { byteLength } from 'byte-length';

const checkMAC: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (value.length !== 12) return callBack(new Error(WordList.RuleMacLength12));

    const reg = /^\+?[0-9a-zA-Z]*$/;
    if (!reg.test(value)) {
        return callBack(new Error(WordList.RuleMacComposition));
    }

    return callBack();
};

const checkSpecialCharacters: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    const reg = /[#&?]/;
    if (reg.test(value)) {
        return callBack(new Error(WordList.RuleSpecialCharactersIncorrect));
    }
    return callBack();
};

const checkRelayName: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (byteLength(value) > 63) {
        return callBack(new Error(WordList.RuleRelayLong));
    }
    const reg = /[,;]/;
    if (reg.test(value)) {
        return callBack(new Error(WordList.RuleSpecialCharactersIncorrect2));
    }
    return callBack();
};

const checkRtspAddress: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    const reg = /^rtsp:\/\/.*/;
    if (!reg.test(value)) {
        return callBack(new Error(WordList.InvalidRTSPAddress));
    }
    if (value.replace(/^rtsp:\/\//, '') === '') {
        return callBack(new Error(WordList.PleaseEnterTheFormat.format(WordList.RtspAddress)));
    }
    return callBack();
};

const checkRtspUserName: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    const reg = /[@:/]/;
    if (reg.test(value)) {
        return callBack(new Error(WordList.RtspUserNameWriteRule));
    }
    return callBack();
};

const checkRtspPassword: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    const reg = /[@:/]/;
    if (reg.test(value)) {
        return callBack(new Error(WordList.RtspPasswordWriteRule));
    }
    return callBack();
};

const checkThirdDeviceMAC: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (value.length !== 12) return callBack(new Error(WordList.MAC12LengthLimit));

    const reg = /^\+?[0-9a-zA-Z]*$/;
    if (!reg.test(value)) {
        return callBack(new Error(WordList.MAC12LengthLimit));
    }

    return callBack();
};

const checkIPAddress: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    if (!isIPv4Format(value) && !isIPv6Format(value)) {
        return callBack(new Error(WordList.InvalidIPAddress));
    }
    return callBack();
};

const checkPort: RuleMethod = (rule, val, callBack) => {
    const regForNumber = /^\+?[0-9]*$/;

    if (!regForNumber.test(val as string)) {
        return callBack(new Error(WordList.InvalidPort));
    }

    const value = Number(val);
    if (value > 65535 || value < 1024) {
        return callBack(new Error(WordList.InvalidPort));
    }
    return callBack();
};

export default null;
export {
    checkMAC,
    checkSpecialCharacters,
    checkRelayName,
    checkRtspAddress,
    checkRtspUserName,
    checkRtspPassword,
    checkThirdDeviceMAC,
    checkIPAddress,
    checkPort
};
