import { RouteRecordRaw } from 'vue-router';

export const superBaseRouter = 'superIndex';
export const superSubRouter = {
    personalDevice: 'personalDevice',
    projectDevice: 'projectDevice',
    personalDeviceDetail: 'personalDeviceDetail',
    communityDeviceDetail: 'communityDeviceDetail',
    thirdPartyDevice: 'thirdPartyDevice',
    thirdPartyDeviceInfo: 'thirdPartyDeviceInfo',
    project: 'project',
    user: 'user',
    userInfo: 'userInfo',
    insInfo: 'insInfo',
    officeInfo: 'officeInfo',
    propertyInfo: 'propertyInfo',
    firmware: 'firmware',
    model: 'model',
    customerService: 'customerService',
    payments: 'payments',
    auditLogs: 'auditLogs',
    macLibrary: 'mngdevice',
    exportOrder: 'exportOrder',
    paymentInfo: 'payInfo',
    featurePlan: 'featurePlan'
};

const index = () => import('@/views/super/index.vue');
const personalDevice = () => import('@/views/super/personal-device/index.vue');
const projectDevice = () => import('@/views/super/project-device/index.vue');
const personalDeviceDetail = () => import('@/views/super/personal-device/detail.vue');
const thirdPartyDeviceInfo = () => import('@/views/community/rdevice/devicedetail.vue');
const project = () => import('@/views/super/community/index.vue');
const user = () => import('@/views/super/user/index.vue');
const userInfo = () => import('@/views/super/user/info.vue');
const insInfo = () => import('@/views/super/user/insInfo.vue');
const officeInfo = () => import('@/views/super/user/office-info.vue');
const propertyInfo = () => import('@/views/super/user/property-info.vue');
const auditLogs = () => import('@/views/super/audit-logs/index.vue');
const customerService = () => import('@/views/super/customer-service/index.vue');
const modelManagement = () => import('@/views/super/model-management/index.vue');
const firmware = () => import('@/views/super/firmware/index.vue');
const macLibrary = () => import('@/views/super/mac-library/index.vue');
const payment = () => import('@/views/super/payment/index.vue');
const exportOrder = () => import('@/views/super/payment/export.vue');
const paymentInfo = () => import('@/views/super/payment/info.vue');
const featurePlan = () => import('@/views/super/feature-plan/index.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: `/${superBaseRouter}`,
        component: index,
        name: 'superIndex',
        redirect: `/${superBaseRouter}/${superSubRouter.personalDevice}`,
        children: [{
            path: superSubRouter.personalDevice,
            component: personalDevice,
            name: `${superBaseRouter}_${superSubRouter.personalDevice}`
        }, {
            path: superSubRouter.projectDevice,
            component: projectDevice,
            name: `${superBaseRouter}_${superSubRouter.projectDevice}`,
            props: (route) => ({ type: route.query.type })
        }, {
            path: superSubRouter.personalDeviceDetail,
            component: personalDeviceDetail,
            name: `${superBaseRouter}_${superSubRouter.personalDeviceDetail}`
        }, {
            path: superSubRouter.communityDeviceDetail,
            component: personalDeviceDetail,
            name: `${superBaseRouter}_${superSubRouter.communityDeviceDetail}`
        }, {
            path: superSubRouter.thirdPartyDeviceInfo,
            component: thirdPartyDeviceInfo,
            name: `${superBaseRouter}_${superSubRouter.thirdPartyDeviceInfo}`,
            props: (route) => ({ deviceType: route.query.deviceType })
        }, {
            path: superSubRouter.project,
            component: project,
            name: `${superBaseRouter}_${superSubRouter.project}`,
            props: (route) => ({ type: route.query.type })
        }, {
            path: superSubRouter.user,
            component: user,
            name: `${superBaseRouter}_${superSubRouter.user}`,
            props: (route) => ({ type: route.query.type })
        }, {
            path: superSubRouter.userInfo,
            component: userInfo,
            name: `${superBaseRouter}_${superSubRouter.userInfo}`,
            props: (route) => ({ id: route.query.id })
        }, {
            path: superSubRouter.insInfo,
            component: insInfo,
            name: `${superBaseRouter}_${superSubRouter.insInfo}`
        }, {
            path: superSubRouter.officeInfo,
            component: officeInfo,
            name: `${superBaseRouter}_${superSubRouter.officeInfo}`,
            props: (route) => ({ id: route.query.id })
        }, {
            path: superSubRouter.propertyInfo,
            component: propertyInfo,
            name: `${superBaseRouter}_${superSubRouter.propertyInfo}`,
            props: (route) => ({ id: route.query.id })
        }, {
            path: superSubRouter.auditLogs,
            component: auditLogs,
            name: `${superBaseRouter}_${superSubRouter.auditLogs}`
        }, {
            path: superSubRouter.customerService,
            component: customerService,
            name: `${superBaseRouter}_${superSubRouter.customerService}`
        }, {
            path: superSubRouter.model,
            component: modelManagement,
            name: `${superBaseRouter}_${superSubRouter.model}`
        }, {
            path: superSubRouter.firmware,
            component: firmware,
            name: `${superBaseRouter}_${superSubRouter.firmware}`
        }, {
            path: superSubRouter.macLibrary,
            component: macLibrary,
            name: `${superBaseRouter}_${superSubRouter.macLibrary}`,
            props: (route) => ({ id: route.query.id })
        }, {
            path: superSubRouter.payments,
            component: payment,
            name: `${superBaseRouter}_${superSubRouter.payments}`
        }, {
            path: superSubRouter.exportOrder,
            component: exportOrder,
            name: `${superBaseRouter}_${superSubRouter.exportOrder}`
        }, {
            path: superSubRouter.paymentInfo,
            component: paymentInfo,
            name: `${superBaseRouter}_${superSubRouter.paymentInfo}`,
            props: (route) => ({ id: route.query.id })
        }, {
            path: superSubRouter.featurePlan,
            component: featurePlan,
            name: `${superBaseRouter}_${superSubRouter.featurePlan}`
        }]
    }
];
export default routes;