const onlyNumber: RuleMethod = (rule, value, callback) => {
    if (value === '') return callback();
    const reg = /^[0-9]*$/;
    if (!reg.test(value as string)) {
        return callback(new Error(WordList.RuleCommonNumber));
    }
    return callback();
};

const onlyNumberAndNotEmpty: RuleMethod = (rule, value, callback) => {
    if (value === '') return callback(new Error(WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesPostal)));
    if (((value as string).replace(/\s*/g, '') === '') && value.toString()) {
        return callback(new Error(WordList.RuleLeastOneLetterOrNumber));
    }
    const reg = /^[0-9｜A-Z|a-z|\s]*$/;
    if (!reg.test(value as string)) {
        return callback(new Error(WordList.RuleLetterNumber));
    }
    return callback();
};

// 限制只能为正数
const onlyPositiveNumber: RuleMethod = (rule, value, callback) => {
    if (value && Number(value) <= 0) {
        return callback(new Error(WordList.LimitPositiveNumber));
    }
    return callback();
};

// 限制不能选择当天
const limitUnableToday = (rule: any, value: any, callback: any, initDate: number) => {
    const today = new Date().getDate();
    if (Number(value) <= today && today <= 10 && value !== initDate) {
        return callback(new Error(WordList.UnableTodayRule));
    }
    return callback();
};
export default null;
export {
    onlyNumber,
    onlyNumberAndNotEmpty,
    onlyPositiveNumber,
    limitUnableToday
};