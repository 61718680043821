
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'question-tip',
    props: {
        placement: {
            type: String
        },
        selfClass: {
            type: String
        }
    }
});
