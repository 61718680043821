import { reactive } from 'vue';
import GetRequest from '../basic';
import { ToolMenuResponse, SysConfig } from './type/system-config.type';

const sysConfig: SysConfig = reactive({
    EnableSmartHome: false,
    IsPMLink: 0
});

const getToolMenu = (callback: Function) => {
    GetRequest('v3/web/common/system/getMenuDownList', {}, callback);
};

const getSysConfig = (callback: (res: SysConfig) => void) => {
    GetRequest('v3/web/common/config/getSysConfig', {}, callback);
};

export default null;
export {
    getToolMenu,
    ToolMenuResponse,
    getSysConfig,
    sysConfig
};
