
import { PropType, defineComponent } from 'vue';

export default defineComponent({
    props: {
        // select选项数据
        options: {
            type: Array as PropType<{[index: string]: any}[]>,
            default: () => ([])
        },
        // label取的对象字段名
        labelKey: {
            type: String,
            default: 'label'
        },
        // value取的对象字段名
        valueKey: {
            type: String,
            default: 'value'
        }
    },
    setup(props, { emit, attrs }) {
        // function changeOptions(val: any) {
        //     if (Object.prototype.hasOwnProperty.call(attrs, 'multiple')) {
        //         const modelValue = attrs.modelValue as any[];
        //         if (modelValue && modelValue.length > 0) {
        //             if (modelValue.length > val.length) {
        //                 emit('update:modelValue', attrs.modelValue);
        //             } else {
        //                 emit('update:modelValue', val);
        //             }
        //         }
        //     } else {
        //         emit('update:modelValue', val);
        //     }
        // }
        function removeTagHandler(val: string) {
            if (Object.prototype.hasOwnProperty.call(attrs, 'multiple')) {
                const modelValue = attrs.modelValue as any[];
                const index = modelValue.findIndex((item) => item === val);
                if (index > -1) {
                    const filterData = modelValue.filter((item, fIndex) => fIndex !== index);
                    emit('update:modelValue', filterData);
                }
            }
        }

        return {
            removeTagHandler
        };
    }
});

