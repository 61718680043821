import { useRoute } from 'vue-router';
import HttpRequest from '@/util/axios.config';
import { ElMessageBox } from 'element-plus';
/**
 * 覆盖表单搜索项(只能在setup中使用)
 * @date 2022-05-31
 * @param {{[index:string]:any}} formData：要覆盖的表单搜索项对象
 * @returns {void}
 */
export function overlayFormData(formData: {[index: string]: any}) {
    const route = useRoute();
    if (JSON.stringify(route.query) !== '{}') {
        Object.keys(route.query).forEach((key) => {
            // eslint-disable-next-line no-param-reassign
            formData[key] = route.query[key];
        });
    }
}

/**
 * 二封文件下载函数，设置cloud-admin项目所需的默认配置
 * @param url 文件所在的 url
 * @param headers 额外的请求头
 */
export function downloadFileHandler(url: string, headers: Record<string, string> = {}, params: Record<string, string> = {}) {
    downloadFile(url, {
        headers: {
            'x-auth-token': localStorage.getItem('token') as string,
            'x-cloud-version': HttpRequest.version as string,
            'x-community-id': localStorage.getItem('communityID') as string,
            ...headers
        },
        // 与后端约定，使用Content-Disposition请求头获取文件名
        filenameHeader: 'Content-Disposition',
        params
    });
}
interface DownloadFileOptions {
    filenameHeader: string;
    headers?: Record<string, string>;
    params?: Record<string, string>;
}

/**
* 通过传入的url下载对应的文件
* @param url 文件所在的 url
* @param options.filenameHeader 获取下载文件的文件名的请求头
* @param options.headers 请求头
* @param options.params 请求参数
*/
export function downloadFile(url: string, options: DownloadFileOptions) {
    const { filenameHeader, headers, params } = options;
    const xhr = new XMLHttpRequest();
    let fileUrl = url;

    if (params) {
        Object.keys(params).forEach((paramKey, index) => {
            if (index === 0) {
                fileUrl += `?${paramKey}=${params[paramKey]}`;
            } else {
                fileUrl += `&${paramKey}=${params[paramKey]}`;
            }
        });
    }

    xhr.open('GET', fileUrl);

    // 额外请求头
    if (headers) {
        Object.keys(headers).forEach((headerKey) => {
            const header = headers[headerKey];
            xhr.setRequestHeader(headerKey, header);
        });
    }

    xhr.send();
    // 返回类型blob
    xhr.responseType = 'blob';
    xhr.onload = function () {
        if (this.status === 200) {
            const blob = this.response;
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function (e) {
                // 转换完成，创建一个a标签用于下载
                const a = document.createElement('a');
                let filename = xhr.getResponseHeader(filenameHeader)
                    ? (xhr.getResponseHeader(filenameHeader) as string).split(';')[1].split('=')[1] : url.split('/')[url.split('/').length - 1];
                filename = filename.replace(/"/g, '');
                a.download = filename;
                a.href = (e.target!.result as string);
                document.body.append(a);
                a.click();
                a.remove();
            };
        }
    };
}

export function captureLocalScriptErrors(callback: Function) {
    const currentHost: string = window.location.host;
    function errorHandler(event: Event): void {
        const target = event.target as HTMLScriptElement;
        if (target.tagName === 'SCRIPT') {
            const scriptSrc: string = target.src;
            const url: URL = new URL(scriptSrc);

            if (url.host === currentHost) {
                console.log('JavaScript resource error captured:', event);
                console.log('Failed script URL:', scriptSrc);
                callback();
            }
        }
    }

    window.addEventListener('error', errorHandler, true);

    window.addEventListener('beforeunload', () => {
        window.removeEventListener('error', errorHandler, true);
        console.log('Local script error listener removed');
    });
}
export function updateVersionHandler() {
    const updateVersionMsgMap = {
        browser: {
            message: WordList.BrowserSideUpdatePrompt,
            confirmButtonText: WordList.TabelFootRefreshNow,
            cancelButtonText: WordList.RefreshLater
        },
        desktop: {
            message: WordList.AkServerUpdateTips,
            confirmButtonText: WordList.UpdateAndRestart,
            cancelButtonText: ''
        }
    };
    const platform = window.crossPlatformConfig.platform.includes('desktop-') ? 'desktop' : 'browser';
    const updateVersionFn = () => {
        ElMessageBox[platform === 'desktop' ? 'alert' : 'confirm'](
            updateVersionMsgMap[platform].message,
            WordList.PersonuserInfo,
            {
                confirmButtonText: updateVersionMsgMap[platform].confirmButtonText,
                cancelButtonText: updateVersionMsgMap[platform].cancelButtonText,
                type: 'info'
            }
        ).then(() => {
            if (platform === 'desktop') {
                window.electronAPI.restartApp();
            } else {
                window.location.reload();
            }
        });
    };
    captureLocalScriptErrors(updateVersionFn);
}

export default null;
