import GetRequest from '../basic';

const getCustomerService = (callback: Function) => {
    GetRequest('v3/web/common/customerServer/getInfo', {}, callback);
};

export default null;

export {
    getCustomerService
};
