import { App } from 'vue';
import { cusSelect, cusOption } from '@/components/common/customize-select';
import passwordInput from '@/components/common/password-input';
import secDatePicker from '@/components/common/sec-date-picker';
import cusButton from '@/components/common/customize-button/index.vue';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import button, { circle, question } from '@/components/common/tip/index';
import customTable from '../common/custom-table';
import customButton from '../common/custom-button';
import customSelect from '../common/custom-select';
import customInput from '../common/custom-input';
import customFormTemplate from '../common/custom-form-template';
import customDialog from '../common/custom-dialog';
import customForm from '../common/custom-form';
import customFormItem from '../common/custom-form-item';
import customUpload from '../common/custom-upload';
import svgIcon from '../common/svg-icon';

export default {
    install(app: App) {
        app.component(cusSelect.name, cusSelect);
        app.component(cusOption.name, cusOption);
        app.component(cusButton.name, cusButton);
        app.component(dialogShell.name, dialogShell);
        app.component(passwordInput.name, passwordInput);
        app.component(secDatePicker.name, secDatePicker);
        app.component(button.name, button);
        app.component(circle.name, circle);
        app.component(question.name, question);
        app.component('custom-table', customTable);
        app.component('custom-button', customButton);
        app.component('custom-select', customSelect);
        app.component('custom-input', customInput);
        app.component('custom-dialog', customDialog);
        app.component('custom-form-template', customFormTemplate);
        app.component('custom-form', customForm);
        app.component('custom-upload', customUpload);
        app.component('custom-form-item', customFormItem);
        app.component('svg-icon', svgIcon);
    }
};
