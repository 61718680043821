
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    setup() {
        // 判断布局方向
        function decideHtmlDirection() {
            const { direction } = document.documentElement.style;
            const rtlLangs = ['he', 'fa'];
            const curLang = Lang;
            if (direction === 'ltr' && rtlLangs.includes(curLang)) {
                // 跳转rtl登录页面
                if (process.env.NODE_ENV === 'production') {
                    window.location.href = '/manager/rtl/#/login';
                } else {
                    window.location.href = '/#/login';
                }
            } else if (direction === 'rtl' && !rtlLangs.includes(curLang)) {
                // 跳转ltr登录页面。
                if (process.env.NODE_ENV === 'production') {
                    window.location.href = '/manager/#/login';
                } else {
                    window.location.href = '/#/login';
                }
            }
        }

        decideHtmlDirection();

        const store = useStore();
        if (window.location.hash !== '#/login') {
            store.dispatch('getEnableSmartHomeOption');
        }
    }
});
