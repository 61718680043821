import HttpRequest from '@/util/axios.config';

const statusOff = '0';
const statusOn = '1';

const updateRightNow = '0';
const updateSpecificTime = '1';

const updateForSingle = 0;
const updateForCommunity = 1;
const updateForOffice = 2;
const stepForGetList = 0;
const stepForUpdate = 1;
const getComUpdatePlanList = (disId: string, step: number, callback: Function, tip: boolean) => {
    HttpRequest.post('v3/web/community/featurePlan/updateFeaturePlan', {
        AccountID: disId,
        Step: step
    }, [callback, tip]);
};

export {
    statusOff,
    statusOn,
    updateRightNow,
    updateSpecificTime,
    stepForGetList,
    stepForUpdate,
    updateForSingle,
    updateForCommunity,
    updateForOffice,
    getComUpdatePlanList
};
