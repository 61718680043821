import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import baseRoutes, { baseRouter, baseSubRouter } from '@/router/routes/base';
import superRoutes, { superBaseRouter, superSubRouter } from './routes/super';
import propertyRoutes, { propertyBaseRouter, propertySubRouter, propertyOldCommunitySubRouter } from './routes/property';
import installerRoutes, { installerBaseRouter, installerSubRouter, installerBatchRouter } from './routes/installer';
import communityRoutes, { communityBaseRouter, communitySubRouter } from './routes/community';
import distributorRoutes, { distributorBaseRouter, distributorSubRouter } from './routes/distributor';

const invalid = () => import('@/views/404.vue');

let routes: Array<RouteRecordRaw> = [{
    path: '/',
    redirect: `/${baseSubRouter.login}`
},
{
    path: '/404',
    component: invalid
},
{
    path: '/agreement/user-agreement',
    component: () => import('@/views/agreement/user-agreement.vue')
}
];
routes = routes.concat(baseRoutes)
    .concat(superRoutes)
    .concat(propertyRoutes)
    .concat(installerRoutes)
    .concat(distributorRoutes)
    .concat(communityRoutes);

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    // '/'及 '/login' 的路径和pm角色到beta版本的代码
    const toPathArr = to.path.split('/');
    if (process.env.VUE_APP_NODE_ENV === 'pro'
    && ((to.path === '/' || to.path === '/login')
    || (toPathArr.length >= 3 && toPathArr[1].indexOf('property') === -1)) && to.path !== '/agreement/user-agreement') {
        if (Lang === 'he' || Lang === 'ar'
        ) {
            window.location.href = '/manager/rtl/#/login';
        } else {
            window.location.href = '/manager/#/login';
        }
    }
    const { matched } = to;
    if (matched.length === 0) next('/404');
    else {
        // 解决表单回车#前面多个？的问题
        const { href } = window.location;
        const before = href.split('#')[0];
        const lastStr = before.substr(before.length - 1, 1);
        if (lastStr === '?') {
            const firstIndex = href.indexOf('#');
            const newHref = `${href.substr(0, firstIndex - 1)}${href.substr(firstIndex)}`;
            window.location.href = newHref;
        }
        next();
    }
});

export default router;
export {
    baseRouter,
    baseSubRouter,
    superBaseRouter,
    superSubRouter,
    propertyBaseRouter,
    propertySubRouter,
    propertyOldCommunitySubRouter,
    installerBatchRouter,
    installerBaseRouter,
    communityBaseRouter,
    communitySubRouter,
    installerSubRouter,
    distributorBaseRouter,
    distributorSubRouter
};
