import { InjectionKey, Ref } from 'vue';

export default null;
export interface SelectValue {
    selected: string | number | symbol;
    match: boolean;
    label: string;
    filterValue: string;
    change: number;
}
export interface MultipleSelectValue {
    selectValue: string | number | symbol;
    label: string;
    choose: boolean;
}
export const selectKey = Symbol('selectKey') as InjectionKey<SelectValue>;
export const multipleSelectKey = Symbol('multipleSelectKey') as InjectionKey<MultipleSelectValue[]>;
export const multiple = Symbol('multiple') as InjectionKey<boolean>;
export const multipleFilterValue = Symbol('multipleFilterValue') as InjectionKey<Ref<string>>;

export function findParentNode(child: Element, parent: Element): boolean {
    if (child === document.body) return false;
    if (child === parent) return true;
    if (child.parentElement) {
        return findParentNode(child.parentElement, parent);
    }
    return false;
}