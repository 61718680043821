
import {
    defineComponent, inject, watch, ref,
    Ref
} from 'vue';
import {
    selectKey, SelectValue,
    multipleSelectKey, MultipleSelectValue,
    multiple, multipleFilterValue
} from './util/data';

export default defineComponent({
    name: 'cus-option',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: [String, Number, Symbol],
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const filter = ref('');
        const selected = inject(selectKey) as SelectValue;
        watch(selected, () => {
            filter.value = selected.filterValue;
            if (selected.selected === props.value) {
                selected.label = props.label;
                selected.match = true;
            }
        }, {
            immediate: true
        });
        // 多选情况下，控制下拉框选项是否隐藏
        const isShowMultipleOption = ref(true);
        const multipleSelected = inject(multipleSelectKey) as Array<MultipleSelectValue>;
        const multipleFilter = inject(multipleFilterValue) as Ref<string>;
        watch(multipleFilter, () => {
            filter.value = multipleFilter.value;
        });
        // 监听勾选项变化，已勾选隐藏
        watch(multipleSelected, () => {
            let isExistItem = false;
            multipleSelected.forEach((selectedItem, selectedIndex) => {
                if (selectedItem.selectValue === props.value) {
                    multipleSelected[selectedIndex].label = props.label;
                    multipleSelected[selectedIndex].choose = true;
                    isExistItem = true;
                }
            });
            isShowMultipleOption.value = !isExistItem;
        }, {
            immediate: true
        });

        const filterOption = () => props.label.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;

        // 注入select父组件的multiple属性
        const isMultiple = inject(multiple) as boolean;
        const choose = () => {
            if (props.disabled) return;
            // 多选择框，隐藏选项
            if (isMultiple) {
                multipleSelected.push({
                    selectValue: props.value,
                    label: props.label,
                    choose: true
                });
                isShowMultipleOption.value = false;
                return;
            }
            if (selected.selected !== props.value) selected.change += 1;
            selected.selected = props.value;
            selected.label = props.label;
        };
        return {
            selected,
            choose,
            filterOption,
            isShowMultipleOption
        };
    }
});
