
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'circle-tip',
    props: {
        type: {
            type: String as PropType<'success' | 'warning' | 'disable'>,
            default: 'success'
        }
    },
    setup() {
        const image = {
            success: require('@/assets/image/Activation.png'),
            warning: require('@/assets/image/Expired.png'),
            disable: require('@/assets/image/Inactivated.png')
        };

        return {
            image
        };
    }
});
