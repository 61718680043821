
import { defineComponent, PropType, ref } from 'vue';
// ts-ignore
// import { Column } from '../types/table.d';
interface ColumnItem {
    prop?: string;
    label: string;
    width?: string;
    fixed?: string;
    align?: string;
    children?: Array<ColumnItem>; [propName: string]: any;
}
type ColumnType = ColumnItem & {children?: Array<ColumnItem>}

export interface Column extends Array<ColumnType> {
    [index: number]: ColumnType;
}
export default defineComponent({
    props: {
        column: {
            type: Array as PropType<Column>,
            default: () => []
        },
        // 表格数据
        tableData: {
            type: Array,
            default: () => []
        },
        // 是否带有纵向边框
        border: {
            type: Boolean,
            default: false
        },
        // 是否显示多选column
        isSelection: {
            type: Boolean,
            default: false
        },
        // 是否显示序号类
        showIndex: {
            type: Boolean,
            default: false
        },
        // 内容对齐方式：如果column有传align，以column为主，不然用prop.align
        align: {
            type: String,
            default: 'center'
        },
        defaultExpandAll: {
            type: Boolean,
            default: false
        },
        // 行数据的 Key
        rowKey: {
            type: String,
            default: 'id'
        },
        // 表格空提示文案
        emptyText: {
            type: String,
            default: WordList.SelectNoData
        },
        // loading状态
        loading: {
            type: Boolean,
            default: false
        },
        // loading文案
        loadingText: {
            type: String,
            default: 'loading…'
        },
        tableLayout: {
            type: String,
            default: 'auto'
        },
        // 表格高度：实现固定表头的表格
        height: {
            type: String
        }
    },
    emits: ['selectionChange', 'rowClick'],
    expose: ['table'],
    setup(props, { emit }) {
        const table = ref();
        /**
         * 点击双选框 选择数据 的回调
         * @date 2022-5-27
         * @param {Array} selection 双选框选择的数据
         * @returns {Array} selection
         */
        function selectionChange<T>(selection: Array<T>) {
            emit('selectionChange', selection);
        }
        function rowClick(row: object) {
            emit('rowClick', row);
        }

        /**
         * 设置行class，后期有需求在调整（外部传rowClassName的设置函数，函数返回值和data-test-${row.ID}拼接）
         * @date 2022-07-09
         * @param {any} {row}:{row:{ID:string}}
         * @returns {any}
         */
        function rowClassName({ row }: {row: {ID: string}}) {
            return `data-test-${row.ID}`;
        }
        return {
            table,
            selectionChange,
            rowClassName,
            rowClick
        };
    }
});

