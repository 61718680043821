import { RouteRecordRaw } from 'vue-router';

const communityBaseRouter = 'communityIndex';
const communitySubRouter = {
    residentDevice: 'residentdevice',
    firmWare: 'comfirmware',
    update: 'comupdate',
    library: 'comlibrary',
    subscriptions: 'comsubscriptions',
    payments: 'compayments',
    paymentinfo: 'compaymentinfo',
    devicedetail: 'comdevicedetail',
    // 施工进度
    constructionprogress: 'comconstructionprogress'
};

const index = () => import('@/views/community/index.vue');
// 社区相关
const comRDivce = () => import('@/views/community/rdevice/index.vue');
const comFirmware = () => import('@/views/community/firmware/index.vue');
const comUpdate = () => import('@/views/community/update/index.vue');
const comLibrary = () => import('@/views/community/library/index.vue');
const comSubscriptions = () => import('@/views/community/subscriptions/index.vue');
const comPayments = () => import('@/views/community/payments/index.vue');
const comPaymentInfo = () => import('@/views/community/payments/info.vue');
const comDeviceDetail = () => import('@/views/community/rdevice/devicedetail.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: `/${communityBaseRouter}`,
        component: index,
        name: communityBaseRouter,
        redirect: `/${communityBaseRouter}/${communitySubRouter.residentDevice}?Build=community&type=user`,
        children: [
            {
                path: communitySubRouter.residentDevice,
                name: `${communityBaseRouter}_${communitySubRouter.residentDevice}`,
                component: comRDivce,
                props: (route) => ({
                    build: route.query.Build,
                    type: route.query.type,
                    id: route.query.id,
                    deviceType: route.query.deviceType
                })
            }, {
                path: communitySubRouter.firmWare,
                name: `${communityBaseRouter}_${communitySubRouter.firmWare}`,
                component: comFirmware
            }, {
                path: communitySubRouter.update,
                name: `${communityBaseRouter}_${communitySubRouter.update}`,
                component: comUpdate
            }, {
                path: communitySubRouter.library,
                name: `${communityBaseRouter}_${communitySubRouter.library}`,
                component: comLibrary
            }, {
                path: communitySubRouter.subscriptions,
                name: `${communityBaseRouter}_${communitySubRouter.subscriptions}`,
                component: comSubscriptions
            }, {
                path: communitySubRouter.payments,
                name: `${communityBaseRouter}_${communitySubRouter.payments}`,
                component: comPayments
            }, {
                path: communitySubRouter.paymentinfo,
                name: `${communityBaseRouter}_${communitySubRouter.paymentinfo}`,
                component: comPaymentInfo,
                props: (route) => ({ id: route.query.id })
            }, {
                path: communitySubRouter.devicedetail,
                name: `${communityBaseRouter}_${communitySubRouter.devicedetail}`,
                component: comDeviceDetail,
                props: (route) => ({ type: route.query.type, deviceType: route.query.deviceType })
            }
        ]
    }
];

export default routes;
export {
    communityBaseRouter,
    communitySubRouter
};