import CryptoJS from 'crypto-js';

interface Config{
    type?: 'localStorage'|'sessionStorage';
    isEncrypt?: boolean;
    // 包括的key
    include?: string[];
    // 排除的key
    exclude?: string[];
}
// 默认配置
const defaultConfig = {
    // 本地存储类型 localStorage/sessionStorage
    type: 'localStorage',
    // 默认加密；为了调试方便, 开发过程和测试环境中可以不加密
    isEncrypt: process.env.NODE_ENV !== 'development' && window.location.href.indexOf('dev.') === -1 && window.location.href.indexOf('test.') === -1,
    include: [],
    exclude: []
};
// 获取config
function getConfig(privateConfig?: Config) {
    const config = { ...defaultConfig };
    if (privateConfig) {
        Object.keys(privateConfig).forEach((key) => {
            // @ts-ignore
            config[key] = privateConfig[key];
        });
    }
    return config as Required<Config>;
}
/**
 * 设置storage
 * @date 2022-06-23
 * @param {string} key:设置storage的key
 * @param {any} value:设置storage的value
 * @param {Config} privateConfig?:自定义配置配置，覆盖默认配置
 * @returns {void}
 */
export function setStorage(key: string, value: any, privateConfig?: Config) {
    const config = getConfig(privateConfig);
    if (value === '' || value === null || value === undefined) {
        value = null;
    }

    const data = {
        value
    };

    const encryptString = config.isEncrypt
        ? encrypt(JSON.stringify(data))
        : JSON.stringify(data);

    window[config.type].setItem(key, encryptString);
}
/**
 * 获取storage
 * @date 2022-06-23
 * @param {string} key:设置storage的key
 * @param {Config} privateConfig?:自定义配置配置，覆盖默认配置
 * @returns {any} 返回storage value
 */
export function getStorage(key: string, privateConfig?: Config) {
    const config = getConfig(privateConfig);
    // key 不存在判断
    if (!window[config.type].getItem(key) || JSON.stringify(window[config.type].getItem(key)) === 'null') {
        return null;
    }
    let storage = { value: '' };
    try {
        storage = config.isEncrypt
            ? JSON.parse(decrypt(window[config.type].getItem(key)!))
            : JSON.parse(window[config.type].getItem(key)!);
    } catch (err) {
        console.log(`key为${key} getStorage 异常,重置值为空字符串`, err);
    }
    return storage.value;
}
/**
 * 删除 removeStorage
 * @date 2022-06-23
 * @param {string} key:设置storage的key
 * @param {Config} privateConfig?:自定义配置配置，覆盖默认配置
 * @returns {void}
 */
export function removeStorage(key: string, privateConfig?: Config) {
    const config = getConfig(privateConfig);
    window[config.type].removeItem(key);
}
/**
 * 清空 clearStorage
 * @date 2022-06-23
 * @param {Config} privateConfig?:自定义配置配置，覆盖默认配置
 * @returns {void}
 */
export function clearStorage(privateConfig?: Config) {
    const config = getConfig(privateConfig);
    if (config.include.length > 0) {
        config.include.forEach((key) => {
            removeStorage(key, privateConfig);
        });
    } else if (config.exclude.length > 0) {
        const excludeStore = config.exclude.map((key) => ({
            key,
            value: getStorage(key, config)
        }));
        window[config.type].clear();
        excludeStore.forEach((item) => {
            setStorage(item.key, item.value, config);
        });
    } else {
        window[config.type].clear();
    }
}

// 十六位十六进制数作为密钥
const SECRET_KEY = CryptoJS.enc.Utf8.parse('3333e6e143439162');
// 十六位十六进制数作为密钥偏移量
const SECRET_IV = CryptoJS.enc.Utf8.parse('e3bbe7e3ba84431b');
/**
 * 加密方法
 * @param data
 * @returns {string}
 */
function encrypt(data: string) {
    if (typeof data === 'object') {
        try {
            data = JSON.stringify(data);
        } catch (error) {
            console.log('encrypt error:', error);
        }
    }
    const dataHex = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(dataHex, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString();
}

/**
 * 解密方法
 * @param data
 * @returns {string}
 */
function decrypt(data: string) {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decryptA = CryptoJS.AES.decrypt(str, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    const decryptedStr = decryptA.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}
