const elLang = {
    el: {
        datepicker: {
            now: WordList.DateInputNow,
            today: WordList.DateInputToday,
            cancel: WordList.TabelFootCancel,
            clear: WordList.TabelConfigInHtmlDelete,
            confirm: WordList.TabelFootSure,
            selectDate: '',
            selectTime: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            prevYear: '',
            nextYear: '',
            prevMonth: '',
            nextMonth: '',
            year: '',
            month1: WordList.DateInputMonthJan,
            month2: WordList.DateInputMonthFeb,
            month3: WordList.DateInputMonthMar,
            month4: WordList.DateInputMonthApr,
            month5: WordList.DateInputMonthMay,
            month6: WordList.DateInputMonthJun,
            month7: WordList.DateInputMonthJul,
            month8: WordList.DateInputMonthAug,
            month9: WordList.DateInputMonthSep,
            month10: WordList.DateInputMonthOct,
            month11: WordList.DateInputMonthNov,
            month12: WordList.DateInputMonthDec,
            weeks: {
                sun: WordList.DateInputWeekSun,
                mon: WordList.DateInputWeekMon,
                tue: WordList.DateInputWeekTue,
                wed: WordList.DateInputWeekWed,
                thu: WordList.DateInputWeekThu,
                fri: WordList.DateInputWeekFri,
                sat: WordList.DateInputWeekSat
            },
            months: {
                jan: WordList.DateInputMonthJan,
                feb: WordList.DateInputMonthFeb,
                mar: WordList.DateInputMonthMar,
                apr: WordList.DateInputMonthApr,
                may: WordList.DateInputMonthMay,
                jun: WordList.DateInputMonthJun,
                jul: WordList.DateInputMonthJul,
                aug: WordList.DateInputMonthAug,
                sep: WordList.DateInputMonthSep,
                oct: WordList.DateInputMonthOct,
                nov: WordList.DateInputMonthNov,
                dec: WordList.DateInputMonthDec
            }
        },
        pagination: {
            goto: WordList.ListInHtmlPaginationGoTo,
            // pagesize: WordList.ListInHtmlPaginationSelect,
            pagesize: '',
            total: `{total} ${WordList.ListInHtmlPaginationGoAllArt}`,
            pageClassifier: '',
            deprecationWarning:
            '你使用了一些已被废弃的用法，请参考 el-pagination 的官方文档'
        }
    }
};

export default elLang;
