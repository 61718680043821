import GetRequest from '../basic';

const getExportTime = (params: { Flag: number}, callback: Function) => {
    GetRequest('v3/web/common/log/getExportTime', params, callback);
};

export default null;

export {
    getExportTime
};
