import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")

  return (_openBlock(), _createBlock(_component_el_date_picker, {
    type: _ctx.type,
    format: _ctx.timeFormat,
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _ctx.update
  }, null, 8, ["type", "format", "model-value", "onUpdate:modelValue"]))
}