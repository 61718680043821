
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'button-tip',
    props: {
        color: {
            type: String,
            default: '#18A1FD'
        },
        width: {
            type: String
        }
    }
});
