import { RouteRecordRaw } from 'vue-router';

const baseRouter = '/';
const baseSubRouter = {
    login: 'login',
    middlePage: 'middle-page'
};
const index = () => import('@/views/base/index.vue');
const login = () => import('@/views/base/login/index.vue');
const middlePage = () => import('@/views/base/middle-page/index.vue');
const routes: Array<RouteRecordRaw> = [
    {
        path: `${baseRouter}`,
        component: index,
        name: baseRouter,
        redirect: `${baseSubRouter.login}`,
        children: [
            {
                path: baseSubRouter.login,
                name: baseSubRouter.login,
                component: login
            },
            {
                path: baseSubRouter.middlePage,
                name: baseSubRouter.middlePage,
                component: middlePage,
                props: (route) => ({
                    joinType: route.query.joinType,
                    akuvoxUrl: route.query.akuvoxUrl
                })
            }
        ]
    }
];

export default routes;
export {
    baseRouter,
    baseSubRouter
};