const country = {
    Location: {
        CountryRegion: [{
            Name: 'Afghanistan',
            Code: 'AFG',
            State: {
                City: [{
                    Name: 'Herat',
                    Code: 'HEA'
                },
                {
                    Name: 'Kabul',
                    Code: 'KBL'
                },
                {
                    Name: 'Kandahar',
                    Code: 'KDH'
                },
                {
                    Name: 'Mazar-i Sharif',
                    Code: 'MZR'
                }
                ]
            }
        },
        {
            Name: 'Aland lslands',
            Code: 'ALA'
        },
        {
            Name: 'Albania',
            Code: 'ALB',
            State: {
                City: [{
                    Name: 'Berat',
                    Code: 'BR'
                },
                {
                    Name: 'Diber',
                    Code: 'DI'
                },
                {
                    Name: 'Durres',
                    Code: 'DR'
                },
                {
                    Name: 'Elbasan',
                    Code: 'EL'
                },
                {
                    Name: 'Fier',
                    Code: 'FR'
                },
                {
                    Name: 'Gjirokaster',
                    Code: 'GJ'
                },
                {
                    Name: 'Korce',
                    Code: 'KO'
                },
                {
                    Name: 'Kukes',
                    Code: 'KU'
                },
                {
                    Name: 'Lezhe',
                    Code: 'LE'
                },
                {
                    Name: 'Shkoder',
                    Code: 'SH'
                },
                {
                    Name: 'Tirane',
                    Code: 'TR'
                },
                {
                    Name: 'Vlore',
                    Code: 'VL'
                }
                ]
            }
        },
        {
            Name: 'Algeria',
            Code: 'DZA',
            State: {
                City: [{
                    Name: 'Adrar',
                    Code: 'ADR'
                },
                {
                    Name: 'Ain Defla',
                    Code: 'ADE'
                },
                {
                    Name: 'Ain Temouchent',
                    Code: 'ATE'
                },
                {
                    Name: 'Alger',
                    Code: 'ALG'
                },
                {
                    Name: 'Annaba',
                    Code: 'AAE'
                },
                {
                    Name: 'Batna',
                    Code: 'BAT'
                },
                {
                    Name: 'Bechar',
                    Code: 'BEC'
                },
                {
                    Name: 'Bejaia',
                    Code: 'BJA'
                },
                {
                    Name: 'Biskra',
                    Code: 'BIS'
                },
                {
                    Name: 'Blida',
                    Code: 'BLI'
                },
                {
                    Name: 'Bordj Bou Arreridj',
                    Code: 'BOR'
                },
                {
                    Name: 'Bouira',
                    Code: 'BOA'
                },
                {
                    Name: 'Boumerdes',
                    Code: 'BOU'
                },
                {
                    Name: 'Chlef',
                    Code: 'CHL'
                },
                {
                    Name: 'Constantine',
                    Code: 'CZL'
                },
                {
                    Name: 'Djelfa',
                    Code: 'DJE'
                },
                {
                    Name: 'El Bayadh',
                    Code: 'EBA'
                },
                {
                    Name: 'El Oued',
                    Code: 'EOU'
                },
                {
                    Name: 'El Tarf',
                    Code: 'ETA'
                },
                {
                    Name: 'Ghardaia',
                    Code: 'GHA'
                },
                {
                    Name: 'Guelma',
                    Code: 'GUE'
                },
                {
                    Name: 'Illizi',
                    Code: 'ILL'
                },
                {
                    Name: 'Jijel',
                    Code: 'JIJ'
                },
                {
                    Name: 'Khenchela',
                    Code: 'KHE'
                },
                {
                    Name: 'Laghouat',
                    Code: 'LAG'
                },
                {
                    Name: 'Mascara',
                    Code: 'MUA'
                },
                {
                    Name: 'Medea',
                    Code: 'MED'
                },
                {
                    Name: 'Mila',
                    Code: 'MIL'
                },
                {
                    Name: 'Mostaganem',
                    Code: 'MOS'
                },
                {
                    Name: 'Msila',
                    Code: 'MSI'
                },
                {
                    Name: 'Naama',
                    Code: 'NAA'
                },
                {
                    Name: 'Oran',
                    Code: 'ORA'
                },
                {
                    Name: 'Ouargla',
                    Code: 'OUA'
                },
                {
                    Name: 'Oum el Bouaghi',
                    Code: 'OEB'
                },
                {
                    Name: 'Relizane',
                    Code: 'REL'
                },
                {
                    Name: 'Saida',
                    Code: 'SAI'
                },
                {
                    Name: 'Setif',
                    Code: 'SET'
                },
                {
                    Name: 'Sidi Bel Abbes',
                    Code: 'SBA'
                },
                {
                    Name: 'Skikda',
                    Code: 'SKI'
                },
                {
                    Name: 'Souk Ahras',
                    Code: 'SAH'
                },
                {
                    Name: 'Tamanghasset',
                    Code: 'TAM'
                },
                {
                    Name: 'Tebessa',
                    Code: 'TEB'
                },
                {
                    Name: 'Tiaret',
                    Code: 'TIA'
                },
                {
                    Name: 'Tindouf',
                    Code: 'TIN'
                },
                {
                    Name: 'Tipaza',
                    Code: 'TIP'
                },
                {
                    Name: 'Tissemsilt',
                    Code: 'TIS'
                },
                {
                    Name: 'Tizi Ouzou',
                    Code: 'IOU'
                },
                {
                    Name: 'Tlemcen',
                    Code: 'TLE'
                }
                ]
            }
        },
        {
            Name: 'American Samoa',
            Code: 'ASM',
            State: {
                City: [{
                    Name: 'Aana',
                    Code: 'AAN'
                },
                {
                    Name: 'Aigaile Tai',
                    Code: 'AIT'
                },
                {
                    Name: 'Atua',
                    Code: 'ATU'
                },
                {
                    Name: 'Faasaleleaga',
                    Code: 'FAA'
                },
                {
                    Name: 'Gagaemauga',
                    Code: 'GMG'
                },
                {
                    Name: 'Gagaifomauga',
                    Code: 'GFG'
                },
                {
                    Name: 'Palauli',
                    Code: 'PAL'
                },
                {
                    Name: 'Satupaitea',
                    Code: 'SAT'
                },
                {
                    Name: 'Savaii',
                    Code: 'SAV'
                },
                {
                    Name: 'Tuamasaga',
                    Code: 'TUA'
                },
                {
                    Name: 'Upolu',
                    Code: 'UPO'
                },
                {
                    Name: 'Vaao Fonoti',
                    Code: 'VAF'
                },
                {
                    Name: 'Vaisigano',
                    Code: 'VAI'
                }
                ]
            }
        },
        {
            Name: 'Andorra',
            Code: 'AND',
            State: {
                City: [{
                    Name: 'Andorra la Vella',
                    Code: '7'
                },
                {
                    Name: 'Canillo',
                    Code: '2'
                },
                {
                    Name: 'Encamp',
                    Code: '3'
                },
                {
                    Name: 'Escaldes-Engordany',
                    Code: '8'
                },
                {
                    Name: 'La Massana',
                    Code: '4'
                },
                {
                    Name: 'Ordino',
                    Code: '5'
                },
                {
                    Name: 'Sant Julia de Loria',
                    Code: '6'
                }
                ]
            }
        },
        {
            Name: 'Angola',
            Code: 'AGO',
            State: {
                City: [{
                    Name: 'Bengo',
                    Code: 'BGO'
                },
                {
                    Name: 'Benguela',
                    Code: 'BGU'
                },
                {
                    Name: 'Bie',
                    Code: 'BIE'
                },
                {
                    Name: 'Cabinda',
                    Code: 'CAB'
                },
                {
                    Name: 'Cuando Cubango',
                    Code: 'CCU'
                },
                {
                    Name: 'Cuanza Norte',
                    Code: 'CNO'
                },
                {
                    Name: 'Cuanza Sul',
                    Code: 'CUS'
                },
                {
                    Name: 'Cunene',
                    Code: 'CNN'
                },
                {
                    Name: 'Huambo',
                    Code: 'HUA'
                },
                {
                    Name: 'Huila',
                    Code: 'HUI'
                },
                {
                    Name: 'Luanda',
                    Code: 'LUA'
                },
                {
                    Name: 'Lunda Norte',
                    Code: 'LNO'
                },
                {
                    Name: 'Lunda Sul',
                    Code: 'LSU'
                },
                {
                    Name: 'Malanje',
                    Code: 'MAL'
                },
                {
                    Name: 'Moxico',
                    Code: 'MOX'
                },
                {
                    Name: 'Namibe',
                    Code: 'NAM'
                },
                {
                    Name: 'Uige',
                    Code: 'UIG'
                },
                {
                    Name: 'Zaire',
                    Code: 'ZAI'
                }
                ]
            }
        },
        {
            Name: 'Anguilla',
            Code: 'AIA'
        },
        {
            Name: 'Antarctica',
            Code: 'ATA'
        },
        {
            Name: 'Antigua and Barbuda',
            Code: 'ATG'
        },
        {
            Name: 'Argentina',
            Code: 'ARG',
            State: {
                City: [{
                    Name: 'Bahia Blanca',
                    Code: 'BHI'
                },
                {
                    Name: 'Buenos Aires',
                    Code: 'BUE'
                },
                {
                    Name: 'Catamarca',
                    Code: 'CTC'
                },
                {
                    Name: 'Comodoro Rivadavia',
                    Code: 'CRD'
                },
                {
                    Name: 'Concordia',
                    Code: 'COC'
                },
                {
                    Name: 'Cordoba',
                    Code: 'COR'
                },
                {
                    Name: 'Corrientes',
                    Code: 'CNQ'
                },
                {
                    Name: 'Formosa',
                    Code: 'FMA'
                },
                {
                    Name: 'Jujuy',
                    Code: 'JUJ'
                },
                {
                    Name: 'La Plata',
                    Code: 'LPG'
                },
                {
                    Name: 'La Rioja',
                    Code: 'IRJ'
                },
                {
                    Name: 'Mar del Plata',
                    Code: 'MDQ'
                },
                {
                    Name: 'Mendoza',
                    Code: 'MDZ'
                },
                {
                    Name: 'Neuquen',
                    Code: 'NQN'
                },
                {
                    Name: 'Parana',
                    Code: 'PRA'
                },
                {
                    Name: 'Posadas',
                    Code: 'PSS'
                },
                {
                    Name: 'Rawson',
                    Code: 'RWO'
                },
                {
                    Name: 'Resistencia',
                    Code: 'RES'
                },
                {
                    Name: 'Rio Cuarto',
                    Code: 'RCU'
                },
                {
                    Name: 'Rio Gallegos',
                    Code: 'RGL'
                },
                {
                    Name: 'Rosario',
                    Code: 'ROS'
                },
                {
                    Name: 'Salta',
                    Code: 'SLA'
                },
                {
                    Name: 'San Juan',
                    Code: 'UAQ'
                },
                {
                    Name: 'San Miguel de Tucuman',
                    Code: 'SMC'
                },
                {
                    Name: 'San Nicolas',
                    Code: 'SNS'
                },
                {
                    Name: 'San Rafael',
                    Code: 'AFA'
                },
                {
                    Name: 'San Luis',
                    Code: 'LUQ'
                },
                {
                    Name: 'Santa Fe',
                    Code: 'SFN'
                },
                {
                    Name: 'Santa Rosa',
                    Code: 'RSA'
                },
                {
                    Name: 'Santiago del Estero',
                    Code: 'SDE'
                },
                {
                    Name: 'Trelew',
                    Code: 'REL'
                },
                {
                    Name: 'Ushuaia',
                    Code: 'USH'
                },
                {
                    Name: 'Viedma',
                    Code: 'VDM'
                },
                {
                    Name: 'Villa Krause',
                    Code: 'VLK'
                }
                ]
            }
        },
        {
            Name: 'Armenia',
            Code: 'ARM',
            State: {
                City: [{
                    Name: 'Aragacotn',
                    Code: 'AGT'
                },
                {
                    Name: 'Ararat',
                    Code: 'ARA'
                },
                {
                    Name: 'Armavir',
                    Code: 'ARM'
                },
                {
                    Name: 'Gelarkunik',
                    Code: 'GEG'
                },
                {
                    Name: 'Kotayk',
                    Code: 'KOT'
                },
                {
                    Name: 'Lorri',
                    Code: 'LOR'
                },
                {
                    Name: 'Shirak',
                    Code: 'SHI'
                },
                {
                    Name: 'Syunik',
                    Code: 'SYU'
                },
                {
                    Name: 'Tavus',
                    Code: 'TAV'
                },
                {
                    Name: 'VayocJor',
                    Code: 'VAY'
                },
                {
                    Name: 'Yerevan',
                    Code: 'EVN'
                }
                ]
            }
        },
        {
            Name: 'Aruba',
            Code: 'ABW'
        },
        {
            Name: 'Ascension Island',
            Code: 'ASC'
        },
        {
            Name: 'Australia',
            Code: 'AUS',
            State: [{
                Name: 'Canberra',
                Code: 'ACT',
                City: {
                    Name: 'Canberra',
                    Code: 'CBR'
                }
            },
            {
                Name: 'New South Wales',
                Code: 'NSW',
                City: [{
                    Name: 'Newcastle',
                    Code: 'NTL'
                },
                {
                    Name: 'Sydney',
                    Code: 'HBS'
                },
                {
                    Name: 'Wollongong',
                    Code: 'WOL'
                }
                ]
            },
            {
                Name: 'Northern Territory',
                Code: 'NT',
                City: [{
                    Name: 'Darwin',
                    Code: 'DRW'
                },
                {
                    Name: 'Palmerston',
                    Code: 'PAL'
                }
                ]
            },
            {
                Name: 'Queensland',
                Code: 'QLD',
                City: [{
                    Name: 'Brisbane',
                    Code: 'BNE'
                },
                {
                    Name: 'Cairns',
                    Code: 'CNS'
                },
                {
                    Name: 'Caloundra',
                    Code: 'CUD'
                },
                {
                    Name: 'Gold Coast',
                    Code: 'OOL'
                },
                {
                    Name: 'Toowoomba',
                    Code: 'TWB'
                },
                {
                    Name: 'Townsville',
                    Code: 'TSV'
                }
                ]
            },
            {
                Name: 'South Australia',
                Code: 'SA',
                City: [{
                    Name: 'Adelaide',
                    Code: 'ADL'
                },
                {
                    Name: 'Mount Gambier',
                    Code: 'MGB'
                },
                {
                    Name: 'Murray Bridge',
                    Code: 'MYB'
                },
                {
                    Name: 'Port Augusta',
                    Code: 'PUG'
                },
                {
                    Name: 'Port Lincoln',
                    Code: 'PLO'
                },
                {
                    Name: 'Port Pirie',
                    Code: 'PPI'
                },
                {
                    Name: 'Victor Harbor',
                    Code: 'VHA'
                },
                {
                    Name: 'Whyalla',
                    Code: 'WAY'
                }
                ]
            },
            {
                Name: 'Tasmania',
                Code: 'TAS',
                City: [{
                    Name: 'Burnie',
                    Code: 'BWT'
                },
                {
                    Name: 'Devonport',
                    Code: 'DPO'
                },
                {
                    Name: 'Hobart',
                    Code: 'HBA'
                },
                {
                    Name: 'Launceston',
                    Code: 'LST'
                }
                ]
            },
            {
                Name: 'Victoria',
                Code: 'VIC',
                City: [{
                    Name: 'Geelong',
                    Code: 'GEX'
                },
                {
                    Name: 'Melbourne',
                    Code: 'MEL'
                }
                ]
            },
            {
                Name: 'Western Australia',
                Code: 'WA',
                City: [{
                    Name: 'Albany',
                    Code: 'ALH'
                },
                {
                    Name: 'Bunbury',
                    Code: 'BUY'
                },
                {
                    Name: 'Fremantle',
                    Code: 'FRE'
                },
                {
                    Name: 'Geraldton',
                    Code: 'GET'
                },
                {
                    Name: 'Kalgoorlie',
                    Code: 'KGI'
                },
                {
                    Name: 'Mandurah',
                    Code: 'MDU'
                },
                {
                    Name: 'Perth',
                    Code: 'PER'
                }
                ]
            }
            ]
        },
        {
            Name: 'Austria',
            Code: 'AUT',
            State: {
                City: [{
                    Name: 'Burgenland',
                    Code: 'BUR'
                },
                {
                    Name: 'Carinthia',
                    Code: 'CAT'
                },
                {
                    Name: 'Lower Austria',
                    Code: 'LAU'
                },
                {
                    Name: 'Salzburg',
                    Code: 'SZG'
                },
                {
                    Name: 'Styria',
                    Code: 'STY'
                },
                {
                    Name: 'Tyrol',
                    Code: 'TYR'
                },
                {
                    Name: 'Upper Austria',
                    Code: 'UAU'
                },
                {
                    Name: 'Vienna',
                    Code: 'VDD'
                },
                {
                    Name: 'Vorarlberg',
                    Code: 'VOR'
                }
                ]
            }
        },
        {
            Name: 'Azerbaijan',
            Code: 'AZE',
            State: {
                City: [{
                    Name: 'Abseron',
                    Code: 'ABS'
                },
                {
                    Name: 'Ganca',
                    Code: 'GA'
                },
                {
                    Name: 'Kalbacar',
                    Code: 'KAL'
                },
                {
                    Name: 'Lankaran',
                    Code: 'LAN'
                },
                {
                    Name: 'Mil-Qarabax',
                    Code: 'MQA'
                },
                {
                    Name: 'Mugan-Salyan',
                    Code: 'MSA'
                },
                {
                    Name: 'Nagorni-Qarabax',
                    Code: 'NQA'
                },
                {
                    Name: 'Naxcivan',
                    Code: 'NX'
                },
                {
                    Name: 'Priaraks',
                    Code: 'PRI'
                },
                {
                    Name: 'Qazax',
                    Code: 'QAZ'
                },
                {
                    Name: 'Saki',
                    Code: 'SA'
                },
                {
                    Name: 'Sirvan',
                    Code: 'SIR'
                },
                {
                    Name: 'Sumqayit',
                    Code: 'SMC'
                },
                {
                    Name: 'Xacmaz',
                    Code: 'XAC'
                }
                ]
            }
        },
        {
            Name: 'Bahamas',
            Code: 'BHS'
        },
        {
            Name: 'Bahrain',
            Code: 'BHR',
            State: {
                City: [{
                    Name: 'Al-Gharbiyah',
                    Code: '10'
                },
                {
                    Name: 'Al-Hadd',
                    Code: '1'
                },
                {
                    Name: 'Al-Manamah',
                    Code: '3'
                },
                {
                    Name: 'Al-Muharraq',
                    Code: '2'
                },
                {
                    Name: 'Al-Wusta',
                    Code: '7'
                },
                {
                    Name: 'Ar-Rifa',
                    Code: '9'
                },
                {
                    Name: 'Ash-Shamaliyah',
                    Code: '5'
                },
                {
                    Name: 'Hammad',
                    Code: '12'
                },
                {
                    Name: 'Isa',
                    Code: '8'
                }
                ]
            }
        },
        {
            Name: 'Bangladesh',
            Code: 'BGD',
            State: {
                City: [{
                    Name: 'Chittagong',
                    Code: 'CGP'
                },
                {
                    Name: 'Dhaka',
                    Code: 'DAC'
                },
                {
                    Name: 'Khulna',
                    Code: 'KHL'
                }
                ]
            }
        },
        {
            Name: 'Barbados',
            Code: 'BRB'
        },
        {
            Name: 'Belgium',
            Code: 'BEL',
            State: {
                City: [{
                    Name: 'Antwerpen',
                    Code: 'VAN'
                },
                {
                    Name: 'Brabant-Wallone',
                    Code: 'WBR'
                },
                {
                    Name: 'Brussels',
                    Code: 'BRU'
                },
                {
                    Name: 'Hainaut',
                    Code: 'WHT'
                },
                {
                    Name: 'Liege',
                    Code: 'WLG'
                },
                {
                    Name: 'Limburg',
                    Code: 'VLI'
                },
                {
                    Name: 'Luxembourg',
                    Code: 'WLX'
                },
                {
                    Name: 'Namur',
                    Code: 'WNA'
                },
                {
                    Name: 'Oost-Vlaanderen',
                    Code: 'VOV'
                },
                {
                    Name: 'Vlaams-Brabant',
                    Code: 'VBR'
                },
                {
                    Name: 'West-Vlaanderen',
                    Code: 'VWV'
                }
                ]
            }
        },
        {
            Name: 'Belize',
            Code: 'BLZ',
            State: {
                City: [{
                    Name: 'Belize',
                    Code: 'BZ'
                },
                {
                    Name: 'Cayo',
                    Code: 'CY'
                },
                {
                    Name: 'Corozal',
                    Code: 'CR'
                },
                {
                    Name: 'Orange Walk',
                    Code: 'OW'
                },
                {
                    Name: 'Stann Creek',
                    Code: 'SC'
                },
                {
                    Name: 'Toledo',
                    Code: 'TO'
                }
                ]
            }
        },
        {
            Name: 'Benin',
            Code: 'BEN',
            State: {
                City: [{
                    Name: 'Alibori',
                    Code: 'AL'
                },
                {
                    Name: 'Atakora',
                    Code: 'AK'
                },
                {
                    Name: 'Atlantique',
                    Code: 'AQ'
                },
                {
                    Name: 'Bohicon',
                    Code: 'BOH'
                },
                {
                    Name: 'Borgou',
                    Code: 'BO'
                },
                {
                    Name: 'Collines',
                    Code: 'CO'
                },
                {
                    Name: 'Donga',
                    Code: 'DO'
                },
                {
                    Name: 'Kouffo',
                    Code: 'KO'
                },
                {
                    Name: 'Littoral',
                    Code: 'LI'
                },
                {
                    Name: 'Mono',
                    Code: 'MO'
                },
                {
                    Name: 'Oueme',
                    Code: 'OU'
                },
                {
                    Name: 'Plateau',
                    Code: 'PL'
                },
                {
                    Name: 'Zou',
                    Code: 'ZO'
                }
                ]
            }
        },
        {
            Name: 'Bermuda',
            Code: 'BMU'
        },
        {
            Name: 'Bhutan',
            Code: 'BTN'
        },
        {
            Name: 'Bolivia',
            Code: 'BOL',
            State: {
                City: [{
                    Name: 'Chuquisaca',
                    Code: 'CHU'
                },
                {
                    Name: 'Cochabamba',
                    Code: 'CBB'
                },
                {
                    Name: 'El Alto',
                    Code: 'ALT'
                },
                {
                    Name: 'El Beni',
                    Code: 'BEN'
                },
                {
                    Name: 'La Paz',
                    Code: 'LPB'
                },
                {
                    Name: 'Oruro',
                    Code: 'ORU'
                },
                {
                    Name: 'Pando',
                    Code: 'PAN'
                },
                {
                    Name: 'Potosi',
                    Code: 'POI'
                },
                {
                    Name: 'Quillacollo',
                    Code: 'QUI'
                },
                {
                    Name: 'Sacaba',
                    Code: 'SAC'
                },
                {
                    Name: 'Santa Cruz',
                    Code: 'SRZ'
                },
                {
                    Name: 'Tarija',
                    Code: 'TJA'
                }
                ]
            }
        },
        {
            Name: 'Bosnia and Herzegovina',
            Code: 'BIH',
            State: {
                City: [{
                    Name: 'Bosansko-Podrinjski',
                    Code: 'FBP'
                },
                {
                    Name: 'Hercegovacko-Bosanski',
                    Code: 'FHB'
                },
                {
                    Name: 'Hercegovacko-Neretvanski',
                    Code: 'FHN'
                },
                {
                    Name: 'Posavski',
                    Code: 'FPO'
                },
                {
                    Name: 'Sarajevo',
                    Code: 'FSA'
                },
                {
                    Name: 'Srednjo-Bosanski',
                    Code: 'FSB'
                },
                {
                    Name: 'Tomislavgrad',
                    Code: 'FTO'
                },
                {
                    Name: 'Tuzlanski-Podrinjski',
                    Code: 'FTU'
                },
                {
                    Name: 'Unsko-Sanski',
                    Code: 'FUS'
                },
                {
                    Name: 'Zapadno-Hercegovaki',
                    Code: 'FZH'
                },
                {
                    Name: 'Zenicko-Dobojski',
                    Code: 'FZE'
                }
                ]
            }
        },
        {
            Name: 'Botswana',
            Code: 'BWA'
        },
        {
            Name: 'Bouvet Island',
            Code: 'BVT'
        },
        {
            Name: 'Brazil',
            Code: 'BRA',
            State: {
                City: [{
                    Name: 'Acre',
                    Code: 'AC'
                },
                {
                    Name: 'Alagoas',
                    Code: 'AL'
                },
                {
                    Name: 'Amapa',
                    Code: 'AP'
                },
                {
                    Name: 'Amazonas',
                    Code: 'AM'
                },
                {
                    Name: 'Bahia',
                    Code: 'BA'
                },
                {
                    Name: 'Brasilia',
                    Code: 'BSB'
                },
                {
                    Name: 'Ceara',
                    Code: 'CE'
                },
                {
                    Name: 'Espirito Santo',
                    Code: 'ES'
                },
                {
                    Name: 'Goias',
                    Code: 'GO'
                },
                {
                    Name: 'Maranhao',
                    Code: 'MA'
                },
                {
                    Name: 'Mato Grosso',
                    Code: 'MT'
                },
                {
                    Name: 'Mato Grosso do Sul',
                    Code: 'MS'
                },
                {
                    Name: 'Minas Gerais',
                    Code: 'MG'
                },
                {
                    Name: 'Para',
                    Code: 'PA'
                },
                {
                    Name: 'Paraiba',
                    Code: 'PB'
                },
                {
                    Name: 'Parana',
                    Code: 'PR'
                },
                {
                    Name: 'Pernambuco',
                    Code: 'PE'
                },
                {
                    Name: 'Piaui',
                    Code: 'PI'
                },
                {
                    Name: 'Rio de Janeiro',
                    Code: 'RJ'
                },
                {
                    Name: 'Rio Grande do Norte',
                    Code: 'RN'
                },
                {
                    Name: 'Rio Grande do Sul',
                    Code: 'RS'
                },
                {
                    Name: 'Rondonia',
                    Code: 'RO'
                },
                {
                    Name: 'Roraima',
                    Code: 'RR'
                },
                {
                    Name: 'Santa Catarina',
                    Code: 'SC'
                },
                {
                    Name: 'Sao Paulo',
                    Code: 'SP'
                },
                {
                    Name: 'Sergipe',
                    Code: 'SE'
                },
                {
                    Name: 'Tocantins',
                    Code: 'TO'
                }
                ]
            }
        },
        {
            Name: 'British Indian Ocean Territory',
            Code: 'IOT'
        },
        {
            Name: 'Brunei',
            Code: 'BRN'
        },
        {
            Name: 'Bulgaria',
            Code: 'BGR',
            State: {
                City: [{
                    Name: 'Burgas',
                    Code: 'BOJ'
                },
                {
                    Name: 'Grad Sofiya',
                    Code: 'GSO'
                },
                {
                    Name: 'Khaskovo',
                    Code: 'KHO'
                },
                {
                    Name: 'Lovech',
                    Code: 'LVP'
                },
                {
                    Name: 'Montana',
                    Code: 'OZA'
                },
                {
                    Name: 'Plovdiv',
                    Code: 'PDV'
                },
                {
                    Name: 'Ruse',
                    Code: 'ROU'
                },
                {
                    Name: 'Sofiya',
                    Code: 'SOF'
                },
                {
                    Name: 'Varna',
                    Code: 'VAR'
                }
                ]
            }
        },
        {
            Name: 'Burkina Faso',
            Code: 'BFA',
            State: {
                City: [{
                    Name: 'Bale',
                    Code: 'BAL'
                },
                {
                    Name: 'Bam',
                    Code: 'BAM'
                },
                {
                    Name: 'Banwa',
                    Code: 'BAN'
                },
                {
                    Name: 'Bazega',
                    Code: 'BAZ'
                },
                {
                    Name: 'Bougouriba',
                    Code: 'BOR'
                },
                {
                    Name: 'Boulgou',
                    Code: 'BLG'
                },
                {
                    Name: 'Boulkiemde',
                    Code: 'BOK'
                },
                {
                    Name: 'Comoe',
                    Code: 'COM'
                },
                {
                    Name: 'Ganzourgou',
                    Code: 'GAN'
                },
                {
                    Name: 'Gnagna',
                    Code: 'GNA'
                },
                {
                    Name: 'Gourma',
                    Code: 'GOU'
                },
                {
                    Name: 'Houet',
                    Code: 'HOU'
                },
                {
                    Name: 'Ioba',
                    Code: 'IOA'
                },
                {
                    Name: 'Kadiogo',
                    Code: 'KAD'
                },
                {
                    Name: 'Kenedougou',
                    Code: 'KEN'
                },
                {
                    Name: 'Komondjari',
                    Code: 'KOO'
                },
                {
                    Name: 'Kompienga',
                    Code: 'KOP'
                },
                {
                    Name: 'Kossi',
                    Code: 'KOS'
                },
                {
                    Name: 'Koulpelogo',
                    Code: 'KOL'
                },
                {
                    Name: 'Kouritenga',
                    Code: 'KOT'
                },
                {
                    Name: 'Kourweogo',
                    Code: 'KOW'
                },
                {
                    Name: 'Leraba',
                    Code: 'LER'
                },
                {
                    Name: 'Loroum',
                    Code: 'LOR'
                },
                {
                    Name: 'Mouhoun',
                    Code: 'MOU'
                },
                {
                    Name: 'Nahouri',
                    Code: 'NAH'
                },
                {
                    Name: 'Namentenga',
                    Code: 'NAM'
                },
                {
                    Name: 'Nayala',
                    Code: 'NAY'
                },
                {
                    Name: 'Noumbiel',
                    Code: 'NOU'
                },
                {
                    Name: 'Oubritenga',
                    Code: 'OUB'
                },
                {
                    Name: 'Oudalan',
                    Code: 'OUD'
                },
                {
                    Name: 'Passore',
                    Code: 'PAS'
                },
                {
                    Name: 'Poni',
                    Code: 'PON'
                },
                {
                    Name: 'Sanguie',
                    Code: 'SAG'
                },
                {
                    Name: 'Sanmatenga',
                    Code: 'SAM'
                },
                {
                    Name: 'Seno',
                    Code: 'SEN'
                },
                {
                    Name: 'Sissili',
                    Code: 'SIS'
                },
                {
                    Name: 'Soum',
                    Code: 'SOM'
                },
                {
                    Name: 'Sourou',
                    Code: 'SOR'
                },
                {
                    Name: 'Tapoa',
                    Code: 'TAP'
                },
                {
                    Name: 'Tuy',
                    Code: 'TUY'
                },
                {
                    Name: 'Yagha',
                    Code: 'YAG'
                },
                {
                    Name: 'Yatenga',
                    Code: 'YAT'
                },
                {
                    Name: 'Ziro',
                    Code: 'ZIR'
                },
                {
                    Name: 'Zondoma',
                    Code: 'ZOD'
                },
                {
                    Name: 'Zoundweogo',
                    Code: 'ZOW'
                }
                ]
            }
        },
        {
            Name: 'Burundi',
            Code: 'BDI',
            State: {
                City: [{
                    Name: 'Bubanza',
                    Code: 'BB'
                },
                {
                    Name: 'Bujumbura Mairie',
                    Code: 'BM'
                },
                {
                    Name: 'Bujumbura Rural',
                    Code: 'BU'
                },
                {
                    Name: 'Bururi',
                    Code: 'BR'
                },
                {
                    Name: 'Cankuzo',
                    Code: 'CA'
                },
                {
                    Name: 'Cibitoke',
                    Code: 'CI'
                },
                {
                    Name: 'Gitega',
                    Code: 'GI'
                },
                {
                    Name: 'Karuzi',
                    Code: 'KR'
                },
                {
                    Name: 'Kayanza',
                    Code: 'KY'
                },
                {
                    Name: 'Kirundo',
                    Code: 'KI'
                },
                {
                    Name: 'Makamba',
                    Code: 'MA'
                },
                {
                    Name: 'Muramvya',
                    Code: 'MU'
                },
                {
                    Name: 'Muyinga',
                    Code: 'MY'
                },
                {
                    Name: 'Mwaro',
                    Code: 'MW'
                },
                {
                    Name: 'Ngozi',
                    Code: 'NG'
                },
                {
                    Name: 'Rutana',
                    Code: 'RT'
                },
                {
                    Name: 'Ruyigi',
                    Code: 'RY'
                }
                ]
            }
        },
        {
            Name: 'Cambodia',
            Code: 'KHM',
            State: {
                City: [{
                    Name: 'Banteay Mean Chey',
                    Code: 'BM'
                },
                {
                    Name: 'Bat Dambang',
                    Code: 'BA'
                },
                {
                    Name: 'Kampong Cham',
                    Code: 'KM'
                },
                {
                    Name: 'Kampong Chhnang',
                    Code: 'KZC'
                },
                {
                    Name: 'Kampong Spoe',
                    Code: 'KO'
                },
                {
                    Name: 'Kampong Thum',
                    Code: 'KZK'
                },
                {
                    Name: 'Kampot',
                    Code: 'KMT'
                },
                {
                    Name: 'Kandal',
                    Code: 'KL'
                },
                {
                    Name: 'Kaoh Kong',
                    Code: 'KKZ'
                },
                {
                    Name: 'Kracheh',
                    Code: 'KH'
                },
                {
                    Name: 'Krong Keb',
                    Code: 'KB'
                },
                {
                    Name: 'Krong Pailin',
                    Code: 'PL'
                },
                {
                    Name: 'Krong Preah',
                    Code: 'KA'
                },
                {
                    Name: 'Mondol Kiri',
                    Code: 'MWV'
                },
                {
                    Name: 'Otdar Mean Chey',
                    Code: 'OC'
                },
                {
                    Name: 'Phnum Penh',
                    Code: 'PNH'
                },
                {
                    Name: 'Pouthĭsat',
                    Code: 'PO'
                },
                {
                    Name: 'Preah Vihear',
                    Code: 'PR'
                },
                {
                    Name: 'Prey Veng',
                    Code: 'PG'
                },
                {
                    Name: 'Rotanak Kiri',
                    Code: 'RBE'
                },
                {
                    Name: 'Siem Reab',
                    Code: 'REP'
                },
                {
                    Name: 'Stoeng Treng',
                    Code: 'TNX'
                },
                {
                    Name: 'Svay Rieng',
                    Code: 'SVR'
                },
                {
                    Name: 'Takev',
                    Code: 'TK'
                }
                ]
            }
        },
        {
            Name: 'Cameroon',
            Code: 'CMR',
            State: {
                City: [{
                    Name: 'Adamaoua',
                    Code: 'ADA'
                },
                {
                    Name: 'Centre',
                    Code: 'CEN'
                },
                {
                    Name: 'Est',
                    Code: 'EST'
                },
                {
                    Name: 'Extreme-Nord',
                    Code: 'EXN'
                },
                {
                    Name: 'Littoral',
                    Code: 'LIT'
                },
                {
                    Name: 'Nord',
                    Code: 'NOR'
                },
                {
                    Name: 'Nord-Oueste',
                    Code: 'NOT'
                },
                {
                    Name: 'Ouest',
                    Code: 'OUE'
                },
                {
                    Name: 'Sud',
                    Code: 'SUD'
                },
                {
                    Name: 'Sud-Oueste',
                    Code: 'SOU'
                }
                ]
            }
        },
        {
            Name: 'Canada',
            Code: 'CAN',
            State: {
                City: [{
                    Name: 'Abbotsford',
                    Code: 'ABB'
                },
                {
                    Name: 'Barrie',
                    Code: 'BAR'
                },
                {
                    Name: 'Brampton',
                    Code: 'BRP'
                },
                {
                    Name: 'Calgary',
                    Code: 'CAL'
                },
                {
                    Name: 'Cape Breton',
                    Code: 'CBR'
                },
                {
                    Name: 'Charlottetown',
                    Code: 'CHA'
                },
                {
                    Name: 'Edmonton',
                    Code: 'EDM'
                },
                {
                    Name: 'Fredericton',
                    Code: 'FRE'
                },
                {
                    Name: 'Guelph',
                    Code: 'GLP'
                },
                {
                    Name: 'Halifax',
                    Code: 'HAL'
                },
                {
                    Name: 'Hamilton',
                    Code: 'HAM'
                },
                {
                    Name: 'Iqaluit',
                    Code: 'IQL'
                },
                {
                    Name: 'Kelowna',
                    Code: 'KWL'
                },
                {
                    Name: 'Kingston',
                    Code: 'KGN'
                },
                {
                    Name: 'London',
                    Code: 'LOD'
                },
                {
                    Name: 'Montreal',
                    Code: 'MTR'
                },
                {
                    Name: 'Oshawa',
                    Code: 'OSH'
                },
                {
                    Name: 'Ottawa',
                    Code: 'OTT'
                },
                {
                    Name: 'Quebec',
                    Code: 'QUE'
                },
                {
                    Name: 'Regina',
                    Code: 'REG'
                },
                {
                    Name: "Saint-John's",
                    Code: 'SJB'
                },
                {
                    Name: 'Saskatoon',
                    Code: 'SAK'
                },
                {
                    Name: 'Sherbrooke',
                    Code: 'SBE'
                },
                {
                    Name: 'St. Catharines',
                    Code: 'SCA'
                },
                {
                    Name: 'Sudbury',
                    Code: 'SUD'
                },
                {
                    Name: 'Thunder Bay',
                    Code: 'THU'
                },
                {
                    Name: 'Toronto',
                    Code: 'TOR'
                },
                {
                    Name: 'Trois-Rivieres',
                    Code: 'TRR'
                },
                {
                    Name: 'Vancouver',
                    Code: 'VAN'
                },
                {
                    Name: 'Victoria',
                    Code: 'VIC'
                },
                {
                    Name: 'Whitehorse',
                    Code: 'YXY'
                },
                {
                    Name: 'Windsor',
                    Code: 'WDR'
                },
                {
                    Name: 'Winnipeg',
                    Code: 'WNP'
                },
                {
                    Name: 'Yellowknife',
                    Code: 'YZF'
                }
                ]
            }
        },
        {
            Name: 'Cape Verde',
            Code: 'CPV',
            State: {
                City: [{
                    Name: 'Boa Vista',
                    Code: 'BV'
                },
                {
                    Name: 'Brava',
                    Code: 'BR'
                },
                {
                    Name: 'Fogo',
                    Code: 'FO'
                },
                {
                    Name: 'Maio',
                    Code: 'MA'
                },
                {
                    Name: 'Mosteiros',
                    Code: 'MO'
                },
                {
                    Name: 'Paul',
                    Code: 'PA'
                },
                {
                    Name: 'Porto Novo',
                    Code: 'PN'
                },
                {
                    Name: 'Praia',
                    Code: 'PR'
                },
                {
                    Name: 'Ribeira Grande',
                    Code: 'RG'
                },
                {
                    Name: 'Sal',
                    Code: 'SL'
                },
                {
                    Name: 'Santa Catarina',
                    Code: 'CA'
                },
                {
                    Name: 'Santa Cruz',
                    Code: 'CR'
                },
                {
                    Name: 'Santiago',
                    Code: 'IA'
                },
                {
                    Name: 'Santo Antao',
                    Code: 'SA'
                },
                {
                    Name: 'Sao Domingos',
                    Code: 'SD'
                },
                {
                    Name: 'Sao Filipe',
                    Code: 'SF'
                },
                {
                    Name: 'Sao Miguel',
                    Code: 'SM'
                },
                {
                    Name: 'Sao Nicolau',
                    Code: 'SN'
                },
                {
                    Name: 'Sao Vicente',
                    Code: 'SV'
                },
                {
                    Name: 'Tarrafal',
                    Code: 'TA'
                }
                ]
            }
        },
        {
            Name: 'Cayman Islands',
            Code: 'CYM'
        },
        {
            Name: 'Central African Republic',
            Code: 'CAF',
            State: {
                City: [{
                    Name: 'Bamingui-Bangoran',
                    Code: 'BB'
                },
                {
                    Name: 'Bangui',
                    Code: 'BGF'
                },
                {
                    Name: 'Basse-Kotto',
                    Code: 'BK'
                },
                {
                    Name: 'Bimbo',
                    Code: 'BI'
                },
                {
                    Name: 'Haute-Kotto',
                    Code: 'HK'
                },
                {
                    Name: 'Haut-Mbomou',
                    Code: 'HM'
                },
                {
                    Name: 'Kemo',
                    Code: 'KG'
                },
                {
                    Name: 'Lobaye',
                    Code: 'LB'
                },
                {
                    Name: 'Mambere-Kadei',
                    Code: 'HS'
                },
                {
                    Name: 'Mbomou',
                    Code: 'MB'
                },
                {
                    Name: 'Nana-Gribizi',
                    Code: 'KB'
                },
                {
                    Name: 'Nana-Mambere',
                    Code: 'NM'
                },
                {
                    Name: 'Ombella-Mpoko',
                    Code: 'MP'
                },
                {
                    Name: 'Ouaka',
                    Code: 'UK'
                },
                {
                    Name: 'Ouham',
                    Code: 'AC'
                },
                {
                    Name: 'Ouham-Pende',
                    Code: 'OP'
                },
                {
                    Name: 'Sangha-Mbaere',
                    Code: 'SE'
                },
                {
                    Name: 'Vakaga',
                    Code: 'VK'
                }
                ]
            }
        },
        {
            Name: 'Chad',
            Code: 'TCD'
        },
        {
            Name: 'Chile',
            Code: 'CHL',
            State: {
                City: [{
                    Name: 'Libertador',
                    Code: 'LI'
                },
                {
                    Name: 'Magallanes y Antartica Chilena',
                    Code: 'MA'
                },
                {
                    Name: 'Metropolitana de Santiago',
                    Code: 'RM'
                },
                {
                    Name: 'Region de Alsen del General Carlos Ibanez del',
                    Code: 'AI'
                },
                {
                    Name: 'Region de Antofagasta',
                    Code: 'AN'
                },
                {
                    Name: 'Region de Atacama',
                    Code: 'AT'
                },
                {
                    Name: 'Region de Coquimbo',
                    Code: 'CO'
                },
                {
                    Name: 'Region de la Araucania',
                    Code: 'AR'
                },
                {
                    Name: 'Region de los Lagos',
                    Code: 'LL'
                },
                {
                    Name: 'Region de Tarapaca',
                    Code: 'TA'
                },
                {
                    Name: 'Region de Valparaiso',
                    Code: 'VS'
                },
                {
                    Name: 'Region del Biobio',
                    Code: 'BI'
                },
                {
                    Name: 'Region del Maule',
                    Code: 'ML'
                }
                ]
            }
        },
        {
            Name: '中国',
            Code: '1',
            State: [{
                Name: 'Anhui',
                Code: '34',
                City: [{
                    Name: 'Anqing',
                    Code: '8'
                },
                {
                    Name: 'Bengbu',
                    Code: '3'
                },
                {
                    Name: 'Bozhou',
                    Code: '16'
                },
                {
                    Name: 'Chaohu',
                    Code: '14'
                },
                {
                    Name: 'Chizhou',
                    Code: '17'
                },
                {
                    Name: 'Chuzhou',
                    Code: '11'
                },
                {
                    Name: 'Fuyang',
                    Code: '12'
                },
                {
                    Name: 'Hefei',
                    Code: '1'
                },
                {
                    Name: 'Huaibei',
                    Code: '6'
                },
                {
                    Name: 'Huainan',
                    Code: '4'
                },
                {
                    Name: 'Huangshan',
                    Code: '10'
                },
                {
                    Name: "Lu'an",
                    Code: '15'
                },
                {
                    Name: "Ma'anshan",
                    Code: '5'
                },
                {
                    Name: 'Suzhou',
                    Code: '13'
                },
                {
                    Name: 'Tongling',
                    Code: '7'
                },
                {
                    Name: 'Wuhu',
                    Code: '2'
                },
                {
                    Name: 'Xuancheng',
                    Code: '18'
                }
                ]
            },
            {
                Name: 'Beijing',
                Code: '11',
                City: [{
                    Name: 'Changping',
                    Code: '21'
                },
                {
                    Name: 'Chaoyang',
                    Code: '5'
                },
                {
                    Name: 'Chongwen',
                    Code: '3'
                },
                {
                    Name: 'Daxing',
                    Code: '24'
                },
                {
                    Name: 'Dongcheng',
                    Code: '1'
                },
                {
                    Name: 'Fangshan',
                    Code: '11'
                },
                {
                    Name: 'Fengtai',
                    Code: '6'
                },
                {
                    Name: 'Haidian',
                    Code: '8'
                },
                {
                    Name: 'Huairou',
                    Code: '27'
                },
                {
                    Name: 'Mentougou',
                    Code: '9'
                },
                {
                    Name: 'Miyun',
                    Code: '28'
                },
                {
                    Name: 'Pinggu',
                    Code: '26'
                },
                {
                    Name: 'Shijingshan',
                    Code: '7'
                },
                {
                    Name: 'Shunyi',
                    Code: '13'
                },
                {
                    Name: 'Tongzhou',
                    Code: '12'
                },
                {
                    Name: 'Xicheng',
                    Code: '2'
                },
                {
                    Name: 'Xuanwu',
                    Code: '4'
                },
                {
                    Name: 'Yanqing',
                    Code: '29'
                }
                ]
            },
            {
                Name: 'Chongqing',
                Code: '50',
                City: [{
                    Name: "Ba'nan",
                    Code: '13'
                },
                {
                    Name: 'Beibei',
                    Code: '9'
                },
                {
                    Name: 'Bishan',
                    Code: '27'
                },
                {
                    Name: 'Changshou',
                    Code: '21'
                },
                {
                    Name: 'Chengkou',
                    Code: '29'
                },
                {
                    Name: 'Dadukou',
                    Code: '4'
                },
                {
                    Name: 'Dazu',
                    Code: '25'
                },
                {
                    Name: 'Dianjiang',
                    Code: '31'
                },
                {
                    Name: 'Fengdu',
                    Code: '30'
                },
                {
                    Name: 'Fengjie',
                    Code: '36'
                },
                {
                    Name: 'Fuling',
                    Code: '2'
                },
                {
                    Name: 'Hechuan',
                    Code: '82'
                },
                {
                    Name: 'Jiangbei',
                    Code: '5'
                },
                {
                    Name: 'Jiangjin',
                    Code: '81'
                },
                {
                    Name: 'Jiulongpo',
                    Code: '7'
                },
                {
                    Name: 'Kaixian',
                    Code: '34'
                },
                {
                    Name: 'Liangping',
                    Code: '28'
                },
                {
                    Name: "Nan'an",
                    Code: '8'
                },
                {
                    Name: 'Nanchuan',
                    Code: '84'
                },
                {
                    Name: 'Pengshui Miao-Tujia Autonomous Country',
                    Code: '43'
                },
                {
                    Name: 'Qianjiang',
                    Code: '39'
                },
                {
                    Name: 'Qijiang',
                    Code: '22'
                },
                {
                    Name: 'Rongchang',
                    Code: '26'
                },
                {
                    Name: 'Shapingba',
                    Code: '6'
                },
                {
                    Name: 'Shizhu Tujia Autonomous Country',
                    Code: '40'
                },
                {
                    Name: 'Shuangqiao',
                    Code: '11'
                },
                {
                    Name: 'Tongliang',
                    Code: '24'
                },
                {
                    Name: 'Tongnan',
                    Code: '23'
                },
                {
                    Name: 'Wansheng',
                    Code: '10'
                },
                {
                    Name: 'Wanzhou',
                    Code: '1'
                },
                {
                    Name: 'Wulong',
                    Code: '32'
                },
                {
                    Name: 'Wushan',
                    Code: '37'
                },
                {
                    Name: 'Wuxi',
                    Code: '38'
                },
                {
                    Name: 'Xiushan Tujia-Miao Autonomous Country',
                    Code: '41'
                },
                {
                    Name: 'Yongchuan',
                    Code: '83'
                },
                {
                    Name: 'Youyang Tujia-Miao Autonomous Country',
                    Code: '42'
                },
                {
                    Name: 'Yubei',
                    Code: '12'
                },
                {
                    Name: 'Yunyang',
                    Code: '35'
                },
                {
                    Name: 'Yuzhong',
                    Code: '3'
                },
                {
                    Name: 'Zhongxian',
                    Code: '33'
                }
                ]
            },
            {
                Name: 'Fujian',
                Code: '35',
                City: [{
                    Name: 'Fuzhou',
                    Code: '1'
                },
                {
                    Name: 'Longyan',
                    Code: '8'
                },
                {
                    Name: 'Nanping',
                    Code: '7'
                },
                {
                    Name: 'Ningde',
                    Code: '9'
                },
                {
                    Name: 'Putian',
                    Code: '3'
                },
                {
                    Name: 'Quanzhou',
                    Code: '5'
                },
                {
                    Name: 'Sanming',
                    Code: '4'
                },
                {
                    Name: 'Xiamen',
                    Code: '2'
                },
                {
                    Name: 'Zhangzhou',
                    Code: '6'
                }
                ]
            },
            {
                Name: 'Gansu',
                Code: '62',
                City: [{
                    Name: 'Baiyin',
                    Code: '3'
                },
                {
                    Name: 'Dingxi',
                    Code: '11'
                },
                {
                    Name: 'Gannan Tibetan Autonomous Prefecture',
                    Code: '30'
                },
                {
                    Name: 'Jiayuguan',
                    Code: '5'
                },
                {
                    Name: 'Jinchang',
                    Code: '2'
                },
                {
                    Name: 'Jiuquan',
                    Code: '9'
                },
                {
                    Name: 'Lanzhou',
                    Code: '1'
                },
                {
                    Name: 'Linxia Hui Autonomous Prefecture',
                    Code: '29'
                },
                {
                    Name: 'Longnan',
                    Code: '12'
                },
                {
                    Name: 'Pingliang',
                    Code: '8'
                },
                {
                    Name: 'Qingyang',
                    Code: '10'
                },
                {
                    Name: 'Tianshui',
                    Code: '4'
                },
                {
                    Name: 'Wuwei',
                    Code: '6'
                },
                {
                    Name: 'Zhangye',
                    Code: '7'
                }
                ]
            },
            {
                Name: 'Guangdong',
                Code: '44',
                City: [{
                    Name: 'Chaozhou',
                    Code: '51'
                },
                {
                    Name: 'Dongguan',
                    Code: '19'
                },
                {
                    Name: 'Foshan',
                    Code: '6'
                },
                {
                    Name: 'Guangzhou',
                    Code: '1'
                },
                {
                    Name: 'Heyuan',
                    Code: '16'
                },
                {
                    Name: 'Huizhou',
                    Code: '13'
                },
                {
                    Name: 'Jiangmen',
                    Code: '7'
                },
                {
                    Name: 'Jieyang',
                    Code: '52'
                },
                {
                    Name: 'Maoming',
                    Code: '9'
                },
                {
                    Name: 'Meizhou',
                    Code: '14'
                },
                {
                    Name: 'Qingyuan',
                    Code: '18'
                },
                {
                    Name: 'Shantou',
                    Code: '5'
                },
                {
                    Name: 'Shanwei',
                    Code: '15'
                },
                {
                    Name: 'Shaoguan',
                    Code: '2'
                },
                {
                    Name: 'Shenzhen',
                    Code: '3'
                },
                {
                    Name: 'Yangjiang',
                    Code: '17'
                },
                {
                    Name: 'Yunfu',
                    Code: '53'
                },
                {
                    Name: 'Zhanjiang',
                    Code: '8'
                },
                {
                    Name: 'Zhaoqing',
                    Code: '12'
                },
                {
                    Name: 'Zhongshan',
                    Code: '20'
                },
                {
                    Name: 'Zhuhai',
                    Code: '4'
                }
                ]
            },
            {
                Name: 'Guangxi',
                Code: '45',
                City: [{
                    Name: 'Baise',
                    Code: '10'
                },
                {
                    Name: 'Beihai',
                    Code: '5'
                },
                {
                    Name: 'Chongzuo',
                    Code: '14'
                },
                {
                    Name: 'Fangchenggang',
                    Code: '6'
                },
                {
                    Name: 'Guigang',
                    Code: '8'
                },
                {
                    Name: 'Guilin',
                    Code: '3'
                },
                {
                    Name: 'Hechi',
                    Code: '12'
                },
                {
                    Name: 'Hezhou',
                    Code: '11'
                },
                {
                    Name: 'Laibin',
                    Code: '13'
                },
                {
                    Name: 'Liuzhou',
                    Code: '2'
                },
                {
                    Name: 'Nanning',
                    Code: '1'
                },
                {
                    Name: 'Qinzhou',
                    Code: '7'
                },
                {
                    Name: 'Wuzhou',
                    Code: '4'
                },
                {
                    Name: 'Yulin',
                    Code: '9'
                }
                ]
            },
            {
                Name: 'Guizhou',
                Code: '52',
                City: [{
                    Name: 'Anshun',
                    Code: '4'
                },
                {
                    Name: 'Bijie',
                    Code: '24'
                },
                {
                    Name: 'Guiyang',
                    Code: '1'
                },
                {
                    Name: 'Liupanshui',
                    Code: '2'
                },
                {
                    Name: 'Qiandongnan Miao-Dong Autonomous Prefecture',
                    Code: '26'
                },
                {
                    Name: 'Qiannan Buyi Autonomous Prefecture',
                    Code: '27'
                },
                {
                    Name: 'Qianxinan Buyi-Miao Autonomous Prefecture',
                    Code: '23'
                },
                {
                    Name: 'Tongren',
                    Code: '22'
                },
                {
                    Name: 'Zunyi',
                    Code: '3'
                }
                ]
            },
            {
                Name: 'Hainan',
                Code: '46',
                City: [{
                    Name: 'Baisha Li Autonomous County',
                    Code: 'A30'
                },
                {
                    Name: 'Baoting Li-Miao Autonomous County',
                    Code: 'A35'
                },
                {
                    Name: 'Cengmai',
                    Code: 'A27'
                },
                {
                    Name: 'Danzhou',
                    Code: '93'
                },
                {
                    Name: "Ding'an",
                    Code: 'A25'
                },
                {
                    Name: 'Dongfang',
                    Code: '97'
                },
                {
                    Name: 'Haikou',
                    Code: '1'
                },
                {
                    Name: 'Jiang Li Autonomous County',
                    Code: 'A31'
                },
                {
                    Name: 'Ledong Li Autonomous County',
                    Code: 'A33'
                },
                {
                    Name: 'Lingao',
                    Code: 'A28'
                },
                {
                    Name: 'Lingshui Li Autonomous County',
                    Code: 'A34'
                },
                {
                    Name: 'Nansha Qundao',
                    Code: 'A38'
                },
                {
                    Name: 'Qionghai',
                    Code: '92'
                },
                {
                    Name: 'Qiongzhong Li-Miao Autonomous County',
                    Code: 'A36'
                },
                {
                    Name: 'Sanya',
                    Code: '2'
                },
                {
                    Name: 'Tunchang',
                    Code: 'A26'
                },
                {
                    Name: 'Wanning',
                    Code: '96'
                },
                {
                    Name: 'Wenchang',
                    Code: '95'
                },
                {
                    Name: 'Wuzhishan',
                    Code: '91'
                },
                {
                    Name: 'Xisha Qundao',
                    Code: 'A37'
                },
                {
                    Name: 'Zhongsha Qundao',
                    Code: 'A39'
                }
                ]
            },
            {
                Name: 'Hebei',
                Code: '13',
                City: [{
                    Name: 'Baoding',
                    Code: '6'
                },
                {
                    Name: 'Cangzhou',
                    Code: '9'
                },
                {
                    Name: 'Chengde',
                    Code: '8'
                },
                {
                    Name: 'Handan',
                    Code: '4'
                },
                {
                    Name: 'Hengshui',
                    Code: '11'
                },
                {
                    Name: 'Langfang',
                    Code: '10'
                },
                {
                    Name: 'Qinhuangdao',
                    Code: '3'
                },
                {
                    Name: 'Shijiazhuang',
                    Code: '1'
                },
                {
                    Name: 'Tangshan',
                    Code: '2'
                },
                {
                    Name: 'Xingtai',
                    Code: '5'
                },
                {
                    Name: 'Zhangjiakou',
                    Code: '7'
                }
                ]
            },
            {
                Name: 'Heilongjiang',
                Code: '23',
                City: [{
                    Name: 'Da Hinggan Ling',
                    Code: '27'
                },
                {
                    Name: 'Daqing',
                    Code: '6'
                },
                {
                    Name: 'Harbin',
                    Code: '1'
                },
                {
                    Name: 'Hegang',
                    Code: '4'
                },
                {
                    Name: 'Heihe',
                    Code: '11'
                },
                {
                    Name: 'Jiamusi',
                    Code: '8'
                },
                {
                    Name: 'Jixi',
                    Code: '3'
                },
                {
                    Name: 'Mudanjiang',
                    Code: '10'
                },
                {
                    Name: 'Qiqihar',
                    Code: '2'
                },
                {
                    Name: 'Qitaihe',
                    Code: '9'
                },
                {
                    Name: 'Shuangyashan',
                    Code: '5'
                },
                {
                    Name: 'Suihua',
                    Code: '12'
                },
                {
                    Name: 'Yichun',
                    Code: '7'
                }
                ]
            },
            {
                Name: 'Henan',
                Code: '41',
                City: [{
                    Name: 'Anyang',
                    Code: '5'
                },
                {
                    Name: 'Hebi',
                    Code: '6'
                },
                {
                    Name: 'Jiaozuo',
                    Code: '8'
                },
                {
                    Name: 'Jiyuan',
                    Code: '18'
                },
                {
                    Name: 'Kaifeng',
                    Code: '2'
                },
                {
                    Name: 'Luohe',
                    Code: '11'
                },
                {
                    Name: 'Luoyang',
                    Code: '3'
                },
                {
                    Name: 'Nanyang',
                    Code: '13'
                },
                {
                    Name: 'Pingdingshan',
                    Code: '4'
                },
                {
                    Name: 'Puyang',
                    Code: '9'
                },
                {
                    Name: 'Sanmenxia',
                    Code: '12'
                },
                {
                    Name: 'Shangqiu',
                    Code: '14'
                },
                {
                    Name: 'Xinxiang',
                    Code: '7'
                },
                {
                    Name: 'Xinyang',
                    Code: '15'
                },
                {
                    Name: 'Xuchang',
                    Code: '10'
                },
                {
                    Name: 'Zhengzhou',
                    Code: '1'
                },
                {
                    Name: 'Zhoukou',
                    Code: '16'
                },
                {
                    Name: 'Zhumadian',
                    Code: '17'
                }
                ]
            },
            {
                Name: 'Hongkong SAR',
                Code: '81'
            },
            {
                Name: 'Hubei',
                Code: '42',
                City: [{
                    Name: 'Enshi Tujia-Miao Autonomous Prefecture',
                    Code: '28'
                },
                {
                    Name: 'Ezhou',
                    Code: '7'
                },
                {
                    Name: 'Huanggang',
                    Code: '11'
                },
                {
                    Name: 'Huangshi',
                    Code: '2'
                },
                {
                    Name: 'Jingmen',
                    Code: '8'
                },
                {
                    Name: 'Jingzhou',
                    Code: '10'
                },
                {
                    Name: 'Qianjiang',
                    Code: '95'
                },
                {
                    Name: 'Shennongjia',
                    Code: 'A21'
                },
                {
                    Name: 'Shiyan',
                    Code: '3'
                },
                {
                    Name: 'Suizhou',
                    Code: '13'
                },
                {
                    Name: 'Tianmen',
                    Code: '96'
                },
                {
                    Name: 'Wuhan',
                    Code: '1'
                },
                {
                    Name: 'Xiangfan',
                    Code: '6'
                },
                {
                    Name: 'Xianning',
                    Code: '12'
                },
                {
                    Name: 'Xiantao',
                    Code: '94'
                },
                {
                    Name: 'Xiaogan',
                    Code: '9'
                },
                {
                    Name: 'Yichang',
                    Code: '5'
                }
                ]
            },
            {
                Name: 'Hunan',
                Code: '43',
                City: [{
                    Name: 'Changde',
                    Code: '7'
                },
                {
                    Name: 'Changsha',
                    Code: '1'
                },
                {
                    Name: 'Chenzhou',
                    Code: '10'
                },
                {
                    Name: 'Hengyang',
                    Code: '4'
                },
                {
                    Name: 'Huaihua',
                    Code: '12'
                },
                {
                    Name: 'Loudi',
                    Code: '13'
                },
                {
                    Name: 'Shaoyang',
                    Code: '5'
                },
                {
                    Name: 'Xiangtan',
                    Code: '3'
                },
                {
                    Name: 'Xiangxi Tujia-Miao Autonomous Prefecture',
                    Code: '31'
                },
                {
                    Name: 'Yiyang',
                    Code: '9'
                },
                {
                    Name: 'Yongzhou',
                    Code: '11'
                },
                {
                    Name: 'Yueyang',
                    Code: '6'
                },
                {
                    Name: 'Zhangjiajie',
                    Code: '8'
                },
                {
                    Name: 'Zhuzhou',
                    Code: '2'
                }
                ]
            },
            {
                Name: 'Inner Mongolia',
                Code: '15',
                City: [{
                    Name: 'Alxa',
                    Code: '29'
                },
                {
                    Name: 'Baotou',
                    Code: '2'
                },
                {
                    Name: 'Bayannur',
                    Code: '8'
                },
                {
                    Name: 'Chifeng',
                    Code: '4'
                },
                {
                    Name: 'Hohhot',
                    Code: '1'
                },
                {
                    Name: 'Hulun Buir',
                    Code: '7'
                },
                {
                    Name: 'Ordos',
                    Code: '6'
                },
                {
                    Name: 'Tongliao',
                    Code: '5'
                },
                {
                    Name: 'Ulan Qab',
                    Code: '9'
                },
                {
                    Name: 'Wuhai',
                    Code: '3'
                },
                {
                    Name: 'Xilin Gol',
                    Code: '25'
                },
                {
                    Name: "Xing'an",
                    Code: '22'
                }
                ]
            },
            {
                Name: 'Jiangsu',
                Code: '32',
                City: [{
                    Name: 'Changzhou',
                    Code: '4'
                },
                {
                    Name: "Huai'an",
                    Code: '8'
                },
                {
                    Name: 'Lianyungang',
                    Code: '7'
                },
                {
                    Name: 'Nanjing',
                    Code: '1'
                },
                {
                    Name: 'Nantong',
                    Code: '6'
                },
                {
                    Name: 'Suqian',
                    Code: '13'
                },
                {
                    Name: 'Suzhou',
                    Code: '5'
                },
                {
                    Name: 'Taizhou',
                    Code: '12'
                },
                {
                    Name: 'Wuxi',
                    Code: '2'
                },
                {
                    Name: 'Xuzhou',
                    Code: '3'
                },
                {
                    Name: 'Yancheng',
                    Code: '9'
                },
                {
                    Name: 'Yangzhou',
                    Code: '10'
                },
                {
                    Name: 'Zhenjiang',
                    Code: '11'
                }
                ]
            },
            {
                Name: 'Jiangxi',
                Code: '36',
                City: [{
                    Name: 'Fuzhou',
                    Code: '10'
                },
                {
                    Name: 'Ganzhou',
                    Code: '7'
                },
                {
                    Name: "Ji'an",
                    Code: '8'
                },
                {
                    Name: 'Jingdezhen',
                    Code: '2'
                },
                {
                    Name: 'Jiujiang',
                    Code: '4'
                },
                {
                    Name: 'Nanchang',
                    Code: '1'
                },
                {
                    Name: 'Pingxiang',
                    Code: '3'
                },
                {
                    Name: 'Shangrao',
                    Code: '11'
                },
                {
                    Name: 'Xinyu',
                    Code: '5'
                },
                {
                    Name: 'Yichun',
                    Code: '9'
                },
                {
                    Name: 'Yingtan',
                    Code: '6'
                }
                ]
            },
            {
                Name: 'Jilin',
                Code: '22',
                City: [{
                    Name: 'Baicheng',
                    Code: '8'
                },
                {
                    Name: 'Baishan',
                    Code: '6'
                },
                {
                    Name: 'Changchun',
                    Code: '1'
                },
                {
                    Name: 'Jilin',
                    Code: '2'
                },
                {
                    Name: 'Liaoyuan',
                    Code: '4'
                },
                {
                    Name: 'Siping',
                    Code: '3'
                },
                {
                    Name: 'Songyuan',
                    Code: '7'
                },
                {
                    Name: 'Tonghua',
                    Code: '5'
                },
                {
                    Name: 'Yanbian Korean Autonomous Prefecture',
                    Code: '24'
                }
                ]
            },
            {
                Name: 'Liaoning',
                Code: '21',
                City: [{
                    Name: 'Anshan',
                    Code: '3'
                },
                {
                    Name: 'Benxi',
                    Code: '5'
                },
                {
                    Name: 'Chaoyang',
                    Code: '13'
                },
                {
                    Name: 'Dalian',
                    Code: '2'
                },
                {
                    Name: 'Dandong',
                    Code: '6'
                },
                {
                    Name: 'Fushun',
                    Code: '4'
                },
                {
                    Name: 'Fuxin',
                    Code: '9'
                },
                {
                    Name: 'Huludao',
                    Code: '14'
                },
                {
                    Name: 'Jinzhou',
                    Code: '7'
                },
                {
                    Name: 'Liaoyang',
                    Code: '10'
                },
                {
                    Name: 'Panjin',
                    Code: '11'
                },
                {
                    Name: 'Shenyang',
                    Code: '1'
                },
                {
                    Name: 'Tieling',
                    Code: '12'
                },
                {
                    Name: 'Yingkou',
                    Code: '8'
                }
                ]
            },
            {
                Name: 'Macao SAR',
                Code: '82'
            },
            {
                Name: 'Ningxia',
                Code: '64',
                City: [{
                    Name: 'Guyuan',
                    Code: '4'
                },
                {
                    Name: 'Shizuishan',
                    Code: '2'
                },
                {
                    Name: 'Wuzhong',
                    Code: '3'
                },
                {
                    Name: 'Yinchuan',
                    Code: '1'
                },
                {
                    Name: 'Zhongwei',
                    Code: '5'
                }
                ]
            },
            {
                Name: 'Qinghai',
                Code: '63',
                City: [{
                    Name: 'Guoluo Tibetan Autonomous Prefecture',
                    Code: '26'
                },
                {
                    Name: 'Haibei Tibetan Autonomous Prefecture',
                    Code: '22'
                },
                {
                    Name: 'Haidong',
                    Code: '21'
                },
                {
                    Name: 'Hainan Tibetan Autonomous Prefecture',
                    Code: '25'
                },
                {
                    Name: 'Haixi Mongol-Tibetan Autonomous Prefecture',
                    Code: '28'
                },
                {
                    Name: 'Huangnan Tibetan Autonomous Prefecture',
                    Code: '23'
                },
                {
                    Name: 'Xining',
                    Code: '1'
                },
                {
                    Name: 'Yushu Tibetan Autonomous Prefecture',
                    Code: '27'
                }
                ]
            },
            {
                Name: 'Shanxi',
                Code: '61',
                City: [{
                    Name: 'Ankang',
                    Code: '9'
                },
                {
                    Name: 'Baoji',
                    Code: '3'
                },
                {
                    Name: 'Hanzhong',
                    Code: '7'
                },
                {
                    Name: 'Shangluo',
                    Code: '10'
                },
                {
                    Name: 'Tongchuan',
                    Code: '2'
                },
                {
                    Name: 'Weinan',
                    Code: '5'
                },
                {
                    Name: "Xi'an",
                    Code: '1'
                },
                {
                    Name: 'Xianyang',
                    Code: '4'
                },
                {
                    Name: "Yan'an",
                    Code: '6'
                },
                {
                    Name: 'Yulin',
                    Code: '8'
                }
                ]
            },
            {
                Name: 'Shandong',
                Code: '37',
                City: [{
                    Name: 'Binzhou',
                    Code: '16'
                },
                {
                    Name: 'Dezhou',
                    Code: '14'
                },
                {
                    Name: 'Dongying',
                    Code: '5'
                },
                {
                    Name: 'Heze',
                    Code: '17'
                },
                {
                    Name: 'Jinan',
                    Code: '1'
                },
                {
                    Name: 'Jining',
                    Code: '8'
                },
                {
                    Name: 'Laiwu',
                    Code: '12'
                },
                {
                    Name: 'Liaocheng',
                    Code: '15'
                },
                {
                    Name: 'Linyi',
                    Code: '13'
                },
                {
                    Name: 'Qingdao',
                    Code: '2'
                },
                {
                    Name: 'Rizhao',
                    Code: '11'
                },
                {
                    Name: "Tai'an",
                    Code: '9'
                },
                {
                    Name: 'Weifang',
                    Code: '7'
                },
                {
                    Name: 'Weihai',
                    Code: '10'
                },
                {
                    Name: 'Yantai',
                    Code: '6'
                },
                {
                    Name: 'Zaozhuang',
                    Code: '4'
                },
                {
                    Name: 'Zibo',
                    Code: '3'
                }
                ]
            },
            {
                Name: 'Shanghai',
                Code: '31',
                City: [{
                    Name: 'Baoshan',
                    Code: '13'
                },
                {
                    Name: 'Changning',
                    Code: '5'
                },
                {
                    Name: 'Chongming',
                    Code: '30'
                },
                {
                    Name: 'Fengxian',
                    Code: '26'
                },
                {
                    Name: 'Hongkou',
                    Code: '9'
                },
                {
                    Name: 'Huangpu',
                    Code: '1'
                },
                {
                    Name: 'Jiading',
                    Code: '14'
                },
                {
                    Name: "Jing'an",
                    Code: '6'
                },
                {
                    Name: 'Jinshan',
                    Code: '16'
                },
                {
                    Name: 'Luwan',
                    Code: '3'
                },
                {
                    Name: 'Minhang',
                    Code: '12'
                },
                {
                    Name: 'Nanhui',
                    Code: '25'
                },
                {
                    Name: 'Pudongxin',
                    Code: '15'
                },
                {
                    Name: 'Putuo',
                    Code: '7'
                },
                {
                    Name: 'Qingpu',
                    Code: '29'
                },
                {
                    Name: 'Songjiang',
                    Code: '17'
                },
                {
                    Name: 'Xuhui',
                    Code: '4'
                },
                {
                    Name: 'Yangpu',
                    Code: '11'
                },
                {
                    Name: 'Zhabei',
                    Code: '8'
                }
                ]
            },
            {
                Name: 'Shanxi',
                Code: '14',
                City: [{
                    Name: 'Changzhi',
                    Code: '4'
                },
                {
                    Name: 'Datong',
                    Code: '2'
                },
                {
                    Name: 'Jincheng',
                    Code: '5'
                },
                {
                    Name: 'Jinzhong',
                    Code: '7'
                },
                {
                    Name: 'Linfen',
                    Code: '10'
                },
                {
                    Name: 'luliang',
                    Code: '11'
                },
                {
                    Name: 'Shuozhou',
                    Code: '6'
                },
                {
                    Name: 'Taiyuan',
                    Code: '1'
                },
                {
                    Name: 'Xinzhou',
                    Code: '9'
                },
                {
                    Name: 'Yangquan',
                    Code: '3'
                },
                {
                    Name: 'Yuncheng',
                    Code: '8'
                }
                ]
            },
            {
                Name: 'Sichuan',
                Code: '51',
                City: [{
                    Name: 'Aba Tibetan-Qiang Autonomous Prefecture',
                    Code: '32'
                },
                {
                    Name: 'Bazhong',
                    Code: '19'
                },
                {
                    Name: 'Chengdu',
                    Code: '1'
                },
                {
                    Name: 'Dazhou',
                    Code: '17'
                },
                {
                    Name: 'Deyang',
                    Code: '6'
                },
                {
                    Name: 'Garze Tibetan Autonomous Prefecture',
                    Code: '33'
                },
                {
                    Name: "Guang'an",
                    Code: '16'
                },
                {
                    Name: 'Guangyuan',
                    Code: '8'
                },
                {
                    Name: 'Leshan',
                    Code: '11'
                },
                {
                    Name: 'Liangshan Yi Autonomous Prefecture',
                    Code: '34'
                },
                {
                    Name: 'Luzhou',
                    Code: '5'
                },
                {
                    Name: 'Meishan',
                    Code: '14'
                },
                {
                    Name: 'Mianyang',
                    Code: '7'
                },
                {
                    Name: 'Nancong',
                    Code: '13'
                },
                {
                    Name: 'Neijiang',
                    Code: '10'
                },
                {
                    Name: 'Panzhihua',
                    Code: '4'
                },
                {
                    Name: 'Suining',
                    Code: '9'
                },
                {
                    Name: "Ya'an",
                    Code: '18'
                },
                {
                    Name: 'Yibin',
                    Code: '15'
                },
                {
                    Name: 'Zigong',
                    Code: '3'
                },
                {
                    Name: 'Ziyang',
                    Code: '20'
                }
                ]
            },
            {
                Name: 'Taiwan',
                Code: '71',
                City: [{
                    Name: 'Changhwa County',
                    Code: '14'
                },
                {
                    Name: 'Chiayi City',
                    Code: '7'
                },
                {
                    Name: 'Chiayi County',
                    Code: '17'
                },
                {
                    Name: 'Hsinchu City',
                    Code: '6'
                },
                {
                    Name: 'Hsinchu County',
                    Code: '11'
                },
                {
                    Name: 'Hualian County',
                    Code: '23'
                },
                {
                    Name: 'Ilan County',
                    Code: '9'
                },
                {
                    Name: 'Kaohsiung City',
                    Code: '2'
                },
                {
                    Name: 'Kaohsiung County',
                    Code: '19'
                },
                {
                    Name: 'Keelung City',
                    Code: '3'
                },
                {
                    Name: 'Miaoli County',
                    Code: '12'
                },
                {
                    Name: 'Nantou County',
                    Code: '15'
                },
                {
                    Name: 'Penghu County',
                    Code: '21'
                },
                {
                    Name: 'Pingtung County',
                    Code: '20'
                },
                {
                    Name: 'Taichung City',
                    Code: '4'
                },
                {
                    Name: 'Taichung County',
                    Code: '13'
                },
                {
                    Name: 'Tainan City',
                    Code: '5'
                },
                {
                    Name: 'Tainan County',
                    Code: '18'
                },
                {
                    Name: 'Taipei City',
                    Code: '1'
                },
                {
                    Name: 'Taipei County',
                    Code: '8'
                },
                {
                    Name: 'Taitung County',
                    Code: '22'
                },
                {
                    Name: 'Taoyuan County',
                    Code: '10'
                },
                {
                    Name: 'Yunnlin County',
                    Code: '16'
                }
                ]
            },
            {
                Name: 'Tianjin',
                Code: '12',
                City: [{
                    Name: 'Baodi',
                    Code: '24'
                },
                {
                    Name: 'Beichen',
                    Code: '13'
                },
                {
                    Name: 'Dagang',
                    Code: '9'
                },
                {
                    Name: 'Dongli',
                    Code: '10'
                },
                {
                    Name: 'Hangu',
                    Code: '8'
                },
                {
                    Name: 'Hebei',
                    Code: '5'
                },
                {
                    Name: 'Hedong',
                    Code: '2'
                },
                {
                    Name: 'Heping',
                    Code: '1'
                },
                {
                    Name: 'Hexi',
                    Code: '3'
                },
                {
                    Name: 'Hongqiao',
                    Code: '6'
                },
                {
                    Name: 'Jinghai',
                    Code: '23'
                },
                {
                    Name: 'Jinnan',
                    Code: '12'
                },
                {
                    Name: 'Jixian',
                    Code: '25'
                },
                {
                    Name: 'Nankai',
                    Code: '4'
                },
                {
                    Name: 'Ninghe',
                    Code: '21'
                },
                {
                    Name: 'Tanggu',
                    Code: '7'
                },
                {
                    Name: 'Wuqing',
                    Code: '22'
                },
                {
                    Name: 'Xiqing',
                    Code: '11'
                }
                ]
            },
            {
                Name: 'Tibet',
                Code: '54',
                City: [{
                    Name: 'Lhasa',
                    Code: '1'
                },
                {
                    Name: 'Nagqu',
                    Code: '24'
                },
                {
                    Name: 'Ngari',
                    Code: '25'
                },
                {
                    Name: 'Nyingchi',
                    Code: '26'
                },
                {
                    Name: 'Qamdo',
                    Code: '21'
                },
                {
                    Name: 'Shannan',
                    Code: '22'
                },
                {
                    Name: 'Xigaze',
                    Code: '23'
                }
                ]
            },
            {
                Name: 'Xinjiang',
                Code: '65',
                City: [{
                    Name: 'Aksu',
                    Code: '29'
                },
                {
                    Name: 'Alar',
                    Code: '92'
                },
                {
                    Name: 'Altay',
                    Code: '43'
                },
                {
                    Name: 'Bayingolin Mongol Autonomous Prefecture',
                    Code: '28'
                },
                {
                    Name: 'Bortala Mongol Autonomous Prefecture',
                    Code: '27'
                },
                {
                    Name: 'Changji Hui Autonomous Prefecture',
                    Code: '23'
                },
                {
                    Name: 'Hami',
                    Code: '22'
                },
                {
                    Name: 'Hotan',
                    Code: '32'
                },
                {
                    Name: 'Ili Kazakh Autonomous Prefecture',
                    Code: '40'
                },
                {
                    Name: 'Karamay',
                    Code: '2'
                },
                {
                    Name: 'Kashi',
                    Code: '31'
                },
                {
                    Name: 'Kizilsu Kirgiz Autonomous Prefecture',
                    Code: '30'
                },
                {
                    Name: 'Shihezi',
                    Code: '91'
                },
                {
                    Name: 'Tacheng',
                    Code: '42'
                },
                {
                    Name: 'Tumsuk',
                    Code: '93'
                },
                {
                    Name: 'Turpan',
                    Code: '21'
                },
                {
                    Name: 'Urumqi',
                    Code: '1'
                },
                {
                    Name: 'Wujiaqu',
                    Code: '94'
                }
                ]
            },
            {
                Name: 'Yunnan',
                Code: '53',
                City: [{
                    Name: 'Baoshan',
                    Code: '5'
                },
                {
                    Name: 'Chuxiong Yi Autonomous Prefecture',
                    Code: '23'
                },
                {
                    Name: 'Dali Bai Autonomous Prefecture',
                    Code: '29'
                },
                {
                    Name: 'Dehong Dai-Jingpo Autonomous Prefecture',
                    Code: '31'
                },
                {
                    Name: 'Diqing Tibetan Autonomous Prefecture',
                    Code: '34'
                },
                {
                    Name: 'Honghe Hani-Yi Autonomous Prefecture',
                    Code: '25'
                },
                {
                    Name: 'Kunming',
                    Code: '1'
                },
                {
                    Name: 'Lijiang',
                    Code: '7'
                },
                {
                    Name: 'Lincang',
                    Code: '9'
                },
                {
                    Name: 'Nujiang Lisu Autonomous Prefecture',
                    Code: '33'
                },
                {
                    Name: "Pu'er",
                    Code: '8'
                },
                {
                    Name: 'Qujing',
                    Code: '3'
                },
                {
                    Name: 'Wenshan Zhuang-Miao Autonomous Prefecture',
                    Code: '26'
                },
                {
                    Name: 'Xishuangbanna Dai Autonomous Prefecture',
                    Code: '28'
                },
                {
                    Name: 'Yuxi',
                    Code: '4'
                },
                {
                    Name: 'Zhaotong',
                    Code: '6'
                }
                ]
            },
            {
                Name: 'Zhejiang',
                Code: '33',
                City: [{
                    Name: 'Hangzhou',
                    Code: '1'
                },
                {
                    Name: 'Huzhou',
                    Code: '5'
                },
                {
                    Name: 'Jiaxing',
                    Code: '4'
                },
                {
                    Name: 'Jinhua',
                    Code: '7'
                },
                {
                    Name: 'Lishui',
                    Code: '11'
                },
                {
                    Name: 'Ningbo',
                    Code: '2'
                },
                {
                    Name: 'Quzhou',
                    Code: '8'
                },
                {
                    Name: 'Shaoxing',
                    Code: '6'
                },
                {
                    Name: 'Taizhou',
                    Code: '10'
                },
                {
                    Name: 'Wenzhou',
                    Code: '3'
                },
                {
                    Name: 'Zhoushan',
                    Code: '9'
                }
                ]
            }
            ]
        },
        {
            Name: 'Christmas Island',
            Code: 'CXR'
        },
        {
            Name: 'Cocos(Keeling)Islands',
            Code: 'CCK'
        },
        {
            Name: 'Colombia',
            Code: 'COL',
            State: {
                City: [{
                    Name: 'Amazonas',
                    Code: 'AMZ'
                },
                {
                    Name: 'Antioquia',
                    Code: 'ANT'
                },
                {
                    Name: 'Arauca',
                    Code: 'ARA'
                },
                {
                    Name: 'Atlantico',
                    Code: 'ATL'
                },
                {
                    Name: 'Bogota',
                    Code: 'BDC'
                },
                {
                    Name: 'Bolivar',
                    Code: 'BOL'
                },
                {
                    Name: 'Boyaca',
                    Code: 'BOY'
                },
                {
                    Name: 'Caldas',
                    Code: 'CAL'
                },
                {
                    Name: 'Caqueta',
                    Code: 'CAQ'
                },
                {
                    Name: 'Casanare',
                    Code: 'CAS'
                },
                {
                    Name: 'Cauca',
                    Code: 'CAU'
                },
                {
                    Name: 'Cesar',
                    Code: 'CES'
                },
                {
                    Name: 'Choco',
                    Code: 'CHO'
                },
                {
                    Name: 'Cordoba',
                    Code: 'COR'
                },
                {
                    Name: 'Cundinamarca',
                    Code: 'CAM'
                },
                {
                    Name: 'Guainia',
                    Code: 'GNA'
                },
                {
                    Name: 'Guaviare',
                    Code: 'GVR'
                },
                {
                    Name: 'Huila',
                    Code: 'HUI'
                },
                {
                    Name: 'La Guajira',
                    Code: 'GJR'
                },
                {
                    Name: 'Magdalena',
                    Code: 'MAG'
                },
                {
                    Name: 'Meta',
                    Code: 'MET'
                },
                {
                    Name: 'Narino',
                    Code: 'NAR'
                },
                {
                    Name: 'Norte de Santander',
                    Code: 'NDS'
                },
                {
                    Name: 'Putumayo',
                    Code: 'PUT'
                },
                {
                    Name: 'Quindio',
                    Code: 'QUI'
                },
                {
                    Name: 'Risaralda',
                    Code: 'RIS'
                },
                {
                    Name: 'San Andres y Providencia',
                    Code: 'SAP'
                },
                {
                    Name: 'Santander',
                    Code: 'SAN'
                },
                {
                    Name: 'Sucre',
                    Code: 'SUC'
                },
                {
                    Name: 'Tolima',
                    Code: 'TOL'
                },
                {
                    Name: 'Valle del Cauca',
                    Code: 'VDC'
                },
                {
                    Name: 'Vaupes',
                    Code: 'VAU'
                },
                {
                    Name: 'Vichada',
                    Code: 'VIC'
                }
                ]
            }
        },
        {
            Name: 'Comoros',
            Code: 'COM'
        },
        {
            Name: 'Congo',
            Code: 'COG'
        },
        {
            Name: 'Congo(DRC)',
            Code: 'COD'
        },
        {
            Name: 'Cook Islands',
            Code: 'COK'
        },
        {
            Name: 'Costa Rica',
            Code: 'CRI',
            State: {
                City: [{
                    Name: 'Alajuela',
                    Code: 'A'
                },
                {
                    Name: 'Cartago',
                    Code: 'C'
                },
                {
                    Name: 'Guanacaste',
                    Code: 'G'
                },
                {
                    Name: 'Heredia',
                    Code: 'H'
                },
                {
                    Name: 'Limon',
                    Code: 'L'
                },
                {
                    Name: 'Puntarenas',
                    Code: 'P'
                },
                {
                    Name: 'San Jose',
                    Code: 'SJ'
                }
                ]
            }
        },
        {
            Name: "Cote d'Ivoire",
            Code: 'CIV',
            State: {
                City: [{
                    Name: 'Agnebi',
                    Code: 'AG'
                },
                {
                    Name: 'Bafing',
                    Code: 'BF'
                },
                {
                    Name: 'Bas-Sassandra',
                    Code: 'BS'
                },
                {
                    Name: 'Denguele',
                    Code: 'DE'
                },
                {
                    Name: 'Fromager',
                    Code: 'FR'
                },
                {
                    Name: 'Haut-Sassandra',
                    Code: 'HT'
                },
                {
                    Name: 'Lacs',
                    Code: 'LC'
                },
                {
                    Name: 'Lagunes',
                    Code: 'LG'
                },
                {
                    Name: 'Marahoue',
                    Code: 'MR'
                },
                {
                    Name: 'Montagnes',
                    Code: 'DH'
                },
                {
                    Name: 'Moyen-Cavally',
                    Code: 'MV'
                },
                {
                    Name: 'Moyen-Comoe',
                    Code: 'MC'
                },
                {
                    Name: 'Nzi-Comoe',
                    Code: 'NC'
                },
                {
                    Name: 'Savanes',
                    Code: 'SV'
                },
                {
                    Name: 'Sud-Bandama',
                    Code: 'SB'
                },
                {
                    Name: 'Sud-Comoe',
                    Code: 'SC'
                },
                {
                    Name: 'Vallee du Bandama',
                    Code: 'VB'
                },
                {
                    Name: 'Worodougou',
                    Code: 'WR'
                },
                {
                    Name: 'Zanzan',
                    Code: 'ZA'
                }
                ]
            }
        },
        {
            Name: 'Croatia',
            Code: 'HRV',
            State: {
                City: [{
                    Name: 'Bjelovarsko-Bilogorska',
                    Code: '7'
                },
                {
                    Name: 'Brodsko-Posavska',
                    Code: '12'
                },
                {
                    Name: 'Dubrovacko-Neretvanska',
                    Code: '19'
                },
                {
                    Name: 'Grad Zagreb',
                    Code: '21'
                },
                {
                    Name: 'Istarska',
                    Code: '18'
                },
                {
                    Name: 'Karlovacka',
                    Code: '4'
                },
                {
                    Name: 'Koprivnicko-Krizevacka',
                    Code: '6'
                },
                {
                    Name: 'Krapinsko-Zagorska',
                    Code: '2'
                },
                {
                    Name: 'Licko-Senjska',
                    Code: '9'
                },
                {
                    Name: 'Medimurska',
                    Code: '20'
                },
                {
                    Name: 'Osjecko-Baranjska',
                    Code: '14'
                },
                {
                    Name: 'Pozega-Slavonia',
                    Code: '11'
                },
                {
                    Name: 'Primorsko-Goranska',
                    Code: '8'
                },
                {
                    Name: 'Sibensko-Kninska',
                    Code: '15'
                },
                {
                    Name: 'Sisacko-Moslavacka',
                    Code: '3'
                },
                {
                    Name: 'Splitsko-Dalmatinska',
                    Code: '17'
                },
                {
                    Name: 'Varazdinska',
                    Code: '5'
                },
                {
                    Name: 'Viroviticko-Podravska',
                    Code: '10'
                },
                {
                    Name: 'Vukovarsko-Srijemska',
                    Code: '16'
                },
                {
                    Name: 'Zadarska',
                    Code: '13'
                },
                {
                    Name: 'Zagrebacka',
                    Code: '1'
                }
                ]
            }
        },
        {
            Name: 'Cuba',
            Code: 'CUB',
            State: {
                City: [{
                    Name: 'Camaguey',
                    Code: '9'
                },
                {
                    Name: 'Ciego de Avila',
                    Code: '8'
                },
                {
                    Name: 'Cienfuegos',
                    Code: '6'
                },
                {
                    Name: 'Ciudad de la Habana',
                    Code: '3'
                },
                {
                    Name: 'Granma',
                    Code: '12'
                },
                {
                    Name: 'Guantanamo',
                    Code: '14'
                },
                {
                    Name: 'Holguin',
                    Code: '11'
                },
                {
                    Name: 'Isla de la Juventud',
                    Code: '99'
                },
                {
                    Name: 'La Habana',
                    Code: '2'
                },
                {
                    Name: 'Las Tunas',
                    Code: '10'
                },
                {
                    Name: 'Manzanillo',
                    Code: 'MZO'
                },
                {
                    Name: 'Matanzas',
                    Code: '4'
                },
                {
                    Name: 'Mayari',
                    Code: 'MAY'
                },
                {
                    Name: 'Pinar del Rio',
                    Code: '1'
                },
                {
                    Name: 'Sancti Spiritus',
                    Code: '7'
                },
                {
                    Name: 'Santiago de Cuba',
                    Code: '13'
                },
                {
                    Name: 'Villa Clara',
                    Code: '5'
                }
                ]
            }
        },
        {
            Name: 'Cyprus',
            Code: 'CYP',
            State: {
                City: [{
                    Name: 'Famagusta',
                    Code: '4'
                },
                {
                    Name: 'Kyrenia',
                    Code: '6'
                },
                {
                    Name: 'Larnaca',
                    Code: '3'
                },
                {
                    Name: 'Limassol',
                    Code: '2'
                },
                {
                    Name: 'Nicosia',
                    Code: '1'
                },
                {
                    Name: 'Pafos',
                    Code: '5'
                }
                ]
            }
        },
        {
            Name: 'Czech Republic',
            Code: 'CZE',
            State: {
                City: [{
                    Name: 'Jihomoravsky',
                    Code: 'JC'
                },
                {
                    Name: 'Karlovarsky',
                    Code: 'KA'
                },
                {
                    Name: 'Kralovehradecky',
                    Code: 'KR'
                },
                {
                    Name: 'Liberecky',
                    Code: 'LI'
                },
                {
                    Name: 'Moravskoslezsky',
                    Code: 'MO'
                },
                {
                    Name: 'Olomoucky',
                    Code: 'OL'
                },
                {
                    Name: 'Pardubicky',
                    Code: 'PA'
                },
                {
                    Name: 'Plzensky',
                    Code: 'PL'
                },
                {
                    Name: 'Prague',
                    Code: 'PR'
                },
                {
                    Name: 'Stredocesky',
                    Code: 'ST'
                },
                {
                    Name: 'Ustecky',
                    Code: 'US'
                },
                {
                    Name: 'Vysocina',
                    Code: 'VY'
                },
                {
                    Name: 'Zlinsky',
                    Code: 'ZL'
                }
                ]
            }
        },
        {
            Name: 'Denmark',
            Code: 'DNK',
            State: {
                City: [{
                    Name: 'Aarhus',
                    Code: 'AR'
                },
                {
                    Name: 'Bornholm',
                    Code: 'BO'
                },
                {
                    Name: 'Copenhagen',
                    Code: 'CPH'
                },
                {
                    Name: 'Frederiksborg',
                    Code: 'FRE'
                },
                {
                    Name: 'Fyn',
                    Code: 'FY'
                },
                {
                    Name: 'Nordjylland',
                    Code: 'VSV'
                },
                {
                    Name: 'Ribe',
                    Code: 'RIB'
                },
                {
                    Name: 'Ringkoebing',
                    Code: 'RKG'
                },
                {
                    Name: 'Roskilde',
                    Code: 'RKE'
                },
                {
                    Name: 'Soenderjylland',
                    Code: 'VBI'
                },
                {
                    Name: 'Storstroem',
                    Code: 'ST'
                },
                {
                    Name: 'Vejle',
                    Code: 'VEJ'
                },
                {
                    Name: 'Vestsjaelland',
                    Code: 'VS'
                },
                {
                    Name: 'Viborg',
                    Code: 'VIB'
                }
                ]
            }
        },
        {
            Name: 'Djibouti',
            Code: 'DJI',
            State: {
                City: [{
                    Name: 'Ali Sabih',
                    Code: 'S'
                },
                {
                    Name: 'Dikhil',
                    Code: 'K'
                },
                {
                    Name: 'Obock',
                    Code: 'O'
                },
                {
                    Name: 'Tadjoura',
                    Code: 'T'
                }
                ]
            }
        },
        {
            Name: 'Dominica',
            Code: 'DMA'
        },
        {
            Name: 'Dominican Republic',
            Code: 'DOM'
        },
        {
            Name: 'Ecuador',
            Code: 'ECU',
            State: {
                City: [{
                    Name: 'Azuay',
                    Code: 'A'
                },
                {
                    Name: 'Bolivar',
                    Code: 'B'
                },
                {
                    Name: 'Canar',
                    Code: 'F'
                },
                {
                    Name: 'Carchi',
                    Code: 'C'
                },
                {
                    Name: 'Chimborazo',
                    Code: 'H'
                },
                {
                    Name: 'Cotopaxi',
                    Code: 'X'
                },
                {
                    Name: 'El Oro',
                    Code: 'O'
                },
                {
                    Name: 'Esmeraldas',
                    Code: 'E'
                },
                {
                    Name: 'Galapagos',
                    Code: 'W'
                },
                {
                    Name: 'Guayas',
                    Code: 'G'
                },
                {
                    Name: 'Imbabura',
                    Code: 'I'
                },
                {
                    Name: 'Loja',
                    Code: 'L'
                },
                {
                    Name: 'Los Rios',
                    Code: 'R'
                },
                {
                    Name: 'Manabi',
                    Code: 'M'
                },
                {
                    Name: 'Morona-Santiago',
                    Code: 'S'
                },
                {
                    Name: 'Napo, Orellana',
                    Code: 'D'
                },
                {
                    Name: 'Pastaza',
                    Code: 'Y'
                },
                {
                    Name: 'Pichincha',
                    Code: 'P'
                },
                {
                    Name: 'Sucumbios',
                    Code: 'U'
                },
                {
                    Name: 'Tungurahua',
                    Code: 'T'
                },
                {
                    Name: 'Zamora-Chinchipe',
                    Code: 'Z'
                }
                ]
            }
        },
        {
            Name: 'Egypt',
            Code: 'EGY',
            State: {
                City: [{
                    Name: 'Al Ghurdaqah',
                    Code: 'GBY'
                },
                {
                    Name: 'Alexandria',
                    Code: 'ALY'
                },
                {
                    Name: 'Aswan',
                    Code: 'ASW'
                },
                {
                    Name: 'Cairo',
                    Code: 'CAI'
                },
                {
                    Name: 'Shubra al Khaymah',
                    Code: 'SKH'
                }
                ]
            }
        },
        {
            Name: 'El Salvador',
            Code: 'SLV',
            State: {
                City: [{
                    Name: 'Ahuachapan',
                    Code: 'AH'
                },
                {
                    Name: 'Apopa',
                    Code: 'APO'
                },
                {
                    Name: 'Cabanas',
                    Code: 'CA'
                },
                {
                    Name: 'Centro Sur',
                    Code: 'CS'
                },
                {
                    Name: 'Chalatenango',
                    Code: 'CH'
                },
                {
                    Name: 'Cuscatlan',
                    Code: 'CU'
                },
                {
                    Name: 'Delgado',
                    Code: 'DE'
                },
                {
                    Name: 'Ilopango',
                    Code: 'IL'
                },
                {
                    Name: 'Kie-Ntem',
                    Code: 'KN'
                },
                {
                    Name: 'La Libertad',
                    Code: 'LB'
                },
                {
                    Name: 'La Paz',
                    Code: 'PZ'
                },
                {
                    Name: 'La Union',
                    Code: 'UN'
                },
                {
                    Name: 'Litoral',
                    Code: 'LI'
                },
                {
                    Name: 'Mejicanos',
                    Code: 'MEJ'
                },
                {
                    Name: 'Morazan',
                    Code: 'MO'
                },
                {
                    Name: 'San Miguel',
                    Code: 'SM'
                },
                {
                    Name: 'San Salvador',
                    Code: 'SS'
                },
                {
                    Name: 'San Vicente',
                    Code: 'SV'
                },
                {
                    Name: 'Santa Ana',
                    Code: 'SA'
                },
                {
                    Name: 'Sonsonate',
                    Code: 'SO'
                },
                {
                    Name: 'Soyapango',
                    Code: 'SOY'
                },
                {
                    Name: 'Usulutan',
                    Code: 'US'
                },
                {
                    Name: 'Wele-Nzas',
                    Code: 'WN'
                }
                ]
            }
        },
        {
            Name: 'Eritrea',
            Code: 'ERI',
            State: {
                City: [{
                    Name: 'Anseba',
                    Code: 'KE'
                },
                {
                    Name: 'Debub',
                    Code: 'DE'
                },
                {
                    Name: 'Debubawi Keyih Bahri',
                    Code: 'DK'
                },
                {
                    Name: 'Gash Barka',
                    Code: 'BR'
                },
                {
                    Name: 'Maekel',
                    Code: 'MA'
                },
                {
                    Name: 'Semenawi Keyih Bahri',
                    Code: 'SK'
                }
                ]
            }
        },
        {
            Name: 'Estonia',
            Code: 'EST',
            State: {
                City: [{
                    Name: 'Harju',
                    Code: '37'
                },
                {
                    Name: 'Hiiu',
                    Code: '39'
                },
                {
                    Name: 'Ida-Viru',
                    Code: '44'
                },
                {
                    Name: 'Jarva',
                    Code: '51'
                },
                {
                    Name: 'Jogeva',
                    Code: '49'
                },
                {
                    Name: 'Laane',
                    Code: '57'
                },
                {
                    Name: 'Laane-Viru',
                    Code: '59'
                },
                {
                    Name: 'Parnu',
                    Code: '67'
                },
                {
                    Name: 'Polva',
                    Code: '65'
                },
                {
                    Name: 'Rapla',
                    Code: '70'
                },
                {
                    Name: 'Saare',
                    Code: '74'
                },
                {
                    Name: 'Tartu',
                    Code: '78'
                },
                {
                    Name: 'Valga',
                    Code: '82'
                },
                {
                    Name: 'Viljandi',
                    Code: '84'
                },
                {
                    Name: 'Voru',
                    Code: '86'
                }
                ]
            }
        },
        {
            Name: 'Ethiopia',
            Code: 'ETH',
            State: {
                City: [{
                    Name: 'Adis abeba',
                    Code: 'AA'
                },
                {
                    Name: 'Afar',
                    Code: 'AF'
                },
                {
                    Name: 'Amara',
                    Code: 'AH'
                },
                {
                    Name: 'Binshangul Gumuz',
                    Code: 'BG'
                },
                {
                    Name: 'Dire Dawa',
                    Code: 'DD'
                },
                {
                    Name: 'Gambela Hizboch',
                    Code: 'GB'
                },
                {
                    Name: 'Hareri  Hizb',
                    Code: 'HR'
                },
                {
                    Name: 'Oromiya',
                    Code: 'OR'
                },
                {
                    Name: 'Sumale',
                    Code: 'SM'
                },
                {
                    Name: 'Tigray',
                    Code: 'TG'
                },
                {
                    Name: 'YeDebub Biheroch',
                    Code: 'SN'
                }
                ]
            }
        },
        {
            Name: 'Falkland Islands',
            Code: 'FLK'
        },
        {
            Name: 'Faroe Islands',
            Code: 'FRO'
        },
        {
            Name: 'Fiji Islands',
            Code: 'FJI'
        },
        {
            Name: 'Finland',
            Code: 'FIN',
            State: {
                City: [{
                    Name: 'Espoo',
                    Code: 'ESP'
                },
                {
                    Name: 'Hameenlinna',
                    Code: 'HMY'
                },
                {
                    Name: 'Helsinki',
                    Code: 'HEL'
                },
                {
                    Name: 'Joensuu',
                    Code: 'JOE'
                },
                {
                    Name: 'Kajaani',
                    Code: 'KAJ'
                },
                {
                    Name: 'Kokkola',
                    Code: 'KOK'
                },
                {
                    Name: 'Kotka',
                    Code: 'KTK'
                },
                {
                    Name: 'Kuopio',
                    Code: 'KUO'
                },
                {
                    Name: 'Lahti',
                    Code: 'LHI'
                },
                {
                    Name: 'Lappeenranta',
                    Code: 'LPP'
                },
                {
                    Name: 'Mariehamn',
                    Code: 'MHQ'
                },
                {
                    Name: 'Mikkeli',
                    Code: 'MIK'
                },
                {
                    Name: 'Oulu',
                    Code: 'OLU'
                },
                {
                    Name: 'Pori',
                    Code: 'POR'
                },
                {
                    Name: 'Porvoo',
                    Code: 'PRV'
                },
                {
                    Name: 'Rovaniemi',
                    Code: 'RVN'
                },
                {
                    Name: 'Tampere',
                    Code: 'TMP'
                },
                {
                    Name: 'Turku',
                    Code: 'TKU'
                },
                {
                    Name: 'Vaasa',
                    Code: 'VAA'
                },
                {
                    Name: 'Vantaa',
                    Code: 'VAT'
                }
                ]
            }
        },
        {
            Name: 'France',
            Code: 'FRA',
            State: {
                City: [{
                    Name: 'Aix-en-Provence',
                    Code: 'QXB'
                },
                {
                    Name: 'Ajaccio',
                    Code: 'AJA'
                },
                {
                    Name: 'Amiens',
                    Code: 'AMI'
                },
                {
                    Name: 'Arles',
                    Code: 'ARL'
                },
                {
                    Name: 'Besancon',
                    Code: 'BSN'
                },
                {
                    Name: 'Caen',
                    Code: 'CFR'
                },
                {
                    Name: 'Chalons-en-Champagne',
                    Code: 'CSM'
                },
                {
                    Name: 'Dijon',
                    Code: 'DIJ'
                },
                {
                    Name: 'Frejus',
                    Code: 'FRJ'
                },
                {
                    Name: 'Lille',
                    Code: 'LLE'
                },
                {
                    Name: 'Limoges',
                    Code: 'LIG'
                },
                {
                    Name: 'Lyon',
                    Code: 'LIO'
                },
                {
                    Name: 'Marseille',
                    Code: 'MRS'
                },
                {
                    Name: 'Metz',
                    Code: 'MZM'
                },
                {
                    Name: 'Montpellier',
                    Code: 'MPL'
                },
                {
                    Name: 'Nantes',
                    Code: 'NTE'
                },
                {
                    Name: 'Nice',
                    Code: 'NCE'
                },
                {
                    Name: 'Orleans',
                    Code: 'ORR'
                },
                {
                    Name: 'Paris',
                    Code: 'PAR'
                },
                {
                    Name: 'Rennes',
                    Code: 'RNS'
                },
                {
                    Name: 'Rouen',
                    Code: 'URO'
                },
                {
                    Name: 'Toulouse',
                    Code: 'TLS'
                },
                {
                    Name: 'Valence',
                    Code: 'VAA'
                }
                ]
            }
        },
        {
            Name: 'French Polynesia',
            Code: 'PYF'
        },
        {
            Name: 'French Guiana',
            Code: 'GUF'
        },
        {
            Name: 'French Southern and Antarctic Lands',
            Code: 'ATF'
        },
        {
            Name: 'Gabon',
            Code: 'GAB',
            State: {
                City: [{
                    Name: 'Estuaire',
                    Code: 'ES'
                },
                {
                    Name: 'Haut-Ogooue',
                    Code: 'HO'
                },
                {
                    Name: 'Moyen-Ogooue',
                    Code: 'MO'
                },
                {
                    Name: 'Ngounie',
                    Code: 'NG'
                },
                {
                    Name: 'Nyanga',
                    Code: 'NY'
                },
                {
                    Name: 'Ogooue-Ivindo',
                    Code: 'OI'
                },
                {
                    Name: 'Ogooue-Lolo',
                    Code: 'OL'
                },
                {
                    Name: 'Ogooue-Maritime',
                    Code: 'OM'
                },
                {
                    Name: 'Woleu-Ntem',
                    Code: 'WN'
                }
                ]
            }
        },
        {
            Name: 'Gambia',
            Code: 'GMB'
        },
        {
            Name: 'Georgia',
            Code: 'GEO'
        },
        {
            Name: 'Germany',
            Code: 'DEU',
            State: {
                City: [{
                    Name: 'Ansbach',
                    Code: 'ANS'
                },
                {
                    Name: 'Arnsberg',
                    Code: 'ARN'
                },
                {
                    Name: 'Augsburg',
                    Code: 'AGB'
                },
                {
                    Name: 'Bayreuth',
                    Code: 'BYU'
                },
                {
                    Name: 'Berlin',
                    Code: 'BE'
                },
                {
                    Name: 'Bielefeld',
                    Code: 'BFE'
                },
                {
                    Name: 'Bochum',
                    Code: 'BOM'
                },
                {
                    Name: 'Bremen',
                    Code: 'HB'
                },
                {
                    Name: 'Brunswick',
                    Code: 'BRW'
                },
                {
                    Name: 'Chemnitz',
                    Code: 'CHE'
                },
                {
                    Name: 'Cologne',
                    Code: 'CGN'
                },
                {
                    Name: 'Darmstadt',
                    Code: 'DAR'
                },
                {
                    Name: 'Dessau',
                    Code: 'DES'
                },
                {
                    Name: 'Detmold',
                    Code: 'DET'
                },
                {
                    Name: 'Dresden',
                    Code: 'DRS'
                },
                {
                    Name: 'Dusseldorf',
                    Code: 'DUS'
                },
                {
                    Name: 'Erfurt',
                    Code: 'ERF'
                },
                {
                    Name: 'Frankfurt',
                    Code: 'FFO'
                },
                {
                    Name: 'Freiburg',
                    Code: 'FBG'
                },
                {
                    Name: 'GieBen',
                    Code: 'GBN'
                },
                {
                    Name: 'Halle',
                    Code: 'HAE'
                },
                {
                    Name: 'Hamburg',
                    Code: 'HH'
                },
                {
                    Name: 'Hannover',
                    Code: 'HAJ'
                },
                {
                    Name: 'Karlsruhe',
                    Code: 'KAE'
                },
                {
                    Name: 'Kassel',
                    Code: 'KAS'
                },
                {
                    Name: 'Kiel',
                    Code: 'KEL'
                },
                {
                    Name: 'Koblenz',
                    Code: 'KOB'
                },
                {
                    Name: 'Landshut',
                    Code: 'LDH'
                },
                {
                    Name: 'Leipzig',
                    Code: 'LEJ'
                },
                {
                    Name: 'Luneburg',
                    Code: 'LBG'
                },
                {
                    Name: 'Magdeburg',
                    Code: 'MAG'
                },
                {
                    Name: 'Mainz',
                    Code: 'MAI'
                },
                {
                    Name: 'Mannheim',
                    Code: 'MHG'
                },
                {
                    Name: 'Muenster',
                    Code: 'MUN'
                },
                {
                    Name: 'Munich',
                    Code: 'MUC'
                },
                {
                    Name: 'Nuremberg',
                    Code: 'NUE'
                },
                {
                    Name: 'Potsdam',
                    Code: 'POT'
                },
                {
                    Name: 'Schwerin',
                    Code: 'SWH'
                },
                {
                    Name: 'Stuttgart',
                    Code: 'STR'
                },
                {
                    Name: 'Trier',
                    Code: 'TRI'
                },
                {
                    Name: 'Wiesbaden',
                    Code: 'WIB'
                },
                {
                    Name: 'Wuerzburg',
                    Code: 'WUG'
                }
                ]
            }
        },
        {
            Name: 'Ghana',
            Code: 'GHA',
            State: {
                City: [{
                    Name: 'Ashanti',
                    Code: 'AS'
                },
                {
                    Name: 'Brong Ahafo',
                    Code: 'BA'
                },
                {
                    Name: 'Central',
                    Code: 'CE'
                },
                {
                    Name: 'Eastern',
                    Code: 'EA'
                },
                {
                    Name: 'Greater Accra',
                    Code: 'GA'
                },
                {
                    Name: 'Northern',
                    Code: 'NO'
                },
                {
                    Name: 'Obuasi',
                    Code: 'OBU'
                },
                {
                    Name: 'Upper East',
                    Code: 'UE'
                },
                {
                    Name: 'Upper West',
                    Code: 'UW'
                },
                {
                    Name: 'Volta',
                    Code: 'VO'
                },
                {
                    Name: 'Western',
                    Code: 'WE'
                }
                ]
            }
        },
        {
            Name: 'Gibraltar',
            Code: 'GIB'
        },
        {
            Name: 'Greece',
            Code: 'GRC',
            State: {
                City: [{
                    Name: 'Athens',
                    Code: 'ATH'
                },
                {
                    Name: 'Chanion',
                    Code: 'CHQ'
                },
                {
                    Name: 'Cyclades',
                    Code: 'CY'
                },
                {
                    Name: 'Dodecanese',
                    Code: 'DO'
                },
                {
                    Name: 'Irakleiou',
                    Code: 'HER'
                },
                {
                    Name: 'Lasithiou',
                    Code: 'LST'
                },
                {
                    Name: 'Lesbos',
                    Code: 'LES'
                },
                {
                    Name: 'Peiraievs',
                    Code: 'PRI'
                },
                {
                    Name: 'Rethymnis',
                    Code: 'RET'
                },
                {
                    Name: 'Samos',
                    Code: 'SMI'
                }
                ]
            }
        },
        {
            Name: 'Greenland',
            Code: 'GRL'
        },
        {
            Name: 'Grenada',
            Code: 'GRD'
        },
        {
            Name: 'Guadeloupe',
            Code: 'GLP'
        },
        {
            Name: 'Guam',
            Code: 'GUM'
        },
        {
            Name: 'Guatemala',
            Code: 'GTM',
            State: {
                City: [{
                    Name: 'Alta Verapaz',
                    Code: 'AV'
                },
                {
                    Name: 'Baja Verapaz',
                    Code: 'BV'
                },
                {
                    Name: 'Chimaltenango',
                    Code: 'CM'
                },
                {
                    Name: 'Chiquimula',
                    Code: 'CQ'
                },
                {
                    Name: 'El Progreso',
                    Code: 'PR'
                },
                {
                    Name: 'Escuintla',
                    Code: 'ES'
                },
                {
                    Name: 'Guatemala',
                    Code: 'GU'
                },
                {
                    Name: 'Huehuetenango',
                    Code: 'HU'
                },
                {
                    Name: 'Izabal',
                    Code: 'IZ'
                },
                {
                    Name: 'Jalapa',
                    Code: 'JA'
                },
                {
                    Name: 'Jutiapa',
                    Code: 'JU'
                },
                {
                    Name: 'Mixco',
                    Code: 'MIX'
                },
                {
                    Name: 'Peten',
                    Code: 'PE'
                },
                {
                    Name: 'Quetzaltenango',
                    Code: 'QZ'
                },
                {
                    Name: 'Quiche',
                    Code: 'QC'
                },
                {
                    Name: 'Retalhuleu',
                    Code: 'RE'
                },
                {
                    Name: 'Sacatepequez',
                    Code: 'ST'
                },
                {
                    Name: 'San Marcos',
                    Code: 'SM'
                },
                {
                    Name: 'Santa Rosa',
                    Code: 'SR'
                },
                {
                    Name: 'Solola',
                    Code: 'SO'
                },
                {
                    Name: 'Suchitepequez',
                    Code: 'SU'
                },
                {
                    Name: 'Totonicapan',
                    Code: 'TO'
                },
                {
                    Name: 'Villa Nueva',
                    Code: 'VIN'
                },
                {
                    Name: 'Zacapa',
                    Code: 'ZA'
                }
                ]
            }
        },
        {
            Name: 'Guernsey',
            Code: 'GGY'
        },
        {
            Name: 'Guinea',
            Code: 'GIN',
            State: {
                City: [{
                    Name: 'Boke',
                    Code: 'BOK'
                },
                {
                    Name: 'Conakry',
                    Code: 'CNK'
                },
                {
                    Name: 'Faranah',
                    Code: 'FRN'
                },
                {
                    Name: 'Kankan',
                    Code: 'KNK'
                },
                {
                    Name: 'Kindia',
                    Code: 'KND'
                },
                {
                    Name: 'Labe',
                    Code: 'LAB'
                },
                {
                    Name: 'Mamou',
                    Code: 'MAM'
                },
                {
                    Name: 'Nzerekore',
                    Code: 'NZR'
                }
                ]
            }
        },
        {
            Name: 'Guinea-Bissau',
            Code: 'GNB'
        },
        {
            Name: 'Guyana',
            Code: 'GUY',
            State: {
                City: [{
                    Name: 'Barima-Waini',
                    Code: 'BW'
                },
                {
                    Name: 'Cuyuni-Mazaruni',
                    Code: 'CM'
                },
                {
                    Name: 'Demerara-Mahaica',
                    Code: 'DM'
                },
                {
                    Name: 'East Berbice-Corentyne',
                    Code: 'EC'
                },
                {
                    Name: 'Essequibo Islands-West Demerara',
                    Code: 'EW'
                },
                {
                    Name: 'Mahaica-Berbice',
                    Code: 'MB'
                },
                {
                    Name: 'Pomeroon-Supenaam',
                    Code: 'PM'
                },
                {
                    Name: 'Potaro-Siparuni',
                    Code: 'PI'
                },
                {
                    Name: 'Upper Demerara-Berbice',
                    Code: 'UD'
                },
                {
                    Name: 'Upper Takutu-Upper Essequibo',
                    Code: 'UT'
                }
                ]
            }
        },
        {
            Name: 'Haiti',
            Code: 'HTI'
        },
        {
            Name: 'Heard Island and McDonald Islands',
            Code: 'HMD'
        },
        {
            Name: 'Honduras',
            Code: 'HND',
            State: {
                City: [{
                    Name: 'Atlantida',
                    Code: 'AT'
                },
                {
                    Name: 'Choloma',
                    Code: 'CHO'
                },
                {
                    Name: 'Choluteca',
                    Code: 'CH'
                },
                {
                    Name: 'Colon',
                    Code: 'CL'
                },
                {
                    Name: 'Comayagua',
                    Code: 'CM'
                },
                {
                    Name: 'Copan',
                    Code: 'CP'
                },
                {
                    Name: 'Cortes',
                    Code: 'CR'
                },
                {
                    Name: 'El Paraiso',
                    Code: 'PA'
                },
                {
                    Name: 'Francisco Morazan',
                    Code: 'FM'
                },
                {
                    Name: 'Gracias a Dios',
                    Code: 'GD'
                },
                {
                    Name: 'Intibuca',
                    Code: 'IN'
                },
                {
                    Name: 'Islas de la Bahia',
                    Code: 'IB'
                },
                {
                    Name: 'La Paz',
                    Code: 'PZ'
                },
                {
                    Name: 'Lempira',
                    Code: 'LE'
                },
                {
                    Name: 'Ocotepeque',
                    Code: 'OC'
                },
                {
                    Name: 'Olancho',
                    Code: 'OL'
                },
                {
                    Name: 'Santa Barbara',
                    Code: 'SB'
                },
                {
                    Name: 'Valle',
                    Code: 'VA'
                },
                {
                    Name: 'Yoro',
                    Code: 'YO'
                }
                ]
            }
        },
        {
            Name: 'Hungary',
            Code: 'HUN',
            State: {
                City: [{
                    Name: 'Bacs-Kiskun',
                    Code: 'BK'
                },
                {
                    Name: 'Baranya',
                    Code: 'BA'
                },
                {
                    Name: 'Bekes',
                    Code: 'BE'
                },
                {
                    Name: 'Borsod-Abauj-Zemplen',
                    Code: 'BZ'
                },
                {
                    Name: 'Budapest',
                    Code: 'BU'
                },
                {
                    Name: 'Csongrad',
                    Code: 'CS'
                },
                {
                    Name: 'Fejer',
                    Code: 'FE'
                },
                {
                    Name: 'Gyor-Moson-Sopron',
                    Code: 'GS'
                },
                {
                    Name: 'Hajdu-Bihar',
                    Code: 'HB'
                },
                {
                    Name: 'Heves',
                    Code: 'HE'
                },
                {
                    Name: 'Jasz-Nagykun-Szolnok',
                    Code: 'JN'
                },
                {
                    Name: 'Komarom-Esztergom',
                    Code: 'KE'
                },
                {
                    Name: 'Nograd',
                    Code: 'NO'
                },
                {
                    Name: 'Pest',
                    Code: 'PE'
                },
                {
                    Name: 'Somogy',
                    Code: 'SO'
                },
                {
                    Name: 'Szabolcs-Szatmar-Bereg',
                    Code: 'SZ'
                },
                {
                    Name: 'Tolna',
                    Code: 'TO'
                },
                {
                    Name: 'Vas',
                    Code: 'VA'
                },
                {
                    Name: 'Veszprem',
                    Code: 'VE'
                },
                {
                    Name: 'Zala',
                    Code: 'ZA'
                }
                ]
            }
        },
        {
            Name: 'Iceland',
            Code: 'ISL'
        },
        {
            Name: 'India',
            Code: 'IND',
            State: {
                City: [{
                    Name: 'Aizawl',
                    Code: 'AJL'
                },
                {
                    Name: 'Bangalore',
                    Code: 'BLR'
                },
                {
                    Name: 'Bhopal',
                    Code: 'BHO'
                },
                {
                    Name: 'Bhubaneswar',
                    Code: 'BBI'
                },
                {
                    Name: 'Calcutta',
                    Code: 'CCU'
                },
                {
                    Name: 'Chandigarh',
                    Code: 'IXC'
                },
                {
                    Name: 'Chennai',
                    Code: 'MAA'
                },
                {
                    Name: 'Coimbatore',
                    Code: 'CJB'
                },
                {
                    Name: 'Daman',
                    Code: 'DAM'
                },
                {
                    Name: 'Diu',
                    Code: 'DIU'
                },
                {
                    Name: 'Gangtok',
                    Code: 'GTO'
                },
                {
                    Name: 'Imphal',
                    Code: 'IMF'
                },
                {
                    Name: 'Indore',
                    Code: 'IDR'
                },
                {
                    Name: 'Jabalpur',
                    Code: 'JLR'
                },
                {
                    Name: 'Jaipur',
                    Code: 'JAI'
                },
                {
                    Name: 'Jalandhar',
                    Code: 'JUC'
                },
                {
                    Name: 'Jodhpur',
                    Code: 'JDH'
                },
                {
                    Name: 'Karaikal',
                    Code: 'KRK'
                },
                {
                    Name: 'Kavaratti',
                    Code: 'KVA'
                },
                {
                    Name: 'Kohima',
                    Code: 'KOM'
                },
                {
                    Name: 'Madurai',
                    Code: 'IXM'
                },
                {
                    Name: 'Mahe',
                    Code: 'MAH'
                },
                {
                    Name: 'New Delhi',
                    Code: 'ICD'
                },
                {
                    Name: 'Pondicherry',
                    Code: 'PNY'
                },
                {
                    Name: 'Sambalpur',
                    Code: 'SLR'
                },
                {
                    Name: 'Shillong',
                    Code: 'SHL'
                },
                {
                    Name: 'Silvassa',
                    Code: 'SIL'
                },
                {
                    Name: 'Trivandrum',
                    Code: 'TRV'
                },
                {
                    Name: 'Udaipur',
                    Code: 'UDR'
                },
                {
                    Name: 'Yanam',
                    Code: 'SRV'
                }
                ]
            }
        },
        {
            Name: 'Indonesia',
            Code: 'IDN',
            State: {
                City: [{
                    Name: 'Aceh',
                    Code: 'AC'
                },
                {
                    Name: 'Bali',
                    Code: 'BA'
                },
                {
                    Name: 'Banten',
                    Code: 'BT'
                },
                {
                    Name: 'Bengkulu',
                    Code: 'BE'
                },
                {
                    Name: 'Daerah Istimewa Yogyakarta',
                    Code: 'YO'
                },
                {
                    Name: 'Daerah Tingkat I Kalimantan Barat',
                    Code: 'KB'
                },
                {
                    Name: 'Irian Jaya',
                    Code: 'IJ'
                },
                {
                    Name: 'Jakarta Raya',
                    Code: 'JK'
                },
                {
                    Name: 'Jambi',
                    Code: 'JA'
                },
                {
                    Name: 'Java Barat',
                    Code: 'JB'
                },
                {
                    Name: 'Java Tengah',
                    Code: 'JT'
                },
                {
                    Name: 'Java Timur',
                    Code: 'JI'
                },
                {
                    Name: 'Kalimantan Selatan',
                    Code: 'KS'
                },
                {
                    Name: 'Kalimantan Tengah',
                    Code: 'KT'
                },
                {
                    Name: 'Kalimantan Timur',
                    Code: 'KI'
                },
                {
                    Name: 'Kepulauan Bangka Belitung',
                    Code: 'BB'
                },
                {
                    Name: 'Lampung',
                    Code: 'LA'
                },
                {
                    Name: 'Maluku',
                    Code: 'MA'
                },
                {
                    Name: 'Nusa Tenggara Barat',
                    Code: 'NB'
                },
                {
                    Name: 'Nusa Tenggara Timur',
                    Code: 'NT'
                },
                {
                    Name: 'Riau',
                    Code: 'RI'
                },
                {
                    Name: 'Sulawesi Selatan',
                    Code: 'SN'
                },
                {
                    Name: 'Sulawesi Tengah',
                    Code: 'ST'
                },
                {
                    Name: 'Sulawesi Tenggara',
                    Code: 'SG'
                },
                {
                    Name: 'Sulawesi Utara',
                    Code: 'SA'
                },
                {
                    Name: 'Sumatera Barat',
                    Code: 'SR'
                },
                {
                    Name: 'Sumatera Selatan',
                    Code: 'SS'
                },
                {
                    Name: 'Sumatera Utara',
                    Code: 'SU'
                }
                ]
            }
        },
        {
            Name: 'Iran',
            Code: 'IRN'
        },
        {
            Name: 'Iraq',
            Code: 'IRQ'
        },
        {
            Name: 'Ireland',
            Code: 'IRL',
            State: {
                City: [{
                    Name: 'Carlow',
                    Code: 'CW'
                },
                {
                    Name: 'Cavan',
                    Code: 'CV'
                },
                {
                    Name: 'Clare',
                    Code: 'CL'
                },
                {
                    Name: 'Cork',
                    Code: 'CK'
                },
                {
                    Name: 'Donegal',
                    Code: 'DG'
                },
                {
                    Name: 'Dublin',
                    Code: 'DB'
                },
                {
                    Name: 'Galway',
                    Code: 'GW'
                },
                {
                    Name: 'Kerry',
                    Code: 'KR'
                },
                {
                    Name: 'Kildare',
                    Code: 'KD'
                },
                {
                    Name: 'Kilkenny',
                    Code: 'KK'
                },
                {
                    Name: 'Laois',
                    Code: 'LA'
                },
                {
                    Name: 'Leitrim',
                    Code: 'LR'
                },
                {
                    Name: 'Limerick',
                    Code: 'LM'
                },
                {
                    Name: 'Longford',
                    Code: 'LF'
                },
                {
                    Name: 'Louth',
                    Code: 'LT'
                },
                {
                    Name: 'Mayo',
                    Code: 'MY'
                },
                {
                    Name: 'Meath',
                    Code: 'MT'
                },
                {
                    Name: 'Monaghan',
                    Code: 'MG'
                },
                {
                    Name: 'Offaly',
                    Code: 'OF'
                },
                {
                    Name: 'Roscommon',
                    Code: 'RC'
                },
                {
                    Name: 'Sligo',
                    Code: 'SL'
                },
                {
                    Name: 'Tipperary',
                    Code: 'TP'
                },
                {
                    Name: 'Waterford',
                    Code: 'WF'
                },
                {
                    Name: 'Westmeath',
                    Code: 'WM'
                },
                {
                    Name: 'Wexford',
                    Code: 'WX'
                },
                {
                    Name: 'Wicklow',
                    Code: 'WK'
                }
                ]
            }
        },
        {
            Name: 'Isle of Man',
            Code: 'IMN'
        },
        {
            Name: 'Israel',
            Code: 'ISR',
            State: {
                City: [{
                    Name: 'Ashdod',
                    Code: 'ASH'
                },
                {
                    Name: 'Bat Yam',
                    Code: 'BAT'
                },
                {
                    Name: 'Beersheba',
                    Code: 'BEV'
                },
                {
                    Name: 'Haifa',
                    Code: 'HFA'
                },
                {
                    Name: 'Holon',
                    Code: 'HOL'
                },
                {
                    Name: 'Jerusalem',
                    Code: 'J'
                },
                {
                    Name: 'Netanya',
                    Code: 'NAT'
                },
                {
                    Name: 'Tel Aviv-Yafo',
                    Code: 'TLV'
                }
                ]
            }
        },
        {
            Name: 'Italy',
            Code: 'ITA',
            State: {
                City: [{
                    Name: 'Alessandria',
                    Code: 'ALE'
                },
                {
                    Name: 'Ancona',
                    Code: 'AOI'
                },
                {
                    Name: 'Aosta',
                    Code: 'AOT'
                },
                {
                    Name: 'Ascoli Piceno',
                    Code: 'ASP'
                },
                {
                    Name: 'Asti',
                    Code: 'AST'
                },
                {
                    Name: 'Bari',
                    Code: 'BRI'
                },
                {
                    Name: 'Benevento',
                    Code: 'BEN'
                },
                {
                    Name: 'Bergamo',
                    Code: 'BGO'
                },
                {
                    Name: 'Biella',
                    Code: 'BIE'
                },
                {
                    Name: 'Bologna',
                    Code: 'BLQ'
                },
                {
                    Name: 'Brescia',
                    Code: 'BRC'
                },
                {
                    Name: 'Brindisi',
                    Code: 'BDS'
                },
                {
                    Name: 'Cagliari',
                    Code: 'CAG'
                },
                {
                    Name: 'Campobasso',
                    Code: 'COB'
                },
                {
                    Name: 'Caserta',
                    Code: 'CST'
                },
                {
                    Name: 'Catania',
                    Code: 'CTA'
                },
                {
                    Name: 'Catanzaro',
                    Code: 'QCZ'
                },
                {
                    Name: 'Como',
                    Code: 'CIY'
                },
                {
                    Name: 'Cosenza',
                    Code: 'QCS'
                },
                {
                    Name: 'Crotone',
                    Code: 'CRV'
                },
                {
                    Name: 'Cuneo',
                    Code: 'CUN'
                },
                {
                    Name: 'Ferrara',
                    Code: 'FRR'
                },
                {
                    Name: 'Firenze',
                    Code: 'FLR'
                },
                {
                    Name: 'Foggia',
                    Code: 'FOG'
                },
                {
                    Name: 'Genova',
                    Code: 'CAX'
                },
                {
                    Name: 'Isernia',
                    Code: 'ISE'
                },
                {
                    Name: "L'Aquila",
                    Code: 'LAQ'
                },
                {
                    Name: 'La Spezia',
                    Code: 'SPE'
                },
                {
                    Name: 'Lecce',
                    Code: 'LCC'
                },
                {
                    Name: 'Lecco',
                    Code: 'LCO'
                },
                {
                    Name: 'Livorno',
                    Code: 'LIV'
                },
                {
                    Name: 'Massa-Carrara',
                    Code: 'MCR'
                },
                {
                    Name: 'Matera',
                    Code: 'MTR'
                },
                {
                    Name: 'Messina',
                    Code: 'MSN'
                },
                {
                    Name: 'Milano',
                    Code: 'MIL'
                },
                {
                    Name: 'Modena',
                    Code: 'MOD'
                },
                {
                    Name: 'Monza e Brianza',
                    Code: 'MZA'
                },
                {
                    Name: 'Naples',
                    Code: 'NAP'
                },
                {
                    Name: 'Novara',
                    Code: 'NVR'
                },
                {
                    Name: 'Nuoro',
                    Code: 'QNU'
                },
                {
                    Name: 'Olbia-Tempio',
                    Code: 'OLB'
                },
                {
                    Name: 'Oristano',
                    Code: 'QOS'
                },
                {
                    Name: 'Palermo',
                    Code: 'PMO'
                },
                {
                    Name: 'Parma',
                    Code: 'PMF'
                },
                {
                    Name: 'Pavia',
                    Code: 'PAV'
                },
                {
                    Name: 'Perugia',
                    Code: 'PEG'
                },
                {
                    Name: 'Pisa',
                    Code: 'PSA'
                },
                {
                    Name: 'Pordenone',
                    Code: 'PRD'
                },
                {
                    Name: 'Potenza',
                    Code: 'QPO'
                },
                {
                    Name: 'Reggio Calabria',
                    Code: 'REG'
                },
                {
                    Name: 'Reggio Emilia',
                    Code: 'RNE'
                },
                {
                    Name: 'Roma',
                    Code: 'ROM'
                },
                {
                    Name: 'Salerno',
                    Code: 'SAL'
                },
                {
                    Name: 'Sassari',
                    Code: 'QSS'
                },
                {
                    Name: 'Savona',
                    Code: 'SVN'
                },
                {
                    Name: 'Siena',
                    Code: 'SNA'
                },
                {
                    Name: 'Syracuse',
                    Code: 'SYR'
                },
                {
                    Name: 'Taranto',
                    Code: 'TAR'
                },
                {
                    Name: 'Trapani',
                    Code: 'TPS'
                },
                {
                    Name: 'Trento',
                    Code: 'TRT'
                },
                {
                    Name: 'Trieste',
                    Code: 'TRS'
                },
                {
                    Name: 'Turin',
                    Code: 'TRN'
                },
                {
                    Name: 'Udine',
                    Code: 'UDN'
                },
                {
                    Name: 'Venice',
                    Code: 'VCE'
                },
                {
                    Name: 'Vercelli',
                    Code: 'VRL'
                },
                {
                    Name: 'Viterbo',
                    Code: 'VIT'
                }
                ]
            }
        },
        {
            Name: 'Jamaica',
            Code: 'JAM',
            State: {
                City: [{
                    Name: 'Clarendon',
                    Code: 'CLA'
                },
                {
                    Name: 'Hanover',
                    Code: 'HAN'
                },
                {
                    Name: 'Kingston',
                    Code: 'KIN'
                },
                {
                    Name: 'Manchester',
                    Code: 'MAN'
                },
                {
                    Name: 'Portland',
                    Code: 'POR'
                },
                {
                    Name: 'St. Andrews',
                    Code: 'AND'
                },
                {
                    Name: 'St. Ann',
                    Code: 'ANN'
                },
                {
                    Name: 'St. Catherine',
                    Code: 'CAT'
                },
                {
                    Name: 'St. Elizabeth',
                    Code: 'ELI'
                },
                {
                    Name: 'St. James',
                    Code: 'JAM'
                },
                {
                    Name: 'St. Mary',
                    Code: 'MAR'
                },
                {
                    Name: 'St. Thomas',
                    Code: 'THO'
                },
                {
                    Name: 'Trelawny',
                    Code: 'TRL'
                },
                {
                    Name: 'Westmoreland',
                    Code: 'WML'
                }
                ]
            }
        },
        {
            Name: 'Japan',
            Code: 'JPN',
            State: {
                City: [{
                    Name: 'Aichi',
                    Code: '23'
                },
                {
                    Name: 'Akita',
                    Code: '5'
                },
                {
                    Name: 'Aomori',
                    Code: '2'
                },
                {
                    Name: 'Chiba',
                    Code: '12'
                },
                {
                    Name: 'Ehime',
                    Code: '38'
                },
                {
                    Name: 'Fukui',
                    Code: '18'
                },
                {
                    Name: 'Fukuoka',
                    Code: '40'
                },
                {
                    Name: 'Fukushima',
                    Code: '7'
                },
                {
                    Name: 'Gifu',
                    Code: '21'
                },
                {
                    Name: 'Gunma',
                    Code: '10'
                },
                {
                    Name: 'Hiroshima',
                    Code: '34'
                },
                {
                    Name: 'Hokkaido',
                    Code: '1'
                },
                {
                    Name: 'Hyogo',
                    Code: '28'
                },
                {
                    Name: 'Ibaraki',
                    Code: '8'
                },
                {
                    Name: 'Ishikawa',
                    Code: '17'
                },
                {
                    Name: 'Iwate',
                    Code: '3'
                },
                {
                    Name: 'Kagawa',
                    Code: '37'
                },
                {
                    Name: 'Kagoshima',
                    Code: '46'
                },
                {
                    Name: 'Kanagawa',
                    Code: '14'
                },
                {
                    Name: 'Kochi',
                    Code: '39'
                },
                {
                    Name: 'Kumamoto',
                    Code: '43'
                },
                {
                    Name: 'Kyoto',
                    Code: '26'
                },
                {
                    Name: 'Mie',
                    Code: '24'
                },
                {
                    Name: 'Miyagi',
                    Code: '4'
                },
                {
                    Name: 'Miyazaki',
                    Code: '45'
                },
                {
                    Name: 'Nagano',
                    Code: '20'
                },
                {
                    Name: 'Nagasaki',
                    Code: '42'
                },
                {
                    Name: 'Nara',
                    Code: '29'
                },
                {
                    Name: 'Niigata',
                    Code: '15'
                },
                {
                    Name: 'Oita',
                    Code: '44'
                },
                {
                    Name: 'Okayama',
                    Code: '33'
                },
                {
                    Name: 'Okinawa',
                    Code: '47'
                },
                {
                    Name: 'Osaka',
                    Code: '27'
                },
                {
                    Name: 'Saga',
                    Code: '41'
                },
                {
                    Name: 'Saitama',
                    Code: '11'
                },
                {
                    Name: 'Shiga',
                    Code: '25'
                },
                {
                    Name: 'Shimane',
                    Code: '32'
                },
                {
                    Name: 'Shizuoka',
                    Code: '22'
                },
                {
                    Name: 'Tochigi',
                    Code: '9'
                },
                {
                    Name: 'Tokushima',
                    Code: '36'
                },
                {
                    Name: 'Tokyo',
                    Code: '13'
                },
                {
                    Name: 'Tottori',
                    Code: '31'
                },
                {
                    Name: 'Toyama',
                    Code: '16'
                },
                {
                    Name: 'Wakayama',
                    Code: '30'
                },
                {
                    Name: 'Yamagata',
                    Code: '6'
                },
                {
                    Name: 'Yamaguchi',
                    Code: '35'
                },
                {
                    Name: 'Yamanashi',
                    Code: '19'
                }
                ]
            }
        },
        {
            Name: 'Jersey',
            Code: 'JEY'
        },
        {
            Name: 'Jordan',
            Code: 'JOR',
            State: {
                City: [{
                    Name: 'Allun',
                    Code: 'AJ'
                },
                {
                    Name: 'Amman',
                    Code: 'AM'
                },
                {
                    Name: 'Aqaba',
                    Code: 'AQ'
                },
                {
                    Name: 'Balqa',
                    Code: 'BA'
                },
                {
                    Name: 'Irbid',
                    Code: 'IR'
                },
                {
                    Name: 'Jarash',
                    Code: 'JA'
                },
                {
                    Name: 'Karak',
                    Code: 'KA'
                },
                {
                    Name: 'Maan',
                    Code: 'MN'
                },
                {
                    Name: 'Madaba',
                    Code: 'MD'
                },
                {
                    Name: 'Mafraq',
                    Code: 'MF'
                },
                {
                    Name: 'Rusayfah',
                    Code: 'RU'
                },
                {
                    Name: 'Tafiela',
                    Code: 'TA'
                },
                {
                    Name: 'Zarqa',
                    Code: 'ZA'
                }
                ]
            }
        },
        {
            Name: 'Kazakhstan',
            Code: 'KAZ',
            State: {
                City: [{
                    Name: 'Aksu',
                    Code: 'AKS'
                },
                {
                    Name: 'Almaty',
                    Code: 'ALA'
                },
                {
                    Name: 'Aqmola',
                    Code: 'AKM'
                },
                {
                    Name: 'Aqtobe',
                    Code: 'AKT'
                },
                {
                    Name: 'Arkalyk',
                    Code: 'AYK'
                },
                {
                    Name: 'Arys',
                    Code: 'ARY'
                },
                {
                    Name: 'Astana',
                    Code: 'AST'
                },
                {
                    Name: 'Atyrau',
                    Code: 'ATY'
                },
                {
                    Name: 'Balkhash',
                    Code: 'BXH'
                },
                {
                    Name: 'Batys Qazaqstan',
                    Code: 'ZAP'
                },
                {
                    Name: 'Ekibastuz',
                    Code: 'EKB'
                },
                {
                    Name: 'Kapchagay',
                    Code: 'KAP'
                },
                {
                    Name: 'Karazhal',
                    Code: 'KZO'
                },
                {
                    Name: 'Kentau',
                    Code: 'KEN'
                },
                {
                    Name: 'Kurchatov',
                    Code: 'KUR'
                },
                {
                    Name: 'Leninogorsk',
                    Code: 'LEN'
                },
                {
                    Name: 'Lisakovsk',
                    Code: 'LKK'
                },
                {
                    Name: 'Mangghystau',
                    Code: 'MAN'
                },
                {
                    Name: 'Ongtustik Qazaqstan',
                    Code: 'KGT'
                },
                {
                    Name: 'Pavlodar',
                    Code: 'PAV'
                },
                {
                    Name: 'Qaraghandy',
                    Code: 'KAR'
                },
                {
                    Name: 'Qostanay',
                    Code: 'KST'
                },
                {
                    Name: 'Qyzylorda',
                    Code: 'KZY'
                },
                {
                    Name: 'Rudny',
                    Code: 'RUD'
                },
                {
                    Name: 'Saran',
                    Code: 'SAR'
                },
                {
                    Name: 'Semey',
                    Code: 'SEM'
                },
                {
                    Name: 'Shakhtinsk',
                    Code: 'SAK'
                },
                {
                    Name: 'Shyghys Qazaqstan',
                    Code: 'VOS'
                },
                {
                    Name: 'Soltustik Qazaqstan',
                    Code: 'SEV'
                },
                {
                    Name: 'Stepnogorsk',
                    Code: 'STE'
                },
                {
                    Name: 'Tekeli',
                    Code: 'TEK'
                },
                {
                    Name: 'Temirtau',
                    Code: 'TEM'
                },
                {
                    Name: 'Turkestan',
                    Code: 'TUR'
                },
                {
                    Name: 'Zhambyl',
                    Code: 'DMB'
                },
                {
                    Name: 'Zhanaozen',
                    Code: 'ZHA'
                },
                {
                    Name: 'Zhezkazgan',
                    Code: 'DZH'
                },
                {
                    Name: 'Zyryanovsk',
                    Code: 'ZYR'
                }
                ]
            }
        },
        {
            Name: 'Kenya',
            Code: 'KEN',
            State: {
                City: [{
                    Name: 'Baringo',
                    Code: 'BAR'
                },
                {
                    Name: 'Bomet',
                    Code: 'BOM'
                },
                {
                    Name: 'Bungoma',
                    Code: 'BUN'
                },
                {
                    Name: 'Busia',
                    Code: 'BUS'
                },
                {
                    Name: 'Central',
                    Code: 'CE'
                },
                {
                    Name: 'Elgeyo-Marakwet',
                    Code: 'EMA'
                },
                {
                    Name: 'Embu',
                    Code: 'EMB'
                },
                {
                    Name: 'Garissa',
                    Code: 'GAS'
                },
                {
                    Name: 'Homa Bay',
                    Code: 'HOB'
                },
                {
                    Name: 'Isiolo',
                    Code: 'ISI'
                },
                {
                    Name: 'Kajiado',
                    Code: 'KAJ'
                },
                {
                    Name: 'Kakamega',
                    Code: 'KAK'
                },
                {
                    Name: 'Kericho',
                    Code: 'KEY'
                },
                {
                    Name: 'Kiambu',
                    Code: 'KIA'
                },
                {
                    Name: 'Kilifi',
                    Code: 'KIL'
                },
                {
                    Name: 'Kirinyaga',
                    Code: 'KIR'
                },
                {
                    Name: 'Kisii',
                    Code: 'KII'
                },
                {
                    Name: 'Kisumu',
                    Code: 'KIS'
                },
                {
                    Name: 'Kitui',
                    Code: 'KIT'
                },
                {
                    Name: 'Kwale',
                    Code: 'KWA'
                },
                {
                    Name: 'Laikipia',
                    Code: 'LAI'
                },
                {
                    Name: 'Lamu',
                    Code: 'LAU'
                },
                {
                    Name: 'Machakos',
                    Code: 'MAC'
                },
                {
                    Name: 'Makueni',
                    Code: 'MAK'
                },
                {
                    Name: 'Mandera',
                    Code: 'MAN'
                },
                {
                    Name: 'Marsabit',
                    Code: 'RBT'
                },
                {
                    Name: 'Meru',
                    Code: 'MER'
                },
                {
                    Name: 'Migori',
                    Code: 'MIG'
                },
                {
                    Name: 'Mombasa',
                    Code: 'MOM'
                },
                {
                    Name: 'Muranga',
                    Code: 'MUR'
                },
                {
                    Name: 'Nairobi',
                    Code: 'NA'
                },
                {
                    Name: 'Nakuru',
                    Code: 'NUU'
                },
                {
                    Name: 'Nandi',
                    Code: 'NAN'
                },
                {
                    Name: 'Narok',
                    Code: 'NAR'
                },
                {
                    Name: 'Nithi',
                    Code: 'NIT'
                },
                {
                    Name: 'Nyamira',
                    Code: 'NYM'
                },
                {
                    Name: 'Nyandarua',
                    Code: 'NYN'
                },
                {
                    Name: 'Nyeri',
                    Code: 'NYE'
                },
                {
                    Name: 'Samburu',
                    Code: 'UAS'
                },
                {
                    Name: 'Siaya',
                    Code: 'SIA'
                },
                {
                    Name: 'Taita-Taveta',
                    Code: 'TTA'
                },
                {
                    Name: 'Tana River',
                    Code: 'TRI'
                },
                {
                    Name: 'Trans-Nzoia',
                    Code: 'TNZ'
                },
                {
                    Name: 'Turkana',
                    Code: 'TUR'
                },
                {
                    Name: 'Uasin Gishu',
                    Code: 'UGI'
                },
                {
                    Name: 'Vihiga',
                    Code: 'VIH'
                },
                {
                    Name: 'Wajir',
                    Code: 'WJR'
                },
                {
                    Name: 'West Pokot',
                    Code: 'WPO'
                }
                ]
            }
        },
        {
            Name: 'Kiribati',
            Code: 'KIR',
            State: {
                City: [{
                    Name: 'Gilberts Islands',
                    Code: 'GIL'
                },
                {
                    Name: 'Line Islands',
                    Code: 'LIN'
                },
                {
                    Name: 'Phoenix Islands',
                    Code: 'PHO'
                }
                ]
            }
        },
        {
            Name: 'Korea',
            Code: 'KOR',
            State: [{
                Name: 'Busan',
                Code: '26'
            },
            {
                Name: 'Chungcheongbuk-do',
                Code: '43',
                City: [{
                    Name: 'Andong',
                    Code: 'ADG'
                },
                {
                    Name: 'Bonghwa County',
                    Code: 'BHA'
                },
                {
                    Name: 'Cheongdo County',
                    Code: 'CDO'
                },
                {
                    Name: 'Cheongsong County',
                    Code: 'CSG'
                },
                {
                    Name: 'Chilgok County',
                    Code: 'CGK'
                },
                {
                    Name: 'Gimcheon',
                    Code: 'KMC'
                },
                {
                    Name: 'Goryeong County',
                    Code: 'GRG'
                },
                {
                    Name: 'Gumi',
                    Code: 'KUM'
                },
                {
                    Name: 'Gunwi County',
                    Code: 'GWI'
                },
                {
                    Name: 'Gyeongju',
                    Code: 'GJU'
                },
                {
                    Name: 'Gyeongsan',
                    Code: 'GYS'
                },
                {
                    Name: 'Mungyeong',
                    Code: 'MGG'
                },
                {
                    Name: 'Pohang',
                    Code: 'KPO'
                },
                {
                    Name: 'Sangju',
                    Code: 'SJU'
                },
                {
                    Name: 'Seongju County',
                    Code: 'SEJ'
                },
                {
                    Name: 'Uiseong County',
                    Code: 'USG'
                },
                {
                    Name: 'Uljin County',
                    Code: 'UJN'
                },
                {
                    Name: 'Ulleung County',
                    Code: 'ULG'
                },
                {
                    Name: 'Yecheon County',
                    Code: 'YEC'
                },
                {
                    Name: 'Yeongcheon',
                    Code: 'YCH'
                },
                {
                    Name: 'Yeongdeok County',
                    Code: 'YDK'
                },
                {
                    Name: 'Yeongju',
                    Code: 'YEJ'
                },
                {
                    Name: 'Yeongyang County',
                    Code: 'YYG'
                }
                ]
            },
            {
                Name: 'Chungcheongnam-do',
                Code: '44',
                City: [{
                    Name: 'Changnyeong County',
                    Code: 'CNG'
                },
                {
                    Name: 'Changwon',
                    Code: 'CHW'
                },
                {
                    Name: 'Geochang County',
                    Code: 'GCH'
                },
                {
                    Name: 'Geoje',
                    Code: 'KJE'
                },
                {
                    Name: 'Gimhae',
                    Code: 'KMH'
                },
                {
                    Name: 'Goseong County',
                    Code: 'GSO'
                },
                {
                    Name: 'Hadong County',
                    Code: 'HDG'
                },
                {
                    Name: 'Haman County',
                    Code: 'HAN'
                },
                {
                    Name: 'Hamyang County',
                    Code: 'HYG'
                },
                {
                    Name: 'Hapcheon County',
                    Code: 'HCE'
                },
                {
                    Name: 'Jinhae',
                    Code: 'CHF'
                },
                {
                    Name: 'Jinju',
                    Code: 'HIN'
                },
                {
                    Name: 'Masan',
                    Code: 'MAS'
                },
                {
                    Name: 'Miryang',
                    Code: 'MIR'
                },
                {
                    Name: 'Namhae County',
                    Code: 'NHE'
                },
                {
                    Name: 'Sacheon',
                    Code: 'SAH'
                },
                {
                    Name: 'Sancheong County',
                    Code: 'SCH'
                },
                {
                    Name: 'Tongyeong',
                    Code: 'TYG'
                },
                {
                    Name: 'Uiryeong County',
                    Code: 'URG'
                },
                {
                    Name: 'Yangsan',
                    Code: 'YSN'
                }
                ]
            },
            {
                Name: 'Daegu',
                Code: '27',
                City: [{
                    Name: 'Daegu',
                    Code: 'TAE'
                },
                {
                    Name: 'Dalseong-gun',
                    Code: 'DSG'
                },
                {
                    Name: 'Suseong-gu',
                    Code: 'SUS'
                }
                ]
            },
            {
                Name: 'Daejeon',
                Code: '30'
            },
            {
                Name: 'Gangwon-do',
                Code: '42',
                City: [{
                    Name: 'Cheorwon County',
                    Code: 'CWN'
                },
                {
                    Name: 'Chuncheon',
                    Code: 'CHC'
                },
                {
                    Name: 'Donghae',
                    Code: 'TGH'
                },
                {
                    Name: 'Gangneung',
                    Code: 'KAG'
                },
                {
                    Name: 'Goseong County',
                    Code: 'GSG'
                },
                {
                    Name: 'Hoengseong County',
                    Code: 'HSG'
                },
                {
                    Name: 'Hongcheon County',
                    Code: 'HCN'
                },
                {
                    Name: 'Hwacheon County',
                    Code: 'HCH'
                },
                {
                    Name: 'Inje County',
                    Code: 'IJE'
                },
                {
                    Name: 'Jeongseon County',
                    Code: 'JSE'
                },
                {
                    Name: 'Pyeongchang County',
                    Code: 'POG'
                },
                {
                    Name: 'Samcheok',
                    Code: 'SUK'
                },
                {
                    Name: 'Sokcho',
                    Code: 'SHO'
                },
                {
                    Name: 'Taebaek',
                    Code: 'TBK'
                },
                {
                    Name: 'Wonju',
                    Code: 'WJU'
                },
                {
                    Name: 'Yanggu County',
                    Code: 'YGU'
                },
                {
                    Name: 'Yangyang County',
                    Code: 'YNY'
                },
                {
                    Name: 'Yeongwol County',
                    Code: 'YWL'
                }
                ]
            },
            {
                Name: 'Gwangju',
                Code: '29'
            },
            {
                Name: 'Jeju-do',
                Code: '41',
                City: {
                    Name: 'Suwon',
                    Code: 'SUO'
                }
            },
            {
                Name: 'Gyeongsangbuk-do',
                Code: '47',
                City: [{
                    Name: 'Buan County',
                    Code: 'PUS'
                },
                {
                    Name: 'Gimje',
                    Code: 'GJE'
                },
                {
                    Name: 'Gochang County',
                    Code: 'GCG'
                },
                {
                    Name: 'Gunsan',
                    Code: 'KUV'
                },
                {
                    Name: 'Iksan',
                    Code: 'IKS'
                },
                {
                    Name: 'Imsil County',
                    Code: 'ISL'
                },
                {
                    Name: 'Jangsu County',
                    Code: 'JSU'
                },
                {
                    Name: 'Jeongeup',
                    Code: 'JEO'
                },
                {
                    Name: 'Jeonju',
                    Code: 'JNJ'
                },
                {
                    Name: 'Jinan County',
                    Code: 'JAN'
                },
                {
                    Name: 'Muju County',
                    Code: 'MJU'
                },
                {
                    Name: 'Namwon',
                    Code: 'NWN'
                },
                {
                    Name: 'Sunchang County',
                    Code: 'SCG'
                },
                {
                    Name: 'Wanju County',
                    Code: 'WAJ'
                }
                ]
            },
            {
                Name: 'Gyeongsangnam-do',
                Code: '48',
                City: [{
                    Name: 'Boseong County',
                    Code: 'BSG'
                },
                {
                    Name: 'Damyang County',
                    Code: 'DYA'
                },
                {
                    Name: 'Gangjin County',
                    Code: 'GJN'
                },
                {
                    Name: 'Goheung County',
                    Code: 'GHG'
                },
                {
                    Name: 'Gokseong County',
                    Code: 'GSE'
                },
                {
                    Name: 'Gurye County',
                    Code: 'GRE'
                },
                {
                    Name: 'Gwangyang',
                    Code: 'KAN'
                },
                {
                    Name: 'Haenam County',
                    Code: 'HAE'
                },
                {
                    Name: 'Hampyeong County',
                    Code: 'HPG'
                },
                {
                    Name: 'Hwasun County',
                    Code: 'HSN'
                },
                {
                    Name: 'Jangheung County',
                    Code: 'JHG'
                },
                {
                    Name: 'Jangseong County',
                    Code: 'JSN'
                },
                {
                    Name: 'Jindo County',
                    Code: 'JDO'
                },
                {
                    Name: 'Mokpo',
                    Code: 'MOK'
                },
                {
                    Name: 'Muan County',
                    Code: 'MAN'
                },
                {
                    Name: 'Naju',
                    Code: 'NJU'
                },
                {
                    Name: 'Sinan County',
                    Code: 'SAN'
                },
                {
                    Name: 'Suncheon',
                    Code: 'SYS'
                },
                {
                    Name: 'Wando County',
                    Code: 'WND'
                },
                {
                    Name: 'Yeongam County',
                    Code: 'YAM'
                },
                {
                    Name: 'Yeonggwang County',
                    Code: 'YGG'
                },
                {
                    Name: 'Yeosu',
                    Code: 'YOS'
                }
                ]
            },
            {
                Name: 'Incheon',
                Code: '28'
            },
            {
                Name: 'Gyeonggi-do',
                Code: '49',
                City: [{
                    Name: 'Ansan',
                    Code: 'ASN'
                },
                {
                    Name: 'Anseong',
                    Code: 'ASG'
                },
                {
                    Name: 'Anyang',
                    Code: 'ANY'
                },
                {
                    Name: 'Bucheon',
                    Code: 'BCN'
                },
                {
                    Name: 'Dongducheon',
                    Code: 'DDC'
                },
                {
                    Name: 'Gapyeong County',
                    Code: 'GPG'
                },
                {
                    Name: 'Gimpo',
                    Code: 'GMP'
                },
                {
                    Name: 'Goyang',
                    Code: 'GYG'
                },
                {
                    Name: 'Gunpo',
                    Code: 'GUN'
                },
                {
                    Name: 'Guri',
                    Code: 'GRI'
                },
                {
                    Name: 'Gwacheon',
                    Code: 'GCN'
                },
                {
                    Name: 'Gwangju',
                    Code: 'KWU'
                },
                {
                    Name: 'Gwangmyeong',
                    Code: 'GMG'
                },
                {
                    Name: 'Hanam',
                    Code: 'HNM'
                },
                {
                    Name: 'Hwaseong',
                    Code: 'HCH'
                },
                {
                    Name: 'Icheon',
                    Code: 'ICE'
                },
                {
                    Name: 'Namyangju',
                    Code: 'NYU'
                },
                {
                    Name: 'Osan',
                    Code: 'OSN'
                },
                {
                    Name: 'Paju',
                    Code: 'PJU'
                },
                {
                    Name: 'Pocheon',
                    Code: 'POC'
                },
                {
                    Name: 'Pyeongtaek',
                    Code: 'PTK'
                },
                {
                    Name: 'Seongnam',
                    Code: 'SEO'
                },
                {
                    Name: 'Siheung',
                    Code: 'SHE'
                },
                {
                    Name: 'Uijeongbu',
                    Code: 'UIJ'
                },
                {
                    Name: 'Uiwang',
                    Code: 'UWN'
                },
                {
                    Name: 'Yangju',
                    Code: 'YYU'
                },
                {
                    Name: 'Yangpyeong County',
                    Code: 'YPG'
                },
                {
                    Name: 'Yeoju County',
                    Code: 'YJU'
                },
                {
                    Name: 'Yeoncheon County',
                    Code: 'YCN'
                },
                {
                    Name: 'Yongin',
                    Code: 'YNG'
                }
                ]
            },
            {
                Name: 'Jeollabuk-do',
                Code: '45',
                City: [{
                    Name: 'Boeun County',
                    Code: 'BEN'
                },
                {
                    Name: 'Cheongju',
                    Code: 'CJJ'
                },
                {
                    Name: 'Cheongwon County',
                    Code: 'CWO'
                },
                {
                    Name: 'Chungju',
                    Code: 'CHU'
                },
                {
                    Name: 'Danyang County',
                    Code: 'DYG'
                },
                {
                    Name: 'Eumseong County',
                    Code: 'ESG'
                },
                {
                    Name: 'Goesan County',
                    Code: 'GSN'
                },
                {
                    Name: 'Jecheon',
                    Code: 'JCH'
                },
                {
                    Name: 'Jeungpyeong County',
                    Code: 'JYG'
                },
                {
                    Name: 'Jincheon County',
                    Code: 'JCN'
                },
                {
                    Name: 'Okcheon County',
                    Code: 'OCN'
                },
                {
                    Name: 'Yeongdong County',
                    Code: 'YDG'
                }
                ]
            },
            {
                Name: 'Jeollanam-do',
                Code: '46',
                City: [{
                    Name: 'Asan',
                    Code: 'ASA'
                },
                {
                    Name: 'Boryeong',
                    Code: 'BOR'
                },
                {
                    Name: 'Buyeo County',
                    Code: 'BYO'
                },
                {
                    Name: 'Cheonan',
                    Code: 'CHO'
                },
                {
                    Name: 'Cheongyang County',
                    Code: 'CYG'
                },
                {
                    Name: 'Dangjin County',
                    Code: 'TJI'
                },
                {
                    Name: 'Geumsan County',
                    Code: 'GSA'
                },
                {
                    Name: 'Gongju',
                    Code: 'GOJ'
                },
                {
                    Name: 'Gyeryong',
                    Code: 'GYE'
                },
                {
                    Name: 'Hongseong County',
                    Code: 'HSE'
                },
                {
                    Name: 'Nonsan',
                    Code: 'NSN'
                },
                {
                    Name: 'Seocheon County',
                    Code: 'SCE'
                },
                {
                    Name: 'Seosan',
                    Code: 'SSA'
                },
                {
                    Name: 'Taean County',
                    Code: 'TAN'
                },
                {
                    Name: 'Yeongi County',
                    Code: 'YGI'
                },
                {
                    Name: 'Yesan County',
                    Code: 'YOS'
                }
                ]
            },
            {
                Name: 'Seoul',
                Code: '11'
            },
            {
                Name: 'Ulsan',
                Code: '31'
            }
            ]
        },
        {
            Name: 'Kuwait',
            Code: 'KWT'
        },
        {
            Name: 'Kyrgyzstan',
            Code: 'KGZ',
            State: {
                City: [{
                    Name: 'Batken',
                    Code: 'B'
                },
                {
                    Name: 'Bishkek',
                    Code: 'GB'
                },
                {
                    Name: 'Chuy',
                    Code: 'C'
                },
                {
                    Name: 'Jalal-Abad',
                    Code: 'J'
                },
                {
                    Name: 'Kant',
                    Code: 'KAN'
                },
                {
                    Name: 'Karabalta',
                    Code: 'KBA'
                },
                {
                    Name: 'Kara-Kol',
                    Code: 'KKO'
                },
                {
                    Name: 'Kok-Jangak',
                    Code: 'KJ'
                },
                {
                    Name: 'Mailuu-Suu',
                    Code: 'MS'
                },
                {
                    Name: 'Naryn',
                    Code: 'N'
                },
                {
                    Name: 'Osh',
                    Code: 'O'
                },
                {
                    Name: 'Suluktu',
                    Code: 'SU'
                },
                {
                    Name: 'Talas',
                    Code: 'T'
                },
                {
                    Name: 'Tash-Kumyr',
                    Code: 'TK'
                },
                {
                    Name: 'Uzgen',
                    Code: 'UG'
                },
                {
                    Name: 'Ysyk-Kol',
                    Code: 'Y'
                }
                ]
            }
        },
        {
            Name: 'Laos',
            Code: 'LAO',
            State: {
                City: [{
                    Name: 'Attapu',
                    Code: 'AT'
                },
                {
                    Name: 'Bokeo',
                    Code: 'BK'
                },
                {
                    Name: 'Bolikhamxai',
                    Code: 'BL'
                },
                {
                    Name: 'Champasak',
                    Code: 'CH'
                },
                {
                    Name: 'Houaphan',
                    Code: 'HO'
                },
                {
                    Name: 'Khammouan',
                    Code: 'KH'
                },
                {
                    Name: 'Louang Namtha',
                    Code: 'LM'
                },
                {
                    Name: 'Louangphrabang',
                    Code: 'LP'
                },
                {
                    Name: 'Oudomxai',
                    Code: 'OU'
                },
                {
                    Name: 'Phongsali',
                    Code: 'PH'
                },
                {
                    Name: 'Saravan',
                    Code: 'SL'
                },
                {
                    Name: 'Savannakhet',
                    Code: 'SV'
                },
                {
                    Name: 'Vientiane',
                    Code: 'VI'
                },
                {
                    Name: 'Xaignabouri',
                    Code: 'XA'
                },
                {
                    Name: 'Xaisomboun',
                    Code: 'XN'
                },
                {
                    Name: 'Xekong',
                    Code: 'XE'
                },
                {
                    Name: 'Xiangkhoang',
                    Code: 'XI'
                }
                ]
            }
        },
        {
            Name: 'Latvia',
            Code: 'LVA',
            State: {
                City: [{
                    Name: 'Aizkraukles',
                    Code: 'AIZ'
                },
                {
                    Name: 'Aluksnes',
                    Code: 'ALU'
                },
                {
                    Name: 'Balvu',
                    Code: 'BAL'
                },
                {
                    Name: 'Bauskas',
                    Code: 'BAU'
                },
                {
                    Name: 'Cesu',
                    Code: 'CES'
                },
                {
                    Name: 'Daugavpils',
                    Code: 'DGR'
                },
                {
                    Name: 'Dobeles',
                    Code: 'DOB'
                },
                {
                    Name: 'Gulbenes',
                    Code: 'GUL'
                },
                {
                    Name: 'Jekabpils',
                    Code: 'JEK'
                },
                {
                    Name: 'Jelgavas',
                    Code: 'JGR'
                },
                {
                    Name: 'Kraslavas',
                    Code: 'KRA'
                },
                {
                    Name: 'Kuldigas',
                    Code: 'KUL'
                },
                {
                    Name: 'Liepajas',
                    Code: 'LPK'
                },
                {
                    Name: 'Limbazu',
                    Code: 'LIM'
                },
                {
                    Name: 'Ludzas',
                    Code: 'LUD'
                },
                {
                    Name: 'Madonas',
                    Code: 'MAD'
                },
                {
                    Name: 'Ogres',
                    Code: 'OGR'
                },
                {
                    Name: 'Preilu',
                    Code: 'PRE'
                },
                {
                    Name: 'Rezeknes',
                    Code: 'RZR'
                },
                {
                    Name: 'Rigas',
                    Code: 'RGA'
                },
                {
                    Name: 'Saldus',
                    Code: 'SAL'
                },
                {
                    Name: 'Talsu',
                    Code: 'TAL'
                },
                {
                    Name: 'Tukuma',
                    Code: 'TUK'
                },
                {
                    Name: 'Valkas',
                    Code: 'VLK'
                },
                {
                    Name: 'Valmieras',
                    Code: 'VLM'
                },
                {
                    Name: 'Ventspils',
                    Code: 'VSL'
                }
                ]
            }
        },
        {
            Name: 'Lebanon',
            Code: 'LBN',
            State: {
                City: [{
                    Name: 'Al-Biqa',
                    Code: 'BI'
                },
                {
                    Name: 'Al-Janub',
                    Code: 'JA'
                },
                {
                    Name: 'An-Nabatiyah',
                    Code: 'NA'
                },
                {
                    Name: 'Ash-Shamal',
                    Code: 'AS'
                },
                {
                    Name: 'Bayrut',
                    Code: 'BA'
                },
                {
                    Name: 'Jabal Lubnan',
                    Code: 'JL'
                }
                ]
            }
        },
        {
            Name: 'Lesotho',
            Code: 'LSO',
            State: {
                City: [{
                    Name: 'Berea',
                    Code: 'D'
                },
                {
                    Name: 'Butha-Buthe',
                    Code: 'B'
                },
                {
                    Name: 'Leribe',
                    Code: 'C'
                },
                {
                    Name: 'Mafeteng',
                    Code: 'E'
                },
                {
                    Name: 'Maseru',
                    Code: 'A'
                },
                {
                    Name: 'Mohales Hoek',
                    Code: 'F'
                },
                {
                    Name: 'Mokhotlong',
                    Code: 'J'
                },
                {
                    Name: 'Qachas Nek',
                    Code: 'H'
                },
                {
                    Name: 'Quthing',
                    Code: 'G'
                },
                {
                    Name: 'Thaba-Tseka',
                    Code: 'K'
                }
                ]
            }
        },
        {
            Name: 'Liberia',
            Code: 'LBR',
            State: {
                City: [{
                    Name: 'Bomi',
                    Code: 'BM'
                },
                {
                    Name: 'Bong',
                    Code: 'BG'
                },
                {
                    Name: 'Bopolu',
                    Code: 'BOP'
                },
                {
                    Name: 'Fish Town',
                    Code: 'FT'
                },
                {
                    Name: 'Gbarpolu',
                    Code: 'GBA'
                },
                {
                    Name: 'Grand Bassa',
                    Code: 'GB'
                },
                {
                    Name: 'Grand Cape Mount',
                    Code: 'CM'
                },
                {
                    Name: 'Grand Gedeh',
                    Code: 'GG'
                },
                {
                    Name: 'Grand Kru',
                    Code: 'GK'
                },
                {
                    Name: 'Lofa',
                    Code: 'LO'
                },
                {
                    Name: 'Margibi',
                    Code: 'MG'
                },
                {
                    Name: 'Maryland',
                    Code: 'MY'
                },
                {
                    Name: 'Montserrado',
                    Code: 'MO'
                },
                {
                    Name: 'Nimba',
                    Code: 'NI'
                },
                {
                    Name: 'River Cess',
                    Code: 'RI'
                },
                {
                    Name: 'River Gee',
                    Code: 'RG'
                },
                {
                    Name: 'Sinoe',
                    Code: 'SI'
                }
                ]
            }
        },
        {
            Name: 'Libya',
            Code: 'LBY'
        },
        {
            Name: 'Liechtenstein',
            Code: 'LIE'
        },
        {
            Name: 'Lithuania',
            Code: 'LTU',
            State: {
                City: [{
                    Name: 'Akmenes',
                    Code: 'AKM'
                },
                {
                    Name: 'Alytus',
                    Code: 'AL'
                },
                {
                    Name: 'Kaunas',
                    Code: 'KA'
                },
                {
                    Name: 'Klaipeda',
                    Code: 'KL'
                },
                {
                    Name: 'Marijampole',
                    Code: 'MA'
                },
                {
                    Name: 'Panevezys',
                    Code: 'PA'
                },
                {
                    Name: 'Siauliai',
                    Code: 'SI'
                },
                {
                    Name: 'Taurages',
                    Code: 'TA'
                },
                {
                    Name: 'Telsiu',
                    Code: 'TE'
                },
                {
                    Name: 'Utenos',
                    Code: 'UT'
                },
                {
                    Name: 'Vilnius',
                    Code: 'VI'
                }
                ]
            }
        },
        {
            Name: 'Luxembourg',
            Code: 'LUX',
            State: {
                City: [{
                    Name: 'Diekirch',
                    Code: 'DD'
                },
                {
                    Name: 'Grevenmacher',
                    Code: 'GG'
                },
                {
                    Name: 'Luxembourg',
                    Code: 'LL'
                }
                ]
            }
        },
        {
            Name: 'Macedonia,Former Yugoslav Republic of',
            Code: 'MKD'
        },
        {
            Name: 'Madagascar',
            Code: 'MDG',
            State: {
                City: [{
                    Name: 'Antananarivo',
                    Code: 'AN'
                },
                {
                    Name: 'Antsiranana',
                    Code: 'AS'
                },
                {
                    Name: 'Fianarantsoa',
                    Code: 'FN'
                },
                {
                    Name: 'Mahajanga',
                    Code: 'MJ'
                },
                {
                    Name: 'Toamasina',
                    Code: 'TM'
                },
                {
                    Name: 'Toliary',
                    Code: 'TL'
                }
                ]
            }
        },
        {
            Name: 'Malawi',
            Code: 'MWI',
            State: {
                City: [{
                    Name: 'Central',
                    Code: 'C'
                },
                {
                    Name: 'Northern',
                    Code: 'N'
                },
                {
                    Name: 'Southern',
                    Code: 'S'
                }
                ]
            }
        },
        {
            Name: 'Malaysia',
            Code: 'MYS',
            State: [{
                Name: 'Johor',
                Code: 'JH',
                City: [{
                    Name: 'Batu Pahat',
                    Code: 'BAT'
                },
                {
                    Name: 'Johor Bahru',
                    Code: 'JHB'
                },
                {
                    Name: 'Kluang',
                    Code: 'KLA'
                },
                {
                    Name: 'Kota Tinggi',
                    Code: 'KTI'
                },
                {
                    Name: 'Mersing',
                    Code: 'MEP'
                },
                {
                    Name: 'Muar',
                    Code: 'MUA'
                },
                {
                    Name: 'Pontian',
                    Code: 'POW'
                },
                {
                    Name: 'Segamat',
                    Code: 'SGM'
                }
                ]
            },
            {
                Name: 'Kedah',
                Code: 'KD',
                City: [{
                    Name: 'Baling',
                    Code: 'BLZ'
                },
                {
                    Name: 'Bandar Baharu',
                    Code: 'BMA'
                },
                {
                    Name: 'Kota Setar',
                    Code: 'KOR'
                },
                {
                    Name: 'Kuala Muda',
                    Code: 'KMU'
                },
                {
                    Name: 'Kubang Pasu',
                    Code: 'KPA'
                },
                {
                    Name: 'Kulim',
                    Code: 'KLM'
                },
                {
                    Name: 'Langkawi',
                    Code: 'LGK'
                },
                {
                    Name: 'Padang Terap',
                    Code: 'PGT'
                },
                {
                    Name: 'Pendang',
                    Code: 'PEN'
                }
                ]
            },
            {
                Name: 'Kelantan',
                Code: 'KN',
                City: [{
                    Name: 'Bachok',
                    Code: 'BAC'
                },
                {
                    Name: 'Gua Musang',
                    Code: 'GMU'
                },
                {
                    Name: 'Jeli',
                    Code: 'JEL'
                },
                {
                    Name: 'Kota Bharu',
                    Code: 'KBR'
                },
                {
                    Name: 'Kuala Krai',
                    Code: 'KUG'
                },
                {
                    Name: 'Machang',
                    Code: 'MAC'
                },
                {
                    Name: 'Pasir Mas',
                    Code: 'PMA'
                },
                {
                    Name: 'Pasir Putih',
                    Code: 'PPU'
                },
                {
                    Name: 'Tanah Merah',
                    Code: 'TMR'
                },
                {
                    Name: 'Tumpat',
                    Code: 'TUM'
                }
                ]
            },
            {
                Name: 'Kuala Lumpur',
                Code: 'KL',
                City: {
                    Name: 'Kuala Lumpur',
                    Code: 'KUL'
                }
            },
            {
                Name: 'Labuan',
                Code: 'LB',
                City: [{
                    Name: 'Labuan',
                    Code: 'LBU'
                },
                {
                    Name: 'Victoria',
                    Code: 'VIC'
                }
                ]
            },
            {
                Name: 'Malacca',
                Code: 'ML',
                City: [{
                    Name: 'Alor Gajah',
                    Code: 'AOG'
                },
                {
                    Name: 'Jasin',
                    Code: 'JAS'
                },
                {
                    Name: 'Melaka',
                    Code: 'MEL'
                }
                ]
            },
            {
                Name: 'Negeri Sembilan',
                Code: 'NS',
                City: [{
                    Name: 'Jelebu',
                    Code: 'JEL'
                },
                {
                    Name: 'Jempol',
                    Code: 'JEP'
                },
                {
                    Name: 'Kuala Pilah',
                    Code: 'KPI'
                },
                {
                    Name: 'Port Dickson',
                    Code: 'PDI'
                },
                {
                    Name: 'Rembau',
                    Code: 'REM'
                },
                {
                    Name: 'Seremban',
                    Code: 'SRB'
                },
                {
                    Name: 'Tampin',
                    Code: 'TAI'
                }
                ]
            },
            {
                Name: 'Pahang',
                Code: 'PG',
                City: [{
                    Name: 'Bentong',
                    Code: 'BEN'
                },
                {
                    Name: 'Bera',
                    Code: 'BER'
                },
                {
                    Name: 'Cameron Highlands',
                    Code: 'CAH'
                },
                {
                    Name: 'Jerantut',
                    Code: 'JER'
                },
                {
                    Name: 'Kuala Lipis',
                    Code: 'KUL'
                },
                {
                    Name: 'Kuantan',
                    Code: 'KUA'
                },
                {
                    Name: 'Maran',
                    Code: 'MAR'
                },
                {
                    Name: 'Pekan',
                    Code: 'PEK'
                },
                {
                    Name: 'Raub',
                    Code: 'RAU'
                },
                {
                    Name: 'Rompin',
                    Code: 'TOM'
                },
                {
                    Name: 'Temerloh',
                    Code: 'TEM'
                }
                ]
            },
            {
                Name: 'Perak',
                Code: 'PK',
                City: [{
                    Name: 'Batu Gajah',
                    Code: 'BGA'
                },
                {
                    Name: 'Ipoh',
                    Code: 'IPH'
                },
                {
                    Name: 'Kuala Kangsar',
                    Code: 'KAR'
                },
                {
                    Name: 'Lumut',
                    Code: 'LUM'
                },
                {
                    Name: 'Sungai Siput',
                    Code: 'SSP'
                },
                {
                    Name: 'Taiping',
                    Code: 'TPG'
                },
                {
                    Name: 'Tanjung Malim',
                    Code: 'TAM'
                },
                {
                    Name: 'Teluk Intan',
                    Code: 'TAS'
                }
                ]
            },
            {
                Name: 'Perlis',
                Code: 'PS',
                City: {
                    Name: 'Kangar',
                    Code: 'KGR'
                }
            },
            {
                Name: 'Pulau Pinang',
                Code: 'PH',
                City: [{
                    Name: 'Bukit Mertajam',
                    Code: 'BMJ'
                },
                {
                    Name: 'Butterworth',
                    Code: 'BWH'
                },
                {
                    Name: 'George Town',
                    Code: 'PEN'
                },
                {
                    Name: 'Nibong Tebal',
                    Code: 'NTE'
                }
                ]
            },
            {
                Name: 'Sabah',
                Code: 'SB',
                City: [{
                    Name: 'Beaufort',
                    Code: 'BEF'
                },
                {
                    Name: 'Beluran',
                    Code: 'BEL'
                },
                {
                    Name: 'Keningau',
                    Code: 'KEG'
                },
                {
                    Name: 'Kinabatangan',
                    Code: 'KBT'
                },
                {
                    Name: 'Kota Belud',
                    Code: 'KBD'
                },
                {
                    Name: 'Kota Kinabalu',
                    Code: 'BKI'
                },
                {
                    Name: 'Kota Marudu',
                    Code: 'KMU'
                },
                {
                    Name: 'Kuala Penyu',
                    Code: 'KPU'
                },
                {
                    Name: 'Kudat',
                    Code: 'KUD'
                },
                {
                    Name: 'Kunak',
                    Code: 'KUN'
                },
                {
                    Name: 'Lahad Datu',
                    Code: 'LDU'
                },
                {
                    Name: 'Nabawan',
                    Code: 'NAB'
                },
                {
                    Name: 'Papar',
                    Code: 'PAP'
                },
                {
                    Name: 'Penampang',
                    Code: 'PMP'
                },
                {
                    Name: 'Pitas',
                    Code: 'PIT'
                },
                {
                    Name: 'Ranau',
                    Code: 'RNU'
                },
                {
                    Name: 'Sandakan',
                    Code: 'SDK'
                },
                {
                    Name: 'Semporna',
                    Code: 'SMM'
                },
                {
                    Name: 'Sipitang',
                    Code: 'SPT'
                },
                {
                    Name: 'Tambunan',
                    Code: 'TAB'
                },
                {
                    Name: 'Tawau',
                    Code: 'TAW'
                },
                {
                    Name: 'Tenom',
                    Code: 'TEN'
                },
                {
                    Name: 'Tuaran',
                    Code: 'TUR'
                }
                ]
            },
            {
                Name: 'Sarawak',
                Code: 'SR',
                City: [{
                    Name: 'Betong',
                    Code: 'BTG'
                },
                {
                    Name: 'Bintulu',
                    Code: 'BTU'
                },
                {
                    Name: 'Kapit',
                    Code: 'KPI'
                },
                {
                    Name: 'Kuching',
                    Code: 'KCH'
                },
                {
                    Name: 'Limbang',
                    Code: 'LMN'
                },
                {
                    Name: 'Miri',
                    Code: 'MYY'
                },
                {
                    Name: 'Mukah',
                    Code: 'MKM'
                },
                {
                    Name: 'Samarahan',
                    Code: 'SMH'
                },
                {
                    Name: 'Sarikei',
                    Code: 'SAR'
                },
                {
                    Name: 'Sibu',
                    Code: 'SBW'
                },
                {
                    Name: 'Sri Aman',
                    Code: 'SAM'
                }
                ]
            },
            {
                Name: 'Selangor',
                Code: 'SL',
                City: [{
                    Name: 'Gombak',
                    Code: 'GOM'
                },
                {
                    Name: 'Hulu Langat',
                    Code: 'HUL'
                },
                {
                    Name: 'Hulu Selangor',
                    Code: 'HUS'
                },
                {
                    Name: 'Kuala Langat',
                    Code: 'KLG'
                },
                {
                    Name: 'Kuala Selangor',
                    Code: 'KSL'
                },
                {
                    Name: 'Petaling',
                    Code: 'PJA'
                },
                {
                    Name: 'Sabak Bernam',
                    Code: 'SBM'
                },
                {
                    Name: 'Sepang',
                    Code: 'SEP'
                }
                ]
            },
            {
                Name: 'Terengganu',
                Code: 'TR',
                City: [{
                    Name: 'Besut',
                    Code: 'BES'
                },
                {
                    Name: 'Dungun',
                    Code: 'DGN'
                },
                {
                    Name: 'Hulu',
                    Code: 'HUL'
                },
                {
                    Name: 'Kemaman',
                    Code: 'KEM'
                },
                {
                    Name: 'Kuala Terengganu',
                    Code: 'TGG'
                },
                {
                    Name: 'Marang',
                    Code: 'MAR'
                },
                {
                    Name: 'Setiu',
                    Code: 'SET'
                }
                ]
            }
            ]
        },
        {
            Name: 'Maldives',
            Code: 'MDV',
            State: {
                City: [{
                    Name: 'Addu Atoll',
                    Code: 'ADD'
                },
                {
                    Name: 'Faadhippolhu',
                    Code: 'FAA'
                },
                {
                    Name: 'Felidhu Atoll',
                    Code: 'FEA'
                },
                {
                    Name: 'Foammulah',
                    Code: 'FMU'
                },
                {
                    Name: 'Hadhdhunmathi',
                    Code: 'HDH'
                },
                {
                    Name: 'Kolhumadulu',
                    Code: 'KLH'
                },
                {
                    Name: 'Male',
                    Code: 'MAL'
                },
                {
                    Name: 'Male Atoll',
                    Code: 'MAA'
                },
                {
                    Name: 'Mulakatholhu',
                    Code: 'MUA'
                },
                {
                    Name: 'North Ari Atoll',
                    Code: 'AAD'
                },
                {
                    Name: 'North Huvadhu Atoll',
                    Code: 'HAD'
                },
                {
                    Name: 'North Maalhosmadhulu',
                    Code: 'MAD'
                },
                {
                    Name: 'North Miladhunmadhulu',
                    Code: 'MLD'
                },
                {
                    Name: 'North Nilandhe Atoll',
                    Code: 'NAD'
                },
                {
                    Name: 'North Thiladhunmathi',
                    Code: 'THD'
                },
                {
                    Name: 'South Ari Atoll',
                    Code: 'AAU'
                },
                {
                    Name: 'South Huvadhu Atoll',
                    Code: 'HAU'
                },
                {
                    Name: 'South Maalhosmadulu',
                    Code: 'MAU'
                },
                {
                    Name: 'South Miladhunmadhulu',
                    Code: 'MLU'
                },
                {
                    Name: 'South Nilandhe Atoll',
                    Code: 'NAU'
                },
                {
                    Name: 'South Thiladhunmathi',
                    Code: 'THU'
                }
                ]
            }
        },
        {
            Name: 'Mali',
            Code: 'MLI',
            State: {
                City: [{
                    Name: 'Bamako',
                    Code: 'CD'
                },
                {
                    Name: 'Gao',
                    Code: 'GA'
                },
                {
                    Name: 'Kayes',
                    Code: 'KY'
                },
                {
                    Name: 'Kidal',
                    Code: 'KD'
                },
                {
                    Name: 'Koulikoro',
                    Code: 'KL'
                },
                {
                    Name: 'Mopti',
                    Code: 'MP'
                },
                {
                    Name: 'Segou',
                    Code: 'SG'
                },
                {
                    Name: 'Sikasso',
                    Code: 'SK'
                },
                {
                    Name: 'Tombouctou',
                    Code: 'TB'
                }
                ]
            }
        },
        {
            Name: 'Malta',
            Code: 'MLT'
        },
        {
            Name: 'Marshall Islands',
            Code: 'MHL'
        },
        {
            Name: 'Martinique',
            Code: 'MTQ'
        },
        {
            Name: 'Mauritania',
            Code: 'MRT',
            State: {
                City: [{
                    Name: 'Adrar',
                    Code: 'AD'
                },
                {
                    Name: 'Brakna',
                    Code: 'BR'
                },
                {
                    Name: 'Dakhlet Nouadhibou',
                    Code: 'DN'
                },
                {
                    Name: 'El-Acaba',
                    Code: 'AS'
                },
                {
                    Name: 'Gorgol',
                    Code: 'GO'
                },
                {
                    Name: 'Guidimaka',
                    Code: 'GM'
                },
                {
                    Name: 'Hodh ech-Chargui',
                    Code: 'HC'
                },
                {
                    Name: 'Hodh el-Gharbi',
                    Code: 'HG'
                },
                {
                    Name: 'Inchiri',
                    Code: 'IN'
                },
                {
                    Name: 'Nouakchott',
                    Code: 'NO'
                },
                {
                    Name: 'Tagant',
                    Code: 'TA'
                },
                {
                    Name: 'Tiris Zemmour',
                    Code: 'TZ'
                },
                {
                    Name: 'Trarza',
                    Code: 'TR'
                }
                ]
            }
        },
        {
            Name: 'Mauritius',
            Code: 'MUS'
        },
        {
            Name: 'Mayotte',
            Code: 'MYT'
        },
        {
            Name: 'Mexico',
            Code: 'MEX',
            State: {
                City: [{
                    Name: 'Acapulco',
                    Code: 'ACA'
                },
                {
                    Name: 'Aguascalientes',
                    Code: 'AGU'
                },
                {
                    Name: 'Benito Juare',
                    Code: 'BJU'
                },
                {
                    Name: 'Campeche',
                    Code: 'CAM'
                },
                {
                    Name: 'Carmen',
                    Code: 'CAR'
                },
                {
                    Name: 'Celaya',
                    Code: 'CLY'
                },
                {
                    Name: 'Cheturnal',
                    Code: 'CTM'
                },
                {
                    Name: 'Chihuahua',
                    Code: 'CHH'
                },
                {
                    Name: 'Chilpancingo',
                    Code: 'CHI'
                },
                {
                    Name: 'Coatzacoalcos',
                    Code: 'COA'
                },
                {
                    Name: 'Colima',
                    Code: 'COL'
                },
                {
                    Name: 'Cuernavaca',
                    Code: 'CVC'
                },
                {
                    Name: 'Culiacan',
                    Code: 'CUL'
                },
                {
                    Name: 'Durango',
                    Code: 'DUR'
                },
                {
                    Name: 'Ensenada',
                    Code: 'ESE'
                },
                {
                    Name: 'Guadalajara',
                    Code: 'GDL'
                },
                {
                    Name: 'Guanajuato',
                    Code: 'GUA'
                },
                {
                    Name: 'Hermosillo',
                    Code: 'HMO'
                },
                {
                    Name: 'Irapuato',
                    Code: 'IRP'
                },
                {
                    Name: 'Jalapa',
                    Code: 'JAL'
                },
                {
                    Name: 'Juarez',
                    Code: 'JUZ'
                },
                {
                    Name: 'La Paz',
                    Code: 'LAP'
                },
                {
                    Name: 'Leon',
                    Code: 'LEN'
                },
                {
                    Name: 'Los Mochis',
                    Code: 'LMM'
                },
                {
                    Name: 'Matamoros',
                    Code: 'MAM'
                },
                {
                    Name: 'Mazatlan',
                    Code: 'MZT'
                },
                {
                    Name: 'Merida',
                    Code: 'MID'
                },
                {
                    Name: 'Mexicali',
                    Code: 'MXL'
                },
                {
                    Name: 'Mexico City',
                    Code: 'MEX'
                },
                {
                    Name: 'Monclova',
                    Code: 'LOV'
                },
                {
                    Name: 'Monterrey',
                    Code: 'MTY'
                },
                {
                    Name: 'Morelia',
                    Code: 'MLM'
                },
                {
                    Name: 'Nogales',
                    Code: 'NOG'
                },
                {
                    Name: 'Nuevo Laredo',
                    Code: 'NLE'
                },
                {
                    Name: 'Oaxaca',
                    Code: 'OAX'
                },
                {
                    Name: 'Obregon',
                    Code: 'OBR'
                },
                {
                    Name: 'Orizaba',
                    Code: 'ORI'
                },
                {
                    Name: 'Pachuca',
                    Code: 'PAC'
                },
                {
                    Name: 'Poza Rica de Hidalgo',
                    Code: 'PRH'
                },
                {
                    Name: 'Puebla',
                    Code: 'PUE'
                },
                {
                    Name: 'Puerto Vallarta',
                    Code: 'PVR'
                },
                {
                    Name: 'Queretaro',
                    Code: 'QUE'
                },
                {
                    Name: 'Reynosa',
                    Code: 'REX'
                },
                {
                    Name: 'Saltillo',
                    Code: 'SLW'
                },
                {
                    Name: 'San Luis Potosi',
                    Code: 'SLP'
                },
                {
                    Name: 'Tampico',
                    Code: 'TAM'
                },
                {
                    Name: 'Tapachula',
                    Code: 'TAP'
                },
                {
                    Name: 'Tehuacan',
                    Code: 'TCN'
                },
                {
                    Name: 'Tepic',
                    Code: 'TPQ'
                },
                {
                    Name: 'Tijuana',
                    Code: 'TIJ'
                },
                {
                    Name: 'Tlaxcala',
                    Code: 'TLA'
                },
                {
                    Name: 'Toluca',
                    Code: 'TLC'
                },
                {
                    Name: 'Torreon',
                    Code: 'TRC'
                },
                {
                    Name: 'Tuxtla Gutierrez',
                    Code: 'TGZ'
                },
                {
                    Name: 'Uruapan',
                    Code: 'UPN'
                },
                {
                    Name: 'Valles',
                    Code: 'VHM'
                },
                {
                    Name: 'Veracruz',
                    Code: 'VER'
                },
                {
                    Name: 'Victoria',
                    Code: 'VIC'
                },
                {
                    Name: 'Villahermosa',
                    Code: 'VSA'
                },
                {
                    Name: 'Zacatecas',
                    Code: 'ZAC'
                }
                ]
            }
        },
        {
            Name: 'Micronesia',
            Code: 'FSM'
        },
        {
            Name: 'Moldova',
            Code: 'MDA'
        },
        {
            Name: 'Monaco',
            Code: 'MCO'
        },
        {
            Name: 'Mongolia',
            Code: 'MNG',
            State: {
                City: [{
                    Name: 'Arhangay',
                    Code: '73'
                },
                {
                    Name: 'Bayanhongor',
                    Code: '69'
                },
                {
                    Name: 'Bayan-Ulgiy',
                    Code: '71'
                },
                {
                    Name: 'Bulgan',
                    Code: '67'
                },
                {
                    Name: 'Darhan-Uul',
                    Code: '37'
                },
                {
                    Name: 'Dornod',
                    Code: '61'
                },
                {
                    Name: 'Dornogovi',
                    Code: '63'
                },
                {
                    Name: 'Dundgovi',
                    Code: '59'
                },
                {
                    Name: 'Dzavhan',
                    Code: '57'
                },
                {
                    Name: 'Govi-Altay',
                    Code: '65'
                },
                {
                    Name: 'Govisumber',
                    Code: '64'
                },
                {
                    Name: 'Hentiy',
                    Code: '39'
                },
                {
                    Name: 'Hovd',
                    Code: '43'
                },
                {
                    Name: 'Hovsgol',
                    Code: '41'
                },
                {
                    Name: 'Orhon',
                    Code: '35'
                },
                {
                    Name: 'Selenge',
                    Code: '49'
                },
                {
                    Name: 'Suhbaatar',
                    Code: '51'
                },
                {
                    Name: 'Tov',
                    Code: '47'
                },
                {
                    Name: 'Ulaanbaatar hot',
                    Code: '1'
                },
                {
                    Name: 'Umnogovi',
                    Code: 'UMN'
                },
                {
                    Name: 'Uvorhangay',
                    Code: 'UVO'
                },
                {
                    Name: 'Uvs',
                    Code: '46'
                }
                ]
            }
        },
        {
            Name: 'Montserrat',
            Code: 'MSR'
        },
        {
            Name: 'Morocco',
            Code: 'MAR',
            State: {
                City: [{
                    Name: 'Casablanca',
                    Code: 'CBL'
                },
                {
                    Name: 'Fes',
                    Code: 'FES'
                },
                {
                    Name: 'Marrakech',
                    Code: 'MRK'
                },
                {
                    Name: 'Meknes',
                    Code: 'MKN'
                },
                {
                    Name: 'Oujda',
                    Code: 'OUJ'
                },
                {
                    Name: 'Rabat',
                    Code: 'RSA'
                },
                {
                    Name: 'Tangier',
                    Code: 'TGR'
                },
                {
                    Name: 'Tetouan',
                    Code: 'TET'
                },
                {
                    Name: 'Western Sahara',
                    Code: 'WSH'
                }
                ]
            }
        },
        {
            Name: 'Mozambique',
            Code: 'MOZ'
        },
        {
            Name: 'Myanmar',
            Code: 'MMR',
            State: {
                City: [{
                    Name: 'Ayeyarwady',
                    Code: 'AY'
                },
                {
                    Name: 'Bago',
                    Code: 'BG'
                },
                {
                    Name: 'Chin',
                    Code: 'CH'
                },
                {
                    Name: 'Kachin',
                    Code: 'KC'
                },
                {
                    Name: 'Kayah',
                    Code: 'KH'
                },
                {
                    Name: 'Kayin',
                    Code: 'KN'
                },
                {
                    Name: 'Magway',
                    Code: 'MG'
                },
                {
                    Name: 'Mandalay',
                    Code: 'MD'
                },
                {
                    Name: 'Mon',
                    Code: 'MN'
                },
                {
                    Name: 'Rakhine',
                    Code: 'RK'
                },
                {
                    Name: 'Sagaing',
                    Code: 'SG'
                },
                {
                    Name: 'Shan',
                    Code: 'SH'
                },
                {
                    Name: 'Tanintharyi',
                    Code: 'TN'
                },
                {
                    Name: 'Yangon',
                    Code: 'YG'
                }
                ]
            }
        },
        {
            Name: 'Namibia',
            Code: 'NAM',
            State: {
                City: [{
                    Name: 'Caprivi',
                    Code: 'CA'
                },
                {
                    Name: 'Erongo',
                    Code: 'ER'
                },
                {
                    Name: 'Hardap',
                    Code: 'HA'
                },
                {
                    Name: 'Karas',
                    Code: 'KR'
                },
                {
                    Name: 'Khomas',
                    Code: 'KH'
                },
                {
                    Name: 'Kunene',
                    Code: 'KU'
                },
                {
                    Name: 'Ohangwena',
                    Code: 'OW'
                },
                {
                    Name: 'Okavango',
                    Code: 'KV'
                },
                {
                    Name: 'Omaheke',
                    Code: 'OK'
                },
                {
                    Name: 'Omusati',
                    Code: 'OT'
                },
                {
                    Name: 'Oshana',
                    Code: 'ON'
                },
                {
                    Name: 'Oshikoto',
                    Code: 'OO'
                },
                {
                    Name: 'Otjozondjupa',
                    Code: 'OJ'
                }
                ]
            }
        },
        {
            Name: 'Nauru',
            Code: 'NRU'
        },
        {
            Name: 'Nepal',
            Code: 'NPL',
            State: {
                City: [{
                    Name: 'Bagmati',
                    Code: 'BA'
                },
                {
                    Name: 'Bheri',
                    Code: 'BH'
                },
                {
                    Name: 'Dhawalagiri',
                    Code: 'DH'
                },
                {
                    Name: 'Gandaki',
                    Code: 'GA'
                },
                {
                    Name: 'Janakpur',
                    Code: 'JA'
                },
                {
                    Name: 'Karnali',
                    Code: 'KA'
                },
                {
                    Name: 'Kosi',
                    Code: 'KO'
                },
                {
                    Name: 'Lumbini',
                    Code: 'LU'
                },
                {
                    Name: 'Mahakali',
                    Code: 'MA'
                },
                {
                    Name: 'Mechi',
                    Code: 'ME'
                },
                {
                    Name: 'Narayani',
                    Code: 'NA'
                },
                {
                    Name: 'Rapti',
                    Code: 'RA'
                },
                {
                    Name: 'Seti',
                    Code: 'SE'
                },
                {
                    Name: 'Sogarmatha',
                    Code: 'SA'
                }
                ]
            }
        },
        {
            Name: 'Netherlands',
            Code: 'NLD',
            State: {
                City: [{
                    Name: "'s-Hertogenbosch",
                    Code: 'HTB'
                },
                {
                    Name: 'Almere',
                    Code: 'AER'
                },
                {
                    Name: 'Amersfoort',
                    Code: 'AME'
                },
                {
                    Name: 'Amsterdam',
                    Code: 'AMS'
                },
                {
                    Name: 'Apeldoorn',
                    Code: 'APE'
                },
                {
                    Name: 'Arnhem',
                    Code: 'ARN'
                },
                {
                    Name: 'Assen',
                    Code: 'ASS'
                },
                {
                    Name: 'Breda',
                    Code: 'BRD'
                },
                {
                    Name: 'Dordrecht',
                    Code: 'DOR'
                },
                {
                    Name: 'Ede',
                    Code: 'EDE'
                },
                {
                    Name: 'Eindhoven',
                    Code: 'EIN'
                },
                {
                    Name: 'Emmen',
                    Code: 'EMM'
                },
                {
                    Name: 'Enschede',
                    Code: 'ENS'
                },
                {
                    Name: 'Groningen',
                    Code: 'GRQ'
                },
                {
                    Name: 'Haarlem',
                    Code: 'HRA'
                },
                {
                    Name: 'Hague',
                    Code: 'HAG'
                },
                {
                    Name: 'Hoofddorp',
                    Code: 'HFD'
                },
                {
                    Name: 'Leeuwarden',
                    Code: 'LWR'
                },
                {
                    Name: 'Leiden',
                    Code: 'LID'
                },
                {
                    Name: 'Lelystad',
                    Code: 'LEY'
                },
                {
                    Name: 'Maastricht',
                    Code: 'MST'
                },
                {
                    Name: 'Middelburg',
                    Code: 'MDL'
                },
                {
                    Name: 'Nijmegen',
                    Code: 'NIJ'
                },
                {
                    Name: 'Rotterdam',
                    Code: 'RTM'
                },
                {
                    Name: 'Tilburg',
                    Code: 'TLB'
                },
                {
                    Name: 'Utrecht',
                    Code: 'UTC'
                },
                {
                    Name: 'Zoetermeer',
                    Code: 'ZTM'
                },
                {
                    Name: 'Zwolle',
                    Code: 'ZWO'
                }
                ]
            }
        },
        {
            Name: 'Netherlands Antilles',
            Code: 'ANT'
        },
        {
            Name: 'New Caledonia',
            Code: 'NCL'
        },
        {
            Name: 'New Zealand',
            Code: 'NZL',
            State: {
                City: [{
                    Name: 'Auckland',
                    Code: 'AUK'
                },
                {
                    Name: 'Blenheim',
                    Code: 'BHE'
                },
                {
                    Name: 'Christchurch',
                    Code: 'CHC'
                },
                {
                    Name: 'Dunedin',
                    Code: 'DUD'
                },
                {
                    Name: 'Far North',
                    Code: 'FNR'
                },
                {
                    Name: 'Gisborne',
                    Code: 'GIS'
                },
                {
                    Name: 'Greymouth',
                    Code: 'GMN'
                },
                {
                    Name: 'Hamilton',
                    Code: 'HLZ'
                },
                {
                    Name: 'Hastings',
                    Code: 'HAS'
                },
                {
                    Name: 'Invercargill',
                    Code: 'IVC'
                },
                {
                    Name: 'Kaipara',
                    Code: 'KAI'
                },
                {
                    Name: 'Manukau',
                    Code: 'MNK'
                },
                {
                    Name: 'Napier',
                    Code: 'NPE'
                },
                {
                    Name: 'Nelson',
                    Code: 'NSN'
                },
                {
                    Name: 'New Plymouth',
                    Code: 'NPL'
                },
                {
                    Name: 'North Shore',
                    Code: 'NSH'
                },
                {
                    Name: 'Palmerston North',
                    Code: 'PMR'
                },
                {
                    Name: 'Richmond',
                    Code: 'RMD'
                },
                {
                    Name: 'Stratford',
                    Code: 'STR'
                },
                {
                    Name: 'Taumarunui',
                    Code: 'TAU'
                },
                {
                    Name: 'Waitakere',
                    Code: 'WAE'
                },
                {
                    Name: 'Wanganui',
                    Code: 'WAG'
                },
                {
                    Name: 'Whakatane',
                    Code: 'WHK'
                },
                {
                    Name: 'Whangarei',
                    Code: 'WRE'
                }
                ]
            }
        },
        {
            Name: 'Nicaragua',
            Code: 'NIC',
            State: {
                City: [{
                    Name: 'Atlantico Norte',
                    Code: 'AN'
                },
                {
                    Name: 'Atlantico Sur',
                    Code: 'AS'
                },
                {
                    Name: 'Boaco',
                    Code: 'BO'
                },
                {
                    Name: 'Carazo',
                    Code: 'CA'
                },
                {
                    Name: 'Chinandega',
                    Code: 'CD'
                },
                {
                    Name: 'Chontales',
                    Code: 'CT'
                },
                {
                    Name: 'Esteli',
                    Code: 'ES'
                },
                {
                    Name: 'Granada',
                    Code: 'GR'
                },
                {
                    Name: 'Jinotega',
                    Code: 'JI'
                },
                {
                    Name: 'Leon',
                    Code: 'LE'
                },
                {
                    Name: 'Madriz',
                    Code: 'MD'
                },
                {
                    Name: 'Managua',
                    Code: 'MN'
                },
                {
                    Name: 'Masaya',
                    Code: 'MS'
                },
                {
                    Name: 'Matagalpa',
                    Code: 'MT'
                },
                {
                    Name: 'Nueva Segovia',
                    Code: 'NS'
                },
                {
                    Name: 'Rio San Juan',
                    Code: 'SJ'
                },
                {
                    Name: 'Rivas',
                    Code: 'RV'
                }
                ]
            }
        },
        {
            Name: 'Niger',
            Code: 'NER',
            State: {
                City: [{
                    Name: 'Agadez',
                    Code: 'AJY'
                },
                {
                    Name: 'Diffa',
                    Code: 'DIF'
                },
                {
                    Name: 'Dosso',
                    Code: 'DSS'
                },
                {
                    Name: 'Maradi',
                    Code: 'MFQ'
                },
                {
                    Name: 'Niamey C.U.',
                    Code: 'NIM'
                },
                {
                    Name: 'Tahoua',
                    Code: 'THZ'
                },
                {
                    Name: 'Tillaberi',
                    Code: 'TIL'
                },
                {
                    Name: 'Zinder',
                    Code: 'ZND'
                }
                ]
            }
        },
        {
            Name: 'Nigeria',
            Code: 'NGA',
            State: {
                City: [{
                    Name: 'Abuja',
                    Code: 'ABV'
                },
                {
                    Name: 'Ibadan',
                    Code: 'IBA'
                },
                {
                    Name: 'Kano',
                    Code: 'KAN'
                },
                {
                    Name: 'Lagos',
                    Code: 'LOS'
                },
                {
                    Name: 'Ogbomosho',
                    Code: 'OGB'
                }
                ]
            }
        },
        {
            Name: 'Niue',
            Code: 'NIU'
        },
        {
            Name: 'Norfolk Island',
            Code: 'NFK'
        },
        {
            Name: 'North Korea',
            Code: 'PRK',
            State: {
                City: [{
                    Name: "Ch'ongjin",
                    Code: 'CHO'
                },
                {
                    Name: 'Haeju',
                    Code: 'HAE'
                },
                {
                    Name: 'Hamhung',
                    Code: 'HAM'
                },
                {
                    Name: 'Hyesan',
                    Code: 'HYE'
                },
                {
                    Name: 'Kaesong',
                    Code: 'KSN'
                },
                {
                    Name: 'Kanggye',
                    Code: 'KAN'
                },
                {
                    Name: "Namp'o",
                    Code: 'NAM'
                },
                {
                    Name: 'Naseon',
                    Code: 'NAS'
                },
                {
                    Name: 'Pyongyang',
                    Code: 'FNJ'
                },
                {
                    Name: 'Sariwon',
                    Code: 'SAR'
                },
                {
                    Name: 'Sinuiju',
                    Code: 'SII'
                },
                {
                    Name: 'Wonsan',
                    Code: 'WON'
                }
                ]
            }
        },
        {
            Name: 'Northern Mariana Islands',
            Code: 'MNP'
        },
        {
            Name: 'Norway',
            Code: 'NOR',
            State: {
                City: [{
                    Name: 'Akershus',
                    Code: '2'
                },
                {
                    Name: 'Aust-Agder',
                    Code: '9'
                },
                {
                    Name: 'Buskerud',
                    Code: '6'
                },
                {
                    Name: 'Finnmark',
                    Code: '20'
                },
                {
                    Name: 'Hedmark',
                    Code: '4'
                },
                {
                    Name: 'Hordaland',
                    Code: '12'
                },
                {
                    Name: 'More og Romsdal',
                    Code: '15'
                },
                {
                    Name: 'Nordland',
                    Code: '18'
                },
                {
                    Name: 'Nord-Trondelag',
                    Code: '17'
                },
                {
                    Name: 'Oppland',
                    Code: '5'
                },
                {
                    Name: 'Oslo',
                    Code: '3'
                },
                {
                    Name: 'Ostfold',
                    Code: '1'
                },
                {
                    Name: 'Rogaland',
                    Code: '11'
                },
                {
                    Name: 'Sogn og Fjordane',
                    Code: '14'
                },
                {
                    Name: 'Sor-Trondelag',
                    Code: '16'
                },
                {
                    Name: 'Telemark',
                    Code: '8'
                },
                {
                    Name: 'Troms',
                    Code: '19'
                },
                {
                    Name: 'Vest-Agder',
                    Code: '10'
                },
                {
                    Name: 'Vestfold',
                    Code: '7'
                }
                ]
            }
        },
        {
            Name: 'Oman',
            Code: 'OMN',
            State: {
                City: [{
                    Name: 'Ad-Dakhiliyah',
                    Code: 'DA'
                },
                {
                    Name: 'Al-Batinah',
                    Code: 'BA'
                },
                {
                    Name: 'Al-Wusta',
                    Code: 'WU'
                },
                {
                    Name: 'Ash-Sharqiyah',
                    Code: 'SH'
                },
                {
                    Name: 'Az-Zahirah',
                    Code: 'ZA'
                },
                {
                    Name: 'Masqat',
                    Code: 'MA'
                },
                {
                    Name: 'Musandam',
                    Code: 'MU'
                },
                {
                    Name: 'Zufar',
                    Code: 'ZU'
                }
                ]
            }
        },
        {
            Name: 'Pakistan',
            Code: 'PAK',
            State: {
                City: [{
                    Name: 'Faisalabad',
                    Code: 'LYP'
                },
                {
                    Name: 'Gujranwala',
                    Code: 'GUJ'
                },
                {
                    Name: 'Hyderabad',
                    Code: 'HDD'
                },
                {
                    Name: 'Islamabad',
                    Code: 'ISB'
                },
                {
                    Name: 'Karachi',
                    Code: 'KCT'
                },
                {
                    Name: 'Lahore',
                    Code: 'LHE'
                },
                {
                    Name: 'Multan',
                    Code: 'MUX'
                },
                {
                    Name: 'Peshawar',
                    Code: 'PEW'
                },
                {
                    Name: 'Rawalpindi',
                    Code: 'RWP'
                }
                ]
            }
        },
        {
            Name: 'Palau',
            Code: 'PLW'
        },
        {
            Name: 'Palestinian Authority',
            Code: 'PSE',
            State: {
                City: [{
                    Name: 'Gaza Strip',
                    Code: 'GZ'
                },
                {
                    Name: 'West Bank',
                    Code: 'WE'
                }
                ]
            }
        },
        {
            Name: 'Panama',
            Code: 'PAN'
        },
        {
            Name: 'Papua New Guinea',
            Code: 'PNG',
            State: {
                City: [{
                    Name: 'Bougainville',
                    Code: 'BV'
                },
                {
                    Name: 'East New Britain',
                    Code: 'EB'
                },
                {
                    Name: 'East Sepik',
                    Code: 'ES'
                },
                {
                    Name: 'Eastern Highlands',
                    Code: 'EH'
                },
                {
                    Name: 'Enga',
                    Code: 'EN'
                },
                {
                    Name: 'Gulf',
                    Code: 'GU'
                },
                {
                    Name: 'Madang',
                    Code: 'MD'
                },
                {
                    Name: 'Manus',
                    Code: 'MN'
                },
                {
                    Name: 'Milne Bay',
                    Code: 'MB'
                },
                {
                    Name: 'Morobe',
                    Code: 'MR'
                },
                {
                    Name: 'New Ireland',
                    Code: 'NI'
                },
                {
                    Name: 'Northern',
                    Code: 'NO'
                },
                {
                    Name: 'Port Moresby',
                    Code: 'NC'
                },
                {
                    Name: 'Sandaun',
                    Code: 'SA'
                },
                {
                    Name: 'Simbu',
                    Code: 'SI'
                },
                {
                    Name: 'Southern Highlands',
                    Code: 'SH'
                },
                {
                    Name: 'West New Britain',
                    Code: 'WB'
                },
                {
                    Name: 'Western',
                    Code: 'WE'
                },
                {
                    Name: 'Western Highlands',
                    Code: 'WH'
                }
                ]
            }
        },
        {
            Name: 'Paraguay',
            Code: 'PRY',
            State: {
                City: [{
                    Name: 'Alto Paraguay',
                    Code: 'AG'
                },
                {
                    Name: 'Alto Parana',
                    Code: 'AN'
                },
                {
                    Name: 'Amambay',
                    Code: 'AM'
                },
                {
                    Name: 'Asuncion',
                    Code: 'AS'
                },
                {
                    Name: 'Boqueron',
                    Code: 'BO'
                },
                {
                    Name: 'Caaguazu',
                    Code: 'CG'
                },
                {
                    Name: 'Caazapa',
                    Code: 'CZ'
                },
                {
                    Name: 'Canindeyu',
                    Code: 'CN'
                },
                {
                    Name: 'Central',
                    Code: 'CE'
                },
                {
                    Name: 'Concepcion',
                    Code: 'CC'
                },
                {
                    Name: 'Cordillera',
                    Code: 'CD'
                },
                {
                    Name: 'Guaira',
                    Code: 'GU'
                },
                {
                    Name: 'Itapua',
                    Code: 'IT'
                },
                {
                    Name: 'Misiones',
                    Code: 'MI'
                },
                {
                    Name: 'Neembucu',
                    Code: 'NE'
                },
                {
                    Name: 'Paraguari',
                    Code: 'PA'
                },
                {
                    Name: 'Presidente Hayes',
                    Code: 'PH'
                },
                {
                    Name: 'San Pedro',
                    Code: 'SP'
                }
                ]
            }
        },
        {
            Name: 'Peru',
            Code: 'PER',
            State: {
                City: [{
                    Name: 'Amazonas',
                    Code: 'AM'
                },
                {
                    Name: 'Ancash',
                    Code: 'AN'
                },
                {
                    Name: 'Apurimac',
                    Code: 'AP'
                },
                {
                    Name: 'Arequipa',
                    Code: 'AR'
                },
                {
                    Name: 'Ayacucho',
                    Code: 'AY'
                },
                {
                    Name: 'Cajamarca',
                    Code: 'CJ'
                },
                {
                    Name: 'Callao',
                    Code: 'CL'
                },
                {
                    Name: 'Chimbote',
                    Code: 'CHM'
                },
                {
                    Name: 'Chincha Alta',
                    Code: 'CHI'
                },
                {
                    Name: 'Cusco',
                    Code: 'CU'
                },
                {
                    Name: 'Huancavelica',
                    Code: 'HV'
                },
                {
                    Name: 'Huanuco',
                    Code: 'HO'
                },
                {
                    Name: 'Ica',
                    Code: 'IC'
                },
                {
                    Name: 'Juliaca',
                    Code: 'JUL'
                },
                {
                    Name: 'Junin',
                    Code: 'JU'
                },
                {
                    Name: 'La Libertad',
                    Code: 'LD'
                },
                {
                    Name: 'Lambayeque',
                    Code: 'LY'
                },
                {
                    Name: 'Lima',
                    Code: 'LI'
                },
                {
                    Name: 'Loreto',
                    Code: 'LO'
                },
                {
                    Name: 'Madre de Dios',
                    Code: 'MD'
                },
                {
                    Name: 'Moquegua',
                    Code: 'MO'
                },
                {
                    Name: 'Pasco',
                    Code: 'PA'
                },
                {
                    Name: 'Piura',
                    Code: 'PI'
                },
                {
                    Name: 'Puno',
                    Code: 'PU'
                },
                {
                    Name: 'San Martin',
                    Code: 'SM'
                },
                {
                    Name: 'Sullana',
                    Code: 'SUL'
                },
                {
                    Name: 'Tacna',
                    Code: 'TA'
                },
                {
                    Name: 'Tumbes',
                    Code: 'TU'
                },
                {
                    Name: 'Ucayali',
                    Code: 'UC'
                }
                ]
            }
        },
        {
            Name: 'Philippines',
            Code: 'PHL',
            State: {
                City: [{
                    Name: 'Caloocan',
                    Code: 'CAO'
                },
                {
                    Name: 'Cebu',
                    Code: 'CEB'
                },
                {
                    Name: 'Davao',
                    Code: 'DOR'
                },
                {
                    Name: 'Manila',
                    Code: 'MNL'
                }
                ]
            }
        },
        {
            Name: 'Pitcairn Islands',
            Code: 'PCN'
        },
        {
            Name: 'Poland',
            Code: 'POL',
            State: {
                City: [{
                    Name: 'Biała Podlaska',
                    Code: 'BAP'
                },
                {
                    Name: 'Białystok',
                    Code: 'BIA'
                },
                {
                    Name: 'Bydgoszcz',
                    Code: 'BZG'
                },
                {
                    Name: 'Bytom',
                    Code: 'BYT'
                },
                {
                    Name: 'Chełm',
                    Code: 'CHO'
                },
                {
                    Name: 'Chorzow',
                    Code: 'CHZ'
                },
                {
                    Name: 'Ciechanow',
                    Code: 'CIE'
                },
                {
                    Name: 'Dabrowa Gornicza',
                    Code: 'DAB'
                },
                {
                    Name: 'Elbląg',
                    Code: 'ELB'
                },
                {
                    Name: 'Gdansk',
                    Code: 'GDN'
                },
                {
                    Name: 'Gdynia',
                    Code: 'GDY'
                },
                {
                    Name: 'Gliwice',
                    Code: 'GWC'
                },
                {
                    Name: 'Gorzow Wlkp',
                    Code: 'GOW'
                },
                {
                    Name: 'Grudziadz',
                    Code: 'GRU'
                },
                {
                    Name: 'Jaworzno',
                    Code: 'JAW'
                },
                {
                    Name: 'Jelenia Gora',
                    Code: 'JEG'
                },
                {
                    Name: 'Kalisz',
                    Code: 'KAL'
                },
                {
                    Name: 'Katowice',
                    Code: 'KTW'
                },
                {
                    Name: 'Kielce',
                    Code: 'KLC'
                },
                {
                    Name: 'Konin',
                    Code: 'KON'
                },
                {
                    Name: 'Koszalin',
                    Code: 'OSZ'
                },
                {
                    Name: 'Krakow',
                    Code: 'KRK'
                },
                {
                    Name: 'Krosno',
                    Code: 'KRO'
                },
                {
                    Name: 'Legnica',
                    Code: 'LEG'
                },
                {
                    Name: 'Leszno',
                    Code: 'LEZ'
                },
                {
                    Name: 'Lodz',
                    Code: 'LOD'
                },
                {
                    Name: 'Lomza',
                    Code: 'QOY'
                },
                {
                    Name: 'Lublin',
                    Code: 'LUL'
                },
                {
                    Name: 'Mysłowice',
                    Code: 'MYL'
                },
                {
                    Name: 'Nowy Sacz',
                    Code: 'NOW'
                },
                {
                    Name: 'Olsztyn',
                    Code: 'OLS'
                },
                {
                    Name: 'Opole',
                    Code: 'OPO'
                },
                {
                    Name: 'Ostrołeka',
                    Code: 'OSS'
                },
                {
                    Name: 'Piła',
                    Code: 'PIL'
                },
                {
                    Name: 'Piotrkow',
                    Code: 'PIO'
                },
                {
                    Name: 'Plock',
                    Code: 'PLO'
                },
                {
                    Name: 'Poznan',
                    Code: 'POZ'
                },
                {
                    Name: 'Przemysl',
                    Code: 'PRZ'
                },
                {
                    Name: 'Radom',
                    Code: 'RDM'
                },
                {
                    Name: 'Ruda Sl',
                    Code: 'RDS'
                },
                {
                    Name: 'Rzeszow',
                    Code: 'RZE'
                },
                {
                    Name: 'Siedlce',
                    Code: 'SDC'
                },
                {
                    Name: 'Siemianowice Sl',
                    Code: 'SOW'
                },
                {
                    Name: 'Sieradz',
                    Code: 'SIR'
                },
                {
                    Name: 'Skierniewice',
                    Code: 'SKI'
                },
                {
                    Name: 'Slupsk',
                    Code: 'SLP'
                },
                {
                    Name: 'Sopot',
                    Code: 'SOP'
                },
                {
                    Name: 'Sosnowiec',
                    Code: 'SWC'
                },
                {
                    Name: 'Suwałki',
                    Code: 'SWL'
                },
                {
                    Name: 'Swietochłowice',
                    Code: 'SWT'
                },
                {
                    Name: 'Swinoujscie',
                    Code: 'SWI'
                },
                {
                    Name: 'Szczecin',
                    Code: 'SZZ'
                },
                {
                    Name: 'Tarnobrzeg',
                    Code: 'QEP'
                },
                {
                    Name: 'Tarnow',
                    Code: 'TAR'
                },
                {
                    Name: 'Torun',
                    Code: 'TOR'
                },
                {
                    Name: 'Tychy',
                    Code: 'TYY'
                },
                {
                    Name: 'Walbrzych',
                    Code: 'WZH'
                },
                {
                    Name: 'Warszawa',
                    Code: 'WAW'
                },
                {
                    Name: 'Wlocławek',
                    Code: 'WLO'
                },
                {
                    Name: 'Wroclaw',
                    Code: 'WRO'
                },
                {
                    Name: 'Zabrze',
                    Code: 'ZAB'
                },
                {
                    Name: 'Zamosc',
                    Code: 'ZAM'
                },
                {
                    Name: 'Zielona Gora',
                    Code: 'IEG'
                }
                ]
            }
        },
        {
            Name: 'Portugal',
            Code: 'PRT',
            State: {
                City: [{
                    Name: 'Alentejo Central',
                    Code: 'ALC'
                },
                {
                    Name: 'Alentejo Litoral',
                    Code: 'ALL'
                },
                {
                    Name: 'Alto Alentejo',
                    Code: 'AAT'
                },
                {
                    Name: 'Alto Tros-os-Montes',
                    Code: 'ATM'
                },
                {
                    Name: 'Ave',
                    Code: 'AES'
                },
                {
                    Name: 'Baixo Alentejo',
                    Code: 'BAL'
                },
                {
                    Name: 'Baixo Mondego',
                    Code: 'BMO'
                },
                {
                    Name: 'Baixo Vouga',
                    Code: 'BVO'
                },
                {
                    Name: 'Beira Interior Norte',
                    Code: 'BIN'
                },
                {
                    Name: 'Beira Interior Sul',
                    Code: 'BIS'
                },
                {
                    Name: 'Cavado',
                    Code: 'CAV'
                },
                {
                    Name: 'Cova da Beira',
                    Code: 'CLB'
                },
                {
                    Name: 'Douro',
                    Code: 'MDR'
                },
                {
                    Name: 'Entre Douro e Vouga',
                    Code: 'EDV'
                },
                {
                    Name: 'Faro',
                    Code: 'FAO'
                },
                {
                    Name: 'Funchal',
                    Code: 'FUN'
                },
                {
                    Name: 'Leziria do Tejo',
                    Code: 'LTE'
                },
                {
                    Name: 'Lisboa',
                    Code: 'LIS'
                },
                {
                    Name: 'Medio Tejo',
                    Code: 'MTE'
                },
                {
                    Name: 'Minho-Lima',
                    Code: 'MLI'
                },
                {
                    Name: 'Oeste',
                    Code: 'OES'
                },
                {
                    Name: 'Peninsula de Setubal',
                    Code: 'PSE'
                },
                {
                    Name: 'Pinhal Interior Norte',
                    Code: 'PIN'
                },
                {
                    Name: 'Pinhal Interior Sul',
                    Code: 'PIS'
                },
                {
                    Name: 'Pinhal Litoral',
                    Code: 'PLT'
                },
                {
                    Name: 'Ponta Delgada',
                    Code: 'PDL'
                },
                {
                    Name: 'Porto',
                    Code: 'VDP'
                },
                {
                    Name: 'Serra da Estrela',
                    Code: 'SES'
                },
                {
                    Name: 'Tamega',
                    Code: 'TAM'
                }
                ]
            }
        },
        {
            Name: 'Puerto Rico',
            Code: 'PRI'
        },
        {
            Name: 'Qatar',
            Code: 'QAT',
            State: {
                City: [{
                    Name: 'Ad Dawhah',
                    Code: 'DW'
                },
                {
                    Name: 'Al Ghuwariyah',
                    Code: 'GW'
                },
                {
                    Name: 'Al Jumaliyah',
                    Code: 'JM'
                },
                {
                    Name: 'Al Khawr',
                    Code: 'KR'
                },
                {
                    Name: 'Al Wakrah',
                    Code: 'WK'
                },
                {
                    Name: 'Ar Rayyan',
                    Code: 'RN'
                },
                {
                    Name: 'Jariyan al Batnah',
                    Code: 'JB'
                },
                {
                    Name: 'Madinat ach Shamal',
                    Code: 'MS'
                },
                {
                    Name: 'Umm Salal',
                    Code: 'UL'
                }
                ]
            }
        },
        {
            Name: 'Reunion',
            Code: 'REU'
        },
        {
            Name: 'Romania',
            Code: 'ROU',
            State: {
                City: [{
                    Name: 'Alba Iulia',
                    Code: 'AL'
                },
                {
                    Name: 'Alexandria',
                    Code: 'AD'
                },
                {
                    Name: 'Arad',
                    Code: 'AR'
                },
                {
                    Name: 'Bacau',
                    Code: 'BA'
                },
                {
                    Name: 'Baia Mare',
                    Code: 'BM'
                },
                {
                    Name: 'Bistrita',
                    Code: 'BN'
                },
                {
                    Name: 'Botosani',
                    Code: 'BO'
                },
                {
                    Name: 'Braila',
                    Code: 'BL'
                },
                {
                    Name: 'Brasov',
                    Code: 'BS'
                },
                {
                    Name: 'Bucuresti',
                    Code: 'BC'
                },
                {
                    Name: 'Buzau',
                    Code: 'BZ'
                },
                {
                    Name: 'Calarasi',
                    Code: 'CR'
                },
                {
                    Name: 'Cluj-Napoca',
                    Code: 'CN'
                },
                {
                    Name: 'Constanta',
                    Code: 'CT'
                },
                {
                    Name: 'Craiova',
                    Code: 'DO'
                },
                {
                    Name: 'Deva',
                    Code: 'DE'
                },
                {
                    Name: 'Drobeta-Turnu Severin',
                    Code: 'DT'
                },
                {
                    Name: 'Focsani',
                    Code: 'FO'
                },
                {
                    Name: 'Galati',
                    Code: 'GL'
                },
                {
                    Name: 'Giurgiu',
                    Code: 'GG'
                },
                {
                    Name: 'Iasi',
                    Code: 'IS'
                },
                {
                    Name: 'Miercurea-Ciuc',
                    Code: 'MC'
                },
                {
                    Name: 'Oradea',
                    Code: 'OR'
                },
                {
                    Name: 'Piatra Neamt',
                    Code: 'PN'
                },
                {
                    Name: 'Pitesti',
                    Code: 'PI'
                },
                {
                    Name: 'Ploiesti',
                    Code: 'PL'
                },
                {
                    Name: 'Resita',
                    Code: 'RE'
                },
                {
                    Name: 'Satu Mare',
                    Code: 'SM'
                },
                {
                    Name: 'Sfantu-Gheorghe',
                    Code: 'SG'
                },
                {
                    Name: 'Sibiu',
                    Code: 'SO'
                },
                {
                    Name: 'Slatina',
                    Code: 'ST'
                },
                {
                    Name: 'Slobozia',
                    Code: 'SB'
                },
                {
                    Name: 'Suceava',
                    Code: 'SU'
                },
                {
                    Name: 'Targovişte',
                    Code: 'TA'
                },
                {
                    Name: 'Timisoara',
                    Code: 'TI'
                },
                {
                    Name: 'Tirgu Mures',
                    Code: 'TM'
                },
                {
                    Name: 'Tirgu-Jiu',
                    Code: 'TJ'
                },
                {
                    Name: 'Tulcea',
                    Code: 'TU'
                },
                {
                    Name: 'Vaslui',
                    Code: 'VA'
                },
                {
                    Name: 'XRimnicu Vilcea',
                    Code: 'VI'
                },
                {
                    Name: 'Zalau',
                    Code: 'ZA'
                }
                ]
            }
        },
        {
            Name: 'Russia',
            Code: 'RUS',
            State: {
                City: [{
                    Name: 'Abakan',
                    Code: 'ABA'
                },
                {
                    Name: 'Aginskoye',
                    Code: 'AGI'
                },
                {
                    Name: 'Anadyr',
                    Code: 'DYR'
                },
                {
                    Name: 'Arkhangelsk',
                    Code: 'ARK'
                },
                {
                    Name: 'Astrakhan',
                    Code: 'AST'
                },
                {
                    Name: 'Barnaul',
                    Code: 'BAX'
                },
                {
                    Name: 'Belgorod',
                    Code: 'BEL'
                },
                {
                    Name: 'Birobidzan',
                    Code: 'BBZ'
                },
                {
                    Name: 'Blagoveshchensk',
                    Code: 'BQS'
                },
                {
                    Name: 'Bryansk',
                    Code: 'BRY'
                },
                {
                    Name: 'Chabarovsk',
                    Code: 'COK'
                },
                {
                    Name: 'Cheboksary',
                    Code: 'CSY'
                },
                {
                    Name: 'Chelyabinsk',
                    Code: 'CHE'
                },
                {
                    Name: 'Cherkessk',
                    Code: 'CKS'
                },
                {
                    Name: 'Chita',
                    Code: 'CHI'
                },
                {
                    Name: 'Elista',
                    Code: 'ESL'
                },
                {
                    Name: 'Gorno-Altajsk',
                    Code: 'GOA'
                },
                {
                    Name: 'Grozny',
                    Code: 'GRV'
                },
                {
                    Name: 'Irkutsk',
                    Code: 'IKT'
                },
                {
                    Name: 'Ivanovo',
                    Code: 'IVO'
                },
                {
                    Name: 'Izhevsk',
                    Code: 'IJK'
                },
                {
                    Name: 'Jakutsk',
                    Code: 'JAK'
                },
                {
                    Name: 'Jaroslavl',
                    Code: 'JAR'
                },
                {
                    Name: 'Jekaterinburg',
                    Code: 'JEK'
                },
                {
                    Name: 'Juzno-Sachalinsk',
                    Code: 'JSA'
                },
                {
                    Name: 'Kaliningrad',
                    Code: 'KGD'
                },
                {
                    Name: 'Kaluga',
                    Code: 'KLF'
                },
                {
                    Name: 'Kazan',
                    Code: 'KZN'
                },
                {
                    Name: 'Kemerovo',
                    Code: 'KEM'
                },
                {
                    Name: 'Khanty-Mansiysk',
                    Code: 'KHM'
                },
                {
                    Name: 'Kirov',
                    Code: 'KIR'
                },
                {
                    Name: 'Kostroma',
                    Code: 'KOS'
                },
                {
                    Name: 'Krasnodar',
                    Code: 'KRR'
                },
                {
                    Name: 'Krasnojarsk',
                    Code: 'KYA'
                },
                {
                    Name: 'Kudymkar',
                    Code: 'KUD'
                },
                {
                    Name: 'Kurgan',
                    Code: 'KRO'
                },
                {
                    Name: 'Kursk',
                    Code: 'URS'
                },
                {
                    Name: 'Kyzyl',
                    Code: 'KYZ'
                },
                {
                    Name: 'Lipeck',
                    Code: 'LIP'
                },
                {
                    Name: 'Magadan',
                    Code: 'MAG'
                },
                {
                    Name: 'Magas',
                    Code: 'IN'
                },
                {
                    Name: 'Makhachkala',
                    Code: 'MCX'
                },
                {
                    Name: 'Maykop',
                    Code: 'MAY'
                },
                {
                    Name: 'Moscow',
                    Code: 'MOW'
                },
                {
                    Name: 'Murmansk',
                    Code: 'MMK'
                },
                {
                    Name: 'Nalchik',
                    Code: 'NAL'
                },
                {
                    Name: 'Naryan-Mar',
                    Code: 'NNM'
                },
                {
                    Name: 'Niznij Novgorod',
                    Code: 'GOJ'
                },
                {
                    Name: 'Novosibirsk',
                    Code: 'NVS'
                },
                {
                    Name: 'Omsk',
                    Code: 'OMS'
                },
                {
                    Name: 'Orel',
                    Code: 'ORL'
                },
                {
                    Name: 'Orenburg',
                    Code: 'ORE'
                },
                {
                    Name: 'Palana',
                    Code: 'PAL'
                },
                {
                    Name: 'Penza',
                    Code: 'PNZ'
                },
                {
                    Name: 'Perm',
                    Code: 'PER'
                },
                {
                    Name: 'Petropavlovsk-Kamchatskiy',
                    Code: 'PKC'
                },
                {
                    Name: 'Petrozavodsk',
                    Code: 'PES'
                },
                {
                    Name: 'Pskov',
                    Code: 'PSK'
                },
                {
                    Name: 'Rostov-na-Donu',
                    Code: 'ROS'
                },
                {
                    Name: 'Ryazan',
                    Code: 'RYA'
                },
                {
                    Name: 'Salekhard',
                    Code: 'SLY'
                },
                {
                    Name: 'Samara',
                    Code: 'SAM'
                },
                {
                    Name: 'Saransk',
                    Code: 'SKX'
                },
                {
                    Name: 'Saratov',
                    Code: 'SAR'
                },
                {
                    Name: 'Smolensk',
                    Code: 'LNX'
                },
                {
                    Name: 'St. Peterburg',
                    Code: 'SPE'
                },
                {
                    Name: 'Stavropol',
                    Code: 'STA'
                },
                {
                    Name: 'Syktyvkar',
                    Code: 'SCW'
                },
                {
                    Name: 'Tambov',
                    Code: 'TAM'
                },
                {
                    Name: 'Tomsk',
                    Code: 'TOM'
                },
                {
                    Name: 'Tula',
                    Code: 'TUL'
                },
                {
                    Name: 'Tver',
                    Code: 'TVE'
                },
                {
                    Name: 'Tyumen',
                    Code: 'TYU'
                },
                {
                    Name: 'Ufa',
                    Code: 'UFA'
                },
                {
                    Name: 'Ulan-Ude',
                    Code: 'UUD'
                },
                {
                    Name: 'Uljanovsk',
                    Code: 'ULY'
                },
                {
                    Name: 'Ust-Ordynsky',
                    Code: 'UOB'
                },
                {
                    Name: 'Velikij Novgorod',
                    Code: 'VUS'
                },
                {
                    Name: 'Vladikavkaz',
                    Code: 'VLA'
                },
                {
                    Name: 'Vladimir',
                    Code: 'VMR'
                },
                {
                    Name: 'Vladivostok',
                    Code: 'VVO'
                },
                {
                    Name: 'Volgograd',
                    Code: 'VOG'
                },
                {
                    Name: 'Vologda',
                    Code: 'VLG'
                },
                {
                    Name: 'Voronezh',
                    Code: 'VOR'
                },
                {
                    Name: 'Yoshkar-Ola',
                    Code: 'YOL'
                }
                ]
            }
        },
        {
            Name: 'Rwanda',
            Code: 'RWA',
            State: {
                City: [{
                    Name: 'Butare',
                    Code: 'BU'
                },
                {
                    Name: 'Byumba',
                    Code: 'BY'
                },
                {
                    Name: 'Cyangugu',
                    Code: 'CY'
                },
                {
                    Name: 'Gikongoro',
                    Code: 'GK'
                },
                {
                    Name: 'Gisenyi',
                    Code: 'GS'
                },
                {
                    Name: 'Gitarama',
                    Code: 'GT'
                },
                {
                    Name: 'Kabuga',
                    Code: 'KA'
                },
                {
                    Name: 'Kibungo',
                    Code: 'KG'
                },
                {
                    Name: 'Kibuye',
                    Code: 'KY'
                },
                {
                    Name: 'Kigali-Ngali',
                    Code: 'KR'
                },
                {
                    Name: 'Kigali-Ville',
                    Code: 'KV'
                },
                {
                    Name: 'Nyanza',
                    Code: 'NY'
                },
                {
                    Name: 'Ruhango',
                    Code: 'RH'
                },
                {
                    Name: 'Ruhengeri',
                    Code: 'RU'
                },
                {
                    Name: 'Rwamagana',
                    Code: 'RW'
                },
                {
                    Name: 'Umutara',
                    Code: 'UM'
                }
                ]
            }
        },
        {
            Name: 'Samoa',
            Code: 'WSM'
        },
        {
            Name: 'San Marino',
            Code: 'SMR'
        },
        {
            Name: 'Sao Tome and Principe',
            Code: 'STP'
        },
        {
            Name: 'Saudi Arabia',
            Code: 'SAU',
            State: {
                City: [{
                    Name: 'Abha',
                    Code: 'AHB'
                },
                {
                    Name: 'Al Bahah',
                    Code: 'BH'
                },
                {
                    Name: 'Al-Hufuf',
                    Code: 'HFF'
                },
                {
                    Name: 'Al-Jubayl',
                    Code: 'JBI'
                },
                {
                    Name: 'Al-Kharj',
                    Code: 'AKH'
                },
                {
                    Name: 'Al-Mubarraz',
                    Code: 'MBR'
                },
                {
                    Name: 'Arar',
                    Code: 'ARA'
                },
                {
                    Name: 'At Tarif',
                    Code: 'TAR'
                },
                {
                    Name: 'Buraydah',
                    Code: 'BUR'
                },
                {
                    Name: 'Dammam',
                    Code: 'DAM'
                },
                {
                    Name: 'Hafar al-Batin',
                    Code: 'HBT'
                },
                {
                    Name: 'Hail',
                    Code: 'HL'
                },
                {
                    Name: 'Jiddah',
                    Code: 'JED'
                },
                {
                    Name: 'Jizan',
                    Code: 'JZ'
                },
                {
                    Name: 'Khamis Mushayt',
                    Code: 'KMX'
                },
                {
                    Name: 'Makkah',
                    Code: 'ML'
                },
                {
                    Name: 'Medina',
                    Code: 'MED'
                },
                {
                    Name: 'Najran',
                    Code: 'NR'
                },
                {
                    Name: 'Riyad',
                    Code: 'RD'
                },
                {
                    Name: 'Sakaka',
                    Code: 'SAK'
                },
                {
                    Name: 'Tabuk',
                    Code: 'TB'
                },
                {
                    Name: 'Yanbu al-Bahr',
                    Code: 'YNB'
                }
                ]
            }
        },
        {
            Name: 'Senegal',
            Code: 'SEN',
            State: {
                City: [{
                    Name: 'Dakar',
                    Code: 'DA'
                },
                {
                    Name: 'Diourbel',
                    Code: 'DI'
                },
                {
                    Name: 'Fatick',
                    Code: 'FA'
                },
                {
                    Name: 'Kaolack',
                    Code: 'KA'
                },
                {
                    Name: 'Kolda',
                    Code: 'KO'
                },
                {
                    Name: 'Louga',
                    Code: 'LO'
                },
                {
                    Name: 'Matam',
                    Code: 'MA'
                },
                {
                    Name: 'Saint-Louis',
                    Code: 'SL'
                },
                {
                    Name: 'Tambacounda',
                    Code: 'TA'
                },
                {
                    Name: 'Thies',
                    Code: 'TH'
                },
                {
                    Name: 'Ziguinchor',
                    Code: 'ZI'
                }
                ]
            }
        },
        {
            Name: 'Serbia,Montenegro',
            Code: 'SCG',
            State: {
                City: [{
                    Name: 'Beograd',
                    Code: 'BEG'
                },
                {
                    Name: 'Kragujevac',
                    Code: 'KGV'
                },
                {
                    Name: 'Nis',
                    Code: 'INI'
                },
                {
                    Name: 'Novi Sad',
                    Code: 'NVS'
                },
                {
                    Name: 'Podgorica',
                    Code: 'POD'
                },
                {
                    Name: 'Pristina',
                    Code: 'PRN'
                },
                {
                    Name: 'Subotica',
                    Code: 'SUB'
                },
                {
                    Name: 'Zemun',
                    Code: 'ZEM'
                }
                ]
            }
        },
        {
            Name: 'Seychelles',
            Code: 'SYC'
        },
        {
            Name: 'Sierra Leone',
            Code: 'SLE',
            State: {
                City: [{
                    Name: 'Eastern',
                    Code: 'E'
                },
                {
                    Name: 'Northern',
                    Code: 'N'
                },
                {
                    Name: 'Southern',
                    Code: 'S'
                },
                {
                    Name: 'Western',
                    Code: 'W'
                }
                ]
            }
        },
        {
            Name: 'Singapore',
            Code: 'SGP'
        },
        {
            Name: 'Slovakia',
            Code: 'SVK',
            State: {
                City: [{
                    Name: 'Banskobystricky',
                    Code: 'BBY'
                },
                {
                    Name: 'Bratislavsky',
                    Code: 'BTS'
                },
                {
                    Name: 'Koricky',
                    Code: 'KOR'
                },
                {
                    Name: 'Nitriansky',
                    Code: 'NRA'
                },
                {
                    Name: 'Prerovsky',
                    Code: 'POV'
                },
                {
                    Name: 'Rilinsky',
                    Code: 'RIL'
                },
                {
                    Name: 'Trenriansky',
                    Code: 'TRE'
                },
                {
                    Name: 'Trnavsky',
                    Code: 'TNA'
                }
                ]
            }
        },
        {
            Name: 'Slovenia',
            Code: 'SVN',
            State: {
                City: [{
                    Name: 'Dolenjska',
                    Code: 'DLJ'
                },
                {
                    Name: 'Gorenjska',
                    Code: 'GSZ'
                },
                {
                    Name: 'Goriska',
                    Code: 'GSK'
                },
                {
                    Name: 'Koroska',
                    Code: 'KOR'
                },
                {
                    Name: 'Notranjsko-kraska',
                    Code: 'NKR'
                },
                {
                    Name: 'Obalno-kraska',
                    Code: 'OKR'
                },
                {
                    Name: 'Osrednjeslovenska',
                    Code: 'OSR'
                },
                {
                    Name: 'Podravska',
                    Code: 'POD'
                },
                {
                    Name: 'Pomurska',
                    Code: 'POM'
                },
                {
                    Name: 'Savinjska',
                    Code: 'SAV'
                },
                {
                    Name: 'Spodnjeposavska',
                    Code: 'SPO'
                },
                {
                    Name: 'Zasavska',
                    Code: 'ZAS'
                }
                ]
            }
        },
        {
            Name: 'Solomon Islands',
            Code: 'SLB',
            State: {
                City: [{
                    Name: 'Central Islands',
                    Code: 'CE'
                },
                {
                    Name: 'Choiseul',
                    Code: 'CH'
                },
                {
                    Name: 'Guadalcanal',
                    Code: 'GC'
                },
                {
                    Name: 'Honiara',
                    Code: 'HO'
                },
                {
                    Name: 'Isabel',
                    Code: 'IS'
                },
                {
                    Name: 'Makira',
                    Code: 'MK'
                },
                {
                    Name: 'Malaita',
                    Code: 'ML'
                },
                {
                    Name: 'Rennell and Bellona',
                    Code: 'RB'
                },
                {
                    Name: 'Temotu',
                    Code: 'TM'
                },
                {
                    Name: 'Western',
                    Code: 'WE'
                }
                ]
            }
        },
        {
            Name: 'Somalia',
            Code: 'SOM'
        },
        {
            Name: 'South Africa',
            Code: 'ZAF',
            State: {
                City: [{
                    Name: 'Barkley East',
                    Code: 'BAE'
                },
                {
                    Name: 'Beaufort West',
                    Code: 'BEW'
                },
                {
                    Name: 'Bisho',
                    Code: 'BIY'
                },
                {
                    Name: 'Bloemfontein',
                    Code: 'BFN'
                },
                {
                    Name: 'Bredasdorp',
                    Code: 'BDD'
                },
                {
                    Name: 'Bronkhorstspruit',
                    Code: 'BHT'
                },
                {
                    Name: 'Cape Town',
                    Code: 'CPT'
                },
                {
                    Name: 'De Aar',
                    Code: 'DAA'
                },
                {
                    Name: 'Dundee',
                    Code: 'DUN'
                },
                {
                    Name: 'Durban',
                    Code: 'DUR'
                },
                {
                    Name: 'East London',
                    Code: 'ELS'
                },
                {
                    Name: 'George',
                    Code: 'GRJ'
                },
                {
                    Name: 'Giyani',
                    Code: 'GIY'
                },
                {
                    Name: 'Groblersdal',
                    Code: 'GBD'
                },
                {
                    Name: 'Ixopo',
                    Code: 'IXO'
                },
                {
                    Name: 'Johannesburg',
                    Code: 'JNB'
                },
                {
                    Name: 'Kimberley',
                    Code: 'KIM'
                },
                {
                    Name: 'Klerksdorp',
                    Code: 'KXE'
                },
                {
                    Name: 'Kuruman',
                    Code: 'KMH'
                },
                {
                    Name: 'Ladysmith',
                    Code: 'LAY'
                },
                {
                    Name: 'Middelburg',
                    Code: 'MDB'
                },
                {
                    Name: 'Mkuze',
                    Code: 'MZQ'
                },
                {
                    Name: 'Moorreesburg',
                    Code: 'MOO'
                },
                {
                    Name: 'Mount Ayliff',
                    Code: 'MAY'
                },
                {
                    Name: 'Nelspruit',
                    Code: 'NLP'
                },
                {
                    Name: 'Newcastle',
                    Code: 'NCS'
                },
                {
                    Name: 'Nylstroom',
                    Code: 'NYL'
                },
                {
                    Name: 'Pietermaritzburg',
                    Code: 'PZB'
                },
                {
                    Name: 'Pietersburg',
                    Code: 'PTG'
                },
                {
                    Name: 'Port Elizabeth',
                    Code: 'PLZ'
                },
                {
                    Name: 'Port Shepstone',
                    Code: 'PSS'
                },
                {
                    Name: 'Pretoria',
                    Code: 'PRY'
                },
                {
                    Name: 'Queenstown',
                    Code: 'UTW'
                },
                {
                    Name: 'Randfontein',
                    Code: 'RFT'
                },
                {
                    Name: 'Richards Bay',
                    Code: 'RCB'
                },
                {
                    Name: 'Rustenburg',
                    Code: 'RSB'
                },
                {
                    Name: 'Sasolburg',
                    Code: 'SAS'
                },
                {
                    Name: 'Secunda',
                    Code: 'ZEC'
                },
                {
                    Name: 'Springbok',
                    Code: 'SBU'
                },
                {
                    Name: 'Thohoyandou',
                    Code: 'THY'
                },
                {
                    Name: 'Thulamahashe',
                    Code: 'TLH'
                },
                {
                    Name: 'Trompsburg',
                    Code: 'TPB'
                },
                {
                    Name: 'Ulundi',
                    Code: 'ULD'
                },
                {
                    Name: 'Umtata',
                    Code: 'UTT'
                },
                {
                    Name: 'Upington',
                    Code: 'UTN'
                },
                {
                    Name: 'Vereeniging',
                    Code: 'VGG'
                },
                {
                    Name: 'Vryburg',
                    Code: 'VRU'
                },
                {
                    Name: 'Welkom',
                    Code: 'WEL'
                },
                {
                    Name: 'Witsieshoek',
                    Code: 'WSH'
                },
                {
                    Name: 'Worcester',
                    Code: 'WOR'
                }
                ]
            }
        },
        {
            Name: 'South Georgia and South Sandwich Islands',
            Code: 'SGS'
        },
        {
            Name: 'Spain',
            Code: 'ESP',
            State: {
                City: [{
                    Name: 'Alava',
                    Code: 'ALA'
                },
                {
                    Name: 'Albacete',
                    Code: 'ALB'
                },
                {
                    Name: 'Alicante',
                    Code: 'ALC'
                },
                {
                    Name: 'Almeria',
                    Code: 'LEI'
                },
                {
                    Name: 'Asturias',
                    Code: 'AST'
                },
                {
                    Name: 'Avila',
                    Code: 'AVI'
                },
                {
                    Name: 'Badajoz',
                    Code: 'BJZ'
                },
                {
                    Name: 'Baleares',
                    Code: 'BLR'
                },
                {
                    Name: 'Barcelona',
                    Code: 'BCN'
                },
                {
                    Name: 'Burgos',
                    Code: 'BUR'
                },
                {
                    Name: 'Caceres',
                    Code: 'CCS'
                },
                {
                    Name: 'Cadiz',
                    Code: 'CAD'
                },
                {
                    Name: 'Castellon',
                    Code: 'CAS'
                },
                {
                    Name: 'Cludad Real',
                    Code: 'CIR'
                },
                {
                    Name: 'Cordoba',
                    Code: 'ODB'
                },
                {
                    Name: 'Cuenca',
                    Code: 'CUE'
                },
                {
                    Name: 'Gerona',
                    Code: 'GRO'
                },
                {
                    Name: 'Granada',
                    Code: 'GRX'
                },
                {
                    Name: 'Guadalajara',
                    Code: 'GUA'
                },
                {
                    Name: 'Guipuzcoa',
                    Code: 'GUI'
                },
                {
                    Name: 'Huelva',
                    Code: 'HUV'
                },
                {
                    Name: 'Huesca',
                    Code: 'HUC'
                },
                {
                    Name: 'Jaen',
                    Code: 'JAE'
                },
                {
                    Name: 'La Coruna',
                    Code: 'LCG'
                },
                {
                    Name: 'La Rioja',
                    Code: 'ARL'
                },
                {
                    Name: 'Las Palmas',
                    Code: 'LPA'
                },
                {
                    Name: 'Leon',
                    Code: 'LEN'
                },
                {
                    Name: 'Lleida',
                    Code: 'LLE'
                },
                {
                    Name: 'Madrid',
                    Code: 'MAD'
                },
                {
                    Name: 'Malaga',
                    Code: 'AGP'
                },
                {
                    Name: 'Murcia',
                    Code: 'MJV'
                },
                {
                    Name: 'Navarra',
                    Code: 'NVV'
                },
                {
                    Name: 'Orense',
                    Code: 'ORE'
                },
                {
                    Name: 'Palencia',
                    Code: 'PAC'
                },
                {
                    Name: 'Provincia de Lugo',
                    Code: 'LGO'
                },
                {
                    Name: 'Provincia de Pontevedra',
                    Code: 'PEV'
                },
                {
                    Name: 'Salamanca',
                    Code: 'SLM'
                },
                {
                    Name: 'Santa Cruz de Tenerife',
                    Code: 'SCT'
                },
                {
                    Name: 'Santander',
                    Code: 'SDR'
                },
                {
                    Name: 'Segovia',
                    Code: 'SEG'
                },
                {
                    Name: 'Sevilla',
                    Code: 'SVQ'
                },
                {
                    Name: 'Soria',
                    Code: 'SOR'
                },
                {
                    Name: 'Tarragona',
                    Code: 'TAR'
                },
                {
                    Name: 'Teruel',
                    Code: 'TER'
                },
                {
                    Name: 'Toledo',
                    Code: 'TOL'
                },
                {
                    Name: 'Valencia',
                    Code: 'VLC'
                },
                {
                    Name: 'Valladolid',
                    Code: 'VLL'
                },
                {
                    Name: 'Vizcaya',
                    Code: 'VSE'
                },
                {
                    Name: 'Zamora',
                    Code: 'ZMR'
                },
                {
                    Name: 'Zaragoza',
                    Code: 'ZAZ'
                }
                ]
            }
        },
        {
            Name: 'Sri Lanka',
            Code: 'LKA',
            State: {
                City: [{
                    Name: 'Ampara',
                    Code: 'AMP'
                },
                {
                    Name: 'Anuradhapura',
                    Code: 'ADP'
                },
                {
                    Name: 'Badulla',
                    Code: 'BAD'
                },
                {
                    Name: 'Batticaloa',
                    Code: 'BTC'
                },
                {
                    Name: 'Colombo',
                    Code: 'CMB'
                },
                {
                    Name: 'Galle',
                    Code: 'GAL'
                },
                {
                    Name: 'Gampaha',
                    Code: 'GAM'
                },
                {
                    Name: 'Hambantota',
                    Code: 'HBA'
                },
                {
                    Name: 'Jaffna',
                    Code: 'JAF'
                },
                {
                    Name: 'Kalutara',
                    Code: 'KLT'
                },
                {
                    Name: 'Kandy',
                    Code: 'KAN'
                },
                {
                    Name: 'Kegalle',
                    Code: 'KEG'
                },
                {
                    Name: 'Kilinochchi',
                    Code: 'KIL'
                },
                {
                    Name: 'Kurunegala',
                    Code: 'KUR'
                },
                {
                    Name: 'Mannar',
                    Code: 'MAN'
                },
                {
                    Name: 'Matale',
                    Code: 'MAT'
                },
                {
                    Name: 'Matara',
                    Code: 'MAA'
                },
                {
                    Name: 'Monaragala',
                    Code: 'MON'
                },
                {
                    Name: 'Mullathivu',
                    Code: 'MUL'
                },
                {
                    Name: 'Nuwara Eliya',
                    Code: 'NUE'
                },
                {
                    Name: 'Polonnaruwa',
                    Code: 'POL'
                },
                {
                    Name: 'Puttalam',
                    Code: 'PUT'
                },
                {
                    Name: 'Ratnapura',
                    Code: 'RAT'
                },
                {
                    Name: 'Trincomalee',
                    Code: 'TRR'
                },
                {
                    Name: 'Vavuniya',
                    Code: 'VAV'
                }
                ]
            }
        },
        {
            Name: 'St.Helena',
            Code: 'SHN'
        },
        {
            Name: 'St.Kitts and Nevis',
            Code: 'KNA'
        },
        {
            Name: 'St.Lucia',
            Code: 'LCA'
        },
        {
            Name: 'St.Pierre and Miquelon',
            Code: 'SPM'
        },
        {
            Name: 'St.Vincent and the Grenadines',
            Code: 'VCT'
        },
        {
            Name: 'Sudan',
            Code: 'SDN',
            State: {
                City: [{
                    Name: 'Aali an-Nil',
                    Code: 'ANB'
                },
                {
                    Name: 'Al Wasta',
                    Code: 'WDH'
                },
                {
                    Name: 'Al-Istiwaiyah',
                    Code: 'SIS'
                },
                {
                    Name: 'Al-Khartum',
                    Code: 'KRT'
                },
                {
                    Name: 'Ash-Shamaliyah',
                    Code: 'ASH'
                },
                {
                    Name: 'Ash-Sharqiyah',
                    Code: 'SHA'
                },
                {
                    Name: 'Bahr al-Ghazal',
                    Code: 'SBG'
                },
                {
                    Name: 'Darfur',
                    Code: 'SDA'
                },
                {
                    Name: 'Kurdufan',
                    Code: 'GKU'
                }
                ]
            }
        },
        {
            Name: 'Suriname',
            Code: 'SUR',
            State: {
                City: [{
                    Name: 'Brokopondo',
                    Code: 'BR'
                },
                {
                    Name: 'Commewijne',
                    Code: 'CM'
                },
                {
                    Name: 'Coronie',
                    Code: 'CR'
                },
                {
                    Name: 'Marowijne',
                    Code: 'MA'
                },
                {
                    Name: 'Nickerie',
                    Code: 'NI'
                },
                {
                    Name: 'Para',
                    Code: 'PA'
                },
                {
                    Name: 'Paramaribo',
                    Code: 'PM'
                },
                {
                    Name: 'Saramacca',
                    Code: 'SA'
                },
                {
                    Name: 'Sipaliwini',
                    Code: 'SI'
                },
                {
                    Name: 'Wanica',
                    Code: 'WA'
                }
                ]
            }
        },
        {
            Name: 'Svalbard and Jan Mayen',
            Code: 'SJM'
        },
        {
            Name: 'Swaziland',
            Code: 'SWZ'
        },
        {
            Name: 'Sweden',
            Code: 'SWE',
            State: {
                City: [{
                    Name: 'Blekinge',
                    Code: 'K'
                },
                {
                    Name: 'Dalarnas',
                    Code: 'DLN'
                },
                {
                    Name: 'Gavleborgs',
                    Code: 'X'
                },
                {
                    Name: 'Gotlands',
                    Code: 'I'
                },
                {
                    Name: 'Hallands',
                    Code: 'N'
                },
                {
                    Name: 'Jamtlands',
                    Code: 'Z'
                },
                {
                    Name: 'Jonkopings',
                    Code: 'F'
                },
                {
                    Name: 'Kalmar',
                    Code: 'H'
                },
                {
                    Name: 'Kronobergs',
                    Code: 'G'
                },
                {
                    Name: 'Norrbottens',
                    Code: 'BD'
                },
                {
                    Name: 'Orebro',
                    Code: 'T'
                },
                {
                    Name: 'Skane',
                    Code: 'M'
                },
                {
                    Name: 'Sodermanlands',
                    Code: 'D'
                },
                {
                    Name: 'Stockholms',
                    Code: 'AB'
                },
                {
                    Name: 'Uppsala',
                    Code: 'C'
                },
                {
                    Name: 'Ustergotland',
                    Code: 'UGL'
                },
                {
                    Name: 'Varmlands',
                    Code: 'S'
                },
                {
                    Name: 'Vasterbottens',
                    Code: 'AC'
                },
                {
                    Name: 'Vasternorrlands',
                    Code: 'Y'
                },
                {
                    Name: 'Vastmanlands',
                    Code: 'U'
                },
                {
                    Name: 'Vastra Gotalands',
                    Code: 'O'
                }
                ]
            }
        },
        {
            Name: 'Switzerland',
            Code: 'CHE',
            State: {
                City: [{
                    Name: 'Aargau',
                    Code: 'AG'
                },
                {
                    Name: 'Appenzell Ausserrhon',
                    Code: 'AR'
                },
                {
                    Name: 'Appenzell Innerrhodn',
                    Code: 'AI'
                },
                {
                    Name: 'Basel Landschaft',
                    Code: 'BL'
                },
                {
                    Name: 'Basel－Sstadt',
                    Code: 'BS'
                },
                {
                    Name: 'Bern',
                    Code: 'BE'
                },
                {
                    Name: 'Freiburg',
                    Code: 'FR'
                },
                {
                    Name: 'Geneve',
                    Code: 'GE'
                },
                {
                    Name: 'Glarus',
                    Code: 'GL'
                },
                {
                    Name: 'Graubünden',
                    Code: 'GR'
                },
                {
                    Name: 'Jura',
                    Code: 'JU'
                },
                {
                    Name: 'Lausanne',
                    Code: 'LA'
                },
                {
                    Name: 'Luzern',
                    Code: 'LU'
                },
                {
                    Name: 'Neuchatel',
                    Code: 'NE'
                },
                {
                    Name: 'Nidwalden',
                    Code: 'NW'
                },
                {
                    Name: 'Obwalden',
                    Code: 'OW'
                },
                {
                    Name: 'Schaffhausen',
                    Code: 'SH'
                },
                {
                    Name: 'Schwyz',
                    Code: 'SZ'
                },
                {
                    Name: 'Solothurn',
                    Code: 'SO'
                },
                {
                    Name: 'St.Gallen',
                    Code: 'SG'
                },
                {
                    Name: 'Thurgau',
                    Code: 'TG'
                },
                {
                    Name: 'Ticino',
                    Code: 'TI'
                },
                {
                    Name: 'Uri',
                    Code: 'UR'
                },
                {
                    Name: 'Vaud',
                    Code: 'VD'
                },
                {
                    Name: 'Wallis',
                    Code: 'VS'
                },
                {
                    Name: 'Zug',
                    Code: 'ZG'
                },
                {
                    Name: 'Zurich',
                    Code: 'ZH'
                }
                ]
            }
        },
        {
            Name: 'Syria',
            Code: 'SYR',
            State: {
                City: [{
                    Name: 'Al Ghab',
                    Code: 'GH'
                },
                {
                    Name: 'Al Hasakah',
                    Code: 'HA'
                },
                {
                    Name: 'Al Ladhiqiyah',
                    Code: 'LA'
                },
                {
                    Name: 'Al Qunaytirah',
                    Code: 'QU'
                },
                {
                    Name: 'Al-Qamishli',
                    Code: 'QA'
                },
                {
                    Name: 'Ar Raqqah',
                    Code: 'RQ'
                },
                {
                    Name: 'As Suwayda',
                    Code: 'SU'
                },
                {
                    Name: 'Dara',
                    Code: 'DA'
                },
                {
                    Name: 'Dayr az Zawr',
                    Code: 'DZ'
                },
                {
                    Name: 'Halab',
                    Code: 'HL'
                },
                {
                    Name: 'Hamah',
                    Code: 'HM'
                },
                {
                    Name: 'Hims',
                    Code: 'HI'
                },
                {
                    Name: 'Idlib',
                    Code: 'ID'
                },
                {
                    Name: 'Madinat Dimashq',
                    Code: 'DI'
                },
                {
                    Name: 'Rif Dimashq',
                    Code: 'RD'
                },
                {
                    Name: 'Tartus',
                    Code: 'TA'
                }
                ]
            }
        },
        {
            Name: 'Tajikistan',
            Code: 'TJK',
            State: {
                City: [{
                    Name: 'Dushanbe',
                    Code: 'DYU'
                },
                {
                    Name: 'Isfara',
                    Code: 'ISF'
                },
                {
                    Name: 'Kanibadam',
                    Code: 'KAN'
                },
                {
                    Name: 'Khorugh',
                    Code: 'KHO'
                },
                {
                    Name: 'Khujand',
                    Code: 'KHU'
                },
                {
                    Name: 'Kofarnihon',
                    Code: 'KOF'
                },
                {
                    Name: 'Kulob',
                    Code: 'KLB'
                },
                {
                    Name: 'Kurgan-Tjube',
                    Code: 'KTJ'
                },
                {
                    Name: 'Nurek',
                    Code: 'NUR'
                },
                {
                    Name: 'Pendzhikent',
                    Code: 'PJK'
                },
                {
                    Name: 'Rogun',
                    Code: 'RGU'
                },
                {
                    Name: 'Sarband',
                    Code: 'SBA'
                },
                {
                    Name: 'Taboshar',
                    Code: 'TBS'
                },
                {
                    Name: 'Tursunzade',
                    Code: 'TSZ'
                },
                {
                    Name: 'Ura-Tjube',
                    Code: 'UTJ'
                }
                ]
            }
        },
        {
            Name: 'Tanzania',
            Code: 'TZA',
            State: {
                City: [{
                    Name: 'Arusha',
                    Code: 'AR'
                },
                {
                    Name: 'Dar es Salaam',
                    Code: 'DS'
                },
                {
                    Name: 'Dodoma',
                    Code: 'DO'
                },
                {
                    Name: 'Iringa',
                    Code: 'IR'
                },
                {
                    Name: 'Kagera',
                    Code: 'KA'
                },
                {
                    Name: 'Kaskazini Pemba',
                    Code: 'PN'
                },
                {
                    Name: 'Kaskazini Unguja',
                    Code: 'UN'
                },
                {
                    Name: 'Kigoma',
                    Code: 'KI'
                },
                {
                    Name: 'Kilimanjaro',
                    Code: 'KJ'
                },
                {
                    Name: 'Kusini Pemba',
                    Code: 'PS'
                },
                {
                    Name: 'Kusini Unguja',
                    Code: 'US'
                },
                {
                    Name: 'Lindi',
                    Code: 'LN'
                },
                {
                    Name: 'Manyara',
                    Code: 'MY'
                },
                {
                    Name: 'Mara',
                    Code: 'MR'
                },
                {
                    Name: 'Mbeya',
                    Code: 'MB'
                },
                {
                    Name: 'Mjini Magharibi',
                    Code: 'MM'
                },
                {
                    Name: 'Morogoro',
                    Code: 'MO'
                },
                {
                    Name: 'Mtwara',
                    Code: 'MT'
                },
                {
                    Name: 'Mwanza',
                    Code: 'MW'
                },
                {
                    Name: 'Pwani',
                    Code: 'PW'
                },
                {
                    Name: 'Rukwa',
                    Code: 'RK'
                },
                {
                    Name: 'Ruvuma',
                    Code: 'RV'
                },
                {
                    Name: 'Shinyanga',
                    Code: 'SH'
                },
                {
                    Name: 'Singida',
                    Code: 'SI'
                },
                {
                    Name: 'Tabora',
                    Code: 'TB'
                },
                {
                    Name: 'Tanga',
                    Code: 'TN'
                },
                {
                    Name: 'Zanzibar',
                    Code: 'ZN'
                }
                ]
            }
        },
        {
            Name: 'Thailand',
            Code: 'THA',
            State: {
                City: [{
                    Name: 'Amnat Charoen',
                    Code: '37'
                },
                {
                    Name: 'Ang Thong',
                    Code: '15'
                },
                {
                    Name: 'Bangkok',
                    Code: '10'
                },
                {
                    Name: 'Buri Ram',
                    Code: '31'
                },
                {
                    Name: 'Chachoengsao',
                    Code: '24'
                },
                {
                    Name: 'Chai Nat',
                    Code: '18'
                },
                {
                    Name: 'Chaiyaphum',
                    Code: '36'
                },
                {
                    Name: 'Chanthaburi',
                    Code: '22'
                },
                {
                    Name: 'Chiang Mai',
                    Code: '50'
                },
                {
                    Name: 'Chiang Rai',
                    Code: '57'
                },
                {
                    Name: 'Chon Buri',
                    Code: '20'
                },
                {
                    Name: 'Chumphon',
                    Code: '86'
                },
                {
                    Name: 'Kalasin',
                    Code: '46'
                },
                {
                    Name: 'Kamphaeng Phet',
                    Code: '62'
                },
                {
                    Name: 'Kanchanaburi',
                    Code: '71'
                },
                {
                    Name: 'Khon Kaen',
                    Code: '40'
                },
                {
                    Name: 'Krabi',
                    Code: '81'
                },
                {
                    Name: 'Lamphun',
                    Code: '51'
                },
                {
                    Name: 'Loei',
                    Code: '42'
                },
                {
                    Name: 'Lop Buri',
                    Code: '16'
                },
                {
                    Name: 'Mae Hong Son',
                    Code: '58'
                },
                {
                    Name: 'Maha Sarakham',
                    Code: '44'
                },
                {
                    Name: 'Mukdahan',
                    Code: '49'
                },
                {
                    Name: 'Nakhon Nayok',
                    Code: '26'
                },
                {
                    Name: 'Nakhon Pathom',
                    Code: '73'
                },
                {
                    Name: 'Nakhon Phanom',
                    Code: '48'
                },
                {
                    Name: 'Nakhon Sawan',
                    Code: '60'
                },
                {
                    Name: 'Nakhon Si Thammarat',
                    Code: '80'
                },
                {
                    Name: 'Nan',
                    Code: '55'
                },
                {
                    Name: 'Narathiwat',
                    Code: '96'
                },
                {
                    Name: 'Nong Bua Lamphu',
                    Code: '39'
                },
                {
                    Name: 'Nong Khai',
                    Code: '43'
                },
                {
                    Name: 'Nonthaburi',
                    Code: '12'
                },
                {
                    Name: 'Pathum Thani',
                    Code: '13'
                },
                {
                    Name: 'Pattani',
                    Code: '94'
                },
                {
                    Name: 'Phangnga',
                    Code: '82'
                },
                {
                    Name: 'Phatthalung',
                    Code: '93'
                },
                {
                    Name: 'Phayao',
                    Code: '56'
                },
                {
                    Name: 'Phetchabun',
                    Code: '76'
                },
                {
                    Name: 'Phetchaburi',
                    Code: '78'
                },
                {
                    Name: 'Phichit',
                    Code: '66'
                },
                {
                    Name: 'Phitsanulok',
                    Code: '65'
                },
                {
                    Name: 'Phra Nakhon Si Ayutthaya',
                    Code: '14'
                },
                {
                    Name: 'Phrae',
                    Code: '54'
                },
                {
                    Name: 'Phuket',
                    Code: '83'
                },
                {
                    Name: 'Prachin Buri',
                    Code: '25'
                },
                {
                    Name: 'Prachuap Khiri Khan',
                    Code: '77'
                },
                {
                    Name: 'Ranong',
                    Code: '85'
                },
                {
                    Name: 'Ratchaburi',
                    Code: '70'
                },
                {
                    Name: 'Rayong',
                    Code: '21'
                },
                {
                    Name: 'Roi Et',
                    Code: '45'
                },
                {
                    Name: 'Sa Kaeo',
                    Code: '27'
                },
                {
                    Name: 'Sakon Nakhon',
                    Code: '47'
                },
                {
                    Name: 'Samut Prakan',
                    Code: '11'
                },
                {
                    Name: 'Samut Sakhon',
                    Code: '74'
                },
                {
                    Name: 'Samut Songkhram',
                    Code: '75'
                },
                {
                    Name: 'Saraburi',
                    Code: '19'
                },
                {
                    Name: 'Satun',
                    Code: '91'
                },
                {
                    Name: 'Si sa ket',
                    Code: '33'
                },
                {
                    Name: 'Sing Buri',
                    Code: '17'
                },
                {
                    Name: 'Songkhla',
                    Code: '90'
                },
                {
                    Name: 'Sukhothai',
                    Code: '64'
                },
                {
                    Name: 'Suphan Buri',
                    Code: '72'
                },
                {
                    Name: 'Surat Thani',
                    Code: '84'
                },
                {
                    Name: 'Surin',
                    Code: '32'
                },
                {
                    Name: 'Tak',
                    Code: '63'
                },
                {
                    Name: 'Trang',
                    Code: '92'
                },
                {
                    Name: 'Trat',
                    Code: '23'
                },
                {
                    Name: 'Ubon Ratchathani',
                    Code: '34'
                },
                {
                    Name: 'Udon Thani',
                    Code: '41'
                },
                {
                    Name: 'Uthai Thani',
                    Code: '61'
                },
                {
                    Name: 'Uttaradit',
                    Code: '53'
                },
                {
                    Name: 'Yala',
                    Code: '95'
                },
                {
                    Name: 'Yasothon',
                    Code: '35'
                }
                ]
            }
        },
        {
            Name: 'Timor-Leste',
            Code: 'TLS',
            State: {
                City: [{
                    Name: 'Aileu',
                    Code: 'AL'
                },
                {
                    Name: 'Ainaro',
                    Code: 'AN'
                },
                {
                    Name: 'Ambeno',
                    Code: 'AM'
                },
                {
                    Name: 'Baucau',
                    Code: 'BA'
                },
                {
                    Name: 'Bobonaro',
                    Code: 'BO'
                },
                {
                    Name: 'Dili',
                    Code: 'DI'
                },
                {
                    Name: 'Ermera',
                    Code: 'ER'
                },
                {
                    Name: 'Kovalima',
                    Code: 'KO'
                },
                {
                    Name: 'Lautem',
                    Code: 'LA'
                },
                {
                    Name: 'Liquica',
                    Code: 'LI'
                },
                {
                    Name: 'Manatuto',
                    Code: 'MT'
                },
                {
                    Name: 'Manofahi',
                    Code: 'MF'
                },
                {
                    Name: 'Viqueque',
                    Code: 'VI'
                }
                ]
            }
        },
        {
            Name: 'Togo',
            Code: 'TGO',
            State: {
                City: [{
                    Name: 'Centre',
                    Code: 'C'
                },
                {
                    Name: 'Kara',
                    Code: 'K'
                },
                {
                    Name: 'Maritime',
                    Code: 'M'
                },
                {
                    Name: 'Plateaux',
                    Code: 'P'
                },
                {
                    Name: 'Savanes',
                    Code: 'S'
                }
                ]
            }
        },
        {
            Name: 'Tokelau',
            Code: 'TKL'
        },
        {
            Name: 'Tonga',
            Code: 'TON',
            State: {
                City: [{
                    Name: 'Eua',
                    Code: 'E'
                },
                {
                    Name: 'Haapai',
                    Code: 'H'
                },
                {
                    Name: 'Niuas',
                    Code: 'N'
                },
                {
                    Name: 'Tongatapu',
                    Code: 'T'
                },
                {
                    Name: 'Vavau',
                    Code: 'V'
                }
                ]
            }
        },
        {
            Name: 'Trinidad and Tobago',
            Code: 'TTO'
        },
        {
            Name: 'Tristan da Cunha',
            Code: 'TAA'
        },
        {
            Name: 'Tunisia',
            Code: 'TUN',
            State: {
                City: [{
                    Name: 'Ariana',
                    Code: 'AR'
                },
                {
                    Name: 'Beja',
                    Code: 'BJ'
                },
                {
                    Name: 'Ben Arous',
                    Code: 'BA'
                },
                {
                    Name: 'Bizerte',
                    Code: 'BI'
                },
                {
                    Name: 'Gabes',
                    Code: 'GB'
                },
                {
                    Name: 'Gafsa',
                    Code: 'GF'
                },
                {
                    Name: 'Jendouba',
                    Code: 'JE'
                },
                {
                    Name: 'Kairouan',
                    Code: 'KR'
                },
                {
                    Name: 'Kasserine',
                    Code: 'KS'
                },
                {
                    Name: 'Kebili',
                    Code: 'KB'
                },
                {
                    Name: 'Le Kef',
                    Code: 'LK'
                },
                {
                    Name: 'Mahdia',
                    Code: 'MH'
                },
                {
                    Name: 'Manouba',
                    Code: 'MN'
                },
                {
                    Name: 'Medenine',
                    Code: 'ME'
                },
                {
                    Name: 'Monastir',
                    Code: 'MO'
                },
                {
                    Name: 'Nabeul',
                    Code: 'NA'
                },
                {
                    Name: 'Sfax',
                    Code: 'SF'
                },
                {
                    Name: 'Sidi Bouzid',
                    Code: 'SD'
                },
                {
                    Name: 'Siliana',
                    Code: 'SL'
                },
                {
                    Name: 'Sousse',
                    Code: 'SO'
                },
                {
                    Name: 'Tataouine',
                    Code: 'TA'
                },
                {
                    Name: 'Tozeur',
                    Code: 'TO'
                },
                {
                    Name: 'Tunis',
                    Code: 'TU'
                },
                {
                    Name: 'Zaghouan',
                    Code: 'ZA'
                }
                ]
            }
        },
        {
            Name: 'Turkey',
            Code: 'TUR',
            State: {
                City: [{
                    Name: 'Adana',
                    Code: 'ADA'
                },
                {
                    Name: 'Adiyaman',
                    Code: 'ADI'
                },
                {
                    Name: 'Afyon',
                    Code: 'AFY'
                },
                {
                    Name: 'Agri',
                    Code: 'AGR'
                },
                {
                    Name: 'Aksaray',
                    Code: 'AKS'
                },
                {
                    Name: 'Amasya',
                    Code: 'AMA'
                },
                {
                    Name: 'Ankara',
                    Code: 'ANK'
                },
                {
                    Name: 'Antalya',
                    Code: 'ANT'
                },
                {
                    Name: 'Ardahan',
                    Code: 'ARD'
                },
                {
                    Name: 'Artvin',
                    Code: 'ART'
                },
                {
                    Name: 'Aydin',
                    Code: 'AYI'
                },
                {
                    Name: 'Balikesir',
                    Code: 'BAL'
                },
                {
                    Name: 'Bartin',
                    Code: 'BAR'
                },
                {
                    Name: 'Batman',
                    Code: 'BAT'
                },
                {
                    Name: 'Bayburt',
                    Code: 'BAY'
                },
                {
                    Name: 'Bilecik',
                    Code: 'BIL'
                },
                {
                    Name: 'Bingol',
                    Code: 'BIN'
                },
                {
                    Name: 'Bitlis',
                    Code: 'BIT'
                },
                {
                    Name: 'Bolu',
                    Code: 'BOL'
                },
                {
                    Name: 'Burdur',
                    Code: 'BRD'
                },
                {
                    Name: 'Bursa',
                    Code: 'BRS'
                },
                {
                    Name: 'Canakkale',
                    Code: 'CKL'
                },
                {
                    Name: 'Cankiri',
                    Code: 'CKR'
                },
                {
                    Name: 'Corum',
                    Code: 'COR'
                },
                {
                    Name: 'Denizli',
                    Code: 'DEN'
                },
                {
                    Name: 'Diyarbakir',
                    Code: 'DIY'
                },
                {
                    Name: 'Edirne',
                    Code: 'EDI'
                },
                {
                    Name: 'Elazig',
                    Code: 'ELA'
                },
                {
                    Name: 'Erzincan',
                    Code: 'EZC'
                },
                {
                    Name: 'Erzurum',
                    Code: 'EZR'
                },
                {
                    Name: 'Eskisehir',
                    Code: 'ESK'
                },
                {
                    Name: 'Gaziantep',
                    Code: 'GAZ'
                },
                {
                    Name: 'Giresun',
                    Code: 'GIR'
                },
                {
                    Name: 'Gumushane',
                    Code: 'GMS'
                },
                {
                    Name: 'Hakkari',
                    Code: 'HKR'
                },
                {
                    Name: 'Hatay',
                    Code: 'HTY'
                },
                {
                    Name: 'Icel',
                    Code: 'ICE'
                },
                {
                    Name: 'Igdir',
                    Code: 'IGD'
                },
                {
                    Name: 'Isparta',
                    Code: 'ISP'
                },
                {
                    Name: 'Istanbul',
                    Code: 'IST'
                },
                {
                    Name: 'Izmir',
                    Code: 'IZM'
                },
                {
                    Name: 'Kahraman Maras',
                    Code: 'KAH'
                },
                {
                    Name: 'Karabuk',
                    Code: 'KRB'
                },
                {
                    Name: 'Karaman',
                    Code: 'KRM'
                },
                {
                    Name: 'Kars',
                    Code: 'KRS'
                },
                {
                    Name: 'Kastamonu',
                    Code: 'KAS'
                },
                {
                    Name: 'Kayseri',
                    Code: 'KAY'
                },
                {
                    Name: 'Kilis',
                    Code: 'KLS'
                },
                {
                    Name: 'Kirikkale',
                    Code: 'KRK'
                },
                {
                    Name: 'Kirklareli',
                    Code: 'KLR'
                },
                {
                    Name: 'Kirsehir',
                    Code: 'KRH'
                },
                {
                    Name: 'Kocaeli',
                    Code: 'KOC'
                },
                {
                    Name: 'Konya',
                    Code: 'KON'
                },
                {
                    Name: 'Kutahya',
                    Code: 'KUT'
                },
                {
                    Name: 'Malatya',
                    Code: 'MAL'
                },
                {
                    Name: 'Manisa',
                    Code: 'MAN'
                },
                {
                    Name: 'Mardin',
                    Code: 'MAR'
                },
                {
                    Name: 'Mugla',
                    Code: 'MUG'
                },
                {
                    Name: 'Mus',
                    Code: 'MUS'
                },
                {
                    Name: 'Nevsehir',
                    Code: 'NEV'
                },
                {
                    Name: 'Nigde',
                    Code: 'NIG'
                },
                {
                    Name: 'Ordu',
                    Code: 'ORD'
                },
                {
                    Name: 'Rize',
                    Code: 'RIZ'
                },
                {
                    Name: 'Sakarya',
                    Code: 'SAK'
                },
                {
                    Name: 'Samsun',
                    Code: 'SAM'
                },
                {
                    Name: 'Siirt',
                    Code: 'SII'
                },
                {
                    Name: 'Sinop',
                    Code: 'SIN'
                },
                {
                    Name: 'Sirnak',
                    Code: 'SIR'
                },
                {
                    Name: 'Sivas',
                    Code: 'SIV'
                },
                {
                    Name: 'Tekirdag',
                    Code: 'TEL'
                },
                {
                    Name: 'Tokat',
                    Code: 'TOK'
                },
                {
                    Name: 'Trabzon',
                    Code: 'TRA'
                },
                {
                    Name: 'Tunceli',
                    Code: 'TUN'
                },
                {
                    Name: 'Urfa',
                    Code: 'URF'
                },
                {
                    Name: 'Usak',
                    Code: 'USK'
                },
                {
                    Name: 'Van',
                    Code: 'VAN'
                },
                {
                    Name: 'Yozgat',
                    Code: 'YOZ'
                },
                {
                    Name: 'Zonguldak',
                    Code: 'ZON'
                }
                ]
            }
        },
        {
            Name: 'Turkmenistan',
            Code: 'TKM',
            State: {
                City: [{
                    Name: 'Ahal',
                    Code: 'A'
                },
                {
                    Name: 'Ashgabat',
                    Code: 'ASB'
                },
                {
                    Name: 'Balkan',
                    Code: 'B'
                },
                {
                    Name: 'Dashoguz',
                    Code: 'D'
                },
                {
                    Name: 'Lebap',
                    Code: 'L'
                },
                {
                    Name: 'Mary',
                    Code: 'M'
                },
                {
                    Name: 'Nebitdag',
                    Code: 'NEB'
                }
                ]
            }
        },
        {
            Name: 'Turks and Caicos Islands',
            Code: 'TCA'
        },
        {
            Name: 'Tuvalu',
            Code: 'TUV'
        },
        {
            Name: 'Uganda',
            Code: 'UGA',
            State: {
                City: [{
                    Name: 'Adjumani',
                    Code: 'ADJ'
                },
                {
                    Name: 'Apac',
                    Code: 'APC'
                },
                {
                    Name: 'Arua',
                    Code: 'ARU'
                },
                {
                    Name: 'Bugiri',
                    Code: 'BUG'
                },
                {
                    Name: 'Bundibugyo',
                    Code: 'BUN'
                },
                {
                    Name: 'Bushenyi',
                    Code: 'BSH'
                },
                {
                    Name: 'Busia',
                    Code: 'BUS'
                },
                {
                    Name: 'Gulu',
                    Code: 'GUL'
                },
                {
                    Name: 'Hoima',
                    Code: 'HOI'
                },
                {
                    Name: 'Iganga',
                    Code: 'IGA'
                },
                {
                    Name: 'Jinja',
                    Code: 'JIN'
                },
                {
                    Name: 'Kabale',
                    Code: 'KBL'
                },
                {
                    Name: 'Kabarole',
                    Code: 'KAR'
                },
                {
                    Name: 'Kaberamaido',
                    Code: 'KAB'
                },
                {
                    Name: 'Kalangala',
                    Code: 'KAL'
                },
                {
                    Name: 'Kampala',
                    Code: 'KMP'
                },
                {
                    Name: 'Kamuli',
                    Code: 'KML'
                },
                {
                    Name: 'Kamwenge',
                    Code: 'KAM'
                },
                {
                    Name: 'Kanungu',
                    Code: 'KAN'
                },
                {
                    Name: 'Kapchorwa',
                    Code: 'KPC'
                },
                {
                    Name: 'Kasese',
                    Code: 'KAS'
                },
                {
                    Name: 'Katakwi',
                    Code: 'KTK'
                },
                {
                    Name: 'Kayunga',
                    Code: 'KAY'
                },
                {
                    Name: 'Kibaale',
                    Code: 'KBA'
                },
                {
                    Name: 'Kiboga',
                    Code: 'KIB'
                },
                {
                    Name: 'Kisoro',
                    Code: 'KIS'
                },
                {
                    Name: 'Kitgum',
                    Code: 'KIT'
                },
                {
                    Name: 'Kotido',
                    Code: 'KOT'
                },
                {
                    Name: 'Kumi',
                    Code: 'KUM'
                },
                {
                    Name: 'Kyenjojo',
                    Code: 'KYE'
                },
                {
                    Name: 'Lira',
                    Code: 'LIR'
                },
                {
                    Name: 'Luwero',
                    Code: 'LUW'
                },
                {
                    Name: 'Masaka',
                    Code: 'MAS'
                },
                {
                    Name: 'Masindi',
                    Code: 'MSN'
                },
                {
                    Name: 'Mayuge',
                    Code: 'MAY'
                },
                {
                    Name: 'Mbale',
                    Code: 'MBA'
                },
                {
                    Name: 'Mbarara',
                    Code: 'MBR'
                },
                {
                    Name: 'Moroto',
                    Code: 'MRT'
                },
                {
                    Name: 'Moyo',
                    Code: 'MOY'
                },
                {
                    Name: 'Mpigi',
                    Code: 'MPI'
                },
                {
                    Name: 'Mubende',
                    Code: 'MUB'
                },
                {
                    Name: 'Mukono',
                    Code: 'MUK'
                },
                {
                    Name: 'Nakapiripirit',
                    Code: 'NAK'
                },
                {
                    Name: 'Nakasongola',
                    Code: 'NKS'
                },
                {
                    Name: 'Nebbi',
                    Code: 'NEB'
                },
                {
                    Name: 'Ntungamo',
                    Code: 'NTU'
                },
                {
                    Name: 'Pader',
                    Code: 'PAD'
                },
                {
                    Name: 'Pallisa',
                    Code: 'PAL'
                },
                {
                    Name: 'Rakai',
                    Code: 'RAK'
                },
                {
                    Name: 'Rukungiri',
                    Code: 'RUK'
                },
                {
                    Name: 'Sembabule',
                    Code: 'SEM'
                },
                {
                    Name: 'Sironko',
                    Code: 'SIR'
                },
                {
                    Name: 'Soroti',
                    Code: 'SOR'
                },
                {
                    Name: 'Tororo',
                    Code: 'TOR'
                },
                {
                    Name: 'Wakiso',
                    Code: 'WAK'
                },
                {
                    Name: 'Yumbe',
                    Code: 'YUM'
                }
                ]
            }
        },
        {
            Name: 'Ukraine',
            Code: 'UKR',
            State: {
                City: [{
                    Name: 'Cherkasy',
                    Code: '71'
                },
                {
                    Name: 'Chernihiv',
                    Code: '74'
                },
                {
                    Name: 'Chernivtsi',
                    Code: '77'
                },
                {
                    Name: 'Dnipropetrovsk',
                    Code: '12'
                },
                {
                    Name: 'Donetsk',
                    Code: '14'
                },
                {
                    Name: 'Ivano-Frankivsk',
                    Code: '26'
                },
                {
                    Name: 'Kharkiv',
                    Code: '63'
                },
                {
                    Name: 'Khersonsrka',
                    Code: '65'
                },
                {
                    Name: 'Khmelnytsky',
                    Code: '68'
                },
                {
                    Name: 'Kirovohrad',
                    Code: '35'
                },
                {
                    Name: 'Kyiv',
                    Code: '30'
                },
                {
                    Name: 'Luhansk',
                    Code: '9'
                },
                {
                    Name: 'Lviv',
                    Code: '46'
                },
                {
                    Name: 'Mykolayiv',
                    Code: '48'
                },
                {
                    Name: 'Odessa',
                    Code: '51'
                },
                {
                    Name: 'Poltava',
                    Code: '53'
                },
                {
                    Name: 'Respublika Krym',
                    Code: '43'
                },
                {
                    Name: 'Rivne',
                    Code: '56'
                },
                {
                    Name: 'Sumy',
                    Code: '59'
                },
                {
                    Name: 'Ternopil',
                    Code: '61'
                },
                {
                    Name: 'Vinnytsya',
                    Code: '5'
                },
                {
                    Name: 'Volyn',
                    Code: '7'
                },
                {
                    Name: 'Zakarpatska',
                    Code: '21'
                },
                {
                    Name: 'Zaporizhzhya',
                    Code: '23'
                },
                {
                    Name: 'Zhytomyr',
                    Code: '18'
                }
                ]
            }
        },
        {
            Name: 'United Arab Emirates',
            Code: 'ARE',
            State: {
                City: [{
                    Name: 'Abu Dhabi',
                    Code: 'AZ'
                },
                {
                    Name: "Al l'Ayn",
                    Code: 'AL'
                },
                {
                    Name: 'Ash Shariqah',
                    Code: 'SH'
                },
                {
                    Name: 'Dubai',
                    Code: 'DU'
                }
                ]
            }
        },
        {
            Name: 'United Kingdom',
            Code: 'GBR',
            State: [{
                Name: 'England',
                Code: 'ENG',
                City: [{
                    Name: 'Bath',
                    Code: 'BAS'
                },
                {
                    Name: 'Birmingham',
                    Code: 'BIR'
                },
                {
                    Name: 'Bradford',
                    Code: 'BRD'
                },
                {
                    Name: 'Brighton & Hove',
                    Code: 'BNH'
                },
                {
                    Name: 'Bristol',
                    Code: 'BST'
                },
                {
                    Name: 'Cambridge',
                    Code: 'CAM'
                },
                {
                    Name: 'Canterbury',
                    Code: 'CNG'
                },
                {
                    Name: 'Carlisle',
                    Code: 'CAX'
                },
                {
                    Name: 'Chester',
                    Code: 'CEG'
                },
                {
                    Name: 'Chichester',
                    Code: 'CST'
                },
                {
                    Name: 'Coventry',
                    Code: 'COV'
                },
                {
                    Name: 'Derby',
                    Code: 'DER'
                },
                {
                    Name: 'Durham',
                    Code: 'DUR'
                },
                {
                    Name: 'Ely',
                    Code: 'ELY'
                },
                {
                    Name: 'Exeter',
                    Code: 'EXE'
                },
                {
                    Name: 'Gloucester',
                    Code: 'GLO'
                },
                {
                    Name: 'Hereford',
                    Code: 'HAF'
                },
                {
                    Name: 'Kingston upon Hull',
                    Code: 'KUH'
                },
                {
                    Name: 'Lancaster',
                    Code: 'LAN'
                },
                {
                    Name: 'Leeds',
                    Code: 'LDS'
                },
                {
                    Name: 'Leicester',
                    Code: 'LCE'
                },
                {
                    Name: 'Lichfield',
                    Code: 'LHF'
                },
                {
                    Name: 'Lincoln',
                    Code: 'LCN'
                },
                {
                    Name: 'Liverpool',
                    Code: 'LIV'
                },
                {
                    Name: 'London',
                    Code: 'LND'
                },
                {
                    Name: 'Manchester',
                    Code: 'MAN'
                },
                {
                    Name: 'Newcastle',
                    Code: 'NCL'
                },
                {
                    Name: 'Norwich',
                    Code: 'NRW'
                },
                {
                    Name: 'Nottingham',
                    Code: 'NGM'
                },
                {
                    Name: 'Oxford',
                    Code: 'OXF'
                },
                {
                    Name: 'Peterborough',
                    Code: 'PTE'
                },
                {
                    Name: 'Plymouth',
                    Code: 'PLY'
                },
                {
                    Name: 'Portsmouth',
                    Code: 'POR'
                },
                {
                    Name: 'Preston',
                    Code: 'PRE'
                },
                {
                    Name: 'Ripon',
                    Code: 'RIP'
                },
                {
                    Name: 'Saint Albans',
                    Code: 'TBL'
                },
                {
                    Name: 'Salford',
                    Code: 'SLF'
                },
                {
                    Name: 'Salisbury',
                    Code: 'SLS'
                },
                {
                    Name: 'Sheffield',
                    Code: 'SHE'
                },
                {
                    Name: 'Southampton',
                    Code: 'STH'
                },
                {
                    Name: 'Stoke-on-Trent',
                    Code: 'SOT'
                },
                {
                    Name: 'Sunderland',
                    Code: 'SUN'
                },
                {
                    Name: 'Truro',
                    Code: 'TRU'
                },
                {
                    Name: 'Wakefield',
                    Code: 'WKF'
                },
                {
                    Name: 'Wells',
                    Code: 'WLS'
                },
                {
                    Name: 'Winchester',
                    Code: 'WNE'
                },
                {
                    Name: 'Wolverhampton',
                    Code: 'WOV'
                },
                {
                    Name: 'Worcester',
                    Code: 'WOR'
                },
                {
                    Name: 'York',
                    Code: 'YOR'
                }
                ]
            },
            {
                Name: 'Northern Ireland',
                Code: 'NIR',
                City: [{
                    Name: 'Belfast',
                    Code: 'BFS'
                },
                {
                    Name: 'Derry',
                    Code: 'DRY'
                },
                {
                    Name: 'Lisburn',
                    Code: 'LSB'
                },
                {
                    Name: 'Newry',
                    Code: 'NYM'
                }
                ]
            },
            {
                Name: 'Scotland',
                Code: 'SCT',
                City: [{
                    Name: 'Aberdeen',
                    Code: 'ABD'
                },
                {
                    Name: 'Dundee',
                    Code: 'DND'
                },
                {
                    Name: 'Edinburgh',
                    Code: 'EDH'
                },
                {
                    Name: 'Glasgow',
                    Code: 'GLG'
                },
                {
                    Name: 'Inverness',
                    Code: 'INV'
                },
                {
                    Name: 'Stirling',
                    Code: 'STG'
                }
                ]
            },
            {
                Name: 'Wales',
                Code: 'WLS',
                City: [{
                    Name: 'Bangor',
                    Code: 'BAN'
                },
                {
                    Name: 'Cardiff',
                    Code: 'CDF'
                },
                {
                    Name: 'Newport',
                    Code: 'NWP'
                },
                {
                    Name: 'Swansea',
                    Code: 'SWA'
                }
                ]
            }
            ]
        },
        {
            Name: 'United States',
            Code: 'USA',
            State: [{
                Name: 'Alabama',
                Code: 'AL',
                City: [{
                    Name: 'Birmingham',
                    Code: 'BHM'
                },
                {
                    Name: 'Mobile',
                    Code: 'MOB'
                },
                {
                    Name: 'Montgomery',
                    Code: 'MGM'
                }
                ]
            },
            {
                Name: 'Alaska',
                Code: 'AK',
                City: [{
                    Name: 'Anchorage',
                    Code: 'ANC'
                },
                {
                    Name: 'Fairbanks',
                    Code: 'FAI'
                },
                {
                    Name: 'Juneau',
                    Code: 'JNU'
                }
                ]
            },
            {
                Name: 'Arizona',
                Code: 'AZ',
                City: [{
                    Name: 'Glendale',
                    Code: 'GDA'
                },
                {
                    Name: 'Mesa',
                    Code: 'MQA'
                },
                {
                    Name: 'Phoenix',
                    Code: 'PHX'
                },
                {
                    Name: 'Scottsdale',
                    Code: 'STZ'
                },
                {
                    Name: 'Tempe',
                    Code: 'TPE'
                },
                {
                    Name: 'Tucson',
                    Code: 'TUC'
                },
                {
                    Name: 'Yuma',
                    Code: 'YUM'
                }
                ]
            },
            {
                Name: 'Arkansas',
                Code: 'AR',
                City: [{
                    Name: 'Fayetteville',
                    Code: 'FYV'
                },
                {
                    Name: 'Fort Smith',
                    Code: 'FSM'
                },
                {
                    Name: 'Little Rock',
                    Code: 'LIT'
                }
                ]
            },
            {
                Name: 'California',
                Code: 'CA',
                City: [{
                    Name: 'Los Angeles',
                    Code: 'LAX'
                },
                {
                    Name: 'San Diego',
                    Code: 'SAN'
                },
                {
                    Name: 'San Francisco',
                    Code: 'SFO'
                },
                {
                    Name: 'San Jose',
                    Code: 'SJC'
                }
                ]
            },
            {
                Name: 'Colorado',
                Code: 'CO',
                City: [{
                    Name: 'Aspen',
                    Code: 'ASE'
                },
                {
                    Name: 'Aurora',
                    Code: 'AUX'
                },
                {
                    Name: 'Boulder',
                    Code: 'WBU'
                },
                {
                    Name: 'Colorado Springs',
                    Code: 'COS'
                },
                {
                    Name: 'Denver',
                    Code: 'DEN'
                },
                {
                    Name: 'Fort Collins',
                    Code: 'FNL'
                },
                {
                    Name: 'Grand Junction',
                    Code: 'GJT'
                },
                {
                    Name: 'Vail',
                    Code: 'VAC'
                }
                ]
            },
            {
                Name: 'Connecticut',
                Code: 'CT',
                City: [{
                    Name: 'Bridgeport',
                    Code: 'BDR'
                },
                {
                    Name: 'Darien',
                    Code: 'DAQ'
                },
                {
                    Name: 'Greenwich',
                    Code: 'GRH'
                },
                {
                    Name: 'Hartford',
                    Code: 'HFD'
                },
                {
                    Name: 'Middletown',
                    Code: 'XIN'
                },
                {
                    Name: 'New Britain',
                    Code: 'NWT'
                },
                {
                    Name: 'New Haven',
                    Code: 'HVN'
                },
                {
                    Name: 'Waterbury',
                    Code: 'WAT'
                },
                {
                    Name: 'Westport',
                    Code: 'WPT'
                }
                ]
            },
            {
                Name: 'Delaware',
                Code: 'DE',
                City: [{
                    Name: 'Dover',
                    Code: 'DOR'
                },
                {
                    Name: 'Newark',
                    Code: 'NWK'
                },
                {
                    Name: 'Wilmington',
                    Code: 'ILG'
                }
                ]
            },
            {
                Name: 'District of Columbia',
                Code: 'DC',
                City: {
                    Name: 'Washington D.C.',
                    Code: 'WAS'
                }
            },
            {
                Name: 'Florida',
                Code: 'FL',
                City: [{
                    Name: 'Cape Canaveral',
                    Code: 'CPV'
                },
                {
                    Name: 'Fort Lauderdale',
                    Code: 'FLL'
                },
                {
                    Name: 'Jacksonville',
                    Code: 'JAX'
                },
                {
                    Name: 'Key West',
                    Code: 'EYW'
                },
                {
                    Name: 'Miami',
                    Code: 'MIA'
                },
                {
                    Name: 'Orlando',
                    Code: 'ORL'
                },
                {
                    Name: 'St. Petersburg',
                    Code: 'PIE'
                },
                {
                    Name: 'Tallahassee',
                    Code: 'TLH'
                },
                {
                    Name: 'Tampa',
                    Code: 'TPA'
                }
                ]
            },
            {
                Name: 'Georgia',
                Code: 'GA',
                City: [{
                    Name: 'Atlanta',
                    Code: 'TAT'
                },
                {
                    Name: 'Augusta',
                    Code: 'AUT'
                },
                {
                    Name: 'Columbus',
                    Code: 'CZX'
                },
                {
                    Name: 'Macon',
                    Code: 'MCN'
                },
                {
                    Name: 'Savannah',
                    Code: 'SAV'
                }
                ]
            },
            {
                Name: 'Hawaii',
                Code: 'HI',
                City: [{
                    Name: 'Hilo',
                    Code: 'ITO'
                },
                {
                    Name: 'Honolulu',
                    Code: 'HNL'
                },
                {
                    Name: 'Kailua',
                    Code: 'KHH'
                }
                ]
            },
            {
                Name: 'Idaho',
                Code: 'ID',
                City: [{
                    Name: 'American Falls',
                    Code: 'YAF'
                },
                {
                    Name: 'Blackfoot',
                    Code: 'BLK'
                },
                {
                    Name: 'Boise',
                    Code: 'BOI'
                },
                {
                    Name: "Coeur d'Alene",
                    Code: 'COE'
                },
                {
                    Name: 'Idaho Falls',
                    Code: 'IDA'
                },
                {
                    Name: 'Ketchum',
                    Code: 'QKM'
                },
                {
                    Name: 'Lewiston',
                    Code: 'LWS'
                },
                {
                    Name: 'Moscow',
                    Code: 'MJL'
                },
                {
                    Name: 'Murphy',
                    Code: 'ZMU'
                },
                {
                    Name: 'Nampa',
                    Code: 'NPA'
                },
                {
                    Name: 'Pocatello',
                    Code: 'PIH'
                },
                {
                    Name: 'Sun Valley',
                    Code: 'SVY'
                }
                ]
            },
            {
                Name: 'Illinois',
                Code: 'IL',
                City: [{
                    Name: 'Alton',
                    Code: 'ALN'
                },
                {
                    Name: 'Aurora',
                    Code: 'AUZ'
                },
                {
                    Name: 'Bloomington',
                    Code: 'BLO'
                },
                {
                    Name: 'Carbondale',
                    Code: 'MDH'
                },
                {
                    Name: 'Centralia',
                    Code: 'CRA'
                },
                {
                    Name: 'Champaign-Urbana',
                    Code: 'CMI'
                },
                {
                    Name: 'Chicago',
                    Code: 'CHI'
                },
                {
                    Name: 'Danville',
                    Code: 'DVI'
                },
                {
                    Name: 'De Kalb',
                    Code: 'DEK'
                },
                {
                    Name: 'Decatur',
                    Code: 'DEC'
                },
                {
                    Name: 'East St Louis',
                    Code: 'ESL'
                },
                {
                    Name: 'Galesburg',
                    Code: 'GSU'
                },
                {
                    Name: 'Normal',
                    Code: 'NOM'
                },
                {
                    Name: 'Peoria',
                    Code: 'PLA'
                },
                {
                    Name: 'Rock Island',
                    Code: 'RKI'
                },
                {
                    Name: 'Rockford',
                    Code: 'RFD'
                },
                {
                    Name: 'Springfield',
                    Code: 'SPI'
                },
                {
                    Name: 'Waukegan',
                    Code: 'UGN'
                }
                ]
            },
            {
                Name: 'Indiana',
                Code: 'IN',
                City: [{
                    Name: 'Evansville',
                    Code: 'EVV'
                },
                {
                    Name: 'Fort Wayne',
                    Code: 'FWA'
                },
                {
                    Name: 'Indianapolis',
                    Code: 'IND'
                }
                ]
            },
            {
                Name: 'Iowa',
                Code: 'IA',
                City: [{
                    Name: 'Cedar Rapids',
                    Code: 'CID'
                },
                {
                    Name: 'Davenport',
                    Code: 'DVN'
                },
                {
                    Name: 'Des Moines',
                    Code: 'DSM'
                }
                ]
            },
            {
                Name: 'Kansas',
                Code: 'KS',
                City: [{
                    Name: 'Abilene',
                    Code: 'ABZ'
                },
                {
                    Name: 'Hutchinson',
                    Code: 'HCH'
                },
                {
                    Name: 'Kansas City',
                    Code: 'KCK'
                },
                {
                    Name: 'Lawrence',
                    Code: 'LWC'
                },
                {
                    Name: 'Leavenworth',
                    Code: 'XIA'
                },
                {
                    Name: 'Manhattan',
                    Code: 'MHK'
                },
                {
                    Name: 'Overland Park',
                    Code: 'OVL'
                },
                {
                    Name: 'Topeka',
                    Code: 'TOP'
                },
                {
                    Name: 'Wichita',
                    Code: 'ICT'
                }
                ]
            },
            {
                Name: 'Kentucky',
                Code: 'KY',
                City: [{
                    Name: 'Lexington',
                    Code: 'LEX'
                },
                {
                    Name: 'Louisville',
                    Code: 'LUI'
                },
                {
                    Name: 'Owensboro',
                    Code: 'OWB'
                }
                ]
            },
            {
                Name: 'Louisiana',
                Code: 'LA',
                City: [{
                    Name: 'Baton Rouge',
                    Code: 'BTR'
                },
                {
                    Name: 'New Orleans',
                    Code: 'MSY'
                },
                {
                    Name: 'Shreveport',
                    Code: 'SHV'
                }
                ]
            },
            {
                Name: 'Maine',
                Code: 'ME',
                City: [{
                    Name: 'Bangor',
                    Code: 'BNQ'
                },
                {
                    Name: 'Lewiston',
                    Code: 'QLW'
                },
                {
                    Name: 'Portland',
                    Code: 'POL'
                }
                ]
            },
            {
                Name: 'Maryland',
                Code: 'MD',
                City: [{
                    Name: 'Balitmore',
                    Code: 'BAL'
                },
                {
                    Name: 'Gaithersburg',
                    Code: 'GAI'
                },
                {
                    Name: 'Rockville',
                    Code: 'RKV'
                }
                ]
            },
            {
                Name: 'Massachusetts',
                Code: 'MA',
                City: [{
                    Name: 'Boston',
                    Code: 'BZD'
                },
                {
                    Name: 'Springfield',
                    Code: 'SFY'
                },
                {
                    Name: 'Worcester',
                    Code: 'ORH'
                }
                ]
            },
            {
                Name: 'Michigan',
                Code: 'MI',
                City: [{
                    Name: 'Ann Arbor',
                    Code: 'ARB'
                },
                {
                    Name: 'Battle Creek',
                    Code: 'BTL'
                },
                {
                    Name: 'Bay City',
                    Code: 'BCY'
                },
                {
                    Name: 'Dearborn',
                    Code: 'DEO'
                },
                {
                    Name: 'Detroit',
                    Code: 'DET'
                },
                {
                    Name: 'Flint',
                    Code: 'FNT'
                },
                {
                    Name: 'Grand Rapids',
                    Code: 'GRR'
                },
                {
                    Name: 'Kalamazoo',
                    Code: 'AZO'
                },
                {
                    Name: 'Lansing',
                    Code: 'LAN'
                },
                {
                    Name: 'Muskegon',
                    Code: 'MKG'
                },
                {
                    Name: 'Pontiac',
                    Code: 'PTK'
                },
                {
                    Name: 'Port Huron',
                    Code: 'PHN'
                },
                {
                    Name: 'Saginaw',
                    Code: 'SGM'
                },
                {
                    Name: 'Sault Ste Marie',
                    Code: 'SSM'
                },
                {
                    Name: 'Warren',
                    Code: 'WAM'
                },
                {
                    Name: 'Wyandotte',
                    Code: 'WYD'
                }
                ]
            },
            {
                Name: 'Minnesota',
                Code: 'MN',
                City: [{
                    Name: 'Minneapolis',
                    Code: 'MES'
                },
                {
                    Name: 'Rochester',
                    Code: 'RST'
                },
                {
                    Name: 'Saint Paul',
                    Code: 'STP'
                }
                ]
            },
            {
                Name: 'Mississippi',
                Code: 'MS',
                City: [{
                    Name: 'Biloxi',
                    Code: 'BIX'
                },
                {
                    Name: 'Greenville',
                    Code: 'GLH'
                },
                {
                    Name: 'Gulfport',
                    Code: 'GPT'
                },
                {
                    Name: 'Hattiesburg',
                    Code: 'HBG'
                },
                {
                    Name: 'Jackson',
                    Code: 'JAN'
                },
                {
                    Name: 'Meridian',
                    Code: 'MEI'
                },
                {
                    Name: 'Vicksburg',
                    Code: 'VKS'
                }
                ]
            },
            {
                Name: 'Missouri',
                Code: 'MO',
                City: [{
                    Name: 'Columbia',
                    Code: 'COV'
                },
                {
                    Name: 'Jefferson City',
                    Code: 'JEF'
                },
                {
                    Name: 'Kansas City',
                    Code: 'MKC'
                },
                {
                    Name: 'Sanit Louis',
                    Code: 'STL'
                },
                {
                    Name: 'Springfield',
                    Code: 'SGF'
                }
                ]
            },
            {
                Name: 'Montana',
                Code: 'MT',
                City: [{
                    Name: 'Billings',
                    Code: 'BGS'
                },
                {
                    Name: 'Great Falls',
                    Code: 'GTF'
                },
                {
                    Name: 'Missoula',
                    Code: 'MSO'
                }
                ]
            },
            {
                Name: 'Nebraska',
                Code: 'NE',
                City: [{
                    Name: 'Bellevue',
                    Code: 'XDE'
                },
                {
                    Name: 'Lincoln',
                    Code: 'LNK'
                },
                {
                    Name: 'Omaha',
                    Code: 'OMA'
                }
                ]
            },
            {
                Name: 'Nevada',
                Code: 'NV',
                City: [{
                    Name: 'Carson City',
                    Code: 'CSN'
                },
                {
                    Name: 'Elko',
                    Code: 'EKO'
                },
                {
                    Name: 'Henderson',
                    Code: 'HNZ'
                },
                {
                    Name: 'Las Vegas',
                    Code: 'LAS'
                },
                {
                    Name: 'North Las Vegas',
                    Code: 'NVS'
                },
                {
                    Name: 'Reno',
                    Code: 'RNO'
                },
                {
                    Name: 'Sparks',
                    Code: 'SPK'
                },
                {
                    Name: 'Virginia City',
                    Code: 'VGI'
                }
                ]
            },
            {
                Name: 'New Hampshire',
                Code: 'NH',
                City: [{
                    Name: 'Concord',
                    Code: 'CON'
                },
                {
                    Name: 'Manchester',
                    Code: 'MHT'
                },
                {
                    Name: 'Nashua',
                    Code: 'ASH'
                }
                ]
            },
            {
                Name: 'New Jersey',
                Code: 'NJ',
                City: [{
                    Name: 'Jersey City',
                    Code: 'JEC'
                },
                {
                    Name: 'Newark',
                    Code: 'NRK'
                },
                {
                    Name: 'Paterson',
                    Code: 'PAT'
                }
                ]
            },
            {
                Name: 'New Mexico',
                Code: 'NM',
                City: [{
                    Name: 'Albuquerque',
                    Code: 'ABQ'
                },
                {
                    Name: 'Las Cruces',
                    Code: 'LRU'
                },
                {
                    Name: 'Roswell',
                    Code: 'ROW'
                },
                {
                    Name: 'Santa Fe',
                    Code: 'SAF'
                }
                ]
            },
            {
                Name: 'New York',
                Code: 'NY',
                City: [{
                    Name: 'Buffalo',
                    Code: 'FFO'
                },
                {
                    Name: 'New York',
                    Code: 'QEE'
                },
                {
                    Name: 'Rochester',
                    Code: 'ROC'
                }
                ]
            },
            {
                Name: 'North Carolina',
                Code: 'NC',
                City: [{
                    Name: 'Asheville',
                    Code: 'AEV'
                },
                {
                    Name: 'Chapel Hill',
                    Code: 'CHE'
                },
                {
                    Name: 'Charlotte',
                    Code: 'CRQ'
                },
                {
                    Name: 'Durham',
                    Code: 'DHH'
                },
                {
                    Name: 'Greensboro',
                    Code: 'GBO'
                },
                {
                    Name: 'Raleigh',
                    Code: 'RAG'
                },
                {
                    Name: 'Raleigh-Durham',
                    Code: 'RDU'
                }
                ]
            },
            {
                Name: 'North Dakota',
                Code: 'ND',
                City: [{
                    Name: 'Bismarck',
                    Code: 'BIS'
                },
                {
                    Name: 'Fargo',
                    Code: 'FAR'
                },
                {
                    Name: 'Grand Forks',
                    Code: 'GFK'
                },
                {
                    Name: 'Minot',
                    Code: 'MOT'
                }
                ]
            },
            {
                Name: 'Ohio',
                Code: 'OH',
                City: [{
                    Name: 'Cincinnati',
                    Code: 'CVG'
                },
                {
                    Name: 'Cleveland',
                    Code: 'CLE'
                },
                {
                    Name: 'Columbus',
                    Code: 'CZX'
                },
                {
                    Name: 'Dayton',
                    Code: 'DYT'
                },
                {
                    Name: 'Toledo',
                    Code: 'TOL'
                }
                ]
            },
            {
                Name: 'Oklahoma',
                Code: 'OK',
                City: [{
                    Name: 'Norman',
                    Code: 'OUN'
                },
                {
                    Name: 'Oklahoma City',
                    Code: 'OKC'
                },
                {
                    Name: 'Tulsa',
                    Code: 'TUL'
                }
                ]
            },
            {
                Name: 'Oregon',
                Code: 'OR',
                City: [{
                    Name: 'Bend',
                    Code: 'BZO'
                },
                {
                    Name: 'Coos Bay',
                    Code: 'COB'
                },
                {
                    Name: 'Corvallis',
                    Code: 'YCV'
                },
                {
                    Name: 'Crater Lake',
                    Code: 'CTR'
                },
                {
                    Name: 'Dallas',
                    Code: 'DAC'
                },
                {
                    Name: 'Eugene',
                    Code: 'EUG'
                },
                {
                    Name: "Grant's Pass",
                    Code: 'XFX'
                },
                {
                    Name: 'Hood River',
                    Code: 'HDX'
                },
                {
                    Name: 'Medford',
                    Code: 'MFR'
                },
                {
                    Name: 'Portland',
                    Code: 'PDX'
                },
                {
                    Name: 'Salem',
                    Code: 'SLE'
                },
                {
                    Name: 'Springfield',
                    Code: 'SPY'
                },
                {
                    Name: 'St Helens',
                    Code: 'STH'
                },
                {
                    Name: 'The Dalles',
                    Code: 'DLS'
                },
                {
                    Name: 'Tillamook',
                    Code: 'TLM'
                }
                ]
            },
            {
                Name: 'Pennsylvania',
                Code: 'PA',
                City: [{
                    Name: 'Allentown',
                    Code: 'AEW'
                },
                {
                    Name: 'Philadephia',
                    Code: 'PHL'
                },
                {
                    Name: 'Pittsburgh',
                    Code: 'PIT'
                }
                ]
            },
            {
                Name: 'Rhode Island',
                Code: 'RI',
                City: [{
                    Name: 'Cranston',
                    Code: 'CQH'
                },
                {
                    Name: 'Newport',
                    Code: 'NPO'
                },
                {
                    Name: 'Pawtucket',
                    Code: 'PAW'
                },
                {
                    Name: 'Providence',
                    Code: 'PVD'
                },
                {
                    Name: 'Warwick',
                    Code: 'UZO'
                },
                {
                    Name: 'Westerly',
                    Code: 'WST'
                },
                {
                    Name: 'Woonsocket',
                    Code: 'SFN'
                }
                ]
            },
            {
                Name: 'South Carolina',
                Code: 'SC',
                City: [{
                    Name: 'Charleston',
                    Code: 'CHS'
                },
                {
                    Name: 'Columbia',
                    Code: 'COV'
                },
                {
                    Name: 'North Charleston',
                    Code: 'NTS'
                }
                ]
            },
            {
                Name: 'South Dakota',
                Code: 'SD',
                City: [{
                    Name: 'Aberdeen',
                    Code: 'ABK'
                },
                {
                    Name: 'Rapid City',
                    Code: 'RAP'
                },
                {
                    Name: 'Sioux Falls',
                    Code: 'FSD'
                }
                ]
            },
            {
                Name: 'Tennessee',
                Code: 'TN',
                City: [{
                    Name: 'Bristol',
                    Code: 'BSJ'
                },
                {
                    Name: 'Chattanooga',
                    Code: 'CHA'
                },
                {
                    Name: 'Johnson City',
                    Code: 'JCY'
                },
                {
                    Name: 'Kingsport',
                    Code: 'TRI'
                },
                {
                    Name: 'Knoxville',
                    Code: 'TYS'
                },
                {
                    Name: 'Memphis',
                    Code: 'MEM'
                },
                {
                    Name: 'Nashville',
                    Code: 'BNA'
                },
                {
                    Name: 'Smyrna',
                    Code: 'MQY'
                },
                {
                    Name: 'Spring Hill',
                    Code: 'RGI'
                },
                {
                    Name: 'Tri-City Area',
                    Code: 'YTC'
                }
                ]
            },
            {
                Name: 'Texas',
                Code: 'TX',
                City: [{
                    Name: 'Austin',
                    Code: 'AUS'
                },
                {
                    Name: 'Corpus Christi',
                    Code: 'CRP'
                },
                {
                    Name: 'Dallas',
                    Code: 'DAL'
                },
                {
                    Name: 'El Paso',
                    Code: 'ELP'
                },
                {
                    Name: 'Galveston',
                    Code: 'GLS'
                },
                {
                    Name: 'Houston',
                    Code: 'HOU'
                },
                {
                    Name: 'Laredo',
                    Code: 'LRD'
                },
                {
                    Name: 'McAllen',
                    Code: 'TXC'
                },
                {
                    Name: 'San Antonio',
                    Code: 'SAT'
                }
                ]
            },
            {
                Name: 'Utah',
                Code: 'UT',
                City: [{
                    Name: 'Layton',
                    Code: 'LTJ'
                },
                {
                    Name: 'Ogden',
                    Code: 'OGD'
                },
                {
                    Name: 'Orem',
                    Code: 'OEU'
                },
                {
                    Name: 'Park City',
                    Code: 'PAC'
                },
                {
                    Name: 'Provo',
                    Code: 'PVU'
                },
                {
                    Name: 'Salt Lake City',
                    Code: 'SLC'
                },
                {
                    Name: 'St.George',
                    Code: 'SGU'
                },
                {
                    Name: 'West Valley City',
                    Code: 'WVC'
                }
                ]
            },
            {
                Name: 'Vermont',
                Code: 'VT',
                City: [{
                    Name: 'Burlington',
                    Code: 'BTV'
                },
                {
                    Name: 'Rutland',
                    Code: 'RUT'
                },
                {
                    Name: 'South Burlington',
                    Code: 'ZBR'
                }
                ]
            },
            {
                Name: 'Virginia',
                Code: 'VA',
                City: [{
                    Name: 'Chesapeake',
                    Code: 'HTW'
                },
                {
                    Name: 'Norfolk',
                    Code: 'ORF'
                },
                {
                    Name: 'Virginia Beach',
                    Code: 'VAB'
                }
                ]
            },
            {
                Name: 'Washington',
                Code: 'WA',
                City: [{
                    Name: 'Seattle',
                    Code: 'SEA'
                },
                {
                    Name: 'Spokane',
                    Code: 'GEG'
                },
                {
                    Name: 'Tacoma',
                    Code: 'TTW'
                }
                ]
            },
            {
                Name: 'West Virginia',
                Code: 'WV',
                City: [{
                    Name: 'Charleston',
                    Code: 'CRW'
                },
                {
                    Name: 'Huntington',
                    Code: 'HNU'
                },
                {
                    Name: 'Parkersburg',
                    Code: 'PKB'
                }
                ]
            },
            {
                Name: 'Wisconsin',
                Code: 'WI',
                City: [{
                    Name: 'Appleton',
                    Code: 'ATW'
                },
                {
                    Name: 'Eau Claire',
                    Code: 'EAU'
                },
                {
                    Name: 'Green Bay',
                    Code: 'GBK'
                },
                {
                    Name: 'Kenosha',
                    Code: 'ENW'
                },
                {
                    Name: 'LaCrosse',
                    Code: 'LSE'
                },
                {
                    Name: 'Madison',
                    Code: 'QMD'
                },
                {
                    Name: 'Manitowoc',
                    Code: 'MTW'
                },
                {
                    Name: 'Milwaukee',
                    Code: 'MKE'
                },
                {
                    Name: 'Oshkosh',
                    Code: 'OSH'
                },
                {
                    Name: 'Racine',
                    Code: 'RAC'
                },
                {
                    Name: 'Sheboygan',
                    Code: 'SBM'
                },
                {
                    Name: 'Wausau',
                    Code: 'AUW'
                }
                ]
            },
            {
                Name: 'Wyoming',
                Code: 'WY',
                City: [{
                    Name: 'Casper',
                    Code: 'CPR'
                },
                {
                    Name: 'Cheyenne',
                    Code: 'CYS'
                },
                {
                    Name: 'Evanston',
                    Code: 'EVD'
                },
                {
                    Name: 'Laramie',
                    Code: 'LAR'
                },
                {
                    Name: 'Rock Springs',
                    Code: 'RKS'
                },
                {
                    Name: 'Sheridan',
                    Code: 'SHR'
                }
                ]
            }
            ]
        },
        {
            Name: 'United States Minor Outlying Islands',
            Code: 'UMI'
        },
        {
            Name: 'Uruguay',
            Code: 'URY',
            State: {
                City: [{
                    Name: 'Artigas',
                    Code: 'AR'
                },
                {
                    Name: 'Canelones',
                    Code: 'CA'
                },
                {
                    Name: 'Cerro Largo',
                    Code: 'CL'
                },
                {
                    Name: 'Colonia',
                    Code: 'CO'
                },
                {
                    Name: 'Durazno',
                    Code: 'DU'
                },
                {
                    Name: 'Flores',
                    Code: 'FS'
                },
                {
                    Name: 'Florida',
                    Code: 'FA'
                },
                {
                    Name: 'Lavalleja',
                    Code: 'LA'
                },
                {
                    Name: 'Maldonado',
                    Code: 'MA'
                },
                {
                    Name: 'Montevideo',
                    Code: 'MO'
                },
                {
                    Name: 'Paysandu',
                    Code: 'PA'
                },
                {
                    Name: 'Rio Negro',
                    Code: 'RN'
                },
                {
                    Name: 'Rivera',
                    Code: 'RV'
                },
                {
                    Name: 'Rocha',
                    Code: 'RO'
                },
                {
                    Name: 'Salto',
                    Code: 'SL'
                },
                {
                    Name: 'San Jose',
                    Code: 'SJ'
                },
                {
                    Name: 'Soriano',
                    Code: 'SO'
                },
                {
                    Name: 'Tacuarembo',
                    Code: 'TAW'
                },
                {
                    Name: 'Treinta y Tres',
                    Code: 'TT'
                }
                ]
            }
        },
        {
            Name: 'Uzbekistan',
            Code: 'UZB',
            State: {
                City: [{
                    Name: 'Andijon',
                    Code: 'AN'
                },
                {
                    Name: 'Buxoro',
                    Code: 'BU'
                },
                {
                    Name: 'Fargona',
                    Code: 'FA'
                },
                {
                    Name: 'Jizzax',
                    Code: 'JI'
                },
                {
                    Name: 'Namangan',
                    Code: 'NG'
                },
                {
                    Name: 'Navoiy',
                    Code: 'NW'
                },
                {
                    Name: 'Qasqadaryo',
                    Code: 'QA'
                },
                {
                    Name: 'Qoraqalpogiston',
                    Code: 'QR'
                },
                {
                    Name: 'Samarqand',
                    Code: 'SA'
                },
                {
                    Name: 'Sirdaryo',
                    Code: 'SI'
                },
                {
                    Name: 'Surxondaryo',
                    Code: 'SU'
                },
                {
                    Name: 'Toshkent',
                    Code: 'TK'
                },
                {
                    Name: 'Toshkent Shahri',
                    Code: 'TO'
                },
                {
                    Name: 'Xorazm',
                    Code: 'XO'
                }
                ]
            }
        },
        {
            Name: 'Vanuatu',
            Code: 'VUT',
            State: {
                City: [{
                    Name: 'Malampa',
                    Code: 'MA'
                },
                {
                    Name: 'Penama',
                    Code: 'PE'
                },
                {
                    Name: 'Sanma',
                    Code: 'SA'
                },
                {
                    Name: 'Shefa',
                    Code: 'SH'
                },
                {
                    Name: 'Tafea',
                    Code: 'TA'
                },
                {
                    Name: 'Torba',
                    Code: 'TO'
                }
                ]
            }
        },
        {
            Name: 'Vatican City',
            Code: 'VAT'
        },
        {
            Name: 'Venezuela',
            Code: 'VEN',
            State: {
                City: [{
                    Name: 'Amazonas',
                    Code: 'Z'
                },
                {
                    Name: 'Anzoategui',
                    Code: 'B'
                },
                {
                    Name: 'Apure',
                    Code: 'C'
                },
                {
                    Name: 'Aragua',
                    Code: 'D'
                },
                {
                    Name: 'Barinas',
                    Code: 'E'
                },
                {
                    Name: 'Bolivar',
                    Code: 'F'
                },
                {
                    Name: 'Carabobo',
                    Code: 'G'
                },
                {
                    Name: 'Caracas',
                    Code: 'A'
                },
                {
                    Name: 'Cojedes',
                    Code: 'H'
                },
                {
                    Name: 'Delta Amacuro',
                    Code: 'Y'
                },
                {
                    Name: 'Dependencias Federales',
                    Code: 'W'
                },
                {
                    Name: 'Estado Nueva Esparta',
                    Code: 'O'
                },
                {
                    Name: 'Falcon',
                    Code: 'I'
                },
                {
                    Name: 'Guarico',
                    Code: 'J'
                },
                {
                    Name: 'Lara',
                    Code: 'K'
                },
                {
                    Name: 'Merida',
                    Code: 'L'
                },
                {
                    Name: 'Miranda',
                    Code: 'M'
                },
                {
                    Name: 'Monagas',
                    Code: 'N'
                },
                {
                    Name: 'Portuguesa',
                    Code: 'P'
                },
                {
                    Name: 'Sucre',
                    Code: 'R'
                },
                {
                    Name: 'Tachira',
                    Code: 'S'
                },
                {
                    Name: 'Trujillo',
                    Code: 'T'
                },
                {
                    Name: 'Yaracuy',
                    Code: 'U'
                },
                {
                    Name: 'Zulia',
                    Code: 'V'
                }
                ]
            }
        },
        {
            Name: 'Vietnam',
            Code: 'VNM',
            State: {
                City: [{
                    Name: 'Haiphong',
                    Code: 'HP'
                },
                {
                    Name: 'Hanoi',
                    Code: 'HI'
                },
                {
                    Name: 'Ho Chi Minh City',
                    Code: 'HC'
                }
                ]
            }
        },
        {
            Name: 'Virgin Islands',
            Code: 'VIR'
        },
        {
            Name: 'Virgin Islands,British',
            Code: 'VGB'
        },
        {
            Name: 'Wallis and Futuna',
            Code: 'WLF'
        },
        {
            Name: 'White Russia',
            Code: 'BLR',
            State: {
                City: [{
                    Name: 'Bresckaja',
                    Code: 'BR'
                },
                {
                    Name: 'Homelskaja',
                    Code: 'HO'
                },
                {
                    Name: 'Hrodzenskaja',
                    Code: 'HR'
                },
                {
                    Name: 'Mahileuskaja',
                    Code: 'MA'
                },
                {
                    Name: 'Minsk',
                    Code: 'MI'
                },
                {
                    Name: 'Vicebskaja',
                    Code: 'VI'
                }
                ]
            }
        },
        {
            Name: 'Yemen',
            Code: 'YEM',
            State: {
                City: [{
                    Name: 'Abyan',
                    Code: 'AB'
                },
                {
                    Name: 'Adan',
                    Code: 'AD'
                },
                {
                    Name: 'Ad-Dali',
                    Code: 'DA'
                },
                {
                    Name: 'Al-Bayda',
                    Code: 'BA'
                },
                {
                    Name: 'Al-Hudaydah',
                    Code: 'HU'
                },
                {
                    Name: 'Al-Jawf',
                    Code: 'JA'
                },
                {
                    Name: 'Al-Mahrah',
                    Code: 'MR'
                },
                {
                    Name: 'Al-Mahwit',
                    Code: 'MW'
                },
                {
                    Name: 'Amran Sana',
                    Code: 'AM'
                },
                {
                    Name: 'Ash-Shihr',
                    Code: 'ASR'
                },
                {
                    Name: 'Dhamar',
                    Code: 'DH'
                },
                {
                    Name: 'Hadramawt',
                    Code: 'HD'
                },
                {
                    Name: 'Hajjah',
                    Code: 'HJ'
                },
                {
                    Name: 'Ibb',
                    Code: 'IB'
                },
                {
                    Name: 'Lahij',
                    Code: 'LA'
                },
                {
                    Name: 'Marib',
                    Code: 'MA'
                },
                {
                    Name: 'Sadah',
                    Code: 'SD'
                },
                {
                    Name: 'Sana',
                    Code: 'SN'
                },
                {
                    Name: 'Seiyun',
                    Code: 'GXF'
                },
                {
                    Name: 'Shabwah',
                    Code: 'SH'
                },
                {
                    Name: 'Taizz',
                    Code: 'TA'
                }
                ]
            }
        },
        {
            Name: 'Zambia',
            Code: 'ZMB',
            State: {
                City: [{
                    Name: 'Central',
                    Code: 'CE'
                },
                {
                    Name: 'Copperbelt',
                    Code: 'CB'
                },
                {
                    Name: 'Eastern',
                    Code: 'EA'
                },
                {
                    Name: 'Luapula',
                    Code: 'LP'
                },
                {
                    Name: 'Lusaka',
                    Code: 'LK'
                },
                {
                    Name: 'Northern',
                    Code: 'NO'
                },
                {
                    Name: 'North-Western',
                    Code: 'NW'
                },
                {
                    Name: 'Southern',
                    Code: 'SO'
                },
                {
                    Name: 'Western',
                    Code: 'WE'
                }
                ]
            }
        },
        {
            Name: 'Zimbabwe',
            Code: 'ZWE',
            State: {
                City: [{
                    Name: 'Bulawayo',
                    Code: 'BU'
                },
                {
                    Name: 'Harare',
                    Code: 'HA'
                },
                {
                    Name: 'Manicaland',
                    Code: 'ML'
                },
                {
                    Name: 'Mashonaland Central',
                    Code: 'MC'
                },
                {
                    Name: 'Mashonaland East',
                    Code: 'ME'
                },
                {
                    Name: 'Mashonaland West',
                    Code: 'MW'
                },
                {
                    Name: 'Masvingo',
                    Code: 'MV'
                },
                {
                    Name: 'Matabeleland North',
                    Code: 'MN'
                },
                {
                    Name: 'Matabeleland South',
                    Code: 'MS'
                },
                {
                    Name: 'Midlands',
                    Code: 'MD'
                }
                ]
            }
        }
        ]
    }
};

const phoneCode = [
    { area: 'Anguilla', code: '1-264' },
    { area: 'Antigua and Barbuda', code: '1-268' },
    { area: 'Aruba', code: '297' },
    { area: 'Bahamas', code: '1-242' },
    { area: 'Barbados', code: '1-246' },
    { area: 'Belize', code: '501' },
    { area: 'Bermuda', code: '1-441' },
    { area: 'Cayman Islands', code: '1-345' },
    { area: 'Costa Rica', code: '506' },
    { area: 'Cuba', code: '53' },
    { area: '中国', code: '86' },
    { area: 'Dominica', code: '1-767' },
    { area: 'Dominican Republic', code: '1-829' },
    { area: 'Dominican Republic', code: '1809' },
    { area: 'Dominican Republic', code: '1849' },
    { area: 'El Salvador', code: '503' },
    { area: 'Greenland', code: '299' },
    { area: 'Grenada', code: '1-473' },
    { area: 'Guadeloupe', code: '590' },
    { area: 'Guatemala', code: '502' },
    { area: 'Haiti', code: '509' },
    { area: 'Honduras', code: '504' },
    { area: 'Jamaica', code: '1-876' },
    { area: 'Martinique', code: '596' },
    { area: 'Mexico', code: '52' },
    { area: 'Montserrat', code: '1-664' },
    { area: 'Netherlands Antilles', code: '599' },
    { area: 'Nicaragua', code: '505' },
    { area: 'Panama', code: '507' },
    { area: 'Puerto Rico', code: '1-787' },
    { area: 'Puerto Rico', code: '1939' },
    { area: 'St Kitts and Nevis', code: '1-869' },
    { area: 'St Lucia', code: '1-758' },
    { area: 'St Pierre and Miquelon', code: '508' },
    { area: 'St Vincent Grenadines', code: '1-784' },
    { area: 'Trinidad and Tobago', code: '1-868' },
    { area: 'Turks and Caicos Islands', code: '1-649' },
    { area: 'United States/Canada', code: '1' },
    { area: 'Virgin Islands, British', code: '1-284' },
    { area: 'Virgin Islands, U.S.', code: '1-340' },
    { area: 'Argentina', code: '54' },
    { area: 'Bolivia', code: '591' },
    { area: 'Brazil', code: '55' },
    { area: 'Chile', code: '56' },
    { area: 'Colombia', code: '57' },
    { area: 'Ecuador', code: '593' },
    { area: 'Falkland Islands', code: '500' },
    { area: 'French Guiana', code: '594' },
    { area: 'Guyana', code: '592' },
    { area: 'Paraguay', code: '595' },
    { area: 'Peru', code: '51' },
    { area: 'Suriname', code: '597' },
    { area: 'Uruguay', code: '598' },
    { area: 'Venezuela', code: '58' },
    { area: 'Albania', code: '355' },
    { area: 'Andorra', code: '376' },
    { area: 'Austria', code: '43' },
    { area: 'Belarus', code: '375' },
    { area: 'Belgium', code: '32' },
    { area: 'Bosnia and Herzegovina', code: '387' },
    { area: 'Bulgaria', code: '359' },
    { area: 'Croatia', code: '385' },
    { area: 'Cyprus', code: '357' },
    { area: 'Czech Republic', code: '420' },
    { area: 'Denmark', code: '45' },
    { area: 'Estonia', code: '372' },
    { area: 'Faroe Islands', code: '298' },
    { area: 'Finland/Aland Islands', code: '358' },
    { area: 'France', code: '33' },
    { area: 'Germany', code: '49' },
    { area: 'Gibraltar', code: '350' },
    { area: 'Greece', code: '30' },
    { area: 'Hungary', code: '36' },
    { area: 'Iceland', code: '354' },
    { area: 'Ireland', code: '353' },
    { area: 'Italy', code: '39' },
    { area: 'Kosovo', code: '383' },
    { area: 'Latvia', code: '371' },
    { area: 'Liechtenstein', code: '423' },
    { area: 'Lithuania', code: '370' },
    { area: 'Luxembourg', code: '352' },
    { area: 'Macedonia', code: '389' },
    { area: 'Malta', code: '356' },
    { area: 'Moldova', code: '373' },
    { area: 'Monaco', code: '377' },
    { area: 'Montenegro', code: '382' },
    { area: 'Netherlands', code: '31' },
    { area: 'Norway', code: '47' },
    { area: 'Poland', code: '48' },
    { area: 'Portugal', code: '351' },
    { area: 'Romania', code: '40' },
    { area: 'San Marino', code: '378' },
    { area: 'Serbia', code: '381' },
    { area: 'Slovakia', code: '421' },
    { area: 'Slovenia', code: '386' },
    { area: 'Spain', code: '34' },
    { area: 'Sweden', code: '46' },
    { area: 'Switzerland', code: '41' },
    { area: 'Turkish Republic of Northern Cyprus', code: '9-0392' },
    { area: 'Ukraine', code: '380' },
    { area: 'United Kingdom', code: '44' },
    { area: 'Vatican City', code: '379' },
    { area: 'Afghanistan', code: '93' },
    { area: 'Armenia', code: '374' },
    { area: 'Azerbaijan', code: '994' },
    { area: 'Bahrain', code: '973' },
    { area: 'Bangladesh', code: '880' },
    { area: 'Bhutan', code: '975' },
    { area: 'Brunei', code: '673' },
    { area: 'Cambodia', code: '855' },
    { area: 'East Timor', code: '670' },
    { area: 'Georgia', code: '995' },
    { area: 'Hong Kong', code: '852' },
    { area: 'India', code: '91' },
    { area: 'Indonesia', code: '62' },
    { area: 'Iran', code: '98' },
    { area: 'Iraq', code: '964' },
    { area: 'Israel', code: '972' },
    { area: 'Japan', code: '81' },
    { area: 'Jordan', code: '962' },
    { area: "Korea, Dem People's Rep", code: '850' },
    { area: 'Korea, Republic of', code: '82' },
    { area: 'Kuwait', code: '965' },
    { area: 'Kyrgyzstan', code: '996' },
    { area: 'Laos PDR', code: '856' },
    { area: 'Lebanon', code: '961' },
    { area: 'Macau', code: '853' },
    { area: 'Malaysia', code: '60' },
    { area: 'Maldives', code: '960' },
    { area: 'Mongolia', code: '976' },
    { area: 'Myanmar', code: '95' },
    { area: 'Nepal', code: '977' },
    { area: 'Oman', code: '968' },
    { area: 'Pakistan', code: '92' },
    { area: 'Palestinian Territory', code: '970' },
    { area: 'Philippines', code: '63' },
    { area: 'Qatar', code: '974' },
    { area: 'Russia/Kazakhstan', code: '7' },
    { area: 'Saudi Arabia', code: '966' },
    { area: 'Singapore', code: '65' },
    { area: 'Sri Lanka', code: '94' },
    { area: 'Syria', code: '963' },
    { area: 'Taiwan', code: '886' },
    { area: 'Tajikistan', code: '992' },
    { area: 'Thailand', code: '66' },
    { area: 'Turkey', code: '90' },
    { area: 'Turkmenistan', code: '993' },
    { area: 'United Arab Emirates', code: '971' },
    { area: 'Uzbekistan', code: '998' },
    { area: 'Vietnam', code: '84' },
    { area: 'Yemen', code: '967' },
    { area: 'Algeria', code: '213' },
    { area: 'Angola', code: '244' },
    { area: 'Benin', code: '229' },
    { area: 'Botswana', code: '267' },
    { area: 'Burkina Faso', code: '226' },
    { area: 'Burundi', code: '257' },
    { area: 'Cameroon', code: '237' },
    { area: 'Cape Verde', code: '238' },
    { area: 'Central Africa', code: '236' },
    { area: 'Chad', code: '235' },
    { area: 'Comoros', code: '269' },
    { area: 'Congo', code: '242' },
    { area: 'Congo, Dem Rep', code: '243' },
    { area: 'Djibouti', code: '253' },
    { area: 'Egypt', code: '20' },
    { area: 'Equatorial Guinea', code: '240' },
    { area: 'Eritrea', code: '291' },
    { area: 'Ethiopia', code: '251' },
    { area: 'Gabon', code: '241' },
    { area: 'Gambia', code: '220' },
    { area: 'Ghana', code: '233' },
    { area: 'Guinea', code: '224' },
    { area: 'Guinea-Bissau', code: '245' },
    { area: 'Ivory Coast', code: '225' },
    { area: 'Kenya', code: '254' },
    { area: 'Lesotho', code: '266' },
    { area: 'Liberia', code: '231' },
    { area: 'Libya', code: '218' },
    { area: 'Madagascar', code: '261' },
    { area: 'Malawi', code: '265' },
    { area: 'Mali', code: '223' },
    { area: 'Mauritania', code: '222' },
    { area: 'Mauritius', code: '230' },
    { area: 'Morocco', code: '212' },
    { area: 'Mozambique', code: '258' },
    { area: 'Namibia', code: '264' },
    { area: 'Niger', code: '227' },
    { area: 'Nigeria', code: '234' },
    { area: 'Reunion/Mayotte', code: '262' },
    { area: 'Rwanda', code: '250' },
    { area: 'Senegal', code: '221' },
    { area: 'Seychelles', code: '248' },
    { area: 'Sierra Leone', code: '232' },
    { area: 'Somalia', code: '252' },
    { area: 'South Africa', code: '27' },
    { area: 'South Sudan', code: '211' },
    { area: 'Sudan', code: '249' },
    { area: 'Swaziland', code: '268' },
    { area: 'Tanzania', code: '255' },
    { area: 'Togo', code: '228' },
    { area: 'Tunisia', code: '216' },
    { area: 'Uganda', code: '256' },
    { area: 'Zambia', code: '260' },
    { area: 'Zimbabwe', code: '263' },
    { area: 'American Samoa', code: '1-684' },
    { area: 'Australia/Cocos/Christmas Island', code: '61' },
    { area: 'Cook Islands', code: '682' },
    { area: 'Fiji', code: '679' },
    { area: 'French Polynesia', code: '689' },
    { area: 'Guam', code: '1-671' },
    { area: 'Marshall Islands', code: '692' },
    { area: 'Micronesia', code: '691' },
    { area: 'New Caledonia', code: '687' },
    { area: 'New Zealand', code: '64' },
    { area: 'Northern Mariana Islands', code: '1-670' },
    { area: 'Palau', code: '680' },
    { area: 'Papua New Guinea', code: '675' },
    { area: 'Samoa', code: '685' },
    { area: 'Solomon Islands', code: '677' },
    { area: 'Tonga', code: '676' },
    { area: 'Vanuatu', code: '678' }
];

const getPhoneCodeName = (code: string) => {
    let name = '';
    phoneCode.forEach((item) => {
        if (item.code === code) name = `${item.area}(+${item.code})`;
    });

    return name;
};

const getPhoneCodeNameTemp = (code: string) => {
    let name = '';
    phoneCode.forEach((item) => {
        if (item.code === code) name = `(+${item.code})${item.area}`;
    });

    return name;
};

export default country;
export {
    phoneCode,
    getPhoneCodeName,
    getPhoneCodeNameTemp
};
