import { community } from '@/data';
import GetRequest from '../basic';

const getPersonnelTempKeysInfo = (params: { ID: string }, callback: Function) => {
    GetRequest(`v3/web/${community.ProjectType.value}/tempKey/getInfo`, params, callback);
};

const getDoorDevice = (params: { Build: string }, callback: Function) => {
    GetRequest(`v3/web/${community.ProjectType.value}/device/getAllPubDoor`, params, callback);
};

const getDeliveryOrDeliveryDetail = (url: string, params: { UUID: string }, callback: Function) => {
    GetRequest(url, params, callback);
};

export default null;

export {
    getPersonnelTempKeysInfo,
    getDoorDevice,
    getDeliveryOrDeliveryDetail
};
