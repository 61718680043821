
import { defineComponent, defineEmit } from 'vue';

export default defineComponent({
    emits: ['vanish'],
    setup(props, { emit }) {
        function closed() {
            emit('vanish');
        }

        return {
            closed
        };
    }
});
