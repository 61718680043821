import moment from 'moment';

const format = (time: Date | string | null, type = 'YYYY-MM-DD HH:mm:ss') => {
    if (time === null) return '';
    if (typeof time === 'string') return time;
    return moment(time).format(type);
};

const getCustomizeForm = (customizeForm: string) => {
    let time1 = 1;
    let time2 = 2;
    if (Number(customizeForm) % 2 === 0) {
        time1 = 1;
    } else {
        time1 = 2;
    }
    time2 = Number(customizeForm) - time1;

    let formatTime = '';
    let formatDate = '';
    if (time1 === 1) {
        formatTime = 'hh:mm:ss a';
    } else {
        formatTime = 'HH:mm:ss';
    }

    if (time2 === 1) {
        formatDate = 'YYYY-MM-DD';
    } else if (time2 === 3) {
        formatDate = 'MM-DD-YYYY';
    } else {
        formatDate = 'DD-MM-YYYY';
    }
    return `${formatDate} ${formatTime}`;
};

const getCustomizeFormToDate = (customizeForm: string) => getCustomizeForm(customizeForm).split(' ')[0];

/**
 * @name 获取传入时间的后几个月时间
 * @param expireTime 计算时间，格式'2020-01-01 11:11:11'
 * @param count 月份
 */
const getNextMonthTime = (expireTime: string, count: number) => moment(expireTime).add(Number(count), 'M');

/**
 * @name 对比当前时间，决定下次过期时间
 * @param initExpireTime 初始过期时间
 * @param count 月份
 */
const getNextExpireTime = (initExpireTime: string, count: number) => {
    const nowTime = new Date();
    const expireTime = new Date(initExpireTime).getTime();
    // 对比当前时间和过期时间，已过期则采用当前时间计算
    const time = (nowTime.getTime() > expireTime || initExpireTime === '--') ? format(nowTime) : initExpireTime;
    return getNextMonthTime(time, count);
};

/**
 * @name 比较两个时间大小
 * @param startTime 第一个时间
 * @param endTime 第二个时间
 * @return boolean endTime > startTime: true
 */
const compareTime = (startTime: Date | string, endTime: Date | string) => {
    let resStartTime = startTime;
    let resEndTime = endTime;
    if (typeof resStartTime === 'string') {
        resStartTime = new Date(resStartTime);
    }
    if (typeof resEndTime === 'string') {
        resEndTime = new Date(resEndTime);
    }
    return resEndTime.getTime() > resStartTime.getTime();
};

export default null;
export {
    format,
    getCustomizeForm,
    getNextMonthTime,
    getNextExpireTime,
    compareTime,
    getCustomizeFormToDate
};