
import { defineComponent } from 'vue';

const __default__ = defineComponent({
    props: {
    // //页码信息
    // pageInfo:{
    //     type:Object,
    //     default:()=>({})
    // },
        total: {
            type: Number,
            default: 0
        },
        // 当前页码
        curPage: {
            type: [Number, String],
            default: 1
        },
        // 每页显示条目个数
        pageSize: {
            type: [Number, String],
            default: 10
        },
        layout: {
            type: String,
            default: 'sizes,prev, pager, next,jumper,total'
        }
    },

    emit: ['pageChange', 'update:cur-page', 'sizeChange', 'update:page-size'],
    setup(props, { emit }) {
        /**
         * current-change 改变时触发
         * @param {Number} page
         * @returns {void}
         */
        function currentChange(page: number) {
            emit('update:cur-page', page);
            emit('pageChange', page);
        }
        /**
       * size 改变时触发
       * @param {Number} size 条数
       * @returns {void}
       */
        function sizeChange(size: number) {
            emit('update:page-size', size);
            emit('sizeChange', size);
        }
        // css go 按钮文本
        const cssContent = {
            jumpCssContent: `"${WordList.ListInHtmlPaginationGo}"`,
            sizesContent: `"${WordList.ListInHtmlPaginationSelect}"`
        };

        return {
            currentChange,
            sizeChange,
            cssContent
        };
    }

});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1377517a": (_ctx.cssContent.jumpCssContent),
  "82862274": (_ctx.cssContent.sizesContent)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__