import { Ref } from 'vue';
/**
 * @name 检查邮箱有效性
 */
const checkEmail: RuleMethod = (rule, value, callback) => {
    if (value === '') return callback();
    const reg = /^[.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (!reg.test(value as string)) {
        return callback(new Error(WordList.RuleEmailInvalid));
    }
    return callback();
};

/**
 * @name 检查邮箱有效性(包括空)
 */
const checkNullOrEmail: RuleMethod = (rule, value, callback) => {
    const reg = /^[.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (!reg.test(value as string) && value) {
        return callback(new Error(WordList.RuleEmailInvalid));
    }
    return callback();
};

/**
 * @name 检查管理员账户有效性
 */
const checkManageAccount: RuleMethod = (rule, value, callback) => {
    const account = value as string;
    if (account.length > 15) {
        return callback(new Error(WordList.RuleCommonLess16));
    }
    return callback();
};

/**
 * @name 密码四种规则的满足情况
 */
const checkPasswordComplexity = (value: string) => {
    const regLow = /[a-z]/;
    const regUp = /[A-Z]/;
    const regNumber = /[0-9]/;
    const regSpecial = /[`~!@#$^&*()=|{}':;',[\\\].<>/?]/;
    return {
        ruleLow: regLow.test(value),
        ruleUp: regUp.test(value),
        ruleNumber: regNumber.test(value),
        ruleSpecial: regSpecial.test(value)
    };
};

const checkPassword: RuleMethod = (rule, value, callback) => {
    const password = value as string;
    const {
        ruleLow, ruleUp, ruleNumber, ruleSpecial
    } = checkPasswordComplexity(password);
    if (password.length < 8) {
        return callback(new Error(WordList.RulePasswordMore8));
    } if (password.length > 20) {
        return callback(new Error(WordList.RulePasswordLess20));
    } if (/\s/.test(password)) {
        return callback(new Error(WordList.RulePasswordSpace));
    }

    const complexityGrade = (ruleLow as unknown as number)
        + (ruleUp as unknown as number)
        + (ruleNumber as unknown as number)
        + (ruleSpecial as unknown as number);
    if (complexityGrade < 3) {
        return callback(new Error(WordList.RulePasswordThreeR));
    }

    return callback();
};

const checkConfirmPassword = (password: Ref<string>): RuleMethod => (rule, value, callback) => {
    const confirm = value as string;
    if (confirm !== password.value) {
        return callback(new Error(WordList.RulePasswordConfirm));
    }
    return callback();
};

/**
 * @name 检查地址长度
 */
const checkAddress: RuleMethod = (rule, value, callback) => {
    const address = value as string;
    if (address.length > 64) {
        return callback(new Error(WordList.RuleCommonLess64));
    }
    return callback();
};

/**
 * @name 检查家庭成员数
 */
const checkAmount: RuleMethod = (rule, value, callback) => {
    const amount = value as string;
    if (Number(amount) > 63) {
        return callback(new Error(WordList.FamilyMemberAmountLimit));
    }
    return callback();
};

const checkNum: RuleMethod = (rule, value, callback) => {
    const reg = /^\+?[0-9]*$/;
    if (!reg.test(value as string)) {
        return callback(new Error(WordList.RuleCommonNumber));
    }
    return callback();
};

const checkFloor: RuleMethod = (rule, value, callback) => {
    if (value !== '') {
        const floor = Number(value);
        if (Number.isNaN(floor) || floor < 1 || floor > 128) {
            return callback(new Error(WordList.FloorRule));
        }
    }
    return callback();
};

export default null;
export {
    checkEmail,
    checkManageAccount,
    checkPasswordComplexity,
    checkPassword,
    checkConfirmPassword,
    checkNullOrEmail,
    checkAddress,
    checkAmount,
    checkNum,
    checkFloor
};