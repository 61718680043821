import GetRequest from '../basic';

const getPMCommunityDetail = (callback: Function) => {
    const url = localStorage.getItem('Grade') === '23' ? 'v3/web/office/officeData/getDetailForPM' : 'v3/web/community/communityData/getDetailForPM';
    GetRequest(url, {}, callback);
};

const getProjectSettingCommunalFee = (callback: Function) => {
    GetRequest('v3/web/community/communalFee/getProjectSetting', {}, callback);
};

export default null;
export {
    getPMCommunityDetail,
    getProjectSettingCommunalFee
};