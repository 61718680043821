import { RouteRecordRaw } from 'vue-router';

const distributorBaseRouter = 'distributorIndex';
const distributorSubRouter = {
    communityDevice: 'communityDevice',
    communityDeviceDetail: 'communityDeviceDetail',
    personalDevice: 'personalDevice',
    personalDeviceDetail: 'personalDeviceDetail',
    thirdPartyDevice: 'thirdPartyDevice',
    user: 'user',
    mac: 'mac',
    userInfo: 'userInfo',
    officeInfo: 'officeInfo',
    firmware: 'firmware',
    library: 'library',
    subscription: 'subscription',
    payments: 'payments',
    paymentInfo: 'paymentInfo',
    customer: 'customer',
    preLoginIns: 'preLoginIns'
};

const index = () => import('@/views/distributor/index.vue');
const communityDevice = () => import('@/views/distributor/community-device/index.vue');
const communityDeviceDetail = () => import('@/views/distributor/community-device/detail.vue');
const personalDevice = () => import('@/views/distributor/personal-device/index.vue');
const personalDeviceDetail = () => import('@/views/distributor/personal-device/detail.vue');
const user = () => import('@/views/distributor/user/index.vue');
const mac = () => import('@/views/distributor/user/mac.vue');
const userInfo = () => import('@/views/distributor/user/info.vue');
const officeInfo = () => import('@/views/distributor/user/office-info.vue');
const firmware = () => import('@/views/distributor/firmware/index.vue');
const library = () => import('@/views/distributor/mac-library/index.vue');
const subscription = () => import('@/views/distributor/subscription/index.vue');
const payments = () => import('@/views/distributor/payments/index.vue');
const paymentInfo = () => import('@/views/distributor/payments/info.vue');
const customer = () => import('@/views/super/customer-service/index.vue');
const thirdPartyDevice = () => import('@/views/distributor/third-party-device/third-party-device.vue');
const preLoginIns = () => import('@/views/distributor/pre-login-ins/index.vue');

const routes: Array<RouteRecordRaw> = [
    {
        path: `/${distributorBaseRouter}`,
        component: index,
        name: distributorBaseRouter,
        redirect: `/${distributorBaseRouter}/${distributorSubRouter.communityDevice}`,
        children: [
            {
                path: distributorSubRouter.communityDevice,
                name: `${distributorBaseRouter}_${distributorSubRouter.communityDevice}`,
                component: communityDevice,
                props: (route) => ({ type: route.query.type })
            }, {
                path: distributorSubRouter.communityDeviceDetail,
                name: `${distributorBaseRouter}_${distributorSubRouter.communityDeviceDetail}`,
                component: communityDeviceDetail
            }, {
                path: distributorSubRouter.personalDevice,
                name: `${distributorBaseRouter}_${distributorSubRouter.personalDevice}`,
                component: personalDevice
            }, {
                path: distributorSubRouter.personalDeviceDetail,
                name: `${distributorBaseRouter}_${distributorSubRouter.personalDeviceDetail}`,
                component: personalDeviceDetail
            }, {
                path: distributorSubRouter.user,
                name: `${distributorBaseRouter}_${distributorSubRouter.user}`,
                component: user,
                props: (route) => ({ type: route.query.type })
            }, {
                path: distributorSubRouter.mac,
                name: `${distributorBaseRouter}_${distributorSubRouter.mac}`,
                component: mac,
                props: (route) => ({ id: route.query.id })
            }, {
                path: distributorSubRouter.userInfo,
                name: `${distributorBaseRouter}_${distributorSubRouter.userInfo}`,
                component: userInfo,
                props: (route) => ({ id: route.query.id, role: route.query.role })
            }, {
                path: distributorSubRouter.officeInfo,
                name: `${distributorBaseRouter}_${distributorSubRouter.officeInfo}`,
                component: officeInfo,
                props: (route) => ({ id: route.query.id })
            }, {
                path: distributorSubRouter.firmware,
                name: `${distributorBaseRouter}_${distributorSubRouter.firmware}`,
                component: firmware
            }, {
                path: distributorSubRouter.library,
                name: `${distributorBaseRouter}_${distributorSubRouter.library}`,
                component: library
            }, {
                path: distributorSubRouter.subscription,
                name: `${distributorBaseRouter}_${distributorSubRouter.subscription}`,
                component: subscription
            }, {
                path: distributorSubRouter.payments,
                name: `${distributorBaseRouter}_${distributorSubRouter.payments}`,
                component: payments
            }, {
                path: distributorSubRouter.paymentInfo,
                name: `${distributorBaseRouter}_${distributorSubRouter.paymentInfo}`,
                component: paymentInfo,
                props: (route) => ({ id: route.query.id })
            }, {
                path: distributorSubRouter.customer,
                name: `${distributorBaseRouter}_${distributorSubRouter.customer}`,
                component: customer
            }, {
                path: distributorSubRouter.thirdPartyDevice,
                name: `${distributorBaseRouter}_${distributorSubRouter.thirdPartyDevice}`,
                component: thirdPartyDevice,
                props: (route) => ({
                    type: route.query.type,
                    deviceType: route.query.deviceType
                })
            },
            {
                path: distributorSubRouter.preLoginIns,
                name: `${distributorBaseRouter}_${distributorSubRouter.preLoginIns}`,
                component: preLoginIns
            }
        ]
    }
];

export default routes;
export {
    distributorBaseRouter,
    distributorSubRouter
};
