import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "form",
    "label-position": _ctx.labelPosition,
    rules: _ctx.rules,
    "label-width": _ctx.labelWidth,
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["label-position", "rules", "label-width"]))
}