
import { defineComponent, PropType, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

export default defineComponent({
    props: {
        // 上传的文件字段名
        name: {
            type: String,
            default: 'image'
        },
        baseUrl: {
            type: String,
            default: `https://${window.location.host}`
        },
        // 接口URL
        url: {
            type: String,
            default: ''
        },
        // 设置上传的请求头部
        headers: {
            type: Object as PropType<Headers>,
            default: () => ({
                'x-auth-token': `${localStorage.getItem('token')}`,
                'x-cloud-version': process.env.VUE_APP_VERSION
            })
        },
        accept: {
            type: String,
            default: '.png,.jpg,.gif,jpeg'
        },
        // 上传时附带的额外参数
        extraParam: {
            type: Object,
            default: () => ({})
        },
        // 是否禁用
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否显示已上传文件列表
        showFileList: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit, expose }) {
        // 上传成功回调
        const uploadRef = ref();
        const onuploadSuccess: any = (response: any, file: any, fileList: any) => {
            if (response.code === 1 || response.code === 2) {
                ElMessageBox.alert(
                    response.msg,
                    WordList.TabelMessageBoxAlertRequireFailed,
                    {
                        type: 'error',
                        confirmButtonText: WordList.TabelFootSure
                    }
                );
                emit('uploadFailed', response, file, fileList);
                uploadRef.value.clearFiles();
                return;
            }
            ElMessage.success(response.msg);
            emit('uploadSuccess', response, file, fileList);
        };
        expose({ uploadRef });
        return {
            props,
            uploadRef,
            onuploadSuccess
        };
    }
});

