import { ElMessageBox, ElMessage, ElNotification } from 'element-plus';

type messageType = 'success' | 'warning' | 'info' | 'error' | undefined;
type messageBoxType = 'alert' | 'confirm' | 'prompt';
type method = () => void;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultError = () => {};

class Notice {
    // message表示通知的正文，type定义不同的状态，success, warning, info, error，showClose表示能否被人工关闭
    message(
        message: string,
        type: messageType = 'success',
        showClose = true,
        options = {}
    ) {
        const messageOption = {
            message,
            type,
            showClose,
            ...options
        };
        ElMessage(messageOption);
    }

    notification(
        message: string,
        title: string,
        type: messageType = 'success',
        options = {}
    ) {
        const notificationOption = {
            message,
            type,
            title,
            ...options
        };
        ElNotification(notificationOption);
    }

    messageBox(
        method: messageBoxType,
        message: string,
        title: string,
        type: messageType,
        confirmButtonText = WordList.TabelFootSure,
        cancelButtonText = WordList.TabelFootCancel,
        options = {
            closeOnClickModal: false
        }
    ) {
        const messageBoxOption = {
            confirmButtonText,
            cancelButtonText,
            type,
            ...options
        };
        return (successCall: method, errorCall = defaultError) => {
            ElMessageBox[method](
                message,
                title,
                messageBoxOption
            ).then(successCall).catch(errorCall);
        };
    }
}

export default new Notice();
