import {
    render, h, defineAsyncComponent, isRef
} from 'vue';
import type { App } from 'vue';
/**
 * dialog render渲染，js形式调用
 * @date 2022-06-06
 * @param {string} componentPath:组件路径
 * @param {props?:{[index:string]:any},setupState?:{[index:string]:any}} data?:props给dialog组件的props;setupState：要改变setup里面的数据。比如dialogFormVisible让弹窗显示
 * @returns {void}
 */
import { toRaw, unref } from 'vue';

export default {
    install: (app: App) => {
        // @ts-ignore
        dialog.appContext = app._context;
    }
};

export async function dialog(componentPath: string, data?: {props?: {[index: string]: any}; exposed?: {[index: string]: any}}) {
    const container: HTMLDivElement = document.createElement('div');
    const asyncComp = defineAsyncComponent(() => import(`@/${componentPath}.vue`));
    const props = data?.props ? data?.props : {};
    const { onVanish, ...other } = props;
    const vnode = h(await asyncComp.__asyncLoader(), {
        ...other,
        onVanish: () => {
            if (onVanish) {
                onVanish();
            }
            render(null, container);
        }
    });
    // @ts-ignore
    vnode.key = new Date().getTime();
    // @ts-ignore
    vnode.appContext = dialog.appContext;

    render(vnode, container);
    document.body.appendChild(container.firstElementChild!);
    const instance = vnode.component!;
    if (data && data.exposed) {
        if (!instance.exposed) {
            console.log('error:弹窗组件未设置defineExpose');
            return;
        }
        const exposedInner = instance.exposed!;
        const exposedOut = data.exposed;
        Object.keys(exposedOut).forEach((key) => {
            if (exposedInner[key] !== void 0) {
                const exposedInnerItemOrigin = unref(toRaw(exposedInner[key]));
                const type = Object.prototype.toString.call(exposedInnerItemOrigin).replace(/^\[object (\S+)\]$/, '$1');
                if (type === 'Object') {
                    Object.keys(exposedInner[key]).forEach((objKey: string) => {
                        exposedInner[key][objKey] = exposedOut[key][objKey];
                    });
                } else if (type === 'Array') {
                    exposedOut[key].forEach((arrItem: any, index: string) => {
                        exposedInner[key][index] = arrItem;
                    });
                } else if (isRef(exposedInner[key])) {
                    exposedInner[key].value = exposedOut[key];
                } else {
                    exposedInner[key] = exposedOut[key];
                }
            }
        });
    }
}
