
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'SvgIcon',
    props: {
        prefix: {
            type: String,
            default: 'icon'
        },
        name: {
            type: String,
            required: true,
            default: 'icon-user'
        },
        color: {
            // 可传16进制的颜色和utils class
            type: String,
            default: ''
        },
        fontSize: {
            type: String,
            default: '16px'
        },
        title: {
            type: String
        }
    },
    setup(props) {
        const symbolId = computed(() => `#${props.name}`);
        return { symbolId };
    }
});
