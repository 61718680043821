import { RoomPaymentListForm } from '@/components/view/pm/payment-records-dialog/types/records.d';
import GetRequest from '../basic';

const getApartmentInfoFromID = (params: { ID: string}, callback: Function) => {
    GetRequest('v3/web/community/user/getRoomInfoByNewPm', params, callback);
};

// pm获取房间物业费订单列表
const getRoomPaymentList = (params: RoomPaymentListForm, callback: Function) => {
    GetRequest('v3/web/community/communalFee/getRoomPaymentList', params, callback);
};

export default null;

export {
    getApartmentInfoFromID,
    getRoomPaymentList
};