
import {
    defineComponent, ref, Ref, watch
} from 'vue';
import { getCustomizeForm } from '@/methods/date';
import { community } from '@/data';

export default defineComponent({
    name: 'sec-date-picker',
    props: {
        modelValue: {},
        type: {
            default: 'datetime'
        }
    },
    setup(props, { emit }) {
        const timeFormat: Ref<string> = ref('YYYY-MM-DD hh:mm:ss a');
        watch(community.customizeForm, (value) => {
            timeFormat.value = getCustomizeForm(value);
        }, {
            immediate: true
        });
        const update = (value: Date|null|undefined) => {
            // 防止列表搜索后清空时间框获取null值，时间框显示Invaild Date导致搜索失败
            emit('update:modelValue', value === null || value === undefined ? '' : value);
        };
        return {
            timeFormat,
            update
        };
    }
});
