import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    ref: "uploadRef",
    action: `${_ctx.baseUrl}${_ctx.props.url}`,
    name: _ctx.props.name,
    headers: _ctx.props.headers,
    data: _ctx.extraParam,
    accept: _ctx.accept,
    disabled: _ctx.disabled,
    "on-success": _ctx.onuploadSuccess,
    "show-file-list": _ctx.showFileList
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["action", "name", "headers", "data", "accept", "disabled", "on-success", "show-file-list"]))
}