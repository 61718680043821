
import {
    defineComponent, PropType, ref
} from 'vue';

export default defineComponent({
    props: {
        labelPosition: {
            type: String as PropType<'left'|'right'|'top'>,
            default: 'top'
        },
        rules: {
            type: Object,
            default: () => ({})
        },
        labelWidth: {
            type: [String, Number],
            default: 'auto'
        }
    },
    setup(props, { expose }) {
        const form = ref();

        // 兼容外层语法
        const validate = (fn: (valid: boolean) => void) => {
            form.value.validate(fn);
        };

        expose({
            form,
            validate
        });
        return {
            form
        };
    }
});

