import { ElLoading } from 'element-plus';
import { nextTick } from 'vue';

let loadingInstance: any;
let needLoadingRequestCount = 0;

const debounce = (fn: Function, delay: number) => {
    let timer = 0;
    return function () {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(fn, delay);
    };
};

const showLoading = () => {
    if (needLoadingRequestCount === 0 && !loadingInstance) {
        loadingInstance = ElLoading.service();
    }
    needLoadingRequestCount += 1;
};

const closeLoading = () => {
    nextTick(() => {
        needLoadingRequestCount -= 1;
        if (needLoadingRequestCount === 0) {
            if (loadingInstance) {
                loadingInstance.close();
                loadingInstance = null;
            }
        }
    });
};

export {
    showLoading,
    closeLoading
};