import HttpRequest from '@/util/axios.config';
import { DeviceConfigType, PersonalDeviceType } from '@/components/view/common/dialog/autop/all.type';
import GetRequest from '../basic';
import { ProjectType } from './community';

const getUserInfoDevice = (id: string, callBack: Function) => {
    GetRequest('getaccountdev', { ID: id }, callBack);
};

const getUserDoor = (id: string, callBack: Function) => {
    GetRequest(`v3/web/${ProjectType.value}/device/getUserAccessDevices`, { ID: id }, callBack);
};

const getPMDeviceInfo = (id: string, callBack: Function) => {
    GetRequest(`v3/web/${ProjectType.value}/device/getInfo`, { ID: id }, callBack);
};

const setDeviceConfig = (type: 'single' | 'community', params: DeviceConfigType, callBack: Function) => {
    const url = {
        single: 'v3/web/single/device/setDeviceConfig',
        community: 'v3/web/common/device/setDeviceConfig'
    };
    HttpRequest.post(url[type], params, callBack);
};

// const getPersonalDevices = (params: PersonalDeviceType, callBack: Function) => {
//     HttpRequest.get('v3/getPersonalDevices', params, callBack);
// };

const singleDeviceType = ['0', '1', '2', '50'];

// 社区
const statusOn = '1';
const statusOff = '0';
const multiTenantPhone = '0';
const indoor = '2';
const managementPhone = '3';
const allBuild = 1;
const communityShare = '1';
const unitShare = '2';
const appShowEntry = '1';

const publicDevice = 1;

export default null;
export {
    getUserInfoDevice,
    getUserDoor,
    getPMDeviceInfo,
    setDeviceConfig,
    // getPersonalDevices,
    singleDeviceType,
    statusOn,
    statusOff,
    indoor,
    allBuild,
    unitShare,
    communityShare,
    appShowEntry,
    multiTenantPhone,
    managementPhone,
    publicDevice
};
