<template>
    <el-input v-bind="$attrs">
        <template v-if="$slots.prefix"  #prefix>
            <slot  name="prefix"></slot>
        </template>
        <template v-if="$slots.prepend"  #prepend>
            <slot  name="prepend"></slot>
        </template>

        <template  v-if="$slots.suffix" #suffix>
            <slot name="suffix"></slot>
        </template>
    </el-input>
</template>
