import HttpRequest from '@/util/axios.config';
import GetRequest from '../basic';

const PayerRole = [WordList.RoleEndUser, WordList.TabelUserInHtmlRoleManage, WordList.ProperAllTextInstaller, WordList.ProperAllTextRoleDistributor];

const getInfo = (id: string, callBack: Function) => {
    GetRequest('v3/web/common/order/getInfo', { ID: id }, callBack);
};

const getInfoProperty = (id: string, callBack: Function) => {
    GetRequest('v3/web/community/order/getInfo', { ID: id }, callBack);
};

const getInfoInstaller = (id: string, callBack: Function) => {
    GetRequest('v3/web/community/order/getListForPm', { ID: id }, callBack);
};
const getInfoOrderCommon = (id: string, callBack: Function) => {
    GetRequest('v3/web/common/order/getInfo', { ID: id }, callBack);
};

const getInfoCommunity = (id: string, callBack: Function) => {
    GetRequest('v3/web/community/order/getInfo', { ID: id }, callBack);
};

const getInfoOffice = (id: string, callBack: Function) => {
    GetRequest('v3/web/office/order/getInfo', { ID: id }, callBack);
};

const getInfoSingleOrder = (id: string, callBack: Function) => {
    GetRequest('v3/web/single/order/getInfo', { ID: id }, callBack);
};

const getShowFeaturePlan = (params: object, callback: Function) => {
    HttpRequest.get('v3/web/community/featurePlan/getShowFeaturePlan', params, callback);
};

const getFeaturePlanFee = (params: object, callback: Function) => {
    HttpRequest.get('v3/web/community/featurePlan/getFeaturePlanFee', params, callback);
};

const getCouponInfo = (callback: Function) => {
    HttpRequest.get('v3/web/community/order/getCouponInfo', {
        Account: sessionStorage.getItem('account') ? sessionStorage.getItem('account') : localStorage.getItem('account')
    }, callback);
};

export default null;
export {
    getInfo,
    getInfoProperty,
    getInfoInstaller,
    getShowFeaturePlan,
    getFeaturePlanFee,
    getCouponInfo,
    PayerRole,
    getInfoSingleOrder,
    getInfoCommunity,
    getInfoOffice,
    getInfoOrderCommon
};
