import HttpRequest from '@/util/axios.config';
import { ref } from 'vue';

const deleteOfficeItem = (ID: string, callback: Function) => {
    HttpRequest.post('v3/web/office/project/deleteOfficeItem', { ID }, callback);
};

const getOfficeUserInfo = (ID: string, callback: Function) => {
    HttpRequest.get('v3/web/office/user/getOfficeUserInfo', { ID }, callback);
};

const getOwnerListDeviceOffice = (ID: string, callback: Function) => {
    HttpRequest.get('v3/web/office/device/getOwnerList', { ID }, callback);
};

const getDepartmentPeople = (callback: Function) => {
    HttpRequest.get('v3/web/office/project/getOfficeDepartmentPeople', {}, callback);
};

const getOwnerDeviceList = (params: object, callback: Function) => {
    HttpRequest.get('v3/web/office/device/getOwnerList', params, callback);
};

const getOfficeDepartment = (callback: Function) => {
    HttpRequest.get('v3/web/office/department/getAll', {}, callback);
};

// 保存当前办公UUID
const officeId = ref('');

export default null;
export {
    deleteOfficeItem,
    getOfficeUserInfo,
    officeId,
    getDepartmentPeople,
    getOwnerDeviceList,
    getOwnerListDeviceOffice,
    getOfficeDepartment
};
