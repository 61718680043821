import HttpRequest from '@/util/axios.config';
import { ref } from 'vue';
import GetRequest from '../basic';

const getRequestDisIncludeInstaller = (callback: Function) => {
    GetRequest('v3/web/common/manage/getAllDisAndIns', {}, callback);
};

const getPropertyBillingInfo = (callback: Function) => {
    GetRequest('v3/web/common/billingInfo/getPM', {}, callback);
};

const getDistributorBillingInfo = (callback: Function) => {
    GetRequest('v3/web/common/manage/getBillingInfo', {}, callback);
};

const getPropertyCommunityList = (callback: Function) => {
    GetRequest('getpallcommunity', {}, callback);
};

const getEndUserInfo = (id: string, callback: Function) => {
    GetRequest('getpersonaluserinfo', { ID: id }, callback);
};

const getAllInstallerCommunity = (callback: Function) => {
    GetRequest('v3/web/common/manage/getAllInstaller', {}, callback);
};

const getSuperFirmwareDialogTableData = (account: string, callback: Function) => {
    GetRequest('v3/web/common/distributor/getAll', account === '' ? {} : {
        Key: account
    }, callback);
};

const getAllDisInstaller = (callback: Function) => {
    GetRequest('v3/web/common/manage/getAllDisAndIns', {}, callback);
};

const getUserInfoForPM = (id: string, callback: Function) => {
    GetRequest('v3/web/community/user/getInfoForNewPm', { ID: id }, callback);
};

const getCustomerServerForInstaller = (callback: Function) => {
    GetRequest('v3/web/common/customerServer/getInfo', {}, callback);
};

const getInstallerBillingInfo = (callback: Function) => {
    GetRequest('v3/web/common/manage/getBillingInfo', {}, callback);
};

const getPersonalUserInfo = (id: string, callback: Function) => {
    GetRequest('v3/web/single/user/getUserInfo', { ID: id }, callback);
};

const getBillInfo = (url: string, id: Array<string>, count: number, manageId = '', callback: Function) => {
    GetRequest(url, { ID: id, Count: count, Manage: manageId }, callback);
};

const getSingleMonitor = (callback: Function) => {
    HttpRequest.get('v3/web/community/user/getDisIndoorMonitor', {
        Account: sessionStorage.getItem('account') ? sessionStorage.getItem('account') : localStorage.getItem('account')
    }, callback);
};

const getFeatureNextExpireTime = (count: number, communityId = '', callback: Function) => {
    const params = communityId === '' ? {
        Count: count
    } : {
        Count: count,
        CommunityId: communityId
    };
    HttpRequest.get('v3/web/community/featurePlan/getFeaturePlanNextExpireTime', params, callback);
};

const changePCIdentity = (account: string | null, callback: Function) => {
    HttpRequest.post('v3/web/common/manage/changeProjectIdentity', {
        Account: account
    }, [callback, false]);
};

export default null;

const superManagerRole = 1;
const distributorRole = 11;
const installerRole = 22;
const communityRole = 21;
const officeRole = 23;
const propertyRole = 31;

const personnelRole = 30;
const staffRole = 31;

const insIsDisChange = ref('');

export {
    getRequestDisIncludeInstaller,
    getPropertyBillingInfo,
    getPropertyCommunityList,
    getEndUserInfo,
    getAllInstallerCommunity,
    getSuperFirmwareDialogTableData,
    getAllDisInstaller,
    getUserInfoForPM,
    getCustomerServerForInstaller,
    getInstallerBillingInfo,
    getPersonalUserInfo,
    getBillInfo,
    getDistributorBillingInfo,
    getSingleMonitor,
    getFeatureNextExpireTime,
    superManagerRole,
    distributorRole,
    installerRole,
    communityRole,
    propertyRole,
    officeRole,
    personnelRole,
    staffRole,
    changePCIdentity,
    insIsDisChange
};
