import { Ref, ref, computed } from 'vue';
import HttpRequest from '@/util/axios.config';
import GetRequest from '../basic';
import { sysConfig } from './system-config';

const customizeForm = ref('3');
const IsEncryptPin = ref('0');
const IsEnableLandline = ref(0);
const enableCommunitySmartHome = ref(0);
const IsEnableSmartHome = computed(() => !!(sysConfig.EnableSmartHome && enableCommunitySmartHome.value));
const ChargeMode = ref(0);
const PayByDis = '1';
const isRefreshProjectList = ref(0);
// TODO 不能复用/installerIndex/communityhome组件监听isRefreshProjectList不会被释放多次重复执行回调
const isFirstRefresh = ref(true);
// 是否完成PM信息的获取
const isGetPmInfoComplete = ref(false);

const smartHomeUUID: Ref<false | string> = ref(false);
// pm下区分办公、社区
const ProjectType: Ref< ProjectType > = ref('community');
// 判断创建qrcode权限
const LimitQRCode = ref('0');

// 判断新旧社区
const isNew = ref(false);

// PM判断是否有第三方设备
const hasThirdPartyDevice = ref(false);
// pm判断是否有中控设备
const hasControlDevice = ref(false);

// 判断是否开启户户通
const isShowComCalls = ref(false);

// ins 判断社区是否有第三方设备功能
const thirdPartyPlan = ref(false);

// pm判断是否有操作个人设备的权限
const hasHaveAccessArea = ref(false);

// 判断pm app开关状态
const statusOff = '0';

// 告警通知未读个数
const alertCount = ref(0);

// 允许pm删除账号
const enableDeleteAccount = ref(true);

// 允许pm查看日志
const enableShowLog = ref(false);

// 是否开启物业费
const isOpenCommunalFee = ref(false);

// 社区默认物业费
const defaultCommunalFee = ref(0);

// 物业费支付截至日期
const communalDueDate = ref(1);

const communityInfoRequested = ref(false);

const getCommunityBr = (callback: Function, communityId?: string) => {
    const param = communityId === '' ? {} : {
        CommunityID: communityId
    };
    GetRequest('v3/web/common/project/getBuildRoom', param, callback);
};

const getPMCommunityDetail = (callback: Function) => {
    const url = localStorage.getItem('Grade') === '23' ? 'v3/web/office/officeData/getDetailForPM' : 'v3/web/community/communityData/getDetailForPM';
    GetRequest(url, {}, callback);
};

const getPropertyCommunityChargeMode = (callback: Function) => {
    HttpRequest.get('v3/web/common/manage/getChargeMode', {}, callback);
};

const getCommunityDetail = (callback: Function) => {
    HttpRequest.get('getcominfo', {}, callback);
};

const getCommunityBuild = (callback: Function) => {
    HttpRequest.get('v3/web/community/build/getAll', {}, callback);
};

const getAllAptLayout = (callback: Function) => {
    GetRequest('v3/getAllCommunityLayouts', {}, callback);
};
const getComLists = (callback: Function) => {
    HttpRequest.get('v3/web/community/project/getAll', {}, callback);
};
const isAutoRenew = ref(false);
export default null;

export {
    getCommunityBr,
    getPMCommunityDetail,
    IsEncryptPin,
    enableCommunitySmartHome,
    IsEnableLandline,
    IsEnableSmartHome,
    customizeForm,
    getPropertyCommunityChargeMode,
    ChargeMode,
    getCommunityDetail,
    getCommunityBuild,
    PayByDis,
    getComLists,
    ProjectType,
    LimitQRCode,
    getAllAptLayout,
    smartHomeUUID,
    isRefreshProjectList,
    isFirstRefresh,
    isNew,
    statusOff,
    alertCount,
    hasThirdPartyDevice,
    isShowComCalls,
    thirdPartyPlan,
    hasControlDevice,
    hasHaveAccessArea,
    isGetPmInfoComplete,
    enableDeleteAccount,
    enableShowLog,
    communityInfoRequested,
    isAutoRenew,
    isOpenCommunalFee,
    defaultCommunalFee,
    communalDueDate
};
