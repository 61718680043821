/**
 * @name 检查delivery的pin规则
 */
const checkDeliveryPIN: RuleMethod = (rule, val, callBack) => {
    const value = val as string;
    const regStart9 = /^9\S{7}$/;
    // 6.7.0 pin必须满足2~8位数字
    const regLength2to8 = /^\d{2,8}$/;
    if (!regLength2to8.test(value)) {
        return callBack(new Error(WordList.RuleCommonRang.format('2', '8')));
    }
    if (regStart9.test(value)) {
        return callBack(new Error(WordList.ProperAllTextPmDeliveryKeyRule));
    }
    return callBack();
};

const checkOldCommunityDeliveryPIN = (encrypt: boolean, operation: 'add'|'edit'): RuleMethod => (rule, val, callback) => {
    const value = val as string;
    const regForEncrypt = /^\+?[*]{4}$/;

    if (encrypt && operation === 'edit' && regForEncrypt.test(value)) {
        return callback();
    }
    return checkDeliveryPIN(rule, value, callback);
};

/**
 * @name 检查开门密码pin有效性
 */
const checkPin = (encrypt: boolean, type: 'delivery'|'resident', operation?: 'add'|'edit'): RuleMethod => (rule, val, callback) => {
    const value = val as string;
    if (value === '') {
        return callback();
    }
    const regForNumber = /^\+?[0-9]*$/;
    const regForEncrypt = /^\+?[*]{4}$/;

    // delivery
    if (type === 'delivery') {
        // 在开启加密的情况下编辑，才可输入****不报错（****表示不做更改）
        if (encrypt && operation === 'edit' && regForEncrypt.test(value)) {
            return callback();
        }
        return checkDeliveryPIN(rule, value, callback);
    }

    if (encrypt && !regForNumber.test(value) && !regForEncrypt.test(value)) {
        return callback(new Error(WordList.RuleCommonNumber));
    }
    if (!encrypt && !regForNumber.test(value)) {
        return callback(new Error(WordList.RuleCommonNumber));
    }
    if ((value).length > 8 || (value).length < 2) {
        return callback(
            new Error(WordList.RuleCommonRang.format('2', '8'))
        );
    }
    return callback();
};
const checkAllTypeOfPin = (encrypt: boolean, operation?: 'add'|'edit'): RuleMethod => (rule, val, callback) => {
    const value = val as string;
    if (value === '') {
        return callback();
    }

    // 在开启加密的情况下编辑，输入****不报错（****表示不做更改）
    const regForEncrypt = /^[*]{4}$/;
    if (encrypt && operation === 'edit' && regForEncrypt.test(value)) {
        return callback();
    }

    // 6.7.0 pin必须满足2~8位数字
    const regLength2to8 = /^\d{2,8}$/;
    if (!regLength2to8.test(value)) {
        return callback(new Error(WordList.PinFormatTip));
    }

    // 以9开头的8位数字会被设备认为是Tempkey
    const regStart9 = /^9\S{7}$/;
    if (regStart9.test(value)) {
        return callback(new Error(WordList.ProperAllTextPmDeliveryKeyRule));
    }

    return callback();
};
/**
 * @name 检查门禁卡rfcard有效性
 */
const checkCard: RuleMethod = (rule, value, callback) => {
    if (value === '') {
        return callback();
    }
    const reg = /^\+?[0-9A-F]*$/;
    if (!reg.test(value as string) || (value as string).length > 16) {
        return callback(new Error(WordList.RFCardInvalid));
    }
    return callback();
};

const checkAllowedTimes: RuleMethod = (rule, val, callback) => {
    const intReg = /^[0-9]*$/;
    if(intReg.test(val.toString()) === false) {
        return new Error(WordList.TempKeyCountFormatTip);
    }

    const value = Number(val);
    if (value < 1 || value > 65535) {
        return new Error(WordList.TempKeyCountFormatTip);
    }
    return true;
};

const checkIDNumber: RuleMethod = (rule, value, callback) => {
    if (value === '') {
        return callback();
    }

    const regex = /^[a-zA-Z0-9]*$/;

    if ((value as string).length > 32) {
        return callback(new Error(WordList.CharactersLengthLimitMsg.format(WordList.ProperAllTextIdNumber, '32')));
    }
    if (!regex.test(value as string)) {
        return callback(new Error(WordList.ContainDigitsAndLettersMsg.format(WordList.ProperAllTextIdNumber, '32')));
    }

    return callback();
};

export default null;
export {
    checkDeliveryPIN,
    checkOldCommunityDeliveryPIN,
    checkPin,
    checkCard,
    checkAllowedTimes,
    checkIDNumber,
    checkAllTypeOfPin
};
